import gql from 'graphql-tag';

export const ADD_COMMENT = gql`
  mutation AddComment($id: Int!, $comment: String!) {
    addThreadComment(threadId: $id, comment: $comment) {
      errors {
        errors
        fieldName
      }
      statusCode
    }
  }
`;
