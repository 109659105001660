export const PERSONAS = [
  {
    label: 'Parent',
    value: 'PARENT',
  },
  {
    label: 'Child',
    value: 'CHILD',
  },
  {
    label: 'Student',
    value: 'STUDENT',
  },
  {
    label: 'Teacher',
    value: 'TEACHER',
  },
];

export const PERSONAS_NAMES = {
  PARENT: 'parent',
  CHILD: 'child',
  STUDENT: 'student',
  TEACHER: 'teacher',
};

export const JOURNEY_STATUS = [
  {
    label: 'Published',
    value: 'published',
  },
  {
    label: 'Unpublish',
    value: 'unpublished',
  },
];

export const LIVE_SESSION_STATUS = [
  {
    label: 'Published',
    value: 'published',
  },
  {
    label: 'Unpublish',
    value: 'unpublished',
  },
];

export const COURSE_STATUS = [
  {
    label: 'Published',
    value: 'published',
  },
  {
    label: 'Unpublish',
    value: 'unpublished',
  },
  {
    label: 'Pending Review',
    value: 'pending_review',
  },
];

export const GRADES = [
  { key: 'grade_1', label: 'الصف الأول الابتدائي', value: '1' },
  { key: 'grade_2', label: 'الصف الثاني الابتدائي', value: '2' },
  { key: 'grade_3', label: 'الصف الثالث الابتدائي', value: '3' },
  { key: 'grade_4', label: 'الصف الرابع الابتدائي', value: '4' },
  { key: 'grade_5', label: 'الصف الخامس الابتدائي', value: '5' },
  { key: 'grade_6', label: 'الصف السادس الابتدائي', value: '6' },
  { key: 'grade_7', label: 'الصف الأول الاعدادي', value: '7' },
  { key: 'grade_8', label: 'الصف الثاني الاعدادي', value: '8' },
  { key: 'grade_9', label: 'الصف الثالث الاعدادي', value: '9' },
  { key: 'grade_10', label: 'الصف الأول الثانوي', value: '10' },
  { key: 'grade_11', label: 'الصف الثاني الثانوي', value: '11' },
  { key: 'grade_12', label: 'الصف الثالث الثانوي', value: '12' },
];

export const AGES = [
  {
    key: 'age_1',
    label: 'Less than 25 years',
    value: 'أقل من 25 سنة',
  },
  {
    key: 'age_2',
    label: 'From 25 to 35 years',
    value: 'من 25 الي 35 سنة',
  },
  {
    key: 'age_3',
    label: 'From 35 to 45 years',
    value: 'من 35 الي 45 سنة',
  },
  {
    key: 'age_4',
    label: 'From 45 to 55 years',
    value: 'من 45 الي 55 سنة',
  },
  {
    key: 'age_5',
    label: 'More than 55 years',
    value: 'أكثر من 55 سنة',
  },
];

export const GENDERS = [
  {
    label: 'Male',
    value: 'MALE',
  },
  {
    label: 'Female',
    value: 'FEMALE',
  },
];

export const NOTIFICATIONS_PLATFORMS = [
  {
    label: 'SMS',
    value: 'SMS',
  },
  {
    label: 'In App',
    value: 'IN_APP',
  },
];

export const COMMENT_STATUS = [
  {
    label: 'Deleted',
    value: 'deleted',
  },
  {
    label: 'Normal',
    value: 'normal',
  },
  {
    label: 'Flagged',
    value: 'flagged',
  },
  {
    label: 'Replied',
    value: 'replied',
  },
];

export const POINTS_BASED_COMPETITION_TYPE = 'POINT_BASED';
export const QUESTIONS_BASED_COMPETITION_TYPE = 'courses';

export const UNIT_TYPE_OPTIONS = [
  {
    label: 'HTML',
    value: 'html',
    defaultChecked: true,
  },
  {
    label: 'Video',
    value: 'video',
  },
  {
    label: 'Workbook',
    value: 'workbook',
  },
  {
    label: 'Graded Workbook',
    value: 'graded_workbook',
  },
  {
    label: 'Scorm Unit',
    value: 'scorm',
  },
];

export const COMPETITION_TYPES = [
  {
    label: 'Points Based',
    value: POINTS_BASED_COMPETITION_TYPE,
  },
  {
    label: 'Questions Based',
    value: QUESTIONS_BASED_COMPETITION_TYPE,
  },
];

export const GRADED_WORKBOOK_QUESTIONS_TYPES = [
  {
    label: 'Workbook Exercise MCQ',
    value: 'mcq',
  },
  {
    label: 'Workbook Exercise T/F',
    value: 'true_false',
  },
  {
    label: 'Workbook Exercise Multi Part',
    value: 'multi',
  },
];

export const GRADED_MULTIPART_QUESTIONS_TYPES = [
  {
    label: 'Workbook Exercise MCQ',
    value: 'mcq',
  },
  {
    label: 'Workbook Exercise T/F',
    value: 'true_false',
  },
];

export const NOT_GRADED_WORKBOOK_QUESTIONS_TYPES = [
  {
    label: 'Workbook Exercise MCQ',
    value: 'mcq',
  },
  {
    label: 'Workbook Exercise T/F',
    value: 'true_false',
  },
  {
    label: 'Workbook Exercise Essay',
    value: 'essay',
  },
  {
    label: 'Workbook Exercise Complete',
    value: 'complete',
  },
  {
    label: 'Workbook Exercise Correct Underlined Word',
    value: 'underline',
  },
  {
    label: 'Workbook Exercise Multi Part',
    value: 'multi',
  },
];

export const NOT_GRADED_MULTIPART_QUESTIONS_TYPES = [
  {
    label: 'Workbook Exercise MCQ',
    value: 'mcq',
  },
  {
    label: 'Workbook Exercise T/F',
    value: 'true_false',
  },
  {
    label: 'Workbook Exercise Essay',
    value: 'essay',
  },
  {
    label: 'Workbook Exercise Complete',
    value: 'complete',
  },
  {
    label: 'Workbook Exercise Correct Underlined Word',
    value: 'underline',
  },
];

export const THREAD_RELATED_TYPES = [
  {
    label: 'Journey',
    value: 'journey',
  },
  {
    label: 'Course',
    value: 'course',
  },
  {
    label: 'Module',
    value: 'module',
  },
  {
    label: 'Lesson',
    value: 'lesson',
  },
];

export const ASSESSMENT_FORM_STEPS = {
  NEW_COURSE_FORM: 0,
  COURSE_CONTENT: 1,
  PRE_ASSESSMENT: 2,
  POST_ASSESSMENT: 3,
  GRADED_ASSESSMENT: 4,
};

export const ADD_NEW_COURSE_STEPS = {
  NEW_COURSE_FORM: 0,
  COURSE_OUTLINE: 1,
  COURSE_CONTENT: 2,
  COURSE_PREVIEW: 3,
};

export const ASSESSMENT_TYPES = {
  PRE_ASSESSMENT_TYPE: 'pre_assessment',
  POST_ASSESSMENT_TYPE: 'post_assessment',
  GRADED_ASSESSMENT_TYPE: 'assessment',
};

export const PAGINATION_PAGE_SIZE = 10;
export const PAGINATION_PAGE_SIZE_LARGE = 20;

export const VIEW_PER_PAGE = [5, 8, 10, 12, 20];

export const ADMIN_ENUMS_ROLES_QUERY = {
  admin: 'admin',
  providerAdmin: 'provider_admin',
};

export const ADMIN_ENUMS_ROLES_MUTATION = {
  admin: 'ADMIN',
  providerAdmin: 'PROVIDER_ADMIN',
};
export const SUPPORTED_FORMATS = ['image/jpeg', 'image/png', 'image/jpg'];
