/**
 * @Author: sarahnouh
 * @Date:   2020-01-20T13:41:41+02:00
 * @Last modified by:   sarahnouh
 * @Last modified time: 2020-01-20T13:45:59+02:00
 */
import React from 'react';
import { Redirect } from 'react-router-dom';
import { DynamicLoading } from '@components';
import { AuthConsumer, Can } from '@auth';
import { ACTIONS } from '../../rbac_rules';

/** exported dynamic import for change password scene */
const ChangePassword = props => {
  return (
    <AuthConsumer>
      {({ role }) => (
        <Can
          role={role}
          perform={ACTIONS.CHANGE_PASSWORD}
          yes={() => (
            <DynamicLoading
              componentImport={() => import('./change_password_wrapper')}
              data={{ ...props }}
            />
          )}
          no={() => {
            return <Redirect to="/" />;
          }}
        />
      )}
    </AuthConsumer>
  );
};

export default ChangePassword;
