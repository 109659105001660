import gql from 'graphql-tag';

export const UpdateCourseUnitMutation = gql`
  mutation updateCourseUnit($content: UpdateUnitInput!) {
    updateCourseUnit(content: $content) {
      statusCode
      message
      errors {
        field_name
      }
    }
  }
`;
