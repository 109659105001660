/**
 * returns object of question initial values
 *
 * @param {values:
 *     {
 *      questionType: string,
 *      body: string,
 *      mcq_answers: Array,
 *      answers: Array,
 *      rightAnswerIndex: number,
 *      rightExplanation: string,
 *      wrongExplanation: string
 *    } or {}} values
 */
export const questionInitialValues = values => {
  const {
    questionType,
    body,
    mcq_answers,
    rightAnswerIndex,
    rightExplanation,
    wrongExplanation,
  } = values;

  return {
    questionType: questionType || '',
    body: body || '',
    mcq_answers: mcq_answers || [
      {
        body: '',
      },
    ],
    true_false_answers: [{ body: 'true' }, { body: 'false' }],
    rightAnswerIndex: rightAnswerIndex || null,
    rightExplanation: rightExplanation || '',
    wrongExplanation: wrongExplanation || '',
  };
};
