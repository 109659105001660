import gql from 'graphql-tag';

export const SET_CERTIFICATE_TEMPLATE = gql`
  mutation createCourseCertificate(
    $courseId: Int!
    $certificateId: Int!
    $certificateCourseName: String
  ) {
    createCourseCertificate(
      courseId: $courseId
      certificateId: $certificateId
      certificateCourseName: $certificateCourseName
    ) {
      errors {
        fieldName
        errors
      }
      statusCode
    }
  }
`;
