import gql from 'graphql-tag';

export const LIST_DISCUSSIONS = gql`
  query listThreads {
    listThreads {
      id
      title
      createdAt
      creatorName
      creatorType
      relatedItemName
      relatedItemType
      replies
      status
    }
  }
`;
