import gql from 'graphql-tag';

export const UpdateAutomaticNotification = gql`
  mutation UpdateNotification(
    $notificationId: Int!
    $modifiedNotification: UpdateNotificationInput!
  ) {
    updateNotification(
      notificationId: $notificationId
      modifiedNotification: $modifiedNotification
    ) {
      statusCode
      errors {
        errors
        fieldName
      }
    }
  }
`;
