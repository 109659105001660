import gql from 'graphql-tag';

export const COMPETITION = gql`
  query viewCompetition($id: ID!) {
    viewCompetition(id: $id) {
      id
      title
      type
      description
      image {
        url
      }
      roles {
        id
        name
      }
      grades {
        id
        name
      }
      age_groups {
        id
        name
      }
      gender
      start_date
      is_enrolled
      end_date
      is_active
      actions {
        id
        name
      }
    }
  }
`;
