import styled from '@emotion/styled/macro';

export const SaveOutlineButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 60px;
  align-items: center;

  button {
      margin-right: 8px;
      background: #1890ff;
      color: white;
      border: 1px solid #e0e0e0;
      border-radius: 4px;
      padding: 8px 15px;
      cursor: pointer;
    
      &:hover {
    background: white;
    color: #1890ff;
    }
  &.disabled{
     color: rgba(0, 0, 0, 0.25);
      background-color: #f5f5f5;
      border-color: #d9d9d9;
      text-shadow: none;
      box-shadow: none;
      pointer-events: none;
  }
  .cancel {
    color: #4a4a4a;
    font-family: Roboto;
    font-size: 16px;
    text-decoration: underline;
    margin-left: 24px;
  }
`;
