import styled from '@emotion/styled/macro';
import { colors } from '@utilities';

export const DButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-items: center;
  padding: 0.7rem 3.2rem;
  border-radius: 50px;
  cursor: pointer;
  text-decoration: ${props => (props.secondarySpecial ? 'underline' : 'none')};
  background-color: ${props =>
    props.outline ? 'white' : colors.primaryButtonColor};
  color: ${props => (props.outline ? colors.primaryButtonColor : 'white')};
  border: ${props => (props.secondarySpecial ? '' : '0.1rem solid')}
    ${props => (props.outline ? colors.primaryButtonColor : 'transparent')};
  text-align: center;
  justify-content: center;
  width: auto;
  outline: none;

  &:disabled,
  &.--disabled {
    background-color: ${colors.lightGray};
    pointer-events: none;
  }
  &:focus {
    outline: none;
    border: 0;
  }

  a {
    color: inherit;
    text-decoration: inherit;
    margin: 0;
  }

  svg {
    width: 0.6rem;
    margin-inline-end: 0.5rem;
    fill: ${props => (props.outline ? colors.primaryButtonColor : 'white')};
  }

  &:hover:not(:disabled) {
    ${props =>
      props.secondarySpecial
        ? ''
        : 'box-shadow: 0 0.5em 0.5em -0.4em rgba(0, 0, 0, 0.3)'};
  }

  @media (max-width: 768px) {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    font-size: 13px;
  }
`;
