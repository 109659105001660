import React, { useRef, useEffect, useState } from 'react';
import { getBarChart } from '@utilities/charts';
import Proptypes from 'prop-types';

const BarChartCard = ({ label, values }) => {
  const canvasRef = useRef();
  const [chart, setChart] = useState(undefined);

  useEffect(() => {
    if (chart) {
      chart.destroy();
    }
    const barChartLabels = values.map(val => val.name);
    const barChartValues = values.map(val => {
      return val.count;
    });
    const barChartData = [
      {
        values: barChartValues,
      },
    ];
    setChart(getBarChart(canvasRef.current, barChartLabels, barChartData));
  }, [values]);
  return (
    <div className="bar-chart-card line-chart-card">
      <h1 className="label">{label}</h1>
      <canvas ref={canvasRef} id={Date.now()} />
    </div>
  );
};

BarChartCard.propTypes = {
  label: Proptypes.string,
  values: Proptypes.arrayOf(
    Proptypes.shape({
      enrollments_count: Proptypes.number,
      name: Proptypes.string,
    })
  ),
};
export default BarChartCard;
