import React, { useState, useContext } from 'react';
import Proptypes from 'prop-types';
import ChartLegend from './chart-legend';
import DoughnutChart from './doughnut-chart';
import { getPercentage } from '@utilities';
import RoleSelcetDropdown from './roles-select-dropdown';
import { AnalyticsContext } from './analytics-context';
import { userRoles, selectedRoleOptions } from '@utilities';
import { useUserAnalytics } from '@hooks';

const CourseCompletionCard = ({ label }) => {
  const [selectedUserRole, setSelectedUserRole] = useState(userRoles.parent);
  const analyticsContext = useContext(AnalyticsContext);

  let counts = [];
  let totalCounts;

  const { analytics, error, loading } = useUserAnalytics(
    analyticsContext?.currentPeriod?.from,
    analyticsContext?.currentPeriod?.to,
    selectedUserRole
  );

  if (!loading) {
    counts = [
      {
        name: 'Completed',
        count: analytics?.enrollment_distribution?.completed,
        color: '#4880FF',
      },
      {
        name: 'Not Finished',
        count: analytics?.enrollment_distribution?.not_completed,
        color: '#FF832B',
      },
    ];

    totalCounts =
      analytics?.enrollment_distribution?.completed +
      analytics?.enrollment_distribution?.not_completed;
  }

  return (
    <div className="total-card total-performance-card">
      <h3 className="title">{label}</h3>
      <RoleSelcetDropdown
        setSelectedUserRole={setSelectedUserRole}
        options={selectedRoleOptions}
      />
      {totalCounts ? (
        <div className="content">
          <DoughnutChart counts={counts} />
          <div className="legends-container">
            {counts?.map((count, index) => {
              return (
                <ChartLegend
                  key={index}
                  label={count?.name}
                  color={count?.color}
                  value={getPercentage(count?.count, totalCounts)}
                />
              );
            })}
          </div>
        </div>
      ) : (
        <p>No data for this period.</p>
      )}
    </div>
  );
};

CourseCompletionCard.propTypes = {
  label: Proptypes.string,
};

export default CourseCompletionCard;
