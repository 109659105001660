import React from 'react';
import { Redirect } from 'react-router-dom';
import { DynamicLoading } from '@components';
import { AuthConsumer, Can } from '@auth';
import { ACTIONS } from '../../rbac_rules';

const PointsSystem = props => {
  return (
    <AuthConsumer>
      {({ role }) => (
        <Can
          role={role}
          perform={ACTIONS.SHOW_POINTS}
          yes={() => (
            <DynamicLoading
              data={props}
              componentImport={() => import('./points_wrapper')}
            />
          )}
          no={() => {
            return <Redirect to="/" />;
          }}
        />
      )}
    </AuthConsumer>
  );
};

export default PointsSystem;