import gql from 'graphql-tag';

export const QuestionsListQuery = gql`
  query listQuestions($questionableId: ID!) {
    listQuestions(questionableId: $questionableId) {
      id
      questionType
      body
      answers {
        id
        body
      }
      rightAnswer {
        id
        body
      }
      rightExplanation
      wrongExplanation
    }
  }
`;
