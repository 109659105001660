/**
 * @Author: sarahnouh
 * @Date:   2020-02-10T18:33:35+02:00
 * @Last modified by:   sarahnouh
 * @Last modified time: 2020-02-11T14:53:57+02:00
 */

import gql from 'graphql-tag';

export const GetAssessmentQuery = gql`
  query getAssessmentQuery($courseId: ID!, $assessmentType: AssessmentEnum!) {
    assessmentByCourse(courseId: $courseId, assessmentType: $assessmentType) {
      description
      id
      title
      numberOfQuestions
      passingGrade
      questions {
        id
        body
        answers {
          id
          body
        }
        rightExplanation
        wrongExplanation
        questionType
        rightAnswer {
          id
          body
        }
      }
      copiedFromPreassessment
    }
  }
`;
