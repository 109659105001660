/**
 * @author Raghda Wessam
 * @date 2021-11-22
 * @description Course Content navigator to outline to fill content
 * @filename course_outline_nav.js
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, Spin } from 'antd';
import { CourseContentNavContent } from './styles/course_content_nav';
import { COURSE_OUTLINE_TYPES } from '@utilities';
import { GetCourseQuery } from '@services';
import { client } from '@utilities';

const CourseContentNav = props => {
  const [loading, setLoading] = useState(false);
  const fetchCourse = async () => {
    const responseObj = {};
    setLoading(true);
    try {
      const response = await client.query({
        query: GetCourseQuery,
        variables: {
          id: props.courseId,
          courseModuleOnly: true,
        },
      });

      if (response.data && !response.data.getCourse?.errors) {
        responseObj.data = response.data.getCourse;
      }
    } catch (err) {
      responseObj.error = err;
    }
    setLoading(false);

    return responseObj;
  };

  return (
    <CourseContentNavContent>
      <h2 className="course-name">Course Outline</h2>
      <ul className="menu">
        {props?.preAssessment && (
          <li
            className={
              props.activeTabContent?.id === props.preAssessment?.id
                ? 'active'
                : ''
            }
            onClick={async () => {
              const { data } = await fetchCourse();
              props.setActiveTabContent(
                data?.assessments?.find(
                  _assessment => _assessment?.id === props.preAssessment?.id
                )
              );
              props.setActiveCourseOutlineType(
                COURSE_OUTLINE_TYPES.PRE_ASSESSMENT
              );
            }}
            // onClick={() => {
            //   if (props.activeTabContent?.id !== props.preAssessment?.id) {
            //     props.setActiveTabContent(props.preAssessment);
            //     props.setActiveCourseOutlineType(
            //       COURSE_OUTLINE_TYPES.PRE_ASSESSMENT
            //     );
            //   }
            // }}
          >
            Pre-assessment
            <Icon type="check-circle" theme="twoTone" twoToneColor="#4A90E2" />
          </li>
        )}
        {props?.modules?.map((module, index) => {
          return (
            <React.Fragment key={`${module.name}-${index}`}>
              <Spin spinning={loading} />

              <li
                key={`${module.name}-${index}`}
                className={props.activeModule?.id === module.id ? 'active' : ''}
              >
                {module.name}
                <Icon
                  type="check-circle"
                  theme="twoTone"
                  twoToneColor="#4A90E2"
                />
              </li>

              {module?.lessons && (
                <ul className="sub-menu">
                  {module?.lessons.map((lesson, index) => {
                    return (
                      <li
                        key={`${lesson.name}-${index}`}
                        className={
                          props.activeTabContent?.id === lesson.id
                            ? 'active'
                            : ''
                        }
                        onClick={async () => {
                          const { data } = await fetchCourse();
                          props.setActiveTabContent(
                            data?.modules
                              ?.find(_module => _module?.id === module?.id)
                              .lessons?.find(
                                _lesson => _lesson?.id === lesson?.id
                              )
                          );
                          props.setActiveCourseOutlineType(
                            COURSE_OUTLINE_TYPES.MODULE
                          );
                        }}
                      >
                        {lesson.name}
                      </li>
                    );
                  })}
                </ul>
              )}
            </React.Fragment>
          );
        })}
        {props?.postAssessment && (
          <li
            className={
              props.activeTabContent?.id === props.postAssessment.id
                ? 'active'
                : ''
            }
            onClick={async () => {
              const { data } = await fetchCourse();
              props.setActiveTabContent(
                data?.assessments?.find(
                  _assessment => _assessment?.id === props.postAssessment?.id
                )
              );
              props.setActiveCourseOutlineType(
                COURSE_OUTLINE_TYPES.POST_ASSESSMENT
              );
            }}
          >
            Post-assessment
            <Icon type="check-circle" theme="twoTone" twoToneColor="#4A90E2" />
          </li>
        )}
        {props?.gradedExam && (
          <li
            className={
              props.activeTabContent?.id === props.gradedExam.id ? 'active' : ''
            }
            onClick={async () => {
              const { data } = await fetchCourse();
              props.setActiveTabContent(
                data?.assessments?.find(
                  _assessment => _assessment?.id === props.gradedExam?.id
                )
              );
              props.setActiveCourseOutlineType(
                COURSE_OUTLINE_TYPES.GRADED_ASSESSMENT
              );
            }}
          >
            Graded Exam
            <Icon type="check-circle" theme="twoTone" twoToneColor="#4A90E2" />
          </li>
        )}
      </ul>
    </CourseContentNavContent>
  );
};

CourseContentNav.propTypes = {
  preAssessment: PropTypes.object,
  postAssessment: PropTypes.object,
  gradedExam: PropTypes.object,
  modules: PropTypes.array,
  activeModule: PropTypes.object,
  activeTabContent: PropTypes.object,
  setActiveTabContent: PropTypes.func,
  activeCourseOutlineType: PropTypes.string,
  courseId: PropTypes.string,
  setActiveCourseOutlineType: PropTypes.func,
};

export default CourseContentNav;
