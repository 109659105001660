import styled from '@emotion/styled/macro';

export const LessonWrapper = styled.div`
  position: relative;
  border: 1px solid #979797;
  border-radius: 5px;
  background-color: #f8f8f8;
  padding: 12px 30px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  label {
    margin-right: 30px;
    color: #4a4a4a;
    font-family: Roboto;
    font-size: 16px;
  }

  input {
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    min-width: 100%;
    font-weight: bold;
    min-height: 40px;
    padding: 12px;
  }

  input::placeholder {
    color: #979797;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
  }
`;
export const ErrorMsgWrapper = styled.div`
  ~ input {
    border: 1px solid red;
  }
  color: red;
  margin-top: 5px;
`;

export const InputWrapper = styled.div`
  min-width: 60%;
`;
