import gql from 'graphql-tag';

export const getProviderAnalyticsQuery = gql`
  query getProvidersAnalytics($from: Date!, $to: Date!) {
    getProvidersAnalytics {
      provider_state_distribution(from: $from, to: $to) {
        active
        inactive
      }
      enrollment_distribution(from: $from, to: $to) {
        name
        enrollments_count
        completed_enrollments_count
      }
      statusCode
      message
      errors {
        field_name
        message
      }
    }
  }
`;
