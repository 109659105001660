import gql from 'graphql-tag';

export const viewUserCoursesQuery = gql`
  query viewUser($id: ID!, $state: CourseState, $first: Int!) {
    viewUser(id: $id) {
      id
      courses(first: $first, enrollments: { state: $state, actor_id: $id }) {
        pageInfo {
          currentPage
        }
        edges {
          node {
            id
            name
            providers {
              id
              name
            }
            progress {
              id
              percentage
            }
            pivot {
              completed_at
              enrolled_at
            }
          }
        }
      }
    }
  }
`;
