import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import customStyles from './style';

export const CustomSelect = ({
  isMulti,
  options,
  form,
  field,
  className,
  placeholder,
}) => {
  const onChange = useCallback(
    option => {
      form.setFieldValue(
        field.name,
        isMulti ? option.map(item => item.value) : option.value
      );
    },
    [form, field, isMulti]
  );

  const getValue = useCallback(() => {
    if (options) {
      return isMulti
        ? options.filter(option => field.value.indexOf(option.value) >= 0)
        : options.find(option => parseInt(option.value) === field.value);
    } else {
      return isMulti ? [] : '';
    }
  }, [field?.value, isMulti, options]);

  return (
    <Select
      isMulti={isMulti}
      name={field.name}
      options={options}
      onChange={onChange}
      className={className}
      placeholder={placeholder}
      value={getValue()}
      styles={customStyles}
    />
  );
};

CustomSelect.propTypes = {
  options: PropTypes.array.isRequired,
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  onBlur: PropTypes.func,
  isMulti: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
};

CustomSelect.defaultProps = {
  isMulti: false,
  onBlur: () => {},
  className: '',
  placeholder: '',
};

export default CustomSelect;
