import gql from 'graphql-tag';

export const UploadBulkUsersMutation = gql`
  mutation adminBulkAddUsers($adminId: ID!, $image: File!) {
    adminBulkAddUsers(adminId: $adminId, image: $image) {
      statusCode
      errors {
        errors
        fieldName
      }
    }
  }
`;
