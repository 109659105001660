import gql from 'graphql-tag';

export const PENDING_REVIEW_COURSE = gql`
  mutation pendingReviewCourse($courseId: Int!) {
    pendingReviewCourse(courseId: $courseId) {
      course {
        status
      }
      errors {
        fieldName
        errors
      }
      statusCode
    }
  }
`;
