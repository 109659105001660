import { useQuery } from '@apollo/client';
import { PersonaList } from '@services';

export const usePersonasList = () => {
  const { loading, error, data } = useQuery(PersonaList, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });
  const personasList = data?.getPlatformRoles || [];

  return { personasList, loading, error };
};
