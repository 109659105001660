/**
 * @Author: abdelrhman-arnos
 * @Date:   2020-02-12T14:00:04+02:00
 */
import gql from 'graphql-tag';

export const ListThirdPartyContentQuery = gql`
  query {
    list3rdPartyCourses {
      id
      name
      callBackUrl
    }
  }
`;
