/**
 * @Author: sarahnouh
 * @Date:   2020-02-18T17:17:50+02:00
 * @Last modified by:   sarahnouh
 * @Last modified time: 2020-02-18T18:47:28+02:00
 */
import gql from 'graphql-tag';

export const CreateManualMessageMutation = gql`
  mutation sendManualNotification(
    $title: String!
    $content: String!
    $courses_ids: [ID!]
    $scheduled_at: DateTime!
    $channel_type: ChannelType!
    $criteria: NotificationCriteria!
  ) {
    sendManualNotification(
      title: $title
      content: $content
      courses_ids: $courses_ids
      scheduled_at: $scheduled_at
      channel_type: $channel_type
      criteria: $criteria
    ) {
      statusCode
      message
      errors {
        field_name
        message
      }
    }
  }
`;
