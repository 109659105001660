import gql from 'graphql-tag';

export const COURSES_LIST = gql`
  query getCourses($first: Int!, $page: Int) {
    getCourses(
      first: $first
      page: $page
      orderBy: { column: "created_at", order: DESC }
    ) {
      data {
        id
        name
        modules_count
        content_lang
        created_at
        status
        roles {
          name
          id
        }
      }

      paginatorInfo {
        count
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
        currentPage
      }
    }
  }
`;
