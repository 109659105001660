import gql from 'graphql-tag';

export const CoursesOverviewAnalyticsQuery = gql`
  query courseOverviewAnalytics(
    $startDate: String
    $endDate: String
    $provider: ID
    $persona: CourseGroupEnum
    $first: Int
    $after: String
    $last: Int
    $before: String
    $sortBy: String
    $sortType: String
  ) {
    courseOverviewAnalytics(
      startDate: $startDate
      endDate: $endDate
      providerId: $provider
      persona: $persona
      first: $first
      after: $after
      last: $last
      before: $before
      sortBy: $sortBy
      sortType: $sortType
    ) {
      nodes {
        averageGrade
        averageRating
        averageTime
        enrolledUsers
        finishedUsers
        persona
        name
        providers {
          id
          name
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;
