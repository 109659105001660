import React, { useState, useContext } from 'react';
import LineChartCard from './line-chart-card';
import { useUserAnalytics } from '@hooks';
import { AnalyticsContext } from './analytics-context';
import { Spin } from 'antd';
import PieChartCard from './pie-chart-card';
import NewCreationCard from './new-creation-card';
import TotalPerformanceCard from './total-performance-card';
import CourseCompletionCard from './course-completion-card';
import AgeDistribuationCard from './age-distribuation-card';
import GenderDistributionCard from './gender-distribution-card';

/*
  This component represent the analytics cards related to users
*/
const UserAnalyticsCards = () => {
  const analyticsContext = useContext(AnalyticsContext);
  const { analytics, error, loading } = useUserAnalytics(
    analyticsContext?.currentPeriod?.from,
    analyticsContext?.currentPeriod?.to
  );

  let registerationValues = [];

  let totalUsersActivityCount = 0;

  if (!loading && analytics) {
    registerationValues = analytics?.breakdown_per_period?.map(value => {
      return value.count;
    });

    //sum of active and inactive users
    totalUsersActivityCount =
      analytics?.user_state_distribution?.active +
      analytics?.user_state_distribution?.inactive;
  }

  if (loading) return <Spin size="large" />;
  return (
    <div className="analyticsDataPerPeriod-container">
      <div className="middle-cards-container">
        {/* display middle cards here */}
        <LineChartCard
          period={analyticsContext?.currentPeriod}
          backgroundColor="#3751FF"
          borderColor="#3751FF"
          values={registerationValues}
          label={`Registration ${analyticsContext?.currentPeriod?.alias}`}
        />

        <PieChartCard
          label="Role Distribuation"
          roles={analytics?.roles_distribution}
        />
        <AgeDistribuationCard />
      </div>
      <div className="analyticsTotalCounts-container">
        {/* display side cards here */}
        <NewCreationCard
          label={'New Users'}
          publishedNumber={analytics?.user_state_distribution?.verified}
          unPublishedNumber={analytics?.user_state_distribution?.unverified}
          publishedLabel={'Verified'}
          unPublishedLabel={'Unverfied'}
        />
        <TotalPerformanceCard
          label={'Total Performance'}
          totalCounts={totalUsersActivityCount}
          counts={[
            {
              name: 'Active Users',
              count: analytics?.user_state_distribution?.active,
              color: '#4880FF',
            },
            {
              name: 'Inactive Users',
              count: analytics?.user_state_distribution?.inactive,
              color: '#FF832B',
            },
          ]}
        />
        <GenderDistributionCard label="Genders Distribution per Role" />
        <CourseCompletionCard label="Course Completion per Role" />
      </div>
    </div>
  );
};

export default UserAnalyticsCards;
