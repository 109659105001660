import React from 'react';
import PropTypes from 'prop-types';
import { FormItem } from '@components';

const ProviderFilter = ({
  initialValue,
  mode,
  validations = [],
  options,
  onChange,
  form,
}) => {
  return (
    <FormItem
      inputName={mode ? 'providers' : 'provider'}
      inputLabel="Provider"
      className="inline-label"
      inputType="select"
      placeHolder="Select Provider"
      validationRules={validations}
      mode={mode}
      initialValue={mode ? undefined : initialValue}
      options={options}
      onChange={onChange}
      form={form}
    />
  );
};

ProviderFilter.propTypes = {
  initialValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.number),
  ]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  validations: PropTypes.array,
  mode: PropTypes.string,

  form: PropTypes.shape({
    /** getFieldDecorator prop of type function passed from antd form to get each field decorator  */
    getFieldDecorator: PropTypes.func,
    /** setFieldsValue prop of type function passed form ant d form to set field value by it's name */
    setFieldsValue: PropTypes.func,
    /** validateFields prop of type function passed from antd form to run its validations  */
    validateFields: PropTypes.func,
  }).isRequired,
};

export default ProviderFilter;
