import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { client } from '@utilities';
import { AuthProvider } from './auth_context';
import { BASE_URL } from '@config';

class Auth extends Component {
  constructor(props) {
    super(props);
    const token = localStorage.getItem('token') || '';
    const user = JSON.parse(localStorage.getItem('user')) || {};
    let role = user?.role?.name || 'Guest';

    this.state = {
      authenticated: !!token,
      user,
      role,
      token,
    };
  }

  login = async ({ admin, token }) => {
    if (!token) {
      return null;
    }

    localStorage.setItem('user', JSON.stringify(admin));
    localStorage.setItem('token', token);
    this.setState({
      authenticated: true,
      user: admin,
      token: token,
      role: admin?.role?.name,
    });
  };

  logout = () => {
    localStorage.clear();
    client.resetStore();
    this.setState({
      authenticated: false,
      user: {},
      token: '',
      role: 'Guest',
    });
  };

  addToken(token) {
    this.setState(prevState => ({
      ...prevState,
      token: token,
    }));
  }

  render() {
    const authProviderValue = {
      ...this.state,
      login: this.login,
      logout: this.logout,
    };
    const storageToken = localStorage.getItem('token');

    if (storageToken && this.state.token !== storageToken) {
      this.addToken(storageToken);
    }

    if (!storageToken && this.state.authenticated) {
      this.logout();
    }

    return (
      <AuthProvider value={authProviderValue}>
        {this.props.children}
      </AuthProvider>
    );
  }
}

Auth.propTypes = {
  children: PropTypes.object,
};

export default Auth;
