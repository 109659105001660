import React, { useContext } from 'react';
import { analyticsTabs } from '@utilities';
import { AnalyticsContext } from './analytics-context';

const AnalyticsTabs = () => {
  const analyticsContext = useContext(AnalyticsContext);

  return (
    <ul className="analytics-tabs">
      {Object.keys(analyticsTabs).map((tab, index) => {
        return (
          <li
            className={
              analyticsTabs[tab] === analyticsContext.activeAnalyticsTab
                ? 'analytics-tab-item active'
                : 'analytics-tab-item'
            }
            onClick={() => {
              analyticsContext.setActiveAnalyticsTab(analyticsTabs[tab]);
            }}
            key={index}
          >
            {analyticsTabs[tab]}
          </li>
        );
      })}
    </ul>
  );
};

export default AnalyticsTabs;
