import gql from 'graphql-tag';

export const SendOtpViaEmail = gql`
  mutation SendOTP($email: String!) {
    sendOTPViaEmail(email: $email) {
      message
      statusCode
    }
  }
`;
