import gql from 'graphql-tag';

export const ListTagsQuery = gql`
  query getTags {
    getTags {
      label: name
      value: id
    }
  }
`;
