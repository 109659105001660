import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';

/** styling of full height scetion */
const Section = styled.div`
  min-height: 100vh;
`;

/** full height section component */
const FullHeightSection = ({ children }) => {
  return <Section>{children}</Section>;
};

FullHeightSection.propTypes = {
  /** children prop of type array contains rendered children of full height section */
  children: PropTypes.object,
};

export default FullHeightSection;
