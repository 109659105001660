import gql from 'graphql-tag';

export const USER_POINTS = gql`
query userPoints($id:ID!){
  userPoints(userId: $id){
    category
    time
    points
  }
}
`;
