import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import { FormItem } from '@components';
import validationRules from './validation_rules';

const EditMatchQuestion = ({ form, updateTargetForm, question }) => {
  useEffect(() => {
    form && updateTargetForm(form);
  }, []);

  return (
    <Form>
      <FormItem
        inputName="body"
        placeHolder="Question"
        validationRules={validationRules.body}
        initialValue={question.body}
        validateTrigger={['onChange', 'onBlur']}
        form={form}
      />
      <FormItem
        inputName="answers[0].body"
        placeHolder="Answer"
        validationRules={validationRules.answers}
        initialValue={question.answers[0].body}
        validateTrigger={['onChange', 'onBlur']}
        form={form}
      />
      <FormItem
        inputName="rightAnswerIndex"
        initialValue={0}
        form={form}
        hidden={true}
      />
    </Form>
  );
};

EditMatchQuestion.propTypes = {
  /** updateTargetForm prop of type function for updating target form object in QuestionForm component
   *  which is required for submission */
  updateTargetForm: PropTypes.func.isRequired,
  /** form prop of type object for ant d form method apis */
  form: PropTypes.object.isRequired,
  /** editQuestionData for fetched edit question data */
  question: PropTypes.shape({
    id: PropTypes.string,
    body: PropTypes.string,
    questionType: PropTypes.string,
    answers: PropTypes.arrayOf(
      PropTypes.shape({
        body: PropTypes.string,
      })
    ),
    rightAnswerIndex: PropTypes.string,
    rightExplanation: PropTypes.string,
    wrongExplanation: PropTypes.string,
  }).isRequired,
};

export default Form.create()(EditMatchQuestion);
