import gql from 'graphql-tag';

export const EDIT_COMPETITION = gql`
  mutation updateCompetition($content: UpdateCompetition!) {
    updateCompetition(content: $content) {
      statusCode
      message
      errors {
        field_name
        message
      }
      competition {
        id
        title
        roles {
          id
          name
        }
        start_date
        end_date
      }
    }
  }
`;
