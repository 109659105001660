import { useQuery } from '@apollo/client';
import { GetCoursePrerequisitesQuery } from '@services';

export const usePrerequisiteCourse = () => {
  const { data, loading, error } = useQuery(GetCoursePrerequisitesQuery, {
    fetchPolicy: 'cache-and-network',
  });

  const categories = data?.getCourseDetailsPrerequisites?.categories || [];
  const tags = data?.getCourseDetailsPrerequisites?.tags || [];
  const providers = data?.getCourseDetailsPrerequisites?.providers || [];
  const personas = data?.getCourseDetailsPrerequisites?.audience_personas || [];
  const ageGroup = data?.getCourseDetailsPrerequisites?.age_groups || [];
  const grades = data?.getCourseDetailsPrerequisites?.grades || [];
  const certificates = data?.getCourseDetailsPrerequisites?.certificates || [];

  return {
    categories,
    tags,
    providers,
    personas,
    ageGroup,
    grades,
    certificates,
    loading,
    error,
  };
};
