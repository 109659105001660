import { notification } from 'antd';

export const notificationHandler = (
  action,
  errors,
  updateList,
  callback = Function()
) => {
  if (errors)
    notification.error({
      message: `${action.capitalize()} Action`,
      description: errors[0].fieldName
        ? errors[0].fieldName + ' ' + errors[0].errors
        : errors[0].errors,
    });
  else {
    notification.success({
      message: `${action.capitalize()} Action`,
      description: `The ${action} action has been successful!`,
    });
    updateList(true);
    callback();
  }
};
