import React from 'react';
import { en as strings } from '../locales/data';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { LOGOUT } from '@services';
import { useMutation } from '@apollo/client';
import { Spin } from 'antd';

/** styling of logout button in header dropdown menu */
const Button = styled.button`
  border: 0;
  background: none;
  cursor: pointer;
  text-transform: capitalize;
`;

const LogoutButton = ({ logout }) => {
  const [logoutMutation, { loading, error }] = useMutation(LOGOUT, {
    onCompleted: () => logout(),
    onError: () => {
      logout();
      window.location.href = '/';
    },
  });

  if (loading) return <Spin />;

  return <Button onClick={logoutMutation}>{strings.logOutText}</Button>;
};

LogoutButton.propTypes = {
  logout: PropTypes.func.isRequired,
};

export default LogoutButton;
