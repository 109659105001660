import gql from 'graphql-tag';

export const DELETE_COMMENT = gql`
  mutation deleteComment($commentId: ID!) {
    deleteComment(commentId: $commentId) {
      statusCode
      errors {
        errors
        fieldName
      }
    }
  }
`;
