import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import QuestionCard from './question_card';
import QuestionsUpload from '@components/questions/components/questions_upload';

const AssessmentContent = props => {
  const [questions, setQuestions] = React.useState([{}]);
  const [activeQuestionIndex, setActiveQuestionIndex] = React.useState(0);
  const [assessmentCardExpand, setAssessmentCardExpand] = React.useState(true);

  const onUploadQuestions = questions => {
    if (questions) setQuestions(questions);
  };
  useEffect(() => {
    if (props.assessment?.questions?.length > 0) {
      setQuestions(props.assessment.questions);
    } else {
      setQuestions([{}]);
    }
  }, [props.assessment]);
  return (
    <div className="assessment-wrapper">
      <h2 className="assessment-title">{props.assessment?.type}</h2>
      <h3>Questions</h3>
      <QuestionsUpload
        assessment_id={props?.assessment?.id}
        onUploadCallback={onUploadQuestions}
      />
      {questions.map((question, index) => {
        return (
          <QuestionCard
            key={index}
            question={question}
            assessmentId={props.assessment?.id}
            canDeleteQuestion={questions.length > 1}
            opened={index === activeQuestionIndex}
            assessmentCardExpand={assessmentCardExpand}
            onToggleQuestion={() => {
              setAssessmentCardExpand(!assessmentCardExpand);
              if (index !== activeQuestionIndex) {
                setActiveQuestionIndex(index);
              }
            }}
            onSaveQuestion={updatedQuestion => {
              const newQuestions = [...questions];
              newQuestions[index] = updatedQuestion;
              setQuestions(newQuestions);
            }}
            onDeleteQuestion={() => {
              const newQuestions = [...questions];
              newQuestions.splice(index, 1);
              setQuestions(newQuestions);
            }}
          />
        );
      })}
      <div className="action-buttons">
        <button
          onClick={() => {
            const newQuestions = [...questions];
            newQuestions.push({});
            setQuestions(newQuestions);
          }}
        >
          Add Question
        </button>
      </div>
    </div>
  );
};

AssessmentContent.propTypes = {
  assessment: PropTypes.object,
};

export default AssessmentContent;
