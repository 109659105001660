import gql from 'graphql-tag';

export const CSV_COURSE_ANALYTICS = gql`
  query csvCourseAnalytics(
    $courseId: ID!
    $startDate: String
    $endDate: String
  ) {
    csvCourseAnalytics(
      courseId: $courseId
      startDate: $startDate
      endDate: $endDate
    )
  }
`;
