import gql from 'graphql-tag';

export const EditCategoryMutation = gql`
  mutation updateCategory(
    $id: ID!
    $name: String
    $description: String
    $index: Int
    $educational_subject: Boolean
    $image: File
    $banner: File
    $is_featured: Boolean
    $certificate_id: Int
    $snippet_video: String
    $snippet_thumbnail: File
    $tags: [Int]
  ) {
    updateCategory(
      id: $id
      name: $name
      description: $description
      index: $index
      educational_subject: $educational_subject
      image: $image
      is_featured: $is_featured
      banner: $banner
      certificate_id: $certificate_id
      snippet_video: $snippet_video
      snippet_thumbnail: $snippet_thumbnail
      tags: $tags
    ) {
      statusCode
      errors {
        field_name
        message
      }
      message
      category {
        id
        name
        description
        index
        educational_subject
        certificates {
          id
          name
          image {
            url
          }
        }
        is_featured
        image {
          url
        }
        banner {
          url
        }
        snippet_video {
          url
        }
        snippet_thumbnail {
          url
        }
        tags {
          id
        }
      }
    }
  }
`;
