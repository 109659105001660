import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';

class BarChartComponent extends PureComponent {
  render() {
    const { data, width, height, strokeDasharray, fill } = this.props;

    const settings = {
      width: width || 500,
      height: height || 300,
      strokeDasharray: strokeDasharray || '1 1',
      fill: fill || '#a32c20',
    };

    return (
      <BarChart width={settings.width} height={settings.height} data={data}>
        <CartesianGrid strokeDasharray={settings.strokeDasharray} />
        <XAxis
          dataKey="name"
          label={{ value: 'Name', position: 'insideBottomRight', offset: 0 }}
        />
        <YAxis label={{ value: 'Value', angle: -90, position: 'insideLeft' }} />
        <Tooltip />
        <Bar dataKey="value" fill={settings.fill} />
      </BarChart>
    );
  }
}

BarChartComponent.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
    })
  ).isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  strokeDasharray: PropTypes.string,
  fill: PropTypes.string,
};

export default BarChartComponent;
