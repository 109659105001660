import styled from '@emotion/styled/macro';

export const Input = styled.input`
  width: 100%;
  height: 32px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  padding: 4px 10px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
  outline: none;
  background-color: #fff;
  :invalid {
    border-color: red !important;
  }
  ::placeholder {
    color: rgba(0, 0, 0, 0.3);
  }
  :-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.3);
  }
  ::-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.3);
  }
  &:focus {
    border-color: #0099ff;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }
`;
