import gql from 'graphql-tag';

export const ListRolesQuery = gql`
  query getPlatformRoles {
    getPlatformRoles {
      label: name
      value: id
    }
  }
`;
