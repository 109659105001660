import styled from '@emotion/styled/macro';

export const CourseContentContainer = styled.div`
  h1 {
    color: #4a4a4a;
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  p {
    color: #4a4a4a;
    font-size: 16px;
  }

  .save-btn-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    button {
      margin-right: 8px;
      background: #1890ff;
      color: white;
      border: 1px solid #e0e0e0;
      border-radius: 4px;
      padding: 8px 15px;
      cursor: pointer;
      &:hover {
    background: white;
    color: #1890ff;
    }
          &.disabled {
        color: rgba(0, 0, 0, 0.25);
        background-color: #f5f5f5;
        border-color: #d9d9d9;
        text-shadow: none;
        box-shadow: none;
        pointer-events: none;
      }

    .cancel {
      color: #4a4a4a;
      font-family: Roboto;
      font-size: 16px;
      text-decoration: underline;
      margin-left: 24px;
    }
  }
`;
export const CourseContentWrapper = styled.div`
  display: flex;
  margin-top: 30px;
`;

export const CourseContentNavWrapper = styled.div`
  margin-right: 30px;
  max-width: 25%;
`;

export const ModuleHeader = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: 60px;
`;

export const ModuleContentWrapper = styled.div`
  flex: 2;

  .unit-card-wrapper {
    background-color: #fff;
    &:nth-child(even) {
      margin: 42px 0;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  .unit-card-header {
    background-color: #4a4a4a;
    padding: 12px 20px;
    background-color: #f8f8f8;
    display: flex;
    align-items: center;
  }
  .unit-card-header .collapse-icon {
    margin-inline-start: auto;
    background: none;
    border: none;
    cursor: pointer;
  }
  .collapse-icon .collapse-icon {
    transform: rotate(180deg);
  }
  .unit-card-body {
    padding: 32px 20px;
    height: initial;

    &.collapsed {
      display: none;
      height: 0;
    }
  }
  .unit-card-section h2 {
    color: #4a4a4a;
    margin: 1rem 0;
    font-weight: 500;
    color: #4a4a4a;
  }
  .unit-card-section span {
    font-size: 1rem;
    color: #999;
  }
  .unit-types {
    display: flex;
    gap: 48px;
    align-items: flex-start;
    margin: 20px 0;
  }
  .unit-type {
    width: 70px;
    color: #4a4a4a;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;

    .img-wrapper {
      width: 70px;
      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #c4c4c4;
      border-radius: 5px;
      margin: auto;
    }

    &.active {
      font-weight: 600;
    }
    &.active .img-wrapper {
      background-color: #daeae9;
    }
  }
  .unit-type img {
    width: 35px;
    object-fit: cover;
  }
  .unit-type span {
    color: #4a4a4a;
    text-align: center;
  }

  .unit-title {
    display: flex;
    align-items: center;
    gap: 20px;
    margin: 32px 0;

    input[type='text'] {
      width: 450px;
      height: 42px;
      border: 1px solid #c4c4c4;
      background-color: #fff;
      padding-inline: 17px;
      border-radius: 5px;
    }
  }
  .link-input-wrapper {
    margin-bottom: 20px;
  }
  .file-input-wrapper {
    margin: 32px 0;
  }
  .form-control label {
    color: #4a4a4a;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
  }
  .form-control input[type='radio'] {
    /* remove standard background appearance */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: inline-block;
    margin-inline-end: 16px;
    width: 17px;
    height: 17px;
    padding: 2px;
    background-clip: content-box;
    border: 2px solid #bbbbbb;
    border-radius: 50%;
  }
  .form-control input[type='radio']:checked {
    background-color: #4a4a4a;
  }
  .actions-btns {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 32px;
  }
  .actions-btns button {
    padding: 12px 24px;
    background-color: #4a90e2;
    border: none;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
  }
  .actions-btns button.disabled {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
    cursor: not-allowed;
  }
  .actions-btns .delete-unit {
    background-color: transparent;
    color: #c4c4c4;
    border-bottom: 4px solid #c4c4c4;
    margin: 0 auto;
    border-radius: 0;
  }
  .form-control .ck-label {
    display: none;
  }
  .form-control .estimation-input-wrapper {
    color: #555;
    display: flex;
    align-items: center;
    padding-inline: 12px 24px;
    width: 350px;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    margin: 16px 0;
  }
  .form-control input[type='text'] {
    border-color: #ddd;
  }
  .form-control .estimation-input-wrapper input {
    border-color: transparent;
    width: 100%;
    height: 45px;
  }
  .form-control input[type='number'],
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    border: none;
    height: 45px;
    background: transparent;
    padding: 0 8px;
    font-size: 14px;
    color: inherit;
    border: 1px solid transparent;
    border-radius: inherit;
    flex: 2;
  }
  input[type='text']::placeholder {
    color: ##979797;
  }
  .is-graded-wrapper {
    margin-block: 20px;
    position: relative;
  }
  .is-graded-wrapper input[type='checkbox'] {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    cursor: pointer;
    opacity: 0;
    z-index: 3;
    left: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    width: 22px;
  }
  .is-graded-wrapper label {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #4a4a4a;
  }
  .is-graded-wrapper label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 3px solid #c4c4c4;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 7px;
    display: inline-block;
    position: relative;
    cursor: pointer;
    margin-right: 10px;
    margin-block: 0;
  }
  .is-graded-wrapper input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 5px;
    left: 7px;
    width: 5px;
    height: 10px;
    border: solid #4a90e2;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  .scorm-file-wrapper > div {
    margin-inline-start: 0;
  }
  .add-unit-btn {
    padding: 12px 24px;
    background-color: #4a90e2;
    border: none;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 32px;

    &:hover {
      background-color: #fff;
      color: #4a90e2;
    }
  }
`;

export const TextInputWrapper = styled.div`
  color: #555;
  display: flex;
  padding: 2px;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  margin-top: 16px;

  input[type='text'] {
    border: none;
    height: 45px;
    background: transparent;
    margin: 0;
    padding: 0 8px;
    font-size: 14px;
    color: inherit;
    border: 1px solid transparent;
    border-radius: inherit;
    flex: 2;
  }
  input[type='text']:focus {
    outline: none;
  }
`;

export const SearchbarWrapper = styled.div`
  margin-inline-start: 35px;

  .input {
    color: #555;
    display: flex;
    padding: 2px;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    margin-top: 16px;
  }

  input[type='search'] {
    border: none;
    height: 45px;
    background: transparent;
    margin: 0;
    padding: 0 8px;
    font-size: 14px;
    color: inherit;
    border: 1px solid transparent;
    border-radius: inherit;
    flex: 2;
  }
  input[type='search']:focus {
    outline: none;
  }
  input[type='search']::placeholder {
    color: ##979797;
  }

  button {
    text-indent: -999px;
    overflow: hidden;
    width: 40px;
    padding: 0;
    margin: 0;
    border: 1px solid transparent;
    border-radius: inherit;
    background: transparent
      url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
      no-repeat center;
    opacity: 0.7;
  }

  button[type='submit']:hover {
    opacity: 1;
  }
`;

export const UploadFileWrapper = styled.div`
  margin-top: 16px;
  margin-inline-start: 35px;

  input[type='file']::-webkit-file-upload-button {
    height: 45px;
    background-color: #e1e1e1;
    color: #4a4a4a;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: inline-block;
  }

  label {
    color: #4a4a4a;
  }
`;

export const AnswerWrapper = styled.div`
  display: flex;
  align-items: center;

  input[type='radio'] {
    /* remove standard background appearance */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: inline-block;
    margin-inline-end: 16px;
    width: 20px;
    height: 20px;
    padding: 2px;
    background-clip: content-box;
    border: 2px solid #bbbbbb;
    border-radius: 50%;
  }
  input[type='radio']:checked {
    background-color: #4a4a4a;
  }

  input[type='text'] {
    width: 250px;
    height: 42px;
    border: 1px solid #c4c4c4;
    background-color: #fff;
    padding-inline: 17px;
    border-radius: 5px;
  }

  input[type='text']:focus {
    outline: none;
  }
`;
