import styled from '@emotion/styled/macro';

export const DropDownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

export const DropDownButton = styled.button`
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  outline: none;
  font-weight: 500;
  color: #000;

  text-transform: capitalize;
  &:focus {
    outline: none;
  }
  &:hover {
    color: #000;
  }
  svg {
    margin-right: 10px;
    width: 20px;
    height: 20px;
  }
`;

export const DropDownList = styled.ul`
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 1;
  min-width: 50px;
  padding: 0;
  margin: 0;
  list-style: none;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  li {
    border-bottom: 1px solid #ccc;
    a {
      padding: 10px;
      display: block;
      color: #000;
      text-decoration: none;
      width: 100%;
      height: 100%;
      &:hover {
        color: #000;
      }
    }
    button {
      padding: 10px;
      display: block;
      width: 100%;
      height: 100%;
      border: none;
      background: none;
      cursor: pointer;
      outline: none;
      color: #000;
      text-transform: capitalize;
      text-align: left;
      &:focus {
        outline: none;
      }
      &:hover {
        color: #000;
      }
    }
    &:last-child {
      border-bottom: none;
    }
    &:hover {
      background: #f5f5f5;
    }
  }
`;
