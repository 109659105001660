import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';

/** check component status based on the props supplied by react loadable.
 * The component doesn't need loading prop to avoid loading component flashing.
 * Therfore, past delay is used instead.
 */
const Loading = ({ error, timedOut, pastDelay, retry }) => {
  if (error) {
    return (
      <div>
        Error! <button onClick={retry}>Retry</button>
      </div>
    );
  } else if (timedOut) {
    return (
      <div>
        Taking a long time... <button onClick={retry}>Retry</button>
      </div>
    );
  } else if (pastDelay) {
    return <Spin size="large" />;
  } else {
    return null;
  }
};

export default Loading;

Loading.propTypes = {
  /** true if an error has occured while loading component */
  error: PropTypes.object,
  /** true if scene timed out while loading */
  timedOut: PropTypes.bool,
  /** a function to retry fetching the scene */
  retry: PropTypes.func,
  /**true once the component has taken longer to load than a
   * set delay to avoid flash of loading component.
   * check https://github.com/jamiebuilds/react-loadable to understand more about loading flashing */
  pastDelay: PropTypes.bool,
};
