import gql from 'graphql-tag';

export const CREATE_COMPETITION = gql`
  mutation createCompetition($content: CreateCompetition!) {
    createCompetition(content: $content) {
      statusCode
      errors {
        field_name
        message
      }
      message
      competition {
        id
        title
        type
        description
        image {
          url
        }
        gender
        start_date
        end_date
        is_active
        actions {
          id
          name
          points
        }
        enrollments_count
        roles {
          id
          name
        }
        grades {
          id
          name
        }
        age_groups {
          id
          name
        }
        is_enrolled
      }
    }
  }
`;
