import gql from 'graphql-tag';

export const ListAdminsQuery = gql`
  query listAdmins($first: Int!, $page: Int) {
    listAdmins(first: $first, page: $page) {
      data {
        id
        email
        name
        provider {
          id
          name
        }
        created_at
        updated_at
      }
      paginatorInfo {
        count
        hasMorePages
        lastPage
        total
        currentPage
      }
    }
  }
`;
