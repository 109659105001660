import gql from 'graphql-tag';

export const DeleteLiveSessionMutation = gql`
  mutation deleteLiveSession($ids: [ID!]!) {
    deleteLiveSession(ids: $ids) {
      statusCode
      message
      errors {
        field_name
        message
      }
    }
  }
`;
