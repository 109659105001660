import gql from 'graphql-tag';

export const deleteCourseItem = gql`
  mutation deleteCourseItem($courseItemId: ID!) {
    deleteCourseItem(id: $courseItemId) {
      statusCode
      message
      errors {
        field_name
        message
      }
    }
  }
`;
