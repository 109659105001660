import gql from 'graphql-tag';

export const COMPETITION_LEADERBOARD = gql`
  query viewCompetition($id: ID!) {
    viewCompetition(id: $id) {
      id
      title
      type
      description
      image {
        url
      }
      gender
      start_date
      end_date
      is_active
      roles {
        id
        name
      }
      grades {
        id
        name
      }
      age_groups {
        id
        name
      }
      actions {
        id
        name
      }
      leaderboard(first: 100) {
        score
        user {
          id
          phone
          first_name
          last_name
        }
      }
    }
  }
`;
