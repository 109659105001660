import gql from 'graphql-tag';

export const CREATE_JOURNEY_MILESTONES = gql`
  mutation createJourneyMilestones(
    $journeyId: ID!
    $milestones: [MilestoneInput!]!
  ) {
    createJourneyMilestones(journeyId: $journeyId, milestones: $milestones) {
      errors {
        errors
      }
      statusCode
    }
  }
`;
