import { string, object, array, mixed, number } from 'yup';
import { SUPPORTED_FORMATS } from '@constants';

export const COURSE_DETAILS_SCHEMA = object({
  name: string()
    .required('Course name is required')
    .max(100, 'Course Name must not exceed 100 characters!'),
  category_id: string().required('Category is required'),
  content_lang: string().required('Content language is required'),
  tags_ids: array().of(string()),
  providers_ids: array()
    .min(1, 'At least one provider is required')
    .required('Providers are required'),
  duration: number()
    .min(1, 'duration must be between 1 to 40 weeks!')
    .max(40, 'duration must be between 1 to 40 weeks!'),
  semester: string().required('Semester is required'),
  description: string().max(500, 'Description must not exceed 500 characters!'),
  objectives: string().max(500, 'Objectives must not exceed 500 characters!'),
  personas_ids: array()
    .min(1, 'Please assign course to one or more roles')
    .required('roles are required'),
  age_grades: array().when('personas_ids', {
    is: personas_ids => personas_ids.includes('23'), //student id
    then: schema =>
      schema.test(
        'type',
        'should select at least one student grade',
        value => value?.some(val => val.includes('grade')) //check if any grade is selected
      ),
    otherwise: array(),
  }),
  image: mixed()
    .required('Image is required.')
    .test('widthHeight', 'Image format not supported', value => {
      // validate if the image is provided as url
      if (value.url) {
        return true;
      }
      if (
        value === null ||
        (value && !SUPPORTED_FORMATS.includes(value.type))
      ) {
        return false;
      }
      return true;
    }),
  index: number().min(1, "Course index can't be less than 1"),
});

/**
 * return initialValues to be used by courseDetails form.
 *
 * @export
 * @param {Object} courseDetails current course details to populate initialValues.
 * @return {Object} initialValues to be used by courseDetails form.
 */
export function getCourseDetailsInitialValues(courseDetails) {
  let initialValues = {
    name: '',
    category_id: '',
    content_lang: '',
    tags_ids: [],
    providers_ids: [],
    duration: '',
    semester: '',
    description: '',
    objectives: '',
    personas_ids: [],
    age_grades: [],
    image: {},
    points: 0,
    index: '',
    hasCertificate: false,
    is_deci: false,
    certificate_id: '',
  };
  if (courseDetails) {
    const ageGroups = courseDetails.age_groups.map(
      gaeGroup => `${gaeGroup.id}_age`
    );
    const grades = courseDetails.grades?.map(grade => `${grade.id}_grade`);
    const personasIds = courseDetails.roles
      ? courseDetails.roles?.map(role => role.id)
      : [];
    let providersIds = courseDetails.providers?.map(provider => provider.id);
    const image = {};
    if (courseDetails.image) {
      image.url = courseDetails.image.url;
    }
    initialValues = {
      name: courseDetails.name ? courseDetails.name : '',
      category_id: courseDetails.category?.id ? courseDetails.category.id : '',
      content_lang: courseDetails.content_lang
        ? courseDetails.content_lang
        : '',
      tags_ids: courseDetails.tags?.map(tag => tag.id),
      providers_ids: providersIds,
      duration: courseDetails.duration ? courseDetails.duration : '',
      semester: courseDetails.semester ? courseDetails.semester : '',
      description: courseDetails.description ? courseDetails.description : '',
      objectives: courseDetails.objectives ? courseDetails.objectives : '',
      personas_ids: personasIds,
      age_grades: [...ageGroups, ...grades],
      image,
      hasCertificate: courseDetails.certificates?.length > 0 ? true : false,
      is_deci: courseDetails.is_deci,
      points: courseDetails.points ? courseDetails.points : 0,
      certificate_id:
        courseDetails.certificates?.length > 0
          ? courseDetails.certificates[0].id
          : '',
      index: courseDetails?.index || '',
    };
  }

  return initialValues;
}
