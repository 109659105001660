import React from 'react';
import PropTypes from 'prop-types';
import { FormItem } from '@components';
import { GENDERS } from '@constants';

const GenderFilter = ({
  initialValue,
  mode,
  validations = [],
  onChange,
  form,
}) => {
  return (
    <FormItem
      inputName="gender"
      inputLabel="Gender"
      className="inline-label"
      inputType="select"
      placeHolder="Select Gender"
      validationRules={validations}
      mode={mode}
      initialValue={mode ? undefined : initialValue}
      options={GENDERS}
      onChange={onChange}
      form={form}
    />
  );
};

GenderFilter.propTypes = {
  initialValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  onChange: PropTypes.func.isRequired,
  validations: PropTypes.array,
  mode: PropTypes.string,

  form: PropTypes.shape({
    /** getFieldDecorator prop of type function passed from antd form to get each field decorator  */
    getFieldDecorator: PropTypes.func,
    /** setFieldsValue prop of type function passed form ant d form to set field value by it's name */
    setFieldsValue: PropTypes.func,
    /** validateFields prop of type function passed from antd form to run its validations  */
    validateFields: PropTypes.func,
  }).isRequired,
};

export default GenderFilter;
