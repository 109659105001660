export const ACTIONS = {
  SIGN_IN: 'sign_in',
  SIGN_OUT: 'sign_out',
  OTP: 'otp',
  SHOW_COURSES_LIST: 'show_courses_list',
  ADD_NEW_COURSE: 'add_new_course',
  EDIT_COURSE: 'edit_course',
  DELETE_COURSE: 'delete_course',
  ADD_NEW_CATEGORY: 'add_new_category',
  RESET_PASSWORD: 'reset_password',
  FORGOT_PASSWORD: 'forgot_password',
  CHANGE_PASSWORD: 'change_password',
  EDIT_CATEGORY: 'edit_category',
  DELETE_CATEGORY: 'delete_category',
  SHOW_CATEGORY_LIST: 'show_category_list',
  SHOW_THIRD_PARTY_CONTENT: 'show_third_party_content',
  SHOW_MANUAL_NOTIFICATIONS_LIST: 'show_manual_notifications_list',
  SHOW_AUTOMATIC_NOTIFICATIONS_LIST: 'show_automatic_notifications_list',
  EDIT_AUTOMATIC_NOTIFICATIONS: 'edit_automatic_notifications',
  DELETE_NOTIFICATION: 'delete_notification',
  SHOW_ADMINS_LIST: 'show_admins_list',
  ADD_NEW_ADMIN: 'add_new_admin',
  ADD_NEW_PROVIDER_ADMIN: 'new_provider_admin',
  SHOW_COMPETITIONS_LIST: 'show_competitions_list',
  ADD_NEW_COMPETITION: 'add_new_competition',
  EDIT_COMPETITION: 'edit_competition',
  VIEW_COMPETITION: 'view_competition',
  SHOW_ANALYTICS: 'show_analytics',
  SHOW_ANALYTICS_COURSE: 'show_analytics_course',
  SHOW_COURSES_OVERVIEW_ANALYTICS: 'show_courses_overview_analytics',
  SHOW_USERS_LIST: 'show_users_list',
  SHOW_USER: 'show_user',
  ADD_NEW_USER: 'add_new_user',
  SHOW_ONGOING_JOBS: 'show_ongoing_jobs',
  SHOW_PROVIDERS: 'show_providers',
  SHOW_JOURNEYS: 'show_journeys',
  SHOW_COMMENTS: 'show_comments',
  SHOW_POINTS: 'show_points',
  SHOW_ADMIN_NOTIFICATIONS: 'show_admin_notifications',
  SHOW_DISCUSSIONS_LIST: 'show_discussions_list',
  SHOW_DISCUSSION_THREAD_FORM: 'show_discussion_thread_form',
  SHOW_DISCUSSION_THREAD_DETAILS: 'show_discussion_thread_details',
  SHOW_NAVBAR_SECTIONS: 'show_navbar_sections',
  ADD_NEW_NAVBAR_SECTION: 'add_new_navbar_section',
  EDIT_NAVBAR_SECTION: 'edit_navbar_section',
  ADD_NEW_LIVE_SESSION: 'add_new_live_session',
  EDIT_LIVE_SESSION: 'edit_live_session',
};

const rules = {
  Guest: {
    static: [ACTIONS.SIGN_IN, ACTIONS.RESET_PASSWORD, ACTIONS.FORGOT_PASSWORD],
  },
  admin: {
    static: [
      ACTIONS.OTP,
      ACTIONS.SIGN_OUT,
      ACTIONS.SHOW_COURSES_LIST,
      ACTIONS.ADD_NEW_COURSE,
      ACTIONS.CHANGE_PASSWORD,
      ACTIONS.EDIT_COURSE,
      ACTIONS.DELETE_COURSE,
      ACTIONS.ADD_NEW_CATEGORY,
      ACTIONS.EDIT_CATEGORY,
      ACTIONS.DELETE_CATEGORY,
      ACTIONS.SHOW_CATEGORY_LIST,
      ACTIONS.SHOW_THIRD_PARTY_CONTENT,
      ACTIONS.CREATE_NOTIFICATION,
      ACTIONS.SHOW_MANUAL_NOTIFICATIONS_LIST,
      ACTIONS.SHOW_AUTOMATIC_NOTIFICATIONS_LIST,
      ACTIONS.EDIT_AUTOMATIC_NOTIFICATIONS,
      ACTIONS.DELETE_NOTIFICATION,
      ACTIONS.SHOW_ADMINS_LIST,
      ACTIONS.ADD_NEW_ADMIN,
      ACTIONS.ADD_NEW_PROVIDER_ADMIN,
      ACTIONS.SHOW_COMPETITIONS_LIST,
      ACTIONS.ADD_NEW_COMPETITION,
      ACTIONS.EDIT_COMPETITION,
      ACTIONS.VIEW_COMPETITION,
      ACTIONS.SHOW_ANALYTICS,
      ACTIONS.SHOW_ANALYTICS_COURSE,
      ACTIONS.SHOW_COURSES_OVERVIEW_ANALYTICS,
      ACTIONS.SHOW_USERS_LIST,
      ACTIONS.ADD_NEW_USER,
      ACTIONS.SHOW_ONGOING_JOBS,
      ACTIONS.SHOW_PROVIDERS,
      ACTIONS.SHOW_USER,
      ACTIONS.SHOW_JOURNEYS,
      ACTIONS.SHOW_COMMENTS,
      ACTIONS.SHOW_POINTS,
      ACTIONS.SHOW_ADMIN_NOTIFICATIONS,
      ACTIONS.SHOW_DISCUSSIONS_LIST,
      ACTIONS.SHOW_DISCUSSION_THREAD_FORM,
      ACTIONS.SHOW_DISCUSSION_THREAD_DETAILS,
      ACTIONS.SHOW_NAVBAR_SECTIONS,
      ACTIONS.EDIT_NAVBAR_SECTION,
      ACTIONS.ADD_NEW_NAVBAR_SECTION,
      ACTIONS.ADD_NEW_LIVE_SESSION,
      ACTIONS.EDIT_LIVE_SESSION,
    ],
  },
  provider_admin: {
    static: [
      ACTIONS.SIGN_OUT,
      ACTIONS.SHOW_COURSES_LIST,
      ACTIONS.ADD_NEW_COURSE,
      ACTIONS.CHANGE_PASSWORD,
      ACTIONS.REQUEST_EDIT_COURSE,
      ACTIONS.SHOW_ANALYTICS_COURSE,
      ACTIONS.SHOW_ADMIN_NOTIFICATIONS,
      ACTIONS.SHOW_DISCUSSIONS_LIST,
      ACTIONS.SHOW_DISCUSSION_THREAD_FORM,
      ACTIONS.SHOW_DISCUSSION_THREAD_DETAILS,
      ACTIONS.ADD_NEW_LIVE_SESSION,
      ACTIONS.EDIT_LIVE_SESSION,
    ],
  },
};

export default rules;
