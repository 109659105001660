import gql from 'graphql-tag';

export const WORKBOOK = gql`
  query workbook($id: ID!) {
    workbook(id: $id) {
      id
      questionableId
      questions {
        answers {
          id
          body
        }
        body
        id
        questionType
        matchQuestionId
        rightAnswer {
          id
          body
        }
        rightExplanation
        wrongExplanation
        subQuestions {
          answers {
            id
            body
          }
          body
          id
          questionType
          matchQuestionId
          rightAnswer {
            id
            body
          }
          rightExplanation
          wrongExplanation
        }
      }
    }
  }
`;
