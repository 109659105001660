import React from 'react';
import PropTypes from 'prop-types';
import { Close, LoadingButton, DButton } from '@components';
import {
  ModalOverlay,
  ModalContent,
  ActionsButtonsWrapper,
} from './styled_component';

export const CustomModal = ({
  width,
  height,
  hideCloseIcon,
  title,
  confirmPopUpButtonText,
  confirmButtonStyles,
  cancelPopUpButtonText,
  loading,
  onConfirm,
  onClose,
  children,
  overlayClose = true,
}) => (
  <>
    <ModalOverlay
      role="dialog"
      aria-labelledby="dialog1"
      aria-modal="true"
      onClick={overlayClose ? onClose : () => null}
    />
    <ModalContent width={width} height={height}>
      <div className="modal-header">
        {hideCloseIcon ? null : (
          <button
            data-dismiss="dialog"
            aria-label="Close"
            className="close"
            onClick={() => onClose()}
          >
            <Close />
          </button>
        )}
        {title ? <p className="headerText">{title}</p> : null}
      </div>
      <div className="content">{children}</div>
      <ActionsButtonsWrapper>
        {loading && <LoadingButton />}
        {confirmPopUpButtonText && !loading ? (
          <DButton style={confirmButtonStyles} onClick={onConfirm}>
            {confirmPopUpButtonText}
          </DButton>
        ) : null}
        {cancelPopUpButtonText ? (
          <DButton outline="true" onClick={onClose}>
            {cancelPopUpButtonText}
          </DButton>
        ) : null}
      </ActionsButtonsWrapper>
    </ModalContent>
  </>
);

CustomModal.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  hideCloseIcon: PropTypes.bool,
  title: PropTypes.string,
  confirmPopUpButtonText: PropTypes.string,
  confirmButtonStyles: PropTypes.object,
  cancelPopUpButtonText: PropTypes.string,
  loading: PropTypes.bool,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  children: PropTypes.node,
  overlayClose: PropTypes.bool,
};

export default CustomModal;
