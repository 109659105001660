import gql from 'graphql-tag';

export const DELETE_COURSE = gql`
  mutation deleteCourse($ids: [ID!]!) {
    deleteCourse(ids: $ids) {
      statusCode
      message
      errors {
        field_name
        message
      }
    }
  }
`;
