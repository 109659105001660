import { useQuery } from '@apollo/client';
import { viewUserCoursesQuery } from '@services';
import { useCallback } from 'react';

export const useViewUserCourses = ({ id, first, courseState }) => {
  const { loading, error, data, refetch } = useQuery(viewUserCoursesQuery, {
    variables: {
      id,
      first,
      state: courseState,
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const usersCourses = data?.viewUser?.courses?.edges || [];
  const pageInfo = data?.viewUser?.pageInfo || {};

  const refetchPageResults = useCallback(async ({ first, page }) => {
    await refetch({ first, page });
  }, []);

  return { usersCourses, pageInfo, loading, error, refetchPageResults };
};
