import gql from 'graphql-tag';

export const EDIT_PROVIDER = gql`
  mutation editProvider(
    $providerId: ID!
    $name: String
    $photo: File
    $photo2x: File
  ) {
    editProvider(
      providerId: $providerId
      name: $name
      photo: $photo
      photo2x: $photo2x
    ) {
      statusCode
      errors {
        errors
        fieldName
      }
    }
  }
`;
