import gql from 'graphql-tag';

export const IMPORT_ASSESSMENTS_TO_COMPETITION = gql`
  mutation importAssessmentsToCompetition(
    $competitionId: ID!
    $coursesAssessments: [CourseAssessmentInput!]!
  ) {
    importAssessmentsToCompetition(
      competitionId: $competitionId
      coursesAssessments: $coursesAssessments
    ) {
      statusCode
      errors {
        errors
        fieldName
      }
    }
  }
`;
