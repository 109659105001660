import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Radio } from 'antd';
import { FormItem } from '@components';
import validationRules from './validation_rules';
import { questionInitialValues } from './initial_values';

const TrueFalseQuestion = props => {
  const { form, updateTargetForm, editQuestionData } = props;
  const {
    questionType,
    body,
    true_false_answers,
    rightExplanation,
    wrongExplanation,
    rightAnswerIndex,
  } = questionInitialValues(editQuestionData || { questionType: 'true_false' });

  useEffect(() => {
    form && updateTargetForm(form);
  }, []);

  /**
   * handle changing in right answer value
   *
   * @param {Event} e
   */
  const handleRightAnswerChange = e => {
    e.preventDefault();
    form.setFieldsValue({
      rightAnswerIndex: e.target.value,
    });
  };

  return (
    <Form>
      <FormItem
        inputName="questionType"
        placeHolder="Question Type"
        hidden={true}
        validationRules={validationRules.questionType}
        initialValue={questionType}
        validateTrigger={['onChange', 'onBlur']}
        form={form}
      />
      <FormItem
        inputName="body"
        placeHolder="Question Title"
        validationRules={validationRules.body}
        initialValue={body}
        validateTrigger={['onChange', 'onBlur']}
        form={form}
      />
      <Form.Item label="Answers">
        {form.getFieldDecorator(`rightAnswerIndex`, {
          rules: validationRules.rightAnswerIndex,
          initialValue: parseInt(rightAnswerIndex),
          validateTrigger: ['onChange', 'onBlur'],
          onChange: handleRightAnswerChange,
        })(
          <Radio.Group>
            <Radio value={0}>True</Radio>
            <Radio value={1}>False</Radio>
          </Radio.Group>
        )}
        {true_false_answers.map((answer, index) => {
          return (
            <FormItem
              key={index}
              hidden={true}
              inputName={`answers.answer_${index}.body`}
              placeHolder=""
              initialValue={answer.body}
              validateTrigger={['onChange', 'onBlur']}
              validationRules={[]}
              form={form}
            />
          );
        })}
      </Form.Item>
      <FormItem
        inputName="rightExplanation"
        placeHolder="Right Explanation"
        validationRules={validationRules.rightExplanation}
        initialValue={rightExplanation}
        validateTrigger={['onChange', 'onBlur']}
        form={form}
      />
      <FormItem
        inputName="wrongExplanation"
        placeHolder="Wrong Explanation"
        validationRules={validationRules.wrongExplanation}
        initialValue={wrongExplanation}
        validateTrigger={['onChange', 'onBlur']}
        form={form}
      />
    </Form>
  );
};

TrueFalseQuestion.propTypes = {
  /** form prop of type object for ant d form method apis */
  form: PropTypes.object.isRequired,
  /** updateTargetForm prop of type function for updating target form object in QuestionForm component
   *  which is required for submission */
  updateTargetForm: PropTypes.func.isRequired,
  /** editQuestionData for fetched edit question data */
  editQuestionData: PropTypes.shape({
    id: PropTypes.string,
    body: PropTypes.string,
    questionType: PropTypes.string,
    answers: PropTypes.arrayOf(
      PropTypes.shape({
        body: PropTypes.string,
      })
    ),
    rightAnswerIndex: PropTypes.string,
    rightExplanation: PropTypes.string,
    wrongExplanation: PropTypes.string,
  }),
};

const TrueFalseQuestionForm = Form.create()(TrueFalseQuestion);

export default TrueFalseQuestionForm;
