import gql from 'graphql-tag';

export const EditUserMutation = gql`
  mutation updateUser(
    $id: ID!
    $first_name: String
    $last_name: String
    $gender: GenderEnum
    $phone: String
    $grade_id: ID
    $age_group_id: ID
    $roles: [UserRole]
  ) {
    updateUser(
      id: $id
      first_name: $first_name
      last_name: $last_name
      gender: $gender
      phone: $phone
      grade_id: $grade_id
      age_group_id: $age_group_id
      roles: $roles
    ) {
      user {
        id
      }
      statusCode
      errors {
        message
        field_name
      }
    }
  }
`;
