/**
 * @Author: sarahnouh
 * @Date:   2019-12-25T17:57:22+02:00
 * @Last modified by:   sarahnouh
 * @Last modified time: 2020-01-15T19:51:31+02:00
 */
import gql from 'graphql-tag';

export const CreateCategoryMutation = gql`
  mutation CreateCategory(
    $name: String!
    $description: String
    $index: Int!
    $educational_subject: Boolean!
    $is_featured: Boolean!
    $image: File!
    $banner: File
    $certificate_id: Int
    $snippet_video: String
    $snippet_thumbnail: File
    $tags: [Int]
  ) {
    createCategory(
      name: $name
      description: $description
      index: $index
      educational_subject: $educational_subject
      is_featured: $is_featured
      image: $image
      banner: $banner
      certificate_id: $certificate_id
      snippet_video: $snippet_video
      snippet_thumbnail: $snippet_thumbnail
      tags: $tags
    ) {
      statusCode
      errors {
        field_name
        message
      }
      message
      category {
        id
        name
        description
        index
        educational_subject
        is_featured
        certificates {
          id
          name
          image {
            url
          }
        }
        image {
          url
        }
        banner {
          url
        }
        snippet_video {
          url
        }
        snippet_thumbnail {
          url
        }
        tags {
          id
        }
      }
    }
  }
`;
