import gql from 'graphql-tag';

export const CREATE_QUESTION = gql`
  mutation createQuestion($questionableId: ID!, $question: QuestionInput!) {
    createQuestion(questionableId: $questionableId, question: $question) {
      errors {
        errors
      }
      statusCode
    }
  }
`;
