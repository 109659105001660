import gql from 'graphql-tag';

export const getUserAnalyticsQuery = gql`
  query getUsersAnalytics($from: Date!, $to: Date!, $persona: UserRole) {
    getUsersAnalytics {
      enrollment_distribution(from: $from, to: $to, persona: $persona) {
        completed
        not_completed
      }

      gender_distribution(from: $from, to: $to, persona: $persona) {
        male
        female
      }

      all_time_gender_distribution: gender_distribution {
        male
        female
      }

      age_distribution(from: $from, to: $to, persona: $persona) {
        less_than_25
        from_25_to_35
        from_35_to_45
        from_45_to_55
        more_than_55
        grade_1
        grade_2
        grade_3
        grade_4
        grade_5
        grade_6
        grade_7
        grade_8
        grade_9
        grade_10
        grade_11
        grade_12
      }

      all_time_age_distribution: age_distribution {
        less_than_25
        from_25_to_35
        from_35_to_45
        from_45_to_55
        more_than_55
        grade_1
        grade_2
        grade_3
        grade_4
        grade_5
        grade_6
        grade_7
        grade_8
        grade_9
        grade_10
        grade_11
        grade_12
      }

      roles_distribution(from: $from, to: $to) {
        parent
        teacher
        student
        child
      }

      user_state_distribution(from: $from, to: $to) {
        verified
        unverified
        active
        inactive
      }

      breakdown_per_period(from: $from, to: $to) {
        date
        count
      }

      statusCode
      message
      errors {
        field_name
        message
      }
    }
  }
`;
