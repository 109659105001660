import gql from 'graphql-tag';

export const UNPUBLISH_COURSE = gql`
  mutation draftCourse($id: ID!) {
    draftCourse(id: $id) {
      statusCode
      message
      errors {
        field_name
        message
      }
    }
  }
`;
