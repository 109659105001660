/**
 * @Author: sarahnouh
 * @Date:   2020-01-30T19:41:08+02:00
 * @Last modified by:   sarahnouh
 * @Last modified time: 2020-02-02T13:23:40+02:00
 */
import gql from 'graphql-tag';

export const ForgetPasswordMutation = gql`
  mutation forgetPassword($email: String!) {
    adminForgetPassword(email: $email) {
      errors {
        errors
      }
      response
      statusCode
    }
  }
`;
