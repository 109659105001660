import gql from 'graphql-tag';

export const GRADED_WORKBOOK = gql`
  query gradedWorkbook($id: ID!) {
    gradedWorkbook(id: $id) {
      id
      questionableId
      passingGrade
      questions {
        answers {
          id
          body
        }
        body
        id
        questionType
        matchQuestionId
        rightAnswer {
          id
          body
        }
        rightExplanation
        wrongExplanation
        subQuestions {
          answers {
            id
            body
          }
          body
          id
          questionType
          matchQuestionId
          rightAnswer {
            id
            body
          }
          rightExplanation
          wrongExplanation
        }
      }
    }
  }
`;
