import gql from 'graphql-tag';

export const DeleteCompetitonMutation = gql`
  mutation deleteCompetition($id: ID!) {
    deleteCompetition(id: $id) {
      id
      title
    }
  }
`;
