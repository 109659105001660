import gql from 'graphql-tag';

export const questionsBulkUpload = gql`
  mutation importAssessmentQuestions(
    $content: importAssessmentQuestionsInput!
  ) {
    importAssessmentQuestions(content: $content) {
      statusCode
      message
      errors {
        field_name
        message
      }
      validations {
        row_number
        errors
      }
      assessment {
        id
        estimated_time
        passing_grade
        number_of_trials
        type
        questions {
          id
          type
          body
          answers {
            id
            body
          }
          right_answer {
            id
            body
          }
          right_answer_id
          right_explanation
          wrong_explanation
        }
      }
    }
  }
`;
