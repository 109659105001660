import gql from 'graphql-tag';

export const PersonaList = gql`
  query getPlatformRoles {
    getPlatformRoles {
      id
      name
    }
  }
`;
