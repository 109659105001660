import gql from 'graphql-tag';

export const EDIT_QUESTION = gql`
  mutation EditQuestion($question: EditQuestionInput!) {
    editQuestion(question: $question) {
      question {
        id
      }
      errors {
        fieldName
        errors
      }
      statusCode
    }
  }
`;
