import React from 'react';
import Proptypes from 'prop-types';
import ChartLegend from './chart-legend';
import DoughnutChart from './doughnut-chart';
import { getPercentage } from '@utilities';

const TotalPerformanceCard = ({ label, totalCounts, counts }) => {
  return (
    <div className="total-card total-performance-card">
      <h3 className="title">{label}</h3>
      {totalCounts > 0 ? (
        <div className="content">
          <DoughnutChart counts={counts} />
          <div className="legends-container">
            {counts?.map((count, index) => {
              return (
                <ChartLegend
                  key={index}
                  label={count?.name}
                  color={count?.color}
                  value={getPercentage(count?.count, totalCounts)}
                />
              );
            })}
          </div>
        </div>
      ) : (
        <div>No data for this period</div>
      )}
    </div>
  );
};

TotalPerformanceCard.propTypes = {
  label: Proptypes.string,
  totalCounts: Proptypes.number,
  counts: Proptypes.arrayOf(
    Proptypes.shape({
      name: Proptypes.string,
      count: Proptypes.number,
      color: Proptypes.string,
    })
  ),
};

export default TotalPerformanceCard;
