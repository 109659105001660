import gql from 'graphql-tag';

export const CreateCourseMutation = gql`
  mutation createCourseDetailsMutation($content: CreateCourseDetailsContent!) {
    createCourseDetails(content: $content) {
      errors {
        field_name
        message
      }
      message
      statusCode
      course {
        id
        name
        description
        objectives
        semester
        status
        content_lang
        points
        image {
          url
        }
        age_groups {
          name
          id
        }
        grades {
          id
          name
        }
        roles {
          id
          name
        }
        certificates {
          id
        }
        category {
          id
          name
        }
        tags {
          id
          name
        }
        providers {
          id
          name
        }
        duration
        modules {
          id
          name
          course_id
          type
          description
          progress {
            percentage
          }
          description
          lessons {
            id
            name
            type
            progress {
              percentage
            }
            description
            units {
              id
              progress {
                percentage
              }
              name
              type
              content {
                media {
                  url
                }
              }
              workbook {
                id
                type
                estimated_time
                passing_grade
              }
            }
          }
        }
        assessments {
          id
          estimated_time
          passing_grade
          number_of_trials
          type
          questions {
            id
            type
            body
            answers {
              id
              body
            }
            right_answer {
              id
              body
            }
            right_explanation
            wrong_explanation
          }
        }
      }
    }
  }
`;
