import { useQuery } from '@apollo/client';
import { ListCompetitions } from '@services';
import { useCallback } from 'react';

export const useCompetitionsList = PAGINATION_PAGE_SIZE => {
  const { loading, error, data, refetch, networkStatus } = useQuery(
    ListCompetitions,
    {
      variables: {
        first: PAGINATION_PAGE_SIZE,
        page: 1,
      },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    }
  );

  const competitonsList = data?.listCompetitions?.data || [];
  const pageInfo = data?.listCompetitions?.paginatorInfo || {};

  const refetchPageResults = useCallback(async ({ first, page }) => {
    await refetch({ first, page });
  }, []);

  return {
    competitonsList,
    pageInfo,
    loading,
    error,
    networkStatus,
    refetchPageResults,
  };
};
