import gql from 'graphql-tag';

export const ListOngoingJobs = gql`
  query listJobs(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $sortBy: String
    $sortType: String
  ) {
    listJobs(
      first: $first
      after: $after
      last: $last
      before: $before
      sortBy: $sortBy
      sortType: $sortType
    ) {
      nodes {
        id
        adminId
        name
        createdAt
        numFailed
        numSuccess
        initialFile
        reportFile
        type
        status
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;
