/**
 * @author Raghda Wessam
 * @date 2021-11-08
 * @description Implementation of course outline related utilities.
 * @filename course_outline.js
 */
import { string, object, array, number } from 'yup';

/**
 * schema to validate the fields for module creation in course outline.
 */
export const MODULE_SCHEMA = object({
  name: string().required('Module name is required'),
  description: string().required('Module description is required'),
  lessons: array().of(object().required('Lesson name is required')),
});

/**
 * schema to validate the fields for pre_assessment creation in course outline.
 */
export const PRE_ASSESSMENT_SCHEMA = object({
  estimatedTime: number()
    .integer('Please enter a valid amount without decimal values')
    .positive('Please enter a valid amount')
    .required('Estimated time is required'),
});

/**
 * schema to validate the fields for post_assessment creation in course outline.
 */
export const POST_ASSESSMENT_SCHEMA = object({
  estimatedTime: number().required('Estimated time is required'),
});

/**
 * schema to validate the fields for graded exam creation in course outline.
 */
export const GRADED_ASSESSMENT_SCHEMA = object({
  estimatedTime: number()
    .integer('Please enter a valid amount without decimal values')
    .positive('Please enter a valid amount')
    .required('Estimated time is required'),
  passingGrade: number()
    .required('Min passing grade is required')
    .max(100, 'Max passing grade is 100')
    .positive('Please enter a valid amount'),
  numberOfTrials: number('please enter a valid number')
    .integer('Please enter a valid amount without decimal values')
    .positive('Please enter a valid amount')
    .when('reset_time', {
      is: v => !!v,
      then: number().required('Number of trails is required'),
    })
    .max(1000, 'Max number of trials is 1000'),
  resetTime: number('please enter a valid number')
    .integer('Please enter a valid amount without decimal values')
    .positive('Please enter a valid amount'),
});

/**Available module and assessment types for course outline */
export const COURSE_OUTLINE_TYPES = {
  MODULE: 'MODULE',
  PRE_ASSESSMENT: 'PRE',
  POST_ASSESSMENT: 'POST',
  GRADED_ASSESSMENT: 'GRADED',
};
