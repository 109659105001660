import gql from 'graphql-tag';

// export const LoginFormMutation = gql`
//   mutation AdminLogin($email: String!, $password: String!) {
//     adminLogin(email: $email, password: $password) {
//       admin: authenticatable {
//         ... on Admin {
//           id
//           email
//           name
//           role {
//             id
//             name
//           }
//           __typename
//         }
//         __typename
//       }
//       __typename
//       errors {
//         field_name
//         message
//       }
//       statusCode
//       message
//       token
//     }
//   }
// `;

export const LoginFormMutation = gql`
  mutation AdminLogin($email: String!, $password: String!, $code: Int!) {
    adminLogin(email: $email, password: $password, code: $code) {
      statusCode
      message

      authenticatable {
        ... on Admin {
          id
          name
          email
          role {
            name
          }
        }
      }
      token
      expires_in
      errors {
        field_name
        message
      }
    }
  }
`;
