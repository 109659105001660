import gql from 'graphql-tag';

export const EditNavbarSection = gql`
  mutation updateNavbarItem(
    $id: ID!
    $name: String
    $index: Int
    $type: NavbarDataType
    $tags_ids: [ID!]
    $categories_ids: [ID!]
    $roles_ids: [ID!]
    $is_active: Boolean
  ) {
    updateNavbarItem(
      id: $id
      name: $name
      index: $index
      type: $type
      tags_ids: $tags_ids
      categories_ids: $categories_ids
      roles_ids: $roles_ids
      is_active: $is_active
    ) {
      navbar_item {
        id
        name
        index
        type
        categories_ids
        tags_ids
        is_active
      }
    }
  }
`;
