import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { Icon } from 'antd';

/** styling of add button */
const CustomButton = styled(Link)`
  text-transform: capitalize;
  i {
    margin: auto 5px;
  }
`;

/** new course link in courses list scene */
const AddButton = ({ text, linkTo, dataCy }) => {
  return (
    <CustomButton className="ant-btn" to={linkTo} data-cy={dataCy}>
      {text}
      <Icon type="plus-circle" />
    </CustomButton>
  );
};

AddButton.propTypes = {
  /** text prop of type string for button text */
  text: PropTypes.string,
  /** linkTo prop of type string as link direction */
  linkTo: PropTypes.string,
  /** dataCy prop of type string as cypress helper selector */
  dataCy: PropTypes.string,
};

export default AddButton;
