import gql from 'graphql-tag';

export const EditAssessmentsMutation = gql`
  mutation updateAssessment($content: UpdateAssessmentInput!) {
    updateAssessment(content: $content) {
      statusCode
      message
      errors {
        field_name
      }
    }
  }
`;
