import gql from 'graphql-tag';

export const getAgeGroups = gql`
  query {
    getAgeGroups {
      id
      name
    }
  }
`;
