const validationRules = {
  questionsFileValidation: [
    {
      required: true,
      message: 'Please upload questions file',
    },
    {
      validator: (rule, value, callback) => {
        {console.log(value)}
        if (value) {
          const validFileType =
            value.type === 'text/csv' ||
            value.type === 'text/comma-separated-values' ||
            value.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"||
            value.type === 'application/csv' ||
            value.type === 'application/excel' ||
            value.type === 'application/vnd.ms-excel' ||
            value.type === 'application/vnd.msexcel';
          setTimeout(() => {
            if (value && !validFileType) {
              callback([new Error('You can only upload CSV file!')]);
            } else {
              callback();
            }
          }, 1000);
        }
      },
    },
    {
      validator: (rule, value, callback) => {
        if (value) {
          const validFileSize = value.size / 1024 / 1024 <= 5;
          setTimeout(() => {
            if (value && !validFileSize) {
              callback([new Error('File must be smaller than 5MB!')]);
            } else {
              callback();
            }
          }, 1000);
        }
      },
    },
  ],
};

export default validationRules;
