import gql from 'graphql-tag';

export const CERTIFICATES_TEMP_LIST = gql`
  query providersCertificates($id: ID!) {
    providersCertificates(id: $id) {
      nodes {
        id
        name
        imageData
        providerId
      }
    }
  }
`;
