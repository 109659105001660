import styled from '@emotion/styled/macro';

export const RightAnswerCheck = styled.span`
  position: absolute;
  top: 0;
  border: 0;
  bottom: 0;
  margin: auto;
  left: calc(100% + 42px);
  width: 300px;
  margin-top: 9px;
  img {
    width: 30px;
  }
`;
export const AnswerInput = styled.div``;
export const QuestionWrapper = styled.div`
  margin-top: 32px;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 4px 10px 0 rgb(60 60 60 / 10%);
  margin-bottom: 25px;

  .assessment-body {
    &.collapsed {
      display: none;
      height: 0;
    }
  }

  .required {
    color: red;
    ::before {
      display: none;
    }
  }

  input::placeholder {
    color: #979797;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
  }

  input {
    font-size: 14px;
    font-weight: 500;
    color: #4a4a4a;
  }

  label {
    color: #4a4a4a;
    font-family: Roboto;
    font-size: 16px;
    margin-bottom: 10px;
    display: block;
  }

  .radio-btn-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    label {
      margin-bottom: 0;
      margin-left: 5px;
    }
  }
`;

export const QuestionHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #979797;
  border-radius: 5px;
  background-color: #f8f8f8;
  padding: 12px 30px;
  min-height: 70px;

  label {
    color: #333333;
    font-family: Roboto;
    font-size: 17px;
    font-weight: bold;
    margin-right: 50px;
  }
`;

export const InputWrapper = styled.div`
  margin-bottom: 20px;

  &.answer-input-wrapper {
    display: flex;
    align-items: center;

    .input-error-wrapper {
      width: calc(100% - 24px);
    }

    input[type='radio'] {
      margin-right: 10px;
    }
  }
  input[type='text'] {
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    background-color: #fff;
    min-height: 40px;
    width: 100%;
    padding: 12px;
  }

  span.required {
    color: red;
    ::before {
      display: none;
    }
  }
`;

export const ErrorMsgWrapper = styled.div`
  ~ input {
    border: 1px solid red;
  }
  color: red;
  margin-top: 5px;
`;

export const QuestionBody = styled.div`
  padding: 0;
`;

export const ContentWrapper = styled.div`
  padding: 20px;

  .form-control {
    margin-bottom: 20px;
  }
`;

export const ArrowWrapper = styled.div`
  position: absolute;
  cursor: pointer;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  img {
    max-width: 15px;
  }

  img.flip {
    transform: rotate(180deg);
  }
`;

export const AnswersWrapper = styled.div`
  max-width: 35%;
  div {
    position: relative;
  }
  h2 {
    color: #4a4a4a;
    font-weight: normal;
    font-size: 16px;

    span.required {
      color: red;
    }
  }

  .right-answer-note {
    color: #999999;
    font-size: 13px;
    margin: 10px 0 20px 0;
  }
`;

export const AnswerInputWrapper = styled.div`
  position: relative;
`;

export const DeleteQuestionBtn = styled.div`
  display: flex;
  padding: 30px;
  justify-content: center;
  border-top: 1px solid #c4c4c4;

  button {
    border: 0;
    background: #fff !important;
    outline: none;
    box-shadow: none;

    span {
      text-decoration: underline;
      color: #ff574d;
      font-family: Roboto;
      font-size: 16px;
    }
  }

  button.disabled {
    span {
      color: #c4c4c4;
    }
  }
`;
