import gql from 'graphql-tag';

export const EditPointsMutation = gql`
  mutation adminUpdatePoint(
    $pointId: ID!
    $point: PointUpdateType!
  ) {
    adminUpdatePoint(
      pointId: $pointId
      point: $point
    ) {
      point {
        title
        value
        id
      }
      errors {
        errors
      }
      statusCode
    }
  }
`;
