import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { COURSE_OUTLINE_TYPES } from '@utilities';
import { Link } from 'react-router-dom';
import {
  CourseContentContainer,
  CourseContentNavWrapper,
  CourseContentWrapper,
  ModuleContentWrapper,
} from './styles/course_content_styles';
import CourseContentNav from './course_content_nav';
import AssessmentContent from './assessment_content';
import LessonContent from './lesson_content';
import { useMutation } from '@apollo/client';
import { PUBLISH_COURSE } from '@services';
import { notificationHandler } from '@utilities';

const CourseContent = props => {
  const preAssessment = props.assessments.find(
    assessment => assessment.type === COURSE_OUTLINE_TYPES.PRE_ASSESSMENT
  );
  const postAssessment = props.assessments.find(
    assessment => assessment.type === COURSE_OUTLINE_TYPES.POST_ASSESSMENT
  );
  const gradedExam = props.assessments.find(
    assessment => assessment.type === COURSE_OUTLINE_TYPES.GRADED_ASSESSMENT
  );
  const initialActiveTab = preAssessment
    ? preAssessment[0]
    : props.modules[0]?.lessons[0];

  const [activeTabContent, setActiveTabContent] = useState(initialActiveTab);
  const [disabledPublishCourse, setDisabledPublishCourse] = useState(true);
  const [noAssessments, setNoAssessments] = useState(false);
  const initialActiveCourseOutlineType = preAssessment
    ? COURSE_OUTLINE_TYPES.PRE_ASSESSMENT
    : COURSE_OUTLINE_TYPES.MODULE;

  const [activeCourseOutlineType, setActiveCourseOutlineType] = useState(
    initialActiveCourseOutlineType
  );

  const courseId = props?.courseId;
  const [publishCourse] = useMutation(PUBLISH_COURSE, {
    variables: { ids: courseId },
    onCompleted: ({ publishCourse: { errors } }) => {
      notificationHandler('publish', errors, () => {
        props.history.push('/courses');
      });
    },
  });

  /**
   * get active assessment based on the active tab Type
   * @returns {Assessment} active Assessment;
   */
  const getActiveAssessment = () => {
    let activeAssessment;

    switch (activeCourseOutlineType) {
      case COURSE_OUTLINE_TYPES.PRE_ASSESSMENT:
        activeAssessment = preAssessment;
        break;
      case COURSE_OUTLINE_TYPES.POST_ASSESSMENT:
        activeAssessment = postAssessment;
        break;
      case COURSE_OUTLINE_TYPES.GRADED_ASSESSMENT:
        activeAssessment = gradedExam;
        break;
      default:
        break;
    }
    return activeAssessment;
  };
  /**
   * Active Module based on the active lesson if exist.
   */
  const activeModule = props.modules?.find(module => {
    return module?.lessons.find(lesson => lesson.id === activeTabContent?.id);
  });

  const addUnitCallback = courseDetails => {
    console.log(courseDetails);
    setDisabledPublishCourse(
      courseDetails.modules?.some(module =>
        module?.lessons?.some(lesson => lesson?.units?.length === 0)
      ) ||
        courseDetails.modules?.some(module =>
          module?.lessons?.some(lesson =>
            lesson?.units?.some(
              unit => unit?.workbook[0]?.questions?.length === 0
            )
          )
        )
    );
  };

  useEffect(() => {
    setNoAssessments(
      props?.assessments?.some(
        assessment => assessment?.questions?.length === 0
      )
    );
  }, [props.assessments]);

  useEffect(() => {
    setDisabledPublishCourse(
      props?.modules?.some(module =>
        module?.lessons?.some(lesson => lesson?.units?.length === 0)
      )
    );

    setDisabledPublishCourse(
      props?.module?.lessons?.some(lesson =>
        lesson?.units.some(unit => unit?.workbook[0]?.questions.length === 0)
      )
    );
  }, [props.modules]);

  useEffect(() => {
    console.log(activeTabContent);
    if (activeTabContent?.units) {
      setDisabledPublishCourse(activeTabContent?.units?.length === 0);
    }
    if (activeTabContent?.questions) {
      setNoAssessments(activeTabContent?.questions?.length === 0);
    }
  }, [activeTabContent]);

  return (
    <CourseContentContainer>
      <h1>Course Content</h1>
      <p>Here you can add all the content of the course.</p>
      <CourseContentWrapper>
        <CourseContentNavWrapper>
          <CourseContentNav
            courseId={courseId}
            preAssessment={preAssessment}
            postAssessment={postAssessment}
            gradedExam={gradedExam}
            modules={props.modules}
            activeModule={activeModule}
            activeTabContent={activeTabContent}
            setActiveTabContent={setActiveTabContent}
            activeCourseOutlineType={activeCourseOutlineType}
            setActiveCourseOutlineType={setActiveCourseOutlineType}
          />
        </CourseContentNavWrapper>

        <ModuleContentWrapper>
          {activeCourseOutlineType === COURSE_OUTLINE_TYPES.MODULE ? (
            <LessonContent
              lesson={activeTabContent}
              module={activeModule}
              courseId={props.courseId}
              addUnitCallback={addUnitCallback}
            />
          ) : (
            <AssessmentContent
              assessment={
                activeTabContent ? activeTabContent : getActiveAssessment()
              }
            />
          )}
        </ModuleContentWrapper>
      </CourseContentWrapper>
      <div className="save-btn-wrapper">
        <button
          className={(disabledPublishCourse || noAssessments) && 'disabled'}
          disabled={disabledPublishCourse || noAssessments}
          onClick={publishCourse}
        >
          Publish course
        </button>
        <Link to="/courses" className="cancel">
          Save As Draft
        </Link>
      </div>
    </CourseContentContainer>
  );
};

export default CourseContent;

CourseContent.propTypes = {
  courseId: PropTypes.string.isRequired,
  onCompleted: PropTypes.func,
  modules: PropTypes.array,
  assessments: PropTypes.array,
};
