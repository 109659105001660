import gql from 'graphql-tag';

export const getCourseAnalyticsQuery = gql`
  query getCoursesAnalytics($from: Date!, $to: Date!) {
    getCoursesAnalytics {
      enrollment_distribution(from: $from, to: $to) {
        total_users
        total_enrollments
        completed_enrollments
      }
      enrollment_breakdown(from: $from, to: $to) {
        breakdown_per_period {
          date
          count
        }
        breakdown_completed_per_period {
          date
          count
        }
      }
      enrollment_rate(from: $from, to: $to) {
        highest_enrollment_rate {
          course_id
          total_enrollments_count
          course_name
          provider_name
        }
        lowest_enrollment_rate {
          course_id
          total_enrollments_count
          course_name
          provider_name
        }
      }

      completion_rate(from: $from, to: $to) {
        highest_completion_rate {
          course_id
          course_completions_count
          total_completions_count
          course_name
          provider_name
        }
        lowest_completion_rate {
          course_id
          course_completions_count
          total_completions_count
          course_name
          provider_name
        }
      }
      course_distribution(from: $from, to: $to) {
        active
        inactive
      }
      statusCode
      message
      errors {
        field_name
        message
      }
    }
  }
`;
