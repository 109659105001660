import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { Button, Icon } from 'antd';

/** styling of add button */
const CustomButton = styled(Button)`
  text-transform: capitalize;
  i {
    margin: auto 5px;
  }
`;

/** new course link in courses list scene */
const ToggleButton = ({
  checkedText,
  unCheckedText,
  checked,
  toggleChecked,
}) => {
  return (
    <CustomButton className="ant-btn" onClick={toggleChecked}>
      {console.log({ checked })}
      {checked ? unCheckedText : checkedText}
      {checked ? <Icon type="close-circle" /> : <Icon type="check-circle" />}
    </CustomButton>
  );
};

ToggleButton.propTypes = {
  /** checkedText prop of type string for button text */
  checkedText: PropTypes.string,

  /** unCheckedText prop of type string for button text */
  unCheckedText: PropTypes.string,

  checked: PropTypes.bool,
  toggleChecked: PropTypes.func,
};

export default ToggleButton;
