import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Select, Tooltip } from 'antd';
import { en as strings } from '../locales/data.json';

const { Option } = Select;

/**
 * certificate dropdown
 * @param {object} props
 * @param {Object[]} props.list // list of certificates
 * @param {string} list[].name // name of the certificate
 * @param {string} list[].id // certificate id
 * @param {string} list[].image.url // link for certificate image
 * @param {object} props.form // passed from formik field component
 * @params {object} props.field // formik field object
 */
const CertificateComponent = ({ list, form, field }) => {
  const onChange = useCallback(
    option => {
      console.log(option);
      form.setFieldValue(field.name, option);
    },
    [form, field]
  );

  return (
    <Select
      placeholder={strings.certificateSelectFieldPlaceHolder}
      onChange={onChange}
      value={field.value}
    >
      {list?.map(certificate => (
        <Option key={certificate.id} value={certificate.id}>
          <Tooltip
            placement="topLeft"
            title={<img src={certificate?.image?.url} alt={certificate.id} />}
            arrowPointAtCenter
            overlayStyle={{
              maxWidth: '400px',
              width: '400px',
            }}
          >
            <div>{certificate.name}</div>
          </Tooltip>
        </Option>
      ))}
    </Select>
  );
};

CertificateComponent.propTypes = {
  list: PropTypes.array,
  form: PropTypes.shape({
    /** setFieldValue prop of type function passed from formik to set the values  */
    setFieldValue: PropTypes.func,
  }).isRequired,
  field: PropTypes.shape({
    /** name prop of type string passed from formik to set the values  */
    name: PropTypes.string,
  }).isRequired,
};

CertificateComponent.defaultProps = {
  list: [],
};

export default CertificateComponent;
