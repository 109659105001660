import React from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import config from '../wyziwyg_editor/partials/custom_config';
import PropTypes from 'prop-types';

export const WysiWygEditorFormik = ({
  form,
  field,
  placeHolder,
  hasUploadImage,
  disabled,
}) => (
  <CKEditor
    editor={ClassicEditor}
    data={field?.value || ''}
    config={config(placeHolder, hasUploadImage)}
    onChange={(ev, editor) => form.setFieldValue(field.name, editor.getData())}
    disabled={disabled}
    name={field?.name}
    onInit={editor => {
      //Control Editor Height
      editor.editing.view.change(writer => {
        writer.setStyle(
          'height',
          '200px',
          editor.editing.view.document.getRoot()
        );
      });
    }}
  />
);

WysiWygEditorFormik.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  placeHolder: PropTypes.string,
  hasUploadImage: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default WysiWygEditorFormik;
