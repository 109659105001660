import gql from 'graphql-tag';

export const CREATE_MATCH_QUESTION = gql`
  mutation createMatchQuestion(
    $questionableId: ID!
    $questions: [QuestionInput!]!
  ) {
    createMatchQuestion(
      questionableId: $questionableId
      questions: $questions
    ) {
      statusCode
      errors {
        errors
        fieldName
      }
    }
  }
`;
