import styled from '@emotion/styled/macro';
import { colors } from '@utilities';

const ErrorNote = styled.p`
  color: ${colors.errorColor};
  margin: 0 2px 0 15px;
  font-family: 'Gess';
  font-weight: 300;
`;

export default ErrorNote;
