import gql from 'graphql-tag';

export const VERIFY_USER = gql`
  mutation adminVerifyUser($userId: ID!) {
    adminVerifyUser(userId: $userId) {
      statusCode
      errors {
        errors
        fieldName
      }
    }
  }
`;
