import React from 'react';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';
import { Form, Input, Radio, InputNumber, Select, Spin } from 'antd';

/** styling for unit type radio group */
const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
};

/** styling of input hint */
const Hint = styled.p`
  opacity: 0.4;
  font-size: 12px;
  margin: 0;
  line-height: 20px;
`;

/**
 * setting values of select field on change
 *
 * @param {any} val
 * @param {String} inputName input filed name
 * @param {Object} form ant d form object
 * @param {func} handleChange custom passed handleChange functionality
 */
const handleSelectValuesChange = (val, inputName, form, handleChange) => {
  form.setFieldsValue({ [inputName]: val });
  handleChange && handleChange(val);
};

/** create form item based on type */
const createFormItem = (type, props) => {
  const { TextArea } = Input;
  const { Option } = Select;

  let item;
  switch (type) {
    case 'text':
      item = <Input {...props} disabled={props.disabled} />;
      break;
    case 'textarea':
      item = <TextArea rows={4} disabled={props.disabled} />;
      break;
    case 'number':
      const { onChange, min, max } = props;
      item = (
        <InputNumber
          style={{ width: '100%' }}
          min={min}
          max={max}
          onChange={onChange}
          disabled={props.disabled}
        />
      );
      break;
    case 'radioGroup':
      let parsedRadioOptions = [];
      for (let i = 0; i < props.options.length; i++) {
        parsedRadioOptions.push(
          <Radio
            key={props.options[i].value + '-' + props.options[i].id}
            style={radioStyle}
            value={props.options[i].value}
          >
            {props.options[i].label}
          </Radio>
        );
      }
      item = (
        <Radio.Group disabled={props.disabled} onChange={props.onChange}>
          {parsedRadioOptions}
        </Radio.Group>
      );
      break;
    case 'select':
      item = (
        <>
          <Select
            showSearch={props.showSearch}
            mode={props.mode}
            optionFilterProp={props.optionFilterProp}
            disabled={props.disabled}
            placeholder={props.placeholder}
            // select value is not mapped to field [TODO: needs better approach]
            value={props.form.getFieldValue(props.inputName)}
            filterOption={props.filterOption}
            onChange={val =>
              handleSelectValuesChange(
                val,
                props.inputName,
                props.form,
                props.onChange
              )
            }
          >
            {props.options?.map(item => (
              <Option
                key={item.value + '-' + item.id}
                value={item.value || item.id}
              >
                {item.label || item.name}
              </Option>
            ))}
          </Select>
          {props.loading && <Spin />}
        </>
      );
      break;
    case 'readonlyInput':
      item = <Input disabled={true} />;
      break;
    default:
      item = (
        <Input
          onChange={val =>
            handleSelectValuesChange(
              val,
              props.inputName,
              props.form,
              props.onChange
            )
          }
        />
      );
      break;
  }

  return item;
};

/** FormItem component */
const FormItem = ({
  inputName,
  inputType,
  inputLabel,
  placeHolder,
  validationRules,
  inputPrefix,
  form,
  onChange,
  min,
  max,
  initialValue,
  options,
  hidden,
  hints,
  hideLabel,
  validateTrigger,
  mode,
  disabled,
  optionFilterProp,
  loading,
  className,
  showSearch,
  filterOption,
}) => {
  return (
    <>
      <Form.Item
        style={hidden ? { display: 'none' } : {}}
        label={!hideLabel && (inputLabel || placeHolder)}
        className={className}
      >
        {form.getFieldDecorator(inputName, {
          rules: validationRules,
          initialValue,
          validateTrigger,
        })(
          createFormItem(inputType, {
            form,
            inputName,
            prefix: inputPrefix,
            type: inputType,
            placeholder: placeHolder,
            onChange,
            min,
            max,
            options,
            mode,
            disabled,
            optionFilterProp,
            loading,
            initialValue,
            showSearch,
            filterOption,
          })
        )}
        {hints?.length &&
          hints.map((hintTxt, index) => {
            return <Hint key={index}>{hintTxt}</Hint>;
          })}
      </Form.Item>
    </>
  );
};

FormItem.propTypes = {
  /** inputName prop of type string  as field name*/
  inputName: PropTypes.string.isRequired,
  /** inputType prop of type string for input field type */
  inputType: PropTypes.string,
  /** inputLabel prop of type string for input prefix label */
  inputLabel: PropTypes.string,
  /** placeHolder prop of type string for input placeholder */
  placeHolder: PropTypes.string,
  /** validationRules prop of type array for validation rules objects */
  validationRules: PropTypes.array,
  /** inputPrefix prop of type object for input prefix icons */
  inputPrefix: PropTypes.object,
  /** form prop of type object passed from ant d form */
  form: PropTypes.object.isRequired,
  /** onChange prop of type function for handle changing in input value */
  onChange: PropTypes.func,
  /** min prop of type number for min input value for input number type */
  min: PropTypes.number,
  /** max prop of type number for max input value for input number type */
  max: PropTypes.number,
  /** prop options of type array for radio group options */
  options: PropTypes.array,
  /** hidden attr of type boolean for hide input field */
  hidden: PropTypes.bool,
  /** initialValue prop of type any*/
  initialValue: PropTypes.any,
  /** hints prop of type array for input notes strings */
  hints: PropTypes.arrayOf(String),
  /** hideLabel prop of type boolean for input label visibility */
  hideLabel: PropTypes.bool,
  /* validateTrigger prop of type array for events to trigger field validation upon*/
  validateTrigger: PropTypes.arrayOf(String),
  /** mode prop of type string specific for select input ex: "multiple" */
  mode: PropTypes.string,
  /** disabled prop of type boolean for disabling input changes */
  disabled: PropTypes.bool,
  optionFilterProp: PropTypes.string,
  /** loading prop of type boolean for fetching options data */
  loading: PropTypes.bool,
  /** className prop of type string for field className */
  className: PropTypes.string,
  /** showSearch prop of type boolean for allowing select options search */
  showSearch: PropTypes.bool,
  /** filterOption prop of type function for handling searching select options */
  filterOption: PropTypes.func,
};

FormItem.defaultProps = {
  /** default prop text for input type if not provided */
  inputType: 'text',
};

export default FormItem;
