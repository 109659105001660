import React, { useState } from 'react';
import { MainLayout } from '@components';
import { startOfYear, format } from 'date-fns';
import { AnalyticsContext } from './analytics-context';
import Header from './header';
import EnrollmentsCard from './enrollments-card';
import UserAnalyticsCards from './user-analytics-cards';
import ProviderAnalyticsCards from './provider-analytics-cards';
import { analyticsTabs } from '@utilities';
import './index.css';

const DashboardAnalytics = () => {
  const [currentPeriod, setCurrentPeriod] = useState({
    alias: 'This Year',
    from: format(startOfYear(new Date()), 'yyyy-MM-dd'),
    to: format(new Date(), 'yyyy-MM-dd'),
    type: 'year',
  });

  const [activeAnalyticsTab, setActiveAnalyticsTab] = useState(
    analyticsTabs.courses
  );

  /*
  This function return the cards/content of the currently selected/active tab 
  (courses, users or providers cards)
  */
  const getActiveAnalyticsTabContent = () => {
    switch (activeAnalyticsTab) {
      case analyticsTabs.courses:
        return <EnrollmentsCard />;
      case analyticsTabs.users:
        return <UserAnalyticsCards />;
      case analyticsTabs.providers:
        return <ProviderAnalyticsCards />;
      default:
        return null;
    }
  };

  return (
    <AnalyticsContext.Provider
      value={{
        currentPeriod,
        updateCurrentPeriod: setCurrentPeriod,
        activeAnalyticsTab,
        setActiveAnalyticsTab,
      }}
    >
      <MainLayout bannerTitle={'Analytics'}>
        <div className="analytics-dashboard">
          <Header />
          {getActiveAnalyticsTabContent()}
        </div>
      </MainLayout>
    </AnalyticsContext.Provider>
  );
};

export default DashboardAnalytics;
