import gql from 'graphql-tag';

export const USER_COMMENTS_LIST = gql`
  query listUserComments(
    $userId: ID!
    $first: Int
    $after: String
    $last: Int
    $before: String
    $sortBy: String
    $sortType: String
  ) {
    listUserComments(
      userId: $userId
      first: $first
      after: $after
      last: $last
      before: $before
      sortBy: $sortBy
      sortType: $sortType
    ) {
      nodes {
        key: id
        id
        comment
        courseName
        createdAt
        crumbs
        status
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export default USER_COMMENTS_LIST;
