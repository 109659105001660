/**
 * @author Raghda Wessam
 * @date 2020-10-17
 * @description Course Outline component is responsible for displaying, creating and
 * deleting already created modules and saving them as the course coutline (step 2)
 * @filename course_outtline.js
 */

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { Button } from 'antd';
import { COURSE_OUTLINE_TYPES } from '@utilities';
import { SaveOutlineButton } from './styles/course_outline_styles';
import ModuleCard from './module_card';
import AssessmentCard from './assessment_card';
import { GetCourseQuery } from '@services';
import { useLazyQuery } from '@apollo/client';

const CourseOutline = props => {
  //initial state of modules , at least 1 module and 1 lesson in the view
  let initialModules = [
    {
      name: '',
      description: '',
      lessons: [{ name: '' }],
    },
  ];
  let initialAssessments = [];

  if (props.modules?.length > 0) {
    initialModules = props.modules;
  }
  if (props.assessments?.length > 0) {
    initialAssessments = props.assessments;
  }
  const [modules, setModules] = useState(initialModules);
  const [refetchCourse, setRefetchCourse] = useState(false);
  const [assessments, setAssessments] = useState(initialAssessments);

  // active type (module or assessment), initially module
  const [activeCourseOutlineType, setActiveCourseOutlineType] = useState(
    COURSE_OUTLINE_TYPES.MODULE
  );
  // current index of expanded module
  const [activeModuleIndex, setActiveModuleIndex] = useState(0);

  const onToggleCard = (cardType, index) => {
    // TODO: implement switch between modules and assessments
    switch (cardType) {
      case COURSE_OUTLINE_TYPES.MODULE:
        setActiveCourseOutlineType(COURSE_OUTLINE_TYPES.MODULE);
        setActiveModuleIndex(index);
        break;
      case COURSE_OUTLINE_TYPES.PRE_ASSESSMENT:
        setActiveCourseOutlineType(COURSE_OUTLINE_TYPES.PRE_ASSESSMENT);
        break;
      case COURSE_OUTLINE_TYPES.POST_ASSESSMENT:
        setActiveCourseOutlineType(COURSE_OUTLINE_TYPES.POST_ASSESSMENT);
        break;
      case COURSE_OUTLINE_TYPES.GRADED_ASSESSMENT:
        setActiveCourseOutlineType(COURSE_OUTLINE_TYPES.GRADED_ASSESSMENT);
        break;
      default:
        break;
    }
  };

  const onSaveModule = saveModule => {
    const updatedModules = [...modules];
    updatedModules.splice(activeModuleIndex, 1, saveModule);
    setModules(updatedModules);
    setRefetchCourse(true);
  };

  const onAddModule = () => {
    const newModule = {
      name: '',
      description: '',
      lessons: [{ name: '' }],
    };
    const updatedModules = modules.concat(newModule);
    setModules(updatedModules);
  };

  const onDeleteModule = () => {
    const updatedModules = modules;
    updatedModules.splice(activeModuleIndex, 1);
    onToggleCard(COURSE_OUTLINE_TYPES.MODULE, updatedModules?.length - 1);
    setModules(updatedModules);
  };

  const onSaveAssessment = (type, assessmentData) => {
    const updatedAssessments = assessments;
    const assessmentIndex = assessments.findIndex(
      assessment => assessment.type === type
    );
    if (assessmentIndex === -1) {
      updatedAssessments.push(assessmentData);
    } else {
      updatedAssessments.splice(assessmentIndex, 1, assessmentData);
    }

    setAssessments(updatedAssessments);
  };

  const onDeleteAssessment = type => {
    const updatedAssessments = assessments.filter(
      assessment => assessment.type !== type
    );
    setAssessments(updatedAssessments);
  };
  const [fetchCourse] = useLazyQuery(GetCourseQuery, {
    variables: { id: props?.courseId },
    onCompleted: data => {
      if (data?.getCourse?.modules) setModules(data?.getCourse?.modules);
    },
  });
  const onSaveCourseOutline = () => {
    props.onCompleted(modules, assessments);
  };

  useEffect(() => {
    if (refetchCourse) {
      fetchCourse();
    }
  }, [refetchCourse]);

  return (
    <>
      <AssessmentCard
        opened={activeCourseOutlineType === COURSE_OUTLINE_TYPES.PRE_ASSESSMENT}
        type={COURSE_OUTLINE_TYPES.PRE_ASSESSMENT}
        title={'Pre-assessment'}
        parentId={props.courseId}
        parentType={'COURSE'}
        assessment={assessments.find(
          assessment => assessment.type === COURSE_OUTLINE_TYPES.PRE_ASSESSMENT
        )}
        onSaveAssessment={savedAssessment => {
          onSaveAssessment(
            COURSE_OUTLINE_TYPES.PRE_ASSESSMENT,
            savedAssessment
          );
        }}
        onDeleteAssessment={() =>
          onDeleteAssessment(COURSE_OUTLINE_TYPES.PRE_ASSESSMENT)
        }
        onToggleAssessment={() =>
          onToggleCard(COURSE_OUTLINE_TYPES.PRE_ASSESSMENT)
        }
      />
      {modules?.map((module, index) => {
        return (
          <ModuleCard
            moduleData={module}
            key={index}
            courseId={props.courseId}
            opened={
              activeCourseOutlineType === COURSE_OUTLINE_TYPES.MODULE &&
              activeModuleIndex === index
            }
            canDeleteModule={index > 0}
            moduleIndex={index}
            onDeleteModule={onDeleteModule}
            onToggleModule={() =>
              onToggleCard(COURSE_OUTLINE_TYPES.MODULE, index)
            }
            onSaveModule={onSaveModule}
          />
        );
      })}
      <Button type="primary" onClick={onAddModule}>
        Add Module
      </Button>
      <AssessmentCard
        opened={
          activeCourseOutlineType === COURSE_OUTLINE_TYPES.POST_ASSESSMENT
        }
        type={COURSE_OUTLINE_TYPES.POST_ASSESSMENT}
        title={'Post-assessment'}
        parentId={props.courseId}
        parentType={'COURSE'}
        assessment={assessments.find(
          assessment => assessment.type === COURSE_OUTLINE_TYPES.POST_ASSESSMENT
        )}
        onSaveAssessment={savedAssessment => {
          onSaveAssessment(
            COURSE_OUTLINE_TYPES.POST_ASSESSMENT,
            savedAssessment
          );
        }}
        onDeleteAssessment={() =>
          onDeleteAssessment(COURSE_OUTLINE_TYPES.POST_ASSESSMENT)
        }
        onToggleAssessment={() =>
          onToggleCard(COURSE_OUTLINE_TYPES.POST_ASSESSMENT)
        }
      />
      <AssessmentCard
        opened={
          activeCourseOutlineType === COURSE_OUTLINE_TYPES.GRADED_ASSESSMENT
        }
        type={COURSE_OUTLINE_TYPES.GRADED_ASSESSMENT}
        title={'Graded Exam'}
        parentId={props.courseId}
        parentType={'COURSE'}
        assessment={assessments.find(
          assessment =>
            assessment.type === COURSE_OUTLINE_TYPES.GRADED_ASSESSMENT
        )}
        onSaveAssessment={savedAssessment => {
          onSaveAssessment(
            COURSE_OUTLINE_TYPES.GRADED_ASSESSMENT,
            savedAssessment
          );
        }}
        onDeleteAssessment={() =>
          onDeleteAssessment(COURSE_OUTLINE_TYPES.GRADED_ASSESSMENT)
        }
        onToggleAssessment={() =>
          onToggleCard(COURSE_OUTLINE_TYPES.GRADED_ASSESSMENT)
        }
      />

      <SaveOutlineButton>
        <button
          disabled={modules[0]?.lessons[0]?.name === ''}
          className={modules[0]?.lessons[0]?.name === '' ? 'disabled' : ''}
          onClick={onSaveCourseOutline}
        >
          Save and Next
        </button>
        <Link to="/courses" className="cancel">
          Cancel
        </Link>
      </SaveOutlineButton>
    </>
  );
};

CourseOutline.propTypes = {
  courseId: PropTypes.string.isRequired,
  onCompleted: PropTypes.func,
  modules: PropTypes.array,
  assessments: PropTypes.array,
};

export default CourseOutline;
