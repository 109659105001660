import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { Layout } from 'antd';
import { AuthConsumer } from '@auth';
import HeaderMenu from './partials/header_menu';
import LayoutSider from './partials/sider';
import { adminSideListData, providerAdminSideListData } from './data/side_list';
import NotificationsBell from './partials/notifications_bell';
import { ADMIN_ENUMS_ROLES_QUERY } from '@constants';
import { Link } from 'react-router-dom';
import BackArrow from './images/arrow-left.png';

const { Header, Content } = Layout;

/** custom style for layout header */
const LayoutHeader = styled(Header)`
  display: flex;
  justify-content: flex-end;
  background-color: #fff;
  min-height: 8vh;
`;

/** custom style for layout */
const CustomLayout = styled(Layout)`
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
  min-height: 100vh;
  padding-left: 200px;
  font-family: 'Roboto', sans-serif;
`;

/** custom style for main content */
const CustomContent = styled(Content)`
  padding: 2vw;
  padding-top: 0;
  position: relative;
`;

/** styling of courses list scene title */
const Title = styled.p`
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  color: #4a4a4a;
  margin-bottom: 32px;
  min-width: 30%;
`;

/** styling of courses list table banner */
const Banner = styled.div`
  padding: 2vw;
  padding-bottom: 20px;
  margin-bottom: 32px;
`;

/** styling of nav buttons wrapper in table banner */
const NavWrapper = styled.div`
  button {
    & + button {
      margin-left: 10px;
    }
  }
`;

const LayoutBackLink = styled.div`
  isplay: flex;
  flex-flow: row;
  align-items: center;
  margin-bottom: 1rem;
  width: 100%;
  img {
    width: 20px;
    opacity: 0.8;
    margin-right: 12px;
  }
  a {
    color: #4a4a4a;
    font-weight: 500;
  }
`;
/** main dashboard layout */
class MainLayout extends Component {
  render() {
    const { children, bannerTitle, navBar, guest, layoutLink } = this.props;
    return (
      <AuthConsumer>
        {({ logout, user, role }) => (
          <CustomLayout>
            <LayoutSider
              list={
                role === ADMIN_ENUMS_ROLES_QUERY.admin
                  ? adminSideListData
                  : guest
                  ? []
                  : providerAdminSideListData
              }
            />
            <Layout>
              {!guest && (
                <LayoutHeader>
                  <NotificationsBell />
                  <HeaderMenu logout={logout} name={user.name} />
                </LayoutHeader>
              )}
              <Banner>
                {layoutLink && (
                  <LayoutBackLink>
                    <img src={BackArrow} alt="go back arrow" />
                    <Link to={layoutLink.link}>{layoutLink.text}</Link>
                  </LayoutBackLink>
                )}
                <Title>{bannerTitle}</Title>
                <NavWrapper>{navBar}</NavWrapper>
              </Banner>
              <CustomContent>{children}</CustomContent>
            </Layout>
          </CustomLayout>
        )}
      </AuthConsumer>
    );
  }
}

MainLayout.propTypes = {
  /** children prop of type any for rendered content */
  children: PropTypes.any,
  /** banner title prop of type string to be displayed as content title*/
  bannerTitle: PropTypes.string,
  /** banner navBar elements */
  navBar: PropTypes.object || PropTypes.string,
};

export default MainLayout;
