import gql from 'graphql-tag';

export const ADMIN_NOTIFICATIONS_SETTINGS_OPTIONS = gql`
  query notificationSubscriptions {
    notificationSubscriptions {
      id
      label
      name
      subscribed
    }
  }
`;
