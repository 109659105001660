import gql from 'graphql-tag';

export const Edit_QUESTION = gql`
  mutation editQuestion($questionId: ID!, $question: EditQuestionInput!) {
    editQuestion(questionId: $questionId, question: $question) {
      statusCode
      errors {
        errors
        fieldName
      }
    }
  }
`;
