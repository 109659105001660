import * as yup from 'yup';

const passwordRules = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;

export const SET_ADMIN_PASSWORD_SCHEMA = yup.object({
  email: yup
    .string()
    .email('Invalid email')
    .required('Email is required'),
  password: yup
    .string()
    .required('Password is required')
    .matches(
      passwordRules,
      'password must be 8 characters, must include Upper case , Lower case, number , and special character'
    ),
  password_confirmation: yup
    .string()
    .required('Password confirmation is required')
    .min(8, 'Password is min 8 characters')
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
});
