import styled from '@emotion/styled/macro';

export const NewUnitWrapper = styled.div`
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 4px 10px 0 rgb(60 60 60 / 10%);
  margin-bottom: 25px;

  input::placeholder {
    color: #979797;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
  }

  input {
    font-size: 14px;
    font-weight: 500;
    color: #4a4a4a;
  }

  label {
    color: #4a4a4a;
    font-family: Roboto;
    font-size: 16px;
    margin-bottom: 10px;
    display: block;

    .required {
      color: red;
      ::before {
        display: none;
      }
    }
  }

  .form-control {
    .note {
      color: #999999;
      font-family: Roboto;
      font-size: 13px;
      margin: 0;
      margin-bottom: 20px;
    }
  }

  .radio-btns-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .radio-btn-item {
      display: flex;
      align-items: center;
      min-width: 100px;

      label {
        margin-bottom: 0;
      }
    }

    .radio-btn-item:not(:last-child) {
      margin-right: 20px;
    }

    .custom-radio-item {
      text-align: center;
      display: block;
      width: 100%;

      input[type='radio'] {
        position: absolute;
        opacity: 0;
        width: 0;
        height: 0;
      }

      input[type='radio'] + img {
        cursor: pointer;
        border: 1px solid #c4c4c4;
        border-radius: 5px;
        background-color: #ffffff;
        padding: 12px;
        margin-bottom: 10px;
        width: 65px;
      }

      input[type='radio']:checked + img {
        border-color: #4a4a4a;
        background-color: #daeae9;
      }

      label.checked {
        font-weight: bold;
      }
    }
  }
`;

export const UnitHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #979797;
  border-radius: 5px;
  background-color: #f8f8f8;
  padding: 12px 30px;
  min-height: 70px;

  label {
    color: #4a4a4a;
    font-family: Roboto;
    font-size: 16px;
    font-weight: normal;
    margin-right: 50px;
    margin-bottom: 0;
  }
`;

export const InputWrapper = styled.div`
  min-width: 60%;
  input[type='text'] {
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    background-color: #fff;
    min-height: 40px;
    width: 100%;
    padding: 12px;
  }
`;

export const ErrorMsgWrapper = styled.div`
  ~ input {
    border: 1px solid red;
  }
  color: red;
  margin-top: 5px;
`;

export const UnitBody = styled.div`
  padding: 0;
  .file-name {
    margin-left: 5px;
  }
`;

export const ContentWrapper = styled.div`
  padding: 30px;
`;

export const TextAreaWrapper = styled.div`
  margin-bottom: 30px;
  label {
    color: #4a4a4a;
    font-family: Roboto;
    font-size: 16px;
    margin-bottom: 10px;
    display: block;
  }

  .ck.ck-content.ck-editor__editable {
    min-height: 200px;
  }
`;

export const ArrowWrapper = styled.div`
  position: absolute;
  cursor: pointer;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  img {
    max-width: 15px;
  }

  img.flip {
    transform: rotate(180deg);
  }
`;

export const UnitTypeContent = styled.div`
  &.video-content {
    .video-items-wrapper {
      margin-bottom: 30px;
    }

    input:disabled {
      cursor: not-allowed;
    }
  }
  .file-btn {
    border-radius: 5px;
    background-color: #e1e1e1;
    color: #4a4a4a;
    font-size: 14px;
    border: 0;
    padding: 5px 20px;
    line-height: 20px;
    cursor: pointer;
  }

  .file-btn.disabled {
    cursor: not-allowed;
  }

  .file-name {
    color: #4a4a4a;
    font-family: Roboto;
    font-size: 14px;
    margin-left: 10px;
  }

  .video-item {
    display: flex;

    input[type='radio'] {
      margin-right: 10px;
      margin-top: 5px;
    }
  }

  .video-item:first-of-type {
    margin-bottom: 20px;
  }

  .video-input-wrapper {
    width: 100%;
  }

  .workbook-content.course-content__content {
    width: 100%;

    h2 {
      color: #4a4a4a;
      font-family: Roboto;
      font-size: 16px;
      margin: 0;
      margin-bottom: 20px;
      font-weight: normal;
    }

    .radio-btns-wrapper {
      flex-direction: column;
      align-items: start;
    }

    textarea {
      border: 1px solid #c4c4c4;
      border-radius: 5px;
      padding: 15px;
      width: 100%;
      min-height: 100px;
    }

    .complete-text {
      color: #999999;
      font-family: Roboto;
      font-size: 13px;
      margin: 0;
      margin-top: 10px;
    }
  }

  .passing-grade-wrapper {
    margin-bottom: 30px;
    .is-graded {
      display: flex;
      align-items: center;
      color: #4a4a4a;
      font-family: Roboto;
      font-size: 16px;

      input {
        margin-right: 10px;
      }
    }
    input[type='text'] {
      width: 35%;
    }
    .input-wrapper {
      margin-top: 20px;
    }
  }
`;
export const DeleteWorkbookUnit = styled.button`
  margin-left: 10px;
`;

export const DeleteUnitBtn = styled.div`
  display: flex;
  padding: 30px;
  justify-content: center;
  border-top: 1px solid #c4c4c4;

  button {
    border: 0;
    background: #fff !important;
    outline: none;
    box-shadow: none;
    text-decoration: underline;
    color: #ff574d;
    font-family: Roboto;
    font-size: 16px;
    cursor: pointer;
  }

  button.disabled {
    color: #c4c4c4;
    cursor: not-allowed;
  }
`;
