/**
 * @Author: sarahnouh
 * @Date:   2019-12-23T19:02:20+02:00
 * @Last modified by:   sarahnouh
 * @Last modified time: 2019-12-29T17:45:56+02:00
 */
import gql from 'graphql-tag';

export const LiveSessionsListQuery = gql`
  query listLiveSessions(
    $first: Int!
    $page: Int
    $LiveSession: LiveSessionFilters
  ) {
    listLiveSessions(first: $first, page: $page, LiveSession: $LiveSession) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        id
        title
        description
        image {
          url
        }
        started_at
        ended_at
        link
        roles {
          id
          name
        }
        age_groups {
          id
          name
        }
        grades {
          id
          name
        }
        is_published
      }
    }
  }
`;

export default LiveSessionsListQuery;
