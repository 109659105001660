import gql from 'graphql-tag';

export const COURSE_ANALYTICS = gql`
  query courseAnalytics($courseId: ID!, $startDate: String, $endDate: String) {
    courseAnalytics(
      courseId: $courseId
      startDate: $startDate
      endDate: $endDate
    ) {
      avgTimeConsumed: averageTime
      avgGraded: averageGrade
      enrolledCourses: enrolledUsers
      finishedCourses: finishedUsers
      lastEnrolled
      ageGradeDistribution: ageDistribution {
        name: age
        count
      }
      genders: genderDistribution {
        value: count
        name: gender
        status: completed
      }
    }
  }
`;
