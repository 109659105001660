import gql from 'graphql-tag';

export const CreateCourseUnitMutation = gql`
  mutation createCourseUnit($content: CreateUnitInput!) {
    createCourseUnit(content: $content) {
      statusCode
      message
      unit {
        id
        content {
          id
          body
          media {
            url
          }
          type
        }
        workbook {
          id
        }
      }
      errors {
        field_name
      }
    }
  }
`;
