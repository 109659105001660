/**
 * @Author: sarahnouh
 * @Date:   2020-01-16T17:22:52+02:00
 * @Last modified by:   sarahnouh
 * @Last modified time: 2020-01-16T17:59:04+02:00
 */

import gql from 'graphql-tag';

export const DeleteAdminMutation = gql`
  mutation deleteAdmins($ids: [ID!]!) {
    deleteAdmins(ids: $ids) {
      id
    }
  }
`;
