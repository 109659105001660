/** @jsx jsx */
import { jsx, Global, css } from '@emotion/core';
import { colors } from '@utilities';

const GlobalStyles = () => {
  return (
    <Global
      styles={css`
        html {
          box-sizing: border-box;
        }
        *,
        ::before,
        ::after {
          box-sizing: inherit;
        }
        html,
        body {
          scroll-behavior: smooth;
          padding: 0;
          margin: 0;
        }
        .ant-spin,
        .ant-spin-lg,
        .ant-spin-spinning {
          position: absolute;
          transform: translate(-50%, -50%);
          top: 50%;
          left: 50%;
          z-index: 10000;
        }

        tr.ant-table-row:nth-of-type(even) {
          background-color: ${colors.dimmedColor};
        }

        .actions-wrapper {
          display: grid;
          grid-template-columns: auto auto;
          grid-column-gap: 1rem;
        }

        .actions-wrapper > button,
        .actions-wrapper > a {
          align-self: center;
          margin-top: 0.8rem;
        }

        .filters {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .filters__actions {
          display: flex;
          margin-left: 2rem;
          margin-top: 0.8rem;
        }

        .filters__inputs {
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          grid-column-gap: 1rem;
          width: 100%;
        }

        .filters__actions > div {
          margin-bottom: 0;
        }

        .filters__actions > div:not(:first-of-type) {
          margin-left: 1rem;
        }

        .--disabled-row {
          pointer-events: none;
          opacity: 0.6;
        }

        .steps-content {
          margin-top: 1rem;

          &.hidden {
            display: none;
          }
        }

        .steps-action {
          display: flex;
          justify-content: space-between;
          flex-direction: row-reverse;
          margin-top: 1.5rem;
        }

        .steps-action > button:not(:first-of-type) {
          margin-left: 1rem;
        }

        .ant-tooltip-inner {
          img {
            width: 100%;
          }
        }
        .input-error {
          border: 1px solid red !important;
          border-radius: 5px;
        }
        .required {
          ::before {
            display: inline-block;
            margin-right: 4px;
            color: red;
            font-size: inherit;
            line-height: inherit;
            content: '*';
          }
        }
      `}
    />
  );
};

export default GlobalStyles;
