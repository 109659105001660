import gql from 'graphql-tag';

export const CreateQuestionsMutation = gql`
  mutation createQuestions($content: [QuestionInput!]!) {
    createQuestions(content: $content) {
      statusCode
      message
      errors {
        field_name
      }
      questions {
        id
        body
      }
    }
  }
`;

export const createQuestionMutation = gql`
  mutation createQuestions($content: QuestionInput!) {
    createQuestion(content: $content) {
      statusCode
      message
      question {
        id
        body
        type
        wrong_explanation
        right_explanation
        right_answer {
          body
          id
        }
        right_answer_id
        answers {
          id
          body
        }
      }
    }
  }
`;

export const deleteQuestionMutation = gql`
  mutation deleteQuestion($id: ID!) {
    deleteQuestion(id: $id) {
      statusCode
      message
    }
  }
`;
