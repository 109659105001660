import { useQuery } from '@apollo/client';
import { getUserAnalyticsQuery } from '@services';

export const useUserAnalytics = (from, to, persona) => {
  const { loading, error, data } = useQuery(getUserAnalyticsQuery, {
    variables: {
      from,
      to,
      persona,
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const analytics = data?.getUsersAnalytics;

  return { analytics, loading, error };
};
