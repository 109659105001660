import gql from 'graphql-tag';

export const AnalyticsQuery = gql`
  query analyticsQuery($startDate: String, $endDate: String) {
    analyticsQuery(startDate: $startDate, endDate: $endDate) {
      ageGrouping {
        age
        count
      }
      genders {
        name: gender
        value: count
        persona
      }
      personaCounts {
        count
        persona
      }
      studentGradeDistribution {
        count
        name: age
      }
      totalNumberUsers
      enrollment: enrollmentRate {
        name: completed
        value: count
        persona: role
      }
    }
  }
`;
