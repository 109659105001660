import React, { useContext } from 'react';
import LineChartCard from './line-chart-card';
import { useCourseAnalytics } from '@hooks';
import { AnalyticsContext } from './analytics-context';
import { Spin } from 'antd';
import CompletionRateCard from './completion-rate-card';
import TotalCountCard from './total-count-card';
import NewCreationCard from './new-creation-card';

/*
  This component represent the analytics cards related to courses
*/
const EnrollmentsCard = () => {
  const analyticsContext = useContext(AnalyticsContext);

  const { analytics, analyticsCourses, error, loading } = useCourseAnalytics(
    analyticsContext?.currentPeriod?.from,
    analyticsContext?.currentPeriod?.to
  );

  let enrollmentsValues = [];
  let completionValues = [];

  if (!loading && analytics) {
    enrollmentsValues = analytics?.enrollment_breakdown?.breakdown_per_period?.map(
      value => {
        return value.count;
      }
    );

    completionValues = analytics?.enrollment_breakdown?.breakdown_completed_per_period?.map(
      value => {
        return value.count;
      }
    );
  }

  if (loading) return <Spin size="large" />;

  return (
    <div className="analyticsDataPerPeriod-container">
      <div className="middle-cards-container">
        <LineChartCard
          period={analyticsContext?.currentPeriod}
          backgroundColor="#3751FF"
          borderColor="#3751FF"
          values={
            analyticsContext?.currentPeriod?.type === 'year'
              ? analytics?.enrollment_breakdown?.breakdown_per_period
              : enrollmentsValues
          }
          label={`Enrollments ${analyticsContext?.currentPeriod?.alias}`}
        />

        <LineChartCard
          period={analyticsContext?.currentPeriod}
          backgroundColor="#34C759"
          borderColor="#34C759"
          values={
            analyticsContext?.currentPeriod?.type === 'year'
              ? analytics?.enrollment_breakdown?.breakdown_completed_per_period
              : completionValues
          }
          label={`Completion ${analyticsContext?.currentPeriod?.alias}`}
        />

        <div className="completion-cards-container">
          {analytics?.completion_rate?.highest_completion_rate?.length > 0 && (
            <CompletionRateCard
              title={'Courses with highest completion rate'}
              courses={analytics?.completion_rate?.highest_completion_rate}
            />
          )}

          {analytics?.completion_rate?.lowest_completion_rate?.length > 0 && (
            <CompletionRateCard
              type={'lowest'}
              title={'Courses with lowest completion rate'}
              courses={analytics?.completion_rate?.lowest_completion_rate}
            />
          )}

          {analytics?.enrollment_rate?.highest_enrollment_rate?.length > 0 && (
            <CompletionRateCard
              type={'count'}
              title={'Courses with the highest enrollments'}
              courses={analytics?.enrollment_rate?.highest_enrollment_rate}
            />
          )}

          {analytics?.enrollment_rate?.lowest_enrollment_rate?.length > 0 && (
            <CompletionRateCard
              type={'count'}
              title={'Courses with the lowest enrollments'}
              courses={analytics?.enrollment_rate?.lowest_enrollment_rate}
            />
          )}
        </div>
      </div>
      <div className="analyticsTotalCounts-container">
        <NewCreationCard
          label={'New courses created'}
          publishedNumber={analyticsCourses?.active}
          unPublishedNumber={analyticsCourses?.inactive}
          publishedLabel={'Published'}
          unPublishedLabel={'Unpublished'}
        />

        <TotalCountCard
          title={'Total Enrollments'}
          numberPerPeriod={
            analytics?.enrollment_distribution?.total_enrollments
          }
          totalNumber={analytics?.enrollment_distribution?.total_users}
        />
        <TotalCountCard
          title={'Completed their courses '}
          numberPerPeriod={
            analytics?.enrollment_distribution?.completed_enrollments
          }
          totalNumber={analytics?.enrollment_distribution?.total_enrollments}
        />
      </div>
    </div>
  );
};

export default EnrollmentsCard;
