import gql from 'graphql-tag';

export const USER_DETAILS = gql`
  query viewUser($id: ID!) {
    viewUser(id: $id) {
      id
      first_name
      last_name
      gender
      phone

      age_group {
        id
        name
      }
      grade {
        id
        name
      }
      roles {
        id
        name
      }
    }
  }
`;
