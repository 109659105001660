import gql from 'graphql-tag';

export const GetCoursePrerequisitesQuery = gql`
  query {
    getCourseDetailsPrerequisites {
      statusCode
      message
      categories {
        id
        name
      }
      providers {
        value: id
        label: name
      }
      tags {
        value: id
        label: name
      }
      audience_personas {
        id
        name
      }
      grades {
        value: id
        label: name
      }
      age_groups {
        value: id
        label: name
      }
      certificates {
        id
        name
        image {
          url
        }
      }
    }
  }
`;
