import gql from 'graphql-tag';

export const COURSE_ITEMS_LIST = gql`
  query listCourseItems($courseId: ID!, $itemType: String!) {
    listCourseItems(courseId: $courseId, itemType: $itemType) {
      content
      contentType
      description
      id
      itemType
      name
      videoUrl
    }
  }
`;
