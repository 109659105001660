import React from 'react';
import { Select } from 'antd';
import Proptypes from 'prop-types';

const RoleSelcetDropdown = ({ setSelectedUserRole, options }) => {
  const { Option } = Select;

  function handleChange(value) {
    setSelectedUserRole(value);
  }
  return (
    <Select defaultValue="parent" onChange={handleChange}>
      {options?.map((option, index) => {
        return (
          <Option key={index} value={option?.value}>
            {option?.label}
          </Option>
        );
      })}
    </Select>
  );
};

export default RoleSelcetDropdown;

RoleSelcetDropdown.propTypes = {
  setSelectedUserRole: Proptypes.func.isRequired,
  options: Proptypes.arrayOf(
    Proptypes.shape({
      value: Proptypes.string,
      label: Proptypes.string,
    })
  ).isRequired,
};
