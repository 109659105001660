import gql from 'graphql-tag';

export const QuestionsUploadMutation = gql`
  mutation uploadQuestions($questionableId: ID!, $image: File!) {
    uploadQuestions(questionableId: $questionableId, image: $image) {
      errors {
        fieldName
        errors
      }
      statusCode
    }
  }
`;
