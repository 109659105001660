import gql from 'graphql-tag';

export const ListCompetitions = gql`
  query ListCompetitions($first: Int!, $orderBy: [OrderByClause!], $page: Int) {
    listCompetitions(first: $first, orderBy: $orderBy, page: $page) {
      paginatorInfo {
        count
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
        currentPage
      }
      data {
        id
        title
        type
        description
        enrollments_count
        image {
          url
        }
        gender
        start_date
        end_date
        is_active
        roles {
          name
        }
        actions {
          id
          name
        }
      }
    }
  }
`;
