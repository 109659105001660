import React, { useContext, useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import { useMutation } from '@apollo/client';
import { Formik, Form, ErrorMessage, Field } from 'formik';
import { MainLayout, ErrorMsgWrapper } from '@components';
import { SET_ADMIN_PASSWORD_SCHEMA } from '@utilities';
import { SetAdminPasswordMutation } from '@services';
import { InputWrapper, SubmitButton } from './styled_component';
import { notification, Spin } from 'antd';
import { authContext } from '@auth';

const SetAdminPassword = ({ history, location }) => {
  const AuthContext = useContext(authContext);
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  const email = queryParams.get('email');
  const initialValues = {
    email,
    password: '',
    password_confirmation: '',
    token,
  };

  const [setAdminPassword, { loading }] = useMutation(
    SetAdminPasswordMutation,
    {
      errorPolicy: 'all',
    }
  );

  useEffect(() => {
    // check if user is already logged in -- > redirect to courses page
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      history.push('/courses');
    }
  }, []);

  const handleSubmit = values => {
    console.log('val', values);

    setAdminPassword({ variables: values })
      .then(response => {
        const errors = response?.data?.setAdminPassword?.errors;
        const statusCode = response?.data?.setAdminPassword?.statusCode;
        const message = response?.data?.setAdminPassword?.message;
        if (!errors && statusCode === 200) {
          notification.success({
            message: 'Set Admin Password',
            description: 'Password has been created successfully!',
          });

          //redirect to login page
          history.push('/');
        }

        const ErrorDescription =
          errors?.graphQLErrors[0]?.errors[
            Object.keys(errors?.graphQLErrors[0]?.errors)[0]
          ];

        if (ErrorDescription) {
          notification.error({
            message: 'Set Admin Password',
            description: ErrorDescription,
          });
        }

        if (statusCode === 403) {
          // invalid link / token
          notification.error({
            message: 'Set Admin Password',
            description:
              message ||
              'link is invalid, please ask admin to re-send registration email',
          });
        }
      })
      .catch(err => {
        notification.error({
          message: 'Set Admin Password',
          description: err,
        });
      });
  };

  if (loading) return <Spin />;

  return (
    <MainLayout bannerTitle={'Create Admin Password'} guest={true}>
      <Formik
        initialValues={initialValues}
        validationSchema={SET_ADMIN_PASSWORD_SCHEMA}
        onSubmit={handleSubmit}
      >
        <Form>
          <InputWrapper>
            <label htmlFor="email">
              Email:<span className="required">*</span>
            </label>
            <Field name="email" type="email" placeholder="Email" disabled />
            <ErrorMessage component={ErrorMsgWrapper} name="email" />
          </InputWrapper>

          <InputWrapper>
            <label htmlFor="password">
              Password:<span className="required">*</span>
            </label>
            <Field name="password" type="password" placeholder="Password" />
            <ErrorMessage component={ErrorMsgWrapper} name="password" />
          </InputWrapper>

          <InputWrapper>
            <label htmlFor="password_confirmation">
              Confirm Password:<span className="required">*</span>
            </label>
            <Field
              name="password_confirmation"
              type="password"
              placeholder="Confirm password"
            />
            <ErrorMessage
              component={ErrorMsgWrapper}
              name="password_confirmation"
            />
          </InputWrapper>
          <SubmitButton className="submit-btn" type="submit">
            Create Password
          </SubmitButton>
        </Form>
      </Formik>
    </MainLayout>
  );
};
export default SetAdminPassword;
