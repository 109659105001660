import gql from 'graphql-tag';

export const CREATE_PROVIDER = gql`
  mutation createProvider($name: String!, $logo: File!, $banner: File!) {
    createProvider(name: $name, logo: $logo, banner: $banner) {
      statusCode
      errors {
        message
        field_name
      }
    }
  }
`;
