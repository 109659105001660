import rules from '../rbac_rules';

const check = (rules, role, action, data) => {
  const permissions = rules[role];
  if (!permissions) {
    // role is not present in the rules
    return false;
  }

  const staticPermissions = permissions.static;

  if (staticPermissions && staticPermissions.includes(action)) {
    // static rule not provided for action
    return true;
  }

  const dynamicPermissions = permissions.dynamic;

  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action];
    if (!permissionCondition) {
      // dynamic rule not provided for action
      return false;
    }

    return permissionCondition(data);
  }
  return false;
};

/** Can component is used to decide whether user can perform the action or not
 * please refer to https://auth0.com/blog/role-based-access-control-rbac-and-react-apps/
 */
const Can = props => {
  if (check(props.rules || rules, props.role, props.perform, props.data)) {
    return props.yes();
  } else {
    return props.no();
  }
};

Can.defaultProps = {
  yes: () => null,
  no: () => null,
};

export default Can;
