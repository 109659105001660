import React, { useContext, useState } from 'react';
import { AnalyticsContext } from './analytics-context';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format, startOfMonth, startOfWeek, startOfYear } from 'date-fns';
import AnalyticsTabs from './analytics-tabs';
/**
 * format a title of a given date range
 *
 * @export
 * @param {Date} from start date
 * @param {Date} to end date
 * @return {*}  {string} a meaningful string of the date range
 */
export function getCustomDatesRangeTitle(from, to) {
  if (!from || !to) {
    return undefined;
  }
  return `${format(from, 'dd/LL/yy')} - ${format(to, 'dd/LL/yy')}`;
}

const Header = () => {
  const analyticsContext = useContext(AnalyticsContext);
  const [currentPeriodMenu, setCurrentPeriodMenu] = useState(false);
  const [showCustomDate, setShowCustomDate] = useState(false);
  const [customStartDate, setCustomStartDate] = useState(new Date());
  const [customEndDate, setCustomEndDate] = useState(new Date());
  /**
   *
   * update analytics context with selected data according to the filter
   * @param {String} alias
   * @param {"week", "month", "year", "custom"} type
   * @param {Date} from
   * @param {Date} to
   * @memberof Header
   */
  const onSelectPeriod = (alias, type, from, to) => {
    if (from && to) {
      analyticsContext.updateCurrentPeriod({
        alias,
        type,
        from: format(from, 'yyyy-MM-dd'),
        to: format(to, 'yyyy-MM-dd'),
      });
    }

    setShowCustomDate(from && to ? false : showCustomDate);
    setCurrentPeriodMenu(from && to ? false : currentPeriodMenu);
    setCustomStartDate(from);
    setCustomEndDate(to);
  };
  return (
    <div className="analytics-header">
      <AnalyticsTabs />
      <div className="dropdown-container">
        {analyticsContext.currentPeriod?.alias}
        <svg
          className="dropdown-icon"
          enableBackground="new 0 0 330 330"
          viewBox="0 0 330 330"
          xmlns="http://www.w3.org/2000/svg"
          onClick={() => {
            setCurrentPeriodMenu(!currentPeriodMenu);
          }}
        >
          <path d="m325.607 79.393c-5.857-5.857-15.355-5.858-21.213.001l-139.39 139.393-139.397-139.394c-5.857-5.857-15.355-5.858-21.213.001-5.858 5.858-5.858 15.355 0 21.213l150.004 150c2.813 2.813 6.628 4.393 10.606 4.393s7.794-1.581 10.606-4.394l149.996-150c5.859-5.857 5.859-15.355.001-21.213z" />
        </svg>
        {currentPeriodMenu && (
          <div className="dropdown-menu">
            <div
              className={`item ${
                analyticsContext.currentPeriod.type === 'week' ? 'active' : ''
              }`}
              onClick={() => {
                onSelectPeriod(
                  'This Week',
                  'week',
                  startOfWeek(new Date()),
                  new Date()
                );
              }}
            >
              This Week
            </div>
            <div
              className={`item ${
                analyticsContext.currentPeriod.type === 'month' ? 'active' : ''
              }`}
              onClick={() => {
                onSelectPeriod(
                  'This Month',
                  'month',
                  startOfMonth(new Date()),
                  new Date()
                );
              }}
            >
              This Month
            </div>
            <div
              className={`item ${
                analyticsContext.currentPeriod.type === 'year' ? 'active' : ''
              }`}
              onClick={() => {
                onSelectPeriod(
                  'This Year',
                  'year',
                  startOfYear(new Date()),
                  new Date()
                );
              }}
            >
              This Year
            </div>
            <div
              className={`item ${
                analyticsContext.currentPeriod.type === 'custom' ? 'active' : ''
              }`}
              onClick={e => {
                e.preventDefault();
                setShowCustomDate(true);
              }}
            >
              <div className="custom">
                Custom
                <svg
                  className={`custom-icon ${showCustomDate ? 'open-icon' : ''}`}
                  enableBackground="new 0 0 330 330"
                  viewBox="0 0 330 330"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m325.607 79.393c-5.857-5.857-15.355-5.858-21.213.001l-139.39 139.393-139.397-139.394c-5.857-5.857-15.355-5.858-21.213.001-5.858 5.858-5.858 15.355 0 21.213l150.004 150c2.813 2.813 6.628 4.393 10.606 4.393s7.794-1.581 10.606-4.394l149.996-150c5.859-5.857 5.859-15.355.001-21.213z" />
                </svg>
              </div>
              {showCustomDate && (
                <DatePicker
                  onChange={dates => {
                    const [start, end] = dates;
                    onSelectPeriod(
                      getCustomDatesRangeTitle(start, end),
                      'custom',
                      start,
                      end
                    );
                  }}
                  selected={customStartDate ? customStartDate : undefined}
                  startDate={customStartDate ? customStartDate : undefined}
                  endDate={customEndDate ? customEndDate : undefined}
                  selectsRange
                  inline
                  closeOnScroll
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
