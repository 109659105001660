import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { Menu, Dropdown, Icon } from 'antd';
import { colors } from '@utilities';
import LogoutButton from './logout_button';

/** style of main layout header drop down link */
const DropdownLink = styled.a`
  color: ${colors.secondaryColor};
  &:hover {
    color: ${colors.primaryColor};
  }
  font-size: 18px;
  span {
    margin: 0 5px;
    font-size: 15px;
  }
`;

/** drop down menu in main layout header */
const HeaderMenu = ({ name, logout }) => {
  const DropdownMenu = (
    <Menu mode="vertical">
      <Menu.Item key="1">
        <LogoutButton logout={logout} />
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown overlay={DropdownMenu} trigger={['click']}>
      <DropdownLink className="ant-dropdown-link" href="/">
        {name}
        <span>
          <Icon type="down" />
        </span>
      </DropdownLink>
    </Dropdown>
  );
};

HeaderMenu.propTypes = {
  /** name prop of type string for admin name */
  name: PropTypes.string,
  /** logout prop of type function passed from auth component */
  logout: PropTypes.func,
};

export default HeaderMenu;
