import gql from 'graphql-tag';

export const CREATE_DISCUSSION = gql`
  mutation createThread($threadData: ThreadInputType!) {
    createThread(threadData: $threadData) {
      statusCode
      errors {
        errors
        fieldName
      }
      thread {
        id
      }
    }
  }
`;
