import gql from 'graphql-tag';

export const DeleteNavbarSection = gql`
  mutation deleteNavbarItems($ids: [ID!]!) {
    deleteNavbarItems(ids: $ids) {
      id
      name
      index
      type
      categories_ids
      tags_ids
    }
  }
`;
