/**
 * @author Ahmed Serag
 * @date 2022-03-22
 */
import gql from 'graphql-tag';

export const deleteAssessment = gql`
  mutation deleteAssessmentMutation($id: ID!) {
    deleteAssessment(id: $id) {
      statusCode
      message
      errors {
        field_name
        message
      }
      assessment {
        id
        type
        estimated_time
        passing_grade
        number_of_trials
        reset_time
      }
    }
  }
`;
