import gql from 'graphql-tag';

export const DISCUSSION_DETAILS = gql`
  query threadDetails($id: ID!) {
    threadDetails(id: $id) {
      createdAt
      creatorName
      creatorType
      description
      id
      relatedItemName
      relatedItemType
      replies
      status
      title
    }
  }
`;
