import React from 'react';
import PropTypes from 'prop-types';
import { FormItem } from '@components';

const CoursesFilter = ({
  inputName,
  initialValue,
  mode,
  validations = [],
  onChange,
  form,
  courses = [],
  isDisabled,
  loading,
}) => {
  return (
    <FormItem
      inputName={inputName}
      inputLabel="Course"
      className="inline-label"
      inputType="select"
      placeHolder="Select Course"
      validationRules={validations}
      mode={mode}
      initialValue={mode ? undefined : initialValue}
      options={courses}
      onChange={onChange}
      form={form}
      disabled={isDisabled}
      loading={loading}
    />
  );
};

CoursesFilter.propTypes = {
  inputName: PropTypes.string.isRequired,
  initialValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  onChange: PropTypes.func.isRequired,
  validations: PropTypes.array,
  mode: PropTypes.string,
  courses: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  isDisabled: PropTypes.bool,
  loading: PropTypes.bool,

  form: PropTypes.shape({
    /** getFieldDecorator prop of type function passed from antd form to get each field decorator  */
    getFieldDecorator: PropTypes.func,
    /** setFieldsValue prop of type function passed form ant d form to set field value by it's name */
    setFieldsValue: PropTypes.func,
    /** validateFields prop of type function passed from antd form to run its validations  */
    validateFields: PropTypes.func,
  }).isRequired,
};

export default CoursesFilter;
