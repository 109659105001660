import gql from 'graphql-tag';

export const updateCourseOutlineMutation = gql`
  mutation updateCourseOutlineMutation($content: UpdateCourseOutlineInput!) {
    updateCourseOutline(content: $content) {
      errors {
        field_name
        message
      }
      statusCode
      message
      course_outline {
        id
        name
        type
        description
        course_id
        parent_id
        children
      }
    }
  }
`;
