import React from 'react';
import PropTypes from 'prop-types';
import { FormItem } from '@components';
import { ListRolesQuery } from '@services';
import { useQuery } from '@apollo/client';
import { Spin } from 'antd';

const PersonaFilter = ({
  initialValue,
  mode,
  validations = [],
  onChange,
  form,
}) => {
  const { loading: rolesLoading, error: rolesError, data: roles } = useQuery(
    ListRolesQuery
  );

  if (rolesLoading) return <Spin />;

  if (rolesError) return <p>{rolesError.message}</p>;

  return (
    <FormItem
      inputName={mode ? 'roles' : 'role'}
      inputLabel="Role"
      className="inline-label"
      inputType="select"
      placeHolder="Select Role"
      validationRules={validations}
      mode={mode}
      initialValue={mode ? undefined : initialValue}
      options={roles?.getPlatformRoles || []}
      onChange={onChange}
      form={form}
    />
  );
};

PersonaFilter.propTypes = {
  initialValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  onChange: PropTypes.func.isRequired,
  validations: PropTypes.array,
  mode: PropTypes.string,

  form: PropTypes.shape({
    /** getFieldDecorator prop of type function passed from antd form to get each field decorator  */
    getFieldDecorator: PropTypes.func,
    /** setFieldsValue prop of type function passed form ant d form to set field value by it's name */
    setFieldsValue: PropTypes.func,
    /** validateFields prop of type function passed from antd form to run its validations  */
    validateFields: PropTypes.func,
  }).isRequired,
};

export default PersonaFilter;
