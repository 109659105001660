import React from 'react';
import Proptypes from 'prop-types';

/** This component represent the legend on the any chart with its
 * value, name and color
 */
const ChartLegend = ({ label, color, value }) => {
  const _label = label.charAt(0).toUpperCase() + label.slice(1);
  return (
    <div className="chart-legend">
      <span className="value">{value}&#37;</span>
      <span className="label">
        <span className="color-code" style={{ backgroundColor: color }}></span>
        {_label}
      </span>
    </div>
  );
};

ChartLegend.propTypes = {
  label: Proptypes.string,
  color: Proptypes.string,
  value: Proptypes.number,
};

export default ChartLegend;
