import gql from 'graphql-tag';

export const AddAdminProviderMutation = gql`
  mutation AddAdminProvider(
    $name: String!
    $email: String!
    $providerId: Int!
  ) {
    providerAdminCreate(name: $name, email: $email, providerId: $providerId) {
      providerAdmin {
        id
      }
      errors {
        errors
      }
      statusCode
    }
  }
`;
