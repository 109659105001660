import gql from 'graphql-tag';

export const DELETE_QUESTION = gql`
  mutation deleteQuestion($questionId: ID!) {
    deleteQuestion(questionId: $questionId) {
      statusCode
      errors {
        errors
        fieldName
      }
    }
  }
`;
