import gql from 'graphql-tag';

export const UnpublishJourney = gql`
  mutation UnpublishJourneyMutation($journeyId: ID!) {
    unpublishJourney(journeyId: $journeyId) {
      errors {
        errors
        fieldName
      }
      statusCode
    }
  }
`;
