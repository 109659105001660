/**
 * @Author: abdelrahmanfayed
 * @Date:   2020-05-01T15:29:05+02:00
 */

import React, { useState } from 'react';
import * as Yup from 'yup';
import styled from '@emotion/styled/macro';
import { FullHeightSection } from '@components';
import OtpInput from 'react-otp-input';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useMutation } from '@apollo/client';
import { SendOtpViaEmail } from '../../../services/otp/otp_request';
import { Icon } from 'antd';
import { LoginFormMutation } from '../../../services/login/login_mutation';
import './styles.css';
import { Link } from 'react-router-dom';

const CodeConfirmationContainer = styled.div`
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    justify-content: center;
    padding-top: 70px;
    padding: 0 28px;
  }
  h1 {
    font-size: 42px;
    line-height: 1.3;
    margin: 0;
    @media (max-width: 768px) {
      text-align: center;
      width: 100%;
      font-size: 35px;
    }
  }
  .field-container {
    display:flex:
    justify-content:center;
    gap:30px;
    
    @media (max-width: 768px) {
      gap:20px;
    }
  }
  .field-container .field-input {
    height: 140px;
    border-radius: 8px;
    border: 1px solid #dddd;
    font-size: 60px;
    position: relative;
    width: 126px !important;
    
    @media (max-width: 768px) {
      height: 70px;
        font-size: 40px;
        width: 70px !important;
    }
  }
`;

const OTPTitle = styled.p`
  color: var(--gray-700);
  font-size: 15px;
  margin: 40px 0;

  @media (max-width: 768px) {
    text-align: center;
    width: 100%;
  }
`;

const OTPResend = styled.p`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 40px;
  p {
    margin: 0;
  }
  a {
    font-size: 15px;
    text-decoration: underline;
    color: black;
    font-weight: bold;
  }
`;

const OtpPage = () => {
  const [otp, setOtp] = useState('');
  const userInfo = JSON.parse(localStorage.getItem('user'));
  const [sendOTP] = useMutation(LoginFormMutation);
  const [resendOTP] = useMutation(SendOtpViaEmail);

  const validation = Yup.object({
    passwordValue: Yup.string().required('Please enter your password'),
  });

  const handleSendOTP = async (email, password, code, setErrors) => {
    try {
      const response = await sendOTP({ variables: { password, email, code } });
      if (response.data.adminLogin.token) {
        localStorage.setItem('token', response.data.adminLogin.token);
        localStorage.setItem(
          'user',
          JSON.stringify({
            email: response.data.adminLogin.authenticatable.email,
            name: response.data.adminLogin.authenticatable.name,
            role: response.data.adminLogin.authenticatable.role,
          })
        );
        window.location.href = '/courses';
      } else {
        setErrors({ passwordValue: response.data.adminLogin.message });
      }
    } catch (error) {
      if (error.message.includes('[adminLogin]')) {
        setErrors({ passwordValue: 'Invalid OTP code, please re-send it' });
      }
    }
  };

  const handleResendOTP = async email => {
    try {
      await resendOTP({ variables: { email } });
    } catch (error) {
      console.error('Error sending OTP:', error);
    }
  };

  return (
    <FullHeightSection>
      <CodeConfirmationContainer>
        <h1>OTP Verification</h1>
        <OTPTitle>
          We've sent an OTP to your email, please enter it below
        </OTPTitle>
        <Formik
          initialValues={{ otpValue: null, passwordValue: '' }}
          validationSchema={validation}
          onSubmit={(values, { setErrors }) => {
            values.otpValue = otp;
            handleSendOTP(
              userInfo.email,
              values.passwordValue,
              Number(values.otpValue),
              setErrors
            );
          }}
        >
          {() => (
            <Form className="form">
              <div>
                <OtpInput
                  value={otp}
                  numInputs={4}
                  onChange={otp => {
                    setOtp(otp);
                  }}
                  isInputNum="true"
                  renderInput={props => <input {...props} />}
                  containerStyle={'field-container'}
                  inputStyle={'field-input'}
                  placeholder={''}
                />
                <ErrorMessage name="otpValue" component="span" />
              </div>

              <div className="form__password-container">
                <div className="form__password">
                  <label>Password:</label>
                  <Field
                    className="form__password-field"
                    name="passwordValue"
                    prefix={<Icon type="lock" style={{ fontSize: 13 }} />}
                    type="password"
                  />
                </div>
                <ErrorMessage
                  className="form__error"
                  name="passwordValue"
                  component="span"
                />
              </div>
              <Link to="/forgot-password" className="form__reset">
                Forgot password ?
              </Link>

              <button type="submit" className="form__button">
                Log In
              </button>
            </Form>
          )}
        </Formik>
        <OTPResend>
          <p>Didn't recieve an OTP?</p>
          <button
            className="form__resend"
            type="button"
            onClick={() => handleResendOTP(userInfo.email)}
          >
            Resend OTP
          </button>
        </OTPResend>
      </CodeConfirmationContainer>
    </FullHeightSection>
  );
};

export default OtpPage;
