import { useQuery } from '@apollo/client';
import { ListPoints } from '@services';

export const usePointsSystem = () => {
  const { data, loading, error } = useQuery(ListPoints, {
    fetchPolicy: 'cache-and-network',
  });

  const points = data?.listCompetitionActions || [];

  return { points, error, loading };
};
