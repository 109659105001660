import gql from 'graphql-tag';

export const GetCategoryQuery = gql`
  query viewCategory($id: ID!) {
    viewCategory(id: $id) {
      id
      name
      description
      index
      educational_subject
      certificates {
        id
        name
        image {
          url
        }
      }
      is_featured
      image {
        url
      }
      banner {
        url
      }
      snippet_video {
        url
      }
      snippet_thumbnail {
        url
      }
      tags {
        id
      }
    }
  }
`;
