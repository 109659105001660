import React from 'react';
import { List, Button } from 'antd';
import styled from '@emotion/styled/macro';
import { en as strings } from './locales/data.json';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 1rem;
`;

const listItemStyle = { display: 'flex', justifyContent: 'space-between' };

const MatchBank = ({
  selected,
  setSelected,
  questions,
  answers,
  matchQuestions,
}) => {
  /**
   * Choose an option (question and answer) and add the id of the option to selected object
   *
   * @access private
   */
  const chooseOption = (type, id) => {
    setSelected(prevState => ({ ...prevState, [type]: id }));
  };

  /**
   * Check if the option selected before
   *
   * @access private
   * @returns {boolean}
   */
  const isSelectedBefore = (type, id) =>
    matchQuestions.find(item => item[type].id === id);

  /**
   * List item component, handle question and answer options
   *
   * @access private
   * @returns {element}
   */
  const listItem = item => (
    <List.Item style={listItemStyle}>
      {item.body}
      <Button
        size={'small'}
        onClick={() => chooseOption(item.type, item.id)}
        type={'primary'}
        loading={selected[item.type] === item.id}
        disabled={
          (selected[item.type] !== item.id && selected[item.type]) ||
          isSelectedBefore(item.type, item.id)
        }
      >
        {strings.link}
      </Button>
    </List.Item>
  );

  return (
    <Wrapper>
      <List
        size="small"
        header={<div>{strings.questions}</div>}
        bordered
        dataSource={questions}
        renderItem={listItem}
      />
      <List
        size="small"
        header={<div>{strings.answers}</div>}
        bordered
        dataSource={answers}
        renderItem={listItem}
      />
    </Wrapper>
  );
};

MatchBank.propTypes = {
  selected: PropTypes.shape({
    question: PropTypes.number,
    answer: PropTypes.number,
  }).isRequired,
  setSelected: PropTypes.func.isRequired,

  questions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
      body: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,

  answers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
      body: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,

  matchQuestions: PropTypes.arrayOf(
    PropTypes.shape({
      questions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          type: PropTypes.string.isRequired,
          body: PropTypes.string.isRequired,
        }).isRequired
      ),

      answers: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          type: PropTypes.string.isRequired,
          body: PropTypes.string.isRequired,
        }).isRequired
      ),
    })
  ).isRequired,
};

export default MatchBank;
