import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { notification, Spin, Input as AntInput } from 'antd';
import {
  ErrorMsgWrapper,
  Input,
  CustomSelect,
  WysiWygEditorFormik,
  CustomModal,
} from '@components';
import {
  COURSE_DETAILS_SCHEMA,
  getCourseDetailsInitialValues,
} from '@utilities';
import { usePrerequisiteCourse } from '@hooks';
import { PERSONAS_NAMES } from '@constants';
import { CreateCourseMutation, updateCourseDetailsMutation } from '@services';
import { ReactComponent as UploadIcon } from './images/upload.svg';
import { en as strings } from './locales/data.json';
import {
  ButtonContainer,
  CheckboxContainer,
  FormContainer,
  FullContainer,
  InputContainer,
  RadioGroup,
  InputDisclaimer,
} from './styles';
import CertificateComponent from './certificateComponent';

const SEMESTER_SCHEMA = [
  { value: 'FIRST', label: 'First' },
  { value: 'SECOND', label: 'Second' },
  { value: 'FULL_YEAR', label: 'Full Year' },
];

const CourseDetails = ({ onCompleted, courseDetails, history }) => {
  const isCoursePublished = courseDetails?.status === 'PUBLISHED';
  const [showModal, setShowModal] = useState(false);
  const {
    categories,
    tags,
    providers,
    personas,
    ageGroup,
    grades,
    certificates,
    loading,
    error,
  } = usePrerequisiteCourse();
  const [createCourseDetails, { loading: loadingCreateCourse }] = useMutation(
    CreateCourseMutation,
    {
      onError: error => {
        const description = error.message.split('GraphQL error: ')[1];

        notification.error({
          message: strings.titles.new,
          description,
        });
      },
    }
  );

  // GraphQL mutation to update course details
  const [updateCourseDetails, { loading: loadingUpdateCourse }] = useMutation(
    updateCourseDetailsMutation,
    {
      onError: error => {
        const description = error.message.split('GraphQL error: ')[1];

        notification.error({
          message: strings.titles.new,
          description,
        });
      },
    }
  );

  const modifiedAgeGroup = useMemo(
    () => ageGroup.map(age => ({ ...age, value: `${age.value}_age` })),
    [ageGroup]
  );

  const modifiedGrades = useMemo(
    () => grades.map(grade => ({ ...grade, value: `${grade.value}_grade` })),
    [grades]
  );

  /**
   * call the graphql mutation responsible for creating a new course.
   *
   * @param {Object} newCourseValues
   * @return {Promise} Promise to return the created course.
   */
  const handleCreateNewCourse = newCourseValues => {
    if (!newCourseValues?.duration) {
      delete newCourseValues?.duration;
    }
    if (!newCourseValues?.index) {
      newCourseValues && (newCourseValues.index = null);
    }
    return createCourseDetails({
      variables: {
        content: newCourseValues,
      },
    }).then(({ data, errors }) => {
      if (errors) {
        return Promise.reject(errors);
      }
      return data?.createCourseDetails?.course;
    });
  };

  /**
   * call the graphql mutation responsible for updating the current course.
   *
   * @param {Object} updatedCourseValues
   * @return {Promise} Promise to return the updated course.
   */
  const handleUpdateCourseDetails = updatedCourseValues => {
    const { image, ...rest } = updatedCourseValues;
    const content = {
      ...rest,
      image,
      id: courseDetails.id,
    };
    // check if the image isn't updated, then remove it from the object.
    if (image?.url) {
      delete content.image;
    }
    if (!content?.duration) {
      content.duration = 0;
    }
    if (!content?.index) {
      content && (content.index = null);
    }
    return updateCourseDetails({
      variables: {
        content,
      },
    }).then(({ data, errors }) => {
      if (errors) {
        return Promise.reject(errors);
      }
      return data?.updateCourseDetails?.course;
    });
  };

  const handleSubmit = values => {
    if (isCoursePublished && !showModal) {
      setShowModal(true);
      return;
    }
    const {
      personas_ids,
      age_grades,
      hasCertificate,
      tags_ids,
      certificate_id,
      ...rest
    } = values;
    const age_grade_groups = age_grades.reduce(
      (acc, curr) => {
        const splitted = curr.split('_');
        if (splitted[1] === 'age') {
          acc.age_groups_ids.push(splitted[0]);
        } else if (splitted[1] === 'grade') {
          acc.grades_ids.push(splitted[0]);
        }
        return acc;
      },
      {
        grades_ids: [],
        age_groups_ids: [],
      }
    );
    const audience_criteria = { personas_ids };
    let content = { ...rest };
    if (tags_ids.length) {
      content.tags_ids = tags_ids;
    }
    if (age_grade_groups.grades_ids.length) {
      audience_criteria.grades_ids = age_grade_groups.grades_ids;
    }
    if (age_grade_groups.age_groups_ids.length) {
      audience_criteria.age_groups_ids = age_grade_groups.age_groups_ids;
    }
    if (certificate_id) {
      content.certificate_id = certificate_id;
    }
    content = { ...content, audience_criteria };

    let createUpdateCoursePromise;

    if (courseDetails?.id === null || courseDetails?.id === undefined) {
      createUpdateCoursePromise = handleCreateNewCourse(content);
    } else {
      createUpdateCoursePromise = handleUpdateCourseDetails(content);
    }

    createUpdateCoursePromise
      .then(course => {
        if (isCoursePublished) {
          history.push(`/courses`);
          setShowModal(false);
          return;
        }
        onCompleted(course);
      })
      .catch(error => {
        console.error(error);
        notification.error({
          message: error?.message ? error?.message : error,
        });
      });
  };

  if (loading || loadingUpdateCourse || loadingCreateCourse)
    return <Spin size="large" />;
  if (error) return <p>Something went wrong</p>;

  return (
    <FormContainer>
      <Formik
        initialValues={getCourseDetailsInitialValues(courseDetails)}
        validationSchema={COURSE_DETAILS_SCHEMA}
        onSubmit={handleSubmit}
      >
        {({ errors, values, setFieldValue, setTouched }) => {
          const ageGroup_grades = [];
          let shouldIncludeAgeGroups = false;
          let shouldIncludeGrades = false;
          for (let persona of values.personas_ids) {
            const personaObject = personas.find(p => p.id === persona);
            if (!personaObject) {
              continue;
            }
            if (
              personaObject.name === PERSONAS_NAMES.STUDENT ||
              personaObject.name === PERSONAS_NAMES.CHILD
            ) {
              shouldIncludeGrades = true;
            } else {
              shouldIncludeAgeGroups = true;
            }
          }
          if (shouldIncludeAgeGroups) {
            ageGroup_grades.push(...modifiedAgeGroup);
          }
          if (shouldIncludeGrades) {
            ageGroup_grades.push(...modifiedGrades);
          }

          return (
            <Form>
              {showModal && (
                <CustomModal
                  title={strings.modal.title}
                  onConfirm={() => handleSubmit(values)}
                  onClose={() => setShowModal(false)}
                  confirmPopUpButtonText={strings.modal.confirmPopUpButtonText}
                  cancelPopUpButtonText={strings.modal.cancelPopUpButtonText}
                >
                  {strings.modal.body}
                </CustomModal>
              )}
              <InputContainer>
                <div className="form-control">
                  <label className="required" htmlFor="name">
                    {strings.courseNameLabel}
                  </label>
                  <Field
                    name="name"
                    placeholder="Add course Name"
                    className={` input-50 ${errors.name && 'input-error'}`}
                    as={Input}
                  />
                  <ErrorMessage component={ErrorMsgWrapper} name="name" />
                </div>
              </InputContainer>
              <InputContainer>
                <div className="form-control">
                  <label className="required" htmlFor="category_id">
                    {strings.categoryLabel}
                  </label>
                  <Field
                    name="category_id"
                    as="select"
                    className={` input-50 ${errors.category_id &&
                      'input-error'}`}
                  >
                    <option value="" disabled hidden>
                      {strings.categoryLabel}
                    </option>
                    {categories.map(category => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    component={ErrorMsgWrapper}
                    name="category_id"
                  />
                </div>
              </InputContainer>
              <InputContainer>
                <div className="form-control">
                  <label className="required " htmlFor="content_lang">
                    {strings.courseLang}
                  </label>
                  <RadioGroup role="group" aria-labelledby="content_lang-group">
                    <label>
                      <Field
                        type="radio"
                        name="content_lang"
                        value={strings.languagesOptions.ar.value}
                      />
                      {strings.languagesOptions.ar.label}
                    </label>
                    <label>
                      <Field
                        type="radio"
                        name="content_lang"
                        value={strings.languagesOptions.en.value}
                      />
                      {strings.languagesOptions.en.label}
                    </label>
                    <ErrorMessage
                      component={ErrorMsgWrapper}
                      name="content_lang"
                    />
                  </RadioGroup>
                </div>
                <div className="form-control">
                  <label htmlFor="image" className="required">
                    Image
                  </label>
                  <label className="custom-file-upload">
                    <input
                      name="image"
                      type="file"
                      accept="image/*"
                      onChange={event => {
                        const file = event.target.files[0];
                        // only run the validation once after the file is selected.
                        setFieldValue('image', file, true);
                        // ignore validation checking again.
                        setTouched({ image: true }, false);
                      }}
                    />
                    <UploadIcon />
                    click to upload
                  </label>
                  {values.image && (
                    <span>
                      {' '}
                      {values?.image.name
                        ? values?.image.name
                        : values?.image.url}{' '}
                    </span>
                  )}
                  {errors?.image && (
                    <ErrorMessage component={ErrorMsgWrapper} name="image" />
                  )}
                </div>
              </InputContainer>
              <InputContainer>
                <div className="form-control">
                  <label>{strings.courseTags.label}</label>
                  <Field
                    name="tags_ids"
                    options={tags}
                    component={CustomSelect}
                    placeholder="Add one or more tags"
                    isMulti
                  />
                </div>
                <div className="form-control">
                  <label className="required" htmlFor="providers_ids">
                    {strings.providersLabel}
                  </label>
                  <Field
                    name="providers_ids"
                    options={providers}
                    component={CustomSelect}
                    placeholder="Add one or more providers"
                    isMulti
                  />
                  <ErrorMessage
                    component={ErrorMsgWrapper}
                    name="providers_ids"
                  />
                </div>
              </InputContainer>
              <InputContainer>
                <div className="form-control">
                  <label htmlFor="duration">{strings.durationLabel}</label>
                  <Field
                    name="duration"
                    placeholder="Enter Quantity"
                    className={` input-50 ${errors.duration && 'input-error'}`}
                    as={AntInput}
                    addonAfter="Weeks"
                    type="number"
                    min="1"
                    max="40"
                  />
                  <ErrorMessage component={ErrorMsgWrapper} name="duration" />
                  <InputDisclaimer>
                    {strings.durationDisclaimer}
                  </InputDisclaimer>
                </div>
              </InputContainer>
              <InputContainer>
                <div className="form-control">
                  <label className="required " htmlFor="semester">
                    {strings.semesterLabel}
                  </label>
                  <Field
                    name="semester"
                    as="select"
                    className={` input-50 ${errors.semester && 'input-error'}`}
                  >
                    <option value="" disabled hidden>
                      {strings.semesterLabel}
                    </option>
                    {SEMESTER_SCHEMA.map(semester => (
                      <option key={semester.value} value={semester.value}>
                        {semester.label}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage component={ErrorMsgWrapper} name="semester" />
                </div>
              </InputContainer>
              <InputContainer>
                <div className="input-50">
                  <label htmlFor="description">
                    {strings.courseDescriptionLabel}
                  </label>
                  <Field
                    name="description"
                    component={WysiWygEditorFormik}
                    placeholder="Add course description"
                  />
                </div>
              </InputContainer>
              <InputContainer>
                <div className="input-50">
                  <label htmlFor="objectives">
                    {strings.courseObjectivesLabel}
                  </label>
                  <Field
                    name="objectives"
                    component={WysiWygEditorFormik}
                    placeholder="Add course objectives"
                  />
                </div>
              </InputContainer>
              <InputContainer>
                <div className="form-control">
                  <label className="required" htmlFor="personas_ids">
                    {strings.selectRoles}
                  </label>
                  <CheckboxContainer
                    role="group"
                    aria-labelledby="checkbox-group"
                  >
                    {personas?.map(persona => (
                      <label key={persona.id}>
                        <Field
                          type="checkbox"
                          name="personas_ids"
                          value={persona.id}
                        />
                        {persona.name}
                      </label>
                    ))}
                  </CheckboxContainer>
                  <ErrorMessage
                    component={ErrorMsgWrapper}
                    name="personas_ids"
                  />
                </div>
                <div className="form-control">
                  <label className="required " htmlFor="age_grades">
                    {strings.ageGroupLabel}
                  </label>
                  <Field
                    name="age_grades"
                    options={ageGroup_grades}
                    component={CustomSelect}
                    placeholder="Add one or multiple age groups"
                    isMulti
                  />
                  <ErrorMessage component={ErrorMsgWrapper} name="age_grades" />
                </div>
              </InputContainer>
              <InputContainer>
                <div className="form-control">
                  <label htmlFor="points">{strings.pointsLabel}</label>
                  <Field
                    name="points"
                    type="number"
                    placeholder="Add Reward points"
                    min="0"
                    className={errors.points && 'input-error'}
                    as={Input}
                  />
                  <ErrorMessage component={ErrorMsgWrapper} name="points" />
                </div>
              </InputContainer>
              <InputContainer>
                <div className="form-control">
                  <label htmlFor="course-index">{strings.indexLabel}</label>
                  <Field
                    id="course-index"
                    name="index"
                    type="number"
                    placeholder="Add Course Index"
                    min="1"
                    className={errors.order && 'input-error'}
                    as={Input}
                  />
                  <ErrorMessage component={ErrorMsgWrapper} name="order" />
                </div>
              </InputContainer>
              <InputContainer>
                <div className="form-control">
                  <label>
                    <Field type="checkbox" name="hasCertificate" />
                    This course has a certificate
                  </label>
                  {values?.hasCertificate && (
                    <Field
                      name="certificate_id"
                      component={CertificateComponent}
                      list={certificates}
                    />
                  )}
                </div>
              </InputContainer>
              <InputContainer>
                <div className="form-control">
                  <label>
                    <Field type="checkbox" name="is_deci" />
                    DECI Course
                  </label>
                </div>
              </InputContainer>
              <ButtonContainer>
                <button
                  type="submit"
                  disabled={loadingCreateCourse || loadingUpdateCourse}
                >
                  {isCoursePublished ? strings.saveChanges : strings.save}
                </button>
                <Link to="/courses">{strings.cancel}</Link>
              </ButtonContainer>
            </Form>
          );
        }}
      </Formik>
    </FormContainer>
  );
};

CourseDetails.propTypes = {
  onCompleted: PropTypes.func.isRequired,
  history: PropTypes.object,
  courseDetails: PropTypes.object,
};

export default CourseDetails;
