import gql from 'graphql-tag';

export const SetAdminPasswordMutation = gql`
  mutation setAdminPassword(
    $email: String!
    $password: String!
    $password_confirmation: String!
    $token: String!
  ) {
    setAdminPassword(
      email: $email
      password: $password
      password_confirmation: $password_confirmation
      token: $token
    ) {
      errors {
        field_name
        message
      }
      statusCode
      message
    }
  }
`;
