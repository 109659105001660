import gql from 'graphql-tag';

export const EditCourseItemMutation = gql`
  mutation editCourseMap(
    $courseId: Int!
    $courseMapId: Int
    $courseItem: EditCourseItemInput!
    $moduleIndex: Int
    $lessonIndex: Int
    $courseItemId: Int
  ) {
    editCourseMap(
      courseId: $courseId
      courseMapId: $courseMapId
      courseItem: $courseItem
      moduleIndex: $moduleIndex
      lessonIndex: $lessonIndex
      courseItemId: $courseItemId
    ) {
      courseMap {
        id
        body {
          ... on GeneralCourseMap {
            id
            __typename
            name
            contentType
            itemType
            children {
              id
              name
              contentType
              itemType
              hasChildren
              description
              children {
                id
                name
                contentType
                itemType
                description
                children {
                  id
                  name
                  contentType
                  itemType
                  description
                  workbookSize
                  gradedWorkbookSize
                  children {
                    id
                    name
                    contentType
                    itemType
                    hasChildren
                    description
                    workbookSize
                    gradedWorkbookSize
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
