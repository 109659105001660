/**
 * @Author: sarahnouh
 * @Date:   2019-12-25T17:57:22+02:00
 * @Last modified by:   sarahnouh
 * @Last modified time: 2020-01-15T19:51:31+02:00
 */
import gql from 'graphql-tag';

export const EditLiveSessionMutation = gql`
  mutation updateLiveSession($content: UpdateLiveSessionInput!) {
    updateLiveSession(content: $content) {
      statusCode
      errors {
        field_name
        message
      }
      message
      liveSession {
        id
        title
        description
        image {
          url
        }
        started_at
        ended_at
        link
        roles {
          id
          name
        }
        age_groups {
          id
          name
        }
        grades {
          id
          name
        }
        is_published
        recommended_age
      }
    }
  }
`;
