import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Steps } from 'antd';
import styled from '@emotion/styled';

const { Step } = Steps;

const StepsContainer = styled.div`
  .ant-steps {
    &-item {
      flex: initial;
    }
  }
`;

class NavBarSteps extends Component {
  onChange = current => {
    this.setState({ current });
  };

  /** create step from titles array */
  parseStepsTitles = titles => {
    const tabs = [];
    for (let i = 0; i < titles.length; i++) {
      tabs.push(<Step key={'step-' + i} title={titles[i]} />);
    }

    return tabs;
  };

  render() {
    const { titles, current } = this.props;

    return (
      <StepsContainer>
        <Steps current={current} onChange={this.onChange}>
          {this.parseStepsTitles(titles)}
        </Steps>
      </StepsContainer>
    );
  }
}

NavBarSteps.propTypes = {
  /** titles prop of type array to be presented as navbar tabs titles */
  titles: PropTypes.array,
  /** current prop of type number for current active step */
  current: PropTypes.number,
};
export default NavBarSteps;
