import gql from 'graphql-tag';

export const CREATE_JOURNEY_DETAILS = gql`
  mutation createJourney($journey: CreateJourneyInput!, $banner: File!) {
    createJourney(journey: $journey, banner: $banner) {
      errors {
        errors
      }
      journey {
        id
      }
      statusCode
    }
  }
`;
