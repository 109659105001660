import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { WysiWygEditorFormik } from '@components';
import { ErrorMsgWrapper } from './styles/new_unit_styles';
import { UNIT_TYPES, NEW_UNIT_SCHEMA } from '@utilities';
import { UploadUnitImage } from '@services';
import { useMutation } from '@apollo/client';
import { notification, Spin } from 'antd';

export default function TextTypeContent(props) {
  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState(false);

  const onSubmit = (values, actions) => {
    setDisabledSubmitBtn(true);

    let promise = Promise.resolve();
    let unitContentInput = {
      name: values.name,
      course_id: props.courseId,
      lesson_id: props.lessonId,
      content: {
        type: UNIT_TYPES.TEXT,
        body: values.body,
      },
    };
    actions.setSubmitting(true);

    if (!props.unitDetails.id) {
      props
        .createCourseUnit({
          variables: {
            content: unitContentInput,
          },
        })
        .then(({ data }) => {
          const { errors } = data?.createCourseUnit;
          if (!errors) {
            props.handleAddedUnit(data?.createCourseUnit?.unit);
            notification.success({
              message: 'Success',
              description: 'Unit created successfully',
            });
          }
          setDisabledSubmitBtn(false);
          // navigateToNextItem();
        })
        .catch(errors => {
          console.error(errors);
        });
    } else {
      unitContentInput.id = props.unitDetails.id;
      delete unitContentInput.lesson_id;
      delete unitContentInput.course_id;
      props
        .updateCourseUnit({
          variables: {
            content: unitContentInput,
          },
        })
        .then(({ data }) => {
          const { errors } = data?.updateCourseUnit;
          if (!errors) {
            notification.success({
              message: 'Success',
              description: 'Unit updated successfully',
            });
          }
          setDisabledSubmitBtn(false);
          // navigateToNextItem();
        })
        .catch(errors => {
          console.error(errors);
        });
    }

    promise.then(() => {
      actions.setSubmitting(false);
    });
  };
  const [
    handleUploadImage,
    { loading: loadingCreateCourseImage },
  ] = useMutation(UploadUnitImage, {
    onError: error => {
      notification.error({
        message: 'invalid image size',
      });
    },
  });

  let initialValues = {
    name:
      props.unitDetails?.content[0]?.type === UNIT_TYPES.TEXT &&
      props.unitDetails?.name
        ? props.unitDetails?.name
        : '',
    body:
      props.unitDetails?.content[0]?.type === UNIT_TYPES.TEXT &&
      props.unitDetails?.content[0]?.body
        ? props.unitDetails?.content[0]?.body
        : '',
    type: UNIT_TYPES.TEXT,
  };

  return (
    <>
      {loadingCreateCourseImage && <Spin />}
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        enableReinitialize={true}
        validationSchema={NEW_UNIT_SCHEMA}
      >
        {({ errors, values, setFieldValue, setTouched }) => {
          return (
            <Form>
              <div className="form-control unit-title">
                <label htmlFor="name">Unit Title</label>
                <Field type="text" name="name" id="name" />
                <ErrorMessage name="name" component={ErrorMsgWrapper} />
              </div>
              <div className="form-control  unit-title">
                <label htmlFor="image">Unit Image</label>
                <label className="custom-file-upload">
                  <input
                    name="image"
                    type="file"
                    accept="image/*"
                    onChange={event => {
                      const file = event.target.files[0];
                      // only run the validation once after the file is selected.
                      setFieldValue('image', file, true);
                      // ignore validation checking again.
                      setTouched({ image: true }, false);
                      handleUploadImage({
                        variables: {
                          image: event.target.files[0],
                        },
                      }).then(({ data, errors }) => {
                        if (errors) {
                          return Promise.reject(errors);
                        }
                        if (data?.uploadCourseContentImage?.data?.url) {
                          setFieldValue(
                            'body',
                            `<img src="${
                              data?.uploadCourseContentImage?.data?.url
                            }"/>  ${values.body.replace(/<img[^>]*>/g, '')}`
                          );
                        }
                      });
                    }}
                  />
                </label>
                {values.image && <span>{values?.image?.url}</span>}
                {errors?.image && (
                  <ErrorMessage component={ErrorMsgWrapper} name="image" />
                )}
              </div>
              <div className="form-control">
                <label htmlFor="body">Unit Description</label>
                <Field
                  name="body"
                  id="body"
                  placeholder="Add unit description"
                  component={WysiWygEditorFormik}
                />

                <ErrorMessage name="body" component={ErrorMsgWrapper} />
              </div>
              <div className="actions-btns">
                <button
                  className={`${disabledSubmitBtn && 'disabled'}`}
                  disabled={disabledSubmitBtn}
                  type="submit"
                >
                  Save
                </button>
                <button
                  className="delete-unit"
                  type="button"
                  disabled={!props.canDeleteUnit || disabledSubmitBtn}
                  onClick={props.onDeleteUnit}
                >
                  Delete Unit
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

TextTypeContent.propTypes = {
  courseId: PropTypes.string,
  lessonId: PropTypes.string,
  unitDetails: PropTypes.object,
  createCourseUnit: PropTypes.func,
  updateCourseUnit: PropTypes.func,
  canDeleteUnit: PropTypes.bool,
  onDeleteUnit: PropTypes.func,
};
