import React from 'react';
import styled from '@emotion/styled/macro';
import { colors } from '@utilities';

const Icon = styled.i`
  cursor: pointer;

  &:hover {
    background-color: black;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 1em;
    height: 1.5em;
    width: 2px;
    background-color: ${colors.gray};
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
`;

export const Close = () => <Icon />;

export default Close;
