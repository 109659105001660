import gql from 'graphql-tag';

export const UploadUnitImage = gql`
  mutation uploadCourseContentImage($image: File!) {
    uploadCourseContentImage(image: $image) {
      data {
        url
      }
      statusCode
      errors {
        field_name
        message
      }
    }
  }
`;
