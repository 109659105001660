import gql from 'graphql-tag';

export const LOGOUT = gql`
  mutation Logout {
    adminLogout {
      message
      statusCode
      token
      errors {
        field_name
        message
      }
    }
  }
`;
