import { useQuery } from '@apollo/client';
import { getCourseAnalyticsQuery } from '@services';

export const useCourseAnalytics = (from, to) => {
  const { loading, error, data } = useQuery(getCourseAnalyticsQuery, {
    variables: {
      from,
      to,
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const analytics = data?.getCoursesAnalytics;
  const analyticsCourses = data?.getCoursesAnalytics?.course_distribution;

  return { analytics, analyticsCourses, loading, error };
};
