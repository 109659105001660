import { en as strings } from '../locales/data.json';

const validationRules = {
  questionType: [{ required: true, message: strings.questionTypeRequired }],
  body: [{ required: true, message: strings.questionBodyRequired }],
  rightExplanation: [
    {
      required: true,
      message: strings.rightExplanationRequired,
    },
  ],
  wrongExplanation: [
    {
      required: true,
      message: strings.wrongExplanationRequired,
    },
  ],
  answers: [{ required: true, message: strings.answersRequired }],
  rightAnswerIndex: [
    {
      validator: (rule, value, callback) => {
        setTimeout(() => {
          if ((!value && value !== 0) || (value && isNaN(value))) {
            callback([new Error(strings.rightAnswerRequired)]);
          } else {
            callback();
          }
        }, 1000);
      },
    },
  ],
};

export default validationRules;
