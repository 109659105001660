/**
 * @Author: sarahnouh
 * @Date:   2019-12-23T19:02:20+02:00
 * @Last modified by:   sarahnouh
 * @Last modified time: 2019-12-29T17:45:56+02:00
 */
import gql from 'graphql-tag';

export const CategoriesListQuery = gql`
  query listCategories($orderBy: [OrderByClause!], $first: Int!, $page: Int) {
    listCategories(orderBy: $orderBy, first: $first, page: $page) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        id
        name
        index
        educational_subject
        is_featured
        published_courses_count
      }
    }
  }
`;

export default CategoriesListQuery;
