import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { UNIT_TYPES } from '@utilities';
import { QUESTION_TYPE } from '@utilities';
import { notification } from 'antd';
import QuestionCard from './workbook_question';
import { DeleteWorkbookUnit } from './styles/new_unit_styles';
import QuestionsUpload from '@components/questions/components/questions_upload';
import { id } from 'date-fns/locale';

export default function WorkbookTypeContent(props) {
  const [questions, setQuestions] = useState([{}]);
  const [unitId, setUnitId] = useState(
    props?.unitDetails?.workbook?.length > 0
      ? props?.unitDetails?.workbook[0]?.id
      : null
  );
  const [isUnitCreated, setIsUnitCreated] = useState(
    props.unitDetails?.workbook
  );

  useEffect(() => {
    if (
      props.unitDetails?.workbook?.length > 0 &&
      props.unitDetails?.workbook[0]?.questions?.length > 0
    ) {
      setQuestions(props.unitDetails?.workbook[0]?.questions);
    } else {
      setQuestions([{}]);
    }
  }, [props?.unitDetails]);

  const onSubmit = (values, actions) => {
    let promise = Promise.resolve();
    let unitContent = {};
    if (values.isGraded) {
      unitContent = {
        type: UNIT_TYPES.WORKBOOK,
        assessment: {
          estimated_time: values.estimated_time,
          passing_grade: values.passing_grade,
          type: 'GRADED_WORKBOOK',
        },
      };
    } else {
      unitContent = {
        type: UNIT_TYPES.WORKBOOK,
        assessment: {
          estimated_time: values.estimated_time,
          type: 'WORKBOOK',
        },
      };
    }

    let unitContentInput = {
      name: values.name,
      course_id: props.courseId,
      lesson_id: props.lessonId,
      content: unitContent,
    };
    actions.setSubmitting(true);
    if (!props.unitDetails.id && !isUnitCreated) {
      props
        .createCourseUnit({
          variables: {
            content: unitContentInput,
          },
        })
        .then(({ data }) => {
          const { errors } = data?.createCourseUnit;
          if (!errors) {
            setUnitId(data?.createCourseUnit?.unit?.workbook[0]?.id);
            props.handleAddedUnit(data?.createCourseUnit?.unit);
            notification.success({
              message: 'Success',
              description: 'Unit created successfully',
            });
          }
          // navigateToNextItem();
        })
        .catch(errors => {
          console.error(errors);
        });
    } else if (props.unitDetails?.id) {
      unitContentInput.id = props.unitDetails.id;
      delete unitContentInput.lesson_id;
      delete unitContentInput.course_id;
      props
        .updateCourseUnit({
          variables: {
            content: unitContentInput,
          },
        })
        .then(({ data }) => {
          const { errors } = data?.updateCourseUnit;
          if (!errors) {
            notification.success({
              message: 'Success',
              description: 'Unit updated successfully',
            });
          }
          // navigateToNextItem();
        })
        .catch(errors => {
          console.error(errors);
        });
    }

    promise.then(() => {
      setIsUnitCreated(true);
      actions.setSubmitting(false);
    });
  };

  const handleQuestionUpdateCallback = isQuestionUpdated => {
    props.handleQuestionUpdateCallback(isQuestionUpdated);
  };

  const onUploadQuestions = questions => {
    if (questions) setQuestions(questions);
  };

  let initialValues = {
    name:
      props.unitDetails?.workbook?.length > 0 ? props?.unitDetails?.name : '',
    type: UNIT_TYPES.WORKBOOK,
    isGraded:
      props.unitDetails?.workbook?.length > 0 &&
      props?.unitDetails?.workbook[0]?.passing_grade
        ? true
        : false,
    passing_grade:
      props.unitDetails?.workbook?.length > 0 &&
      props?.unitDetails?.workbook[0]?.passing_grade
        ? props?.unitDetails?.workbook[0]?.passing_grade
        : 0,
    estimated_time:
      props.unitDetails?.workbook?.length > 0 &&
      props?.unitDetails?.workbook[0]?.estimated_time
        ? props?.unitDetails?.workbook[0]?.estimated_time
        : 0,
    activeQuestionIndex: 0,
    questions:
      props.unitDetails?.workbook?.length > 0 &&
      props?.unitDetails?.workbook[0]?.questions
        ? props?.unitDetails?.workbook[0]?.questions
        : [
            {
              type: QUESTION_TYPE.MCQ,
              body: '',
              answers: [{ body: '', is_right: false }],
              right_explanation: '',
              wrong_explanation: '',
            },
          ],
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      {({ values }) => (
        <Form>
          <div className="form-control">
            <label htmlFor="name">Unit title</label>
            <div className="estimation-input-wrapper">
              <Field type="text" name="name" id="name" />
            </div>
          </div>
          <div className="form-control">
            <label htmlFor="">Estimated Time</label>
            <div className="estimation-input-wrapper">
              <Field
                min={0}
                type="number"
                name="estimated_time"
                id="estimatedTime"
                step={1}
              />
              <span>min</span>
            </div>
          </div>
          <div className="form-control">
            <div className="is-graded-wrapper">
              <Field type="checkbox" name="isGraded" />
              <label htmlFor="isGraded">This workbook is graded</label>
            </div>
          </div>
          {values.isGraded && (
            <div className="form-control">
              <label htmlFor="">Minimum Passing Grade* </label>
              <div className="estimation-input-wrapper">
                <Field
                  min={0}
                  max={100}
                  type="number"
                  name="passing_grade"
                  id="passing_grade"
                />
                <span>%</span>
              </div>
            </div>
          )}

          {!isUnitCreated ? (
            <button type="submit">Add Questions</button>
          ) : (
            <button type="submit">Update Unit</button>
          )}
          {isUnitCreated && props?.unitDetails?.workbook?.length > 0 && (
            <QuestionsUpload
              assessment_id={props?.unitDetails?.workbook[0]?.id}
              onUploadCallback={onUploadQuestions}
            />
          )}
          {isUnitCreated && questions && (
            <>
              {questions.map((question, index) => {
                return (
                  <QuestionCard
                    handleQuestionUpdateCallback={handleQuestionUpdateCallback}
                    key={index}
                    question={question}
                    assessmentId={unitId}
                    canDeleteQuestion={questions?.length > 1}
                    onSaveQuestion={updatedQuestion => {
                      const newQuestions = [...questions];
                      newQuestions[index] = updatedQuestion;
                      setQuestions(newQuestions);
                    }}
                    onDeleteQuestion={() => {
                      const newQuestions = [...questions];
                      newQuestions.splice(index, 1);
                      setQuestions(newQuestions);
                    }}
                  />
                );
              })}
              <div className="action-buttons">
                <button
                  onClick={() => {
                    const newQuestions = [...questions];
                    newQuestions.push({});
                    setQuestions(newQuestions);
                  }}
                >
                  Add Question
                </button>
                <DeleteWorkbookUnit
                  className="delete-unit"
                  type="button"
                  onClick={props.onDeleteUnit}
                >
                  Delete Unit
                </DeleteWorkbookUnit>
              </div>
            </>
          )}
        </Form>
      )}
    </Formik>
  );
}
