import gql from 'graphql-tag';

export const createCourseItem = gql`
  mutation createCourseItemMutation($content: CreateCourseItemInput!) {
    createCourseItem(content: $content) {
      statusCode
      message
      course_item {
        id
        name
        type
      }
      errors {
        field_name
        message
      }
    }
  }
`;
