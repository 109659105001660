import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import UnitCard from './lesson_unit/unit_card';
import { ModuleHeader } from './styles/course_content_styles';
import { UNIT_TYPES } from '@utilities';

const LessonContent = props => {
  const [units, setUnits] = React.useState([
    {
      content: [
        {
          type: UNIT_TYPES.TEXT,
        },
      ],
    },
  ]);

  React.useEffect(() => {
    if (props.lesson?.units && props.lesson?.units?.length > 0) {
      setUnits(props.lesson.units);
    } else {
      setUnits([
        {
          content: [
            {
              type: UNIT_TYPES.TEXT,
            },
          ],
        },
      ]);
    }
  }, [props.lesson]);

  const handleAddUnit = courseDetails => {
    props.addUnitCallback(courseDetails);
  };
  const handleAddWorkbook = unit => {
    setUnits([unit]);
  };

  return (
    <>
      <ModuleHeader>{props.lesson?.name}</ModuleHeader>
      {!units ? (
        <Spin />
      ) : (
        <div className="course-content__content">
          {units?.map((unit, index) => {
            return (
              <UnitCard
                addUnitCallback={handleAddUnit}
                addWorkbookCallback={handleAddWorkbook}
                key={
                  unit.id
                    ? unit.id
                    : props.lesson?.id * Math.floor(Math.random() * 10)
                }
                onSaveUnit={savedUnit => {
                  const newUnits = [...units];
                  newUnits[index] = savedUnit;
                  setUnits(newUnits);
                }}
                onDeleteUnit={() => {
                  console.log('onDeleteUnit');
                  const newUnits = [...units];
                  newUnits.splice(index, 1);
                  setUnits(newUnits);
                }}
                lessonId={props.lesson.id}
                courseId={props.courseId}
                moduleId={props.module?.id}
                unit={unit}
                canDeleteUnit={units.length > 1}
              />
            );
          })}
          <button
            className="add-unit-btn"
            onClick={() =>
              setUnits([
                ...units,
                {
                  content: {
                    type: UNIT_TYPES.TEXT,
                  },
                },
              ])
            }
          >
            Add Unit
          </button>
        </div>
      )}
    </>
  );
};

LessonContent.propTypes = {
  lesson: PropTypes.object,
  courseId: PropTypes.string,
  module: PropTypes.object,
};

export default LessonContent;
