import React from 'react';
import PropTypes from 'prop-types';
import { Form, DatePicker } from 'antd';
import moment from 'moment';

const DateRangeFilter = ({ form, dates = [], onChange }) => {
  const { RangePicker } = DatePicker;

  const dateOnChange = dates => {
    form.setFieldsValue({ dates });
    onChange(dates);
  };

  return (
    <>
      <Form.Item label="Date Range">
        {form.getFieldDecorator('dates', {
          initialValue: [
            dates[0] && moment(dates[0]),
            dates[1] && moment(dates[1]),
          ],
        })(<RangePicker onChange={dateOnChange} name="dates" />)}
      </Form.Item>
    </>
  );
};

DateRangeFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  dates: PropTypes.array,

  form: PropTypes.shape({
    /** getFieldDecorator prop of type function passed from antd form to get each field decorator  */
    getFieldDecorator: PropTypes.func,
    /** setFieldsValue prop of type function passed form ant d form to set field value by it's name */
    setFieldsValue: PropTypes.func,
  }).isRequired,
};

export default DateRangeFilter;
