import gql from 'graphql-tag';

export const CoursesPerPersonaQuery = gql`
  query getCourses($first: Int!, $course: CourseFilters) {
    getCourses(first: $first, course: $course) {
      data {
        id
        name
      }
    }
  }
`;
