import React, { useContext } from 'react';
import { useProviderAnalytics } from '@hooks';
import { AnalyticsContext } from './analytics-context';
import { Spin } from 'antd';
import NewCreationCard from './new-creation-card';
import BarChartCard from './bar-chart-card';

/*
  This component represent the analytics cards related to users
*/
const UserAnalyticsCards = () => {
  const analyticsContext = useContext(AnalyticsContext);

  const { analytics, error, loading } = useProviderAnalytics(
    analyticsContext?.currentPeriod?.from,
    analyticsContext?.currentPeriod?.to
  );

  let enrollmentsValues = [];
  let completionValues = [];

  if (!loading && analytics) {
    enrollmentsValues = analytics?.enrollment_distribution?.map(val => {
      return {
        name: val.name,
        count: Number(val.enrollments_count),
      };
    });

    completionValues = analytics?.enrollment_distribution?.map(val => {
      return {
        name: val.name,
        count: Number(val.completed_enrollments_count),
      };
    });
  }

  if (loading) return <Spin size="large" />;
  return (
    <div className="analyticsDataPerPeriod-container">
      <div className="middle-cards-container">
        {/* display middle cards here */}
        <BarChartCard
          values={enrollmentsValues}
          label={`Enrollment per provider ${analyticsContext?.currentPeriod?.alias}`}
        />

        <BarChartCard
          values={completionValues}
          label={`Completion per provider ${analyticsContext?.currentPeriod?.alias}`}
        />
      </div>
      <div className="analyticsTotalCounts-container">
        {/* display side cards here */}
        <NewCreationCard
          label={'New Providers added'}
          publishedNumber={analytics?.provider_state_distribution?.active}
          unPublishedNumber={analytics?.provider_state_distribution?.inactive}
          publishedLabel={'Active'}
          unPublishedLabel={'Inactive'}
        />
      </div>
    </div>
  );
};

export default UserAnalyticsCards;
