import gql from 'graphql-tag';

export const CompetitionStatusToggle = gql`
  mutation toggleCompetitionStatus($ids: [ID!]!) {
    toggleCompetitionStatus(ids: $ids) {
      statusCode
      message
    }
  }
`;
