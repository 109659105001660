import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { List, Divider } from 'antd';
import MatchBank from './match_bank';
import ActionMenu from './actions_menu';
import { DELETE_QUESTION } from '@services';
import { useMutation } from '@apollo/client';

const MatchList = forwardRef(
  (
    {
      isEdit,
      setMatchOptionValue,
      questions,
      answers,
      setQuestions,
      setAnswers,
      updateMatchQuestions,
      matchQuestionId,
      questionableId,
    },
    ref
  ) => {
    const defaultSelected = { question: null, answer: null };
    const [matchQuestions, setMatchQuestions] = useState([]);
    const [selected, setSelected] = useState(defaultSelected);

    const [deleteMatchQuestionMutation] = useMutation(DELETE_QUESTION);

    const deleteMatchQuestion = (questionId, answerId) => {
      if (isEdit) deleteMatchQuestionMutation({ variables: { questionId } });
      setMatchQuestions(items => {
        const itemsFiltered = items.filter(
          item => item.question.id !== questionId && item.answer.id !== answerId
        );

        updateMatchQuestions(itemsFiltered);

        return itemsFiltered;
      });
    };

    /**
     * Delete the question from the questions bank
     *
     * @param {number} questionId - question ID
     */
    const deleteQuestion = questionId => {
      setQuestions(questions =>
        questions.filter(question => question.id !== questionId)
      );
    };

    /**
     * Delete the answer from the answers bank
     *
     * @param {number} answerId - answer ID
     */
    const deleteAnswer = answerId => {
      setAnswers(answers => answers.filter(answer => answer.id !== answerId));
    };

    const editMatchQuestion = (questionId, answerId) => {
      const { question, answer } = matchQuestions.find(
        item => item.question.id === questionId && item.answer.id === answerId
      );

      deleteMatchQuestion(questionId, answerId);
      deleteQuestion(questionId);
      deleteAnswer(answerId);

      setMatchOptionValue(
        Object.assign(
          {},
          {
            question: question.body,
            answer: answer.body,
            ...(isEdit &&
              matchQuestionId &&
              questionableId && {
                matchQuestionId: question.matchQuestionId,
                questionableId: question.questionableId,
              }),
          }
        )
      );

      if (isEdit) deleteMatchQuestionMutation({ variables: { questionId } });
    };

    useEffect(() => {
      if (selected.question && selected.answer) {
        appendMatchQuestions();

        resetSelected();
      }
    }, [selected]);

    /**
     * Return from the callback passed as the second argument
     */
    useImperativeHandle(ref, () => ({ matchQuestions, setMatchQuestions }), [
      matchQuestions,
    ]);

    /**
     * Reset selected (question and answer)
     *
     * @access private
     */
    const resetSelected = () => {
      setSelected(Object.assign({}, defaultSelected));
    };

    /**
     * Append the match question to the list of selected of match questions
     *
     * @access private
     */
    const appendMatchQuestions = () => {
      const question = questions.find(q => q.id === selected.question);

      if (isEdit && matchQuestionId && questionableId) {
        question['matchQuestionId'] = matchQuestionId;
        question['questionableId'] = questionableId;
      }

      const answer = answers.find(a => a.id === selected.answer);

      setMatchQuestions(matchQuestions => {
        const questionsList = matchQuestions.concat({ question, answer });

        updateMatchQuestions(questionsList);

        return questionsList;
      });
    };

    return (
      <>
        <MatchBank
          selected={selected}
          setSelected={setSelected}
          questions={questions}
          answers={answers}
          matchQuestions={matchQuestions}
        />

        {matchQuestions.length > 0 && (
          <>
            <Divider />
            <List
              itemLayout="horizontal"
              dataSource={matchQuestions}
              renderItem={item => (
                <List.Item>
                  <List.Item.Meta
                    title={item.question.body}
                    description={item.answer.body}
                  />
                  <ActionMenu
                    questionId={item.question.id}
                    answerId={item.answer.id}
                    editMatchQuestion={editMatchQuestion}
                    deleteMatchQuestion={deleteMatchQuestion}
                  />
                </List.Item>
              )}
            />
          </>
        )}
      </>
    );
  }
);

MatchList.propTypes = {
  isEdit: PropTypes.bool.isRequired,
  setMatchOptionValue: PropTypes.func.isRequired,
  setQuestions: PropTypes.func.isRequired,
  setAnswers: PropTypes.func.isRequired,

  questions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
      body: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,

  answers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
      body: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,

  updateMatchQuestions: PropTypes.func.isRequired,
  matchQuestionId: PropTypes.string,
  questionableId: PropTypes.string,
};

export default MatchList;
