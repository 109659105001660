import gql from 'graphql-tag';

export const ShowQuestionInfoQuery = gql`
  query question($questionId: ID!) {
    question(questionId: $questionId) {
      id
      questionType
      body
      answers {
        body
        id
      }
      rightAnswer {
        body
        id
      }
      rightExplanation
      wrongExplanation
    }
  }
`;
