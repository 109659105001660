import styled from '@emotion/styled/macro';
import { colors } from '@utilities';

export const FormContainer = styled.div`
  form {
    display: flex;
    flex-direction: column;
  }
  .form-control {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  label {
    margin-bottom: 12px;
    font-size: 16px;
    color: #4a4a4a;
    display: flex;
    align-items: center;
  }
  .input-50 {
    width: 50%;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  .input-50 > label {
    display: block;
    margin-bottom: 12px;
    font-size: 16px;
    color: #4a4a4a;
  }
  select,
  input {
    height: 46px;
    padding: 4px 10px;
    border-color: #c4c4c4;
    border-radius: 5px;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png)
      no-repeat 98% 17px #fff;
    background-size: 12px;
    border-color: #c4c4c4;
    border-radius: 5px;
  }

  input[type='file'] {
    display: none;
  }
  .custom-file-upload {
    border: 1px solid #ccc;
    display: block;
    padding: 6px 12px;
    cursor: pointer;
    background: white;
    border: 1px solid #c4c4c4;
    border-radius: 3px;
    white-space: nowrap;
    width: fit-content;
    svg {
      margin-right: 5px;
    }
  }

  input[type='checkbox'] {
    margin-inline-end: 4px;
    width: 20px;
    height: 20px;
  }
`;

export const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  label {
    margin-bottom: 0;
    background-color: white;
    border: 1px solid #e0e0e0;
    padding: 15px 20px;
    display: flex;
    width: fit-content;
    align-items: center;
    cursor: pointer;
    border-radius: 25px;
  }
  input {
    margin-right: 8px;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 15px;

  &:nth-child(even) {
    margin: 32px 0;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const FullContainer = styled.div`
  width: 100%;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: fit-content;

  label {
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    cursor: pointer;
    input[type='checkbox'] {
      border-radius: 50%;
      border: 1px solid #e0e0e0;
      margin-right: 8px;
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  margin-top: 24px;
  button {
    margin-right: 8px;
    background: ${colors.blue};
    color: white;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 8px 15px;
    cursor: pointer;
    :hover {
      background: white;
      color: ${colors.blue};
    }
  }
  a {
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 8px 15px;
    background: white;
    text-decoration: none;
    cursor: pointer;
    color: black;
    :hover {
      color: ${colors.blue};
      border-color: ${colors.blue};
    }
  }
`;

export const InputDisclaimer = styled.p`
  font-size: 14px;
  color: #4a4a4a;
  margin-top: 10px;
  width: 50%;
`;
