/**
 * @Author: sarahnouh
 * @Date:   2020-02-02T14:50:30+02:00
 * @Last modified by:   sarahnouh
 * @Last modified time: 2020-02-02T14:58:17+02:00
 */
import gql from 'graphql-tag';

export const ResetPasswordMutation = gql`
  mutation resetPassword(
    $token: String!
    $newPassword: String!
    $newPasswordConfirmation: String!
  ) {
    adminResetPassword(
      verificationToken: $token
      newPassword: $newPassword
      newPasswordConfirmation: $newPasswordConfirmation
    ) {
      errors {
        errors
      }
      response
      statusCode
    }
  }
`;
