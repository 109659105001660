import React, { useRef, useEffect, useState } from 'react';
import { getDoughnutChart } from '@utilities/charts';
import Proptypes from 'prop-types';

const DoughnutChart = ({ counts }) => {
  const canvasRef = useRef();
  const [chart, setChart] = useState(undefined);

  useEffect(() => {
    if (chart) {
      chart.destroy();
    }

    setChart(getDoughnutChart(canvasRef.current, counts));
  }, [counts]);
  return (
    <div className="dougnut-chart">
      <canvas ref={canvasRef} id={Date.now()} />
    </div>
  );
};

DoughnutChart.propTypes = {
  counts: Proptypes.arrayOf(
    Proptypes.shape({
      name: Proptypes.string,
      count: Proptypes.number,
      color: Proptypes.string,
    })
  ),
};
export default DoughnutChart;
