import gql from 'graphql-tag';

export const ListJourneys = gql`
  query listJourneys(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $sortBy: String
    $sortType: String
  ) {
    listJourneys(
      first: $first
      after: $after
      last: $last
      before: $before
      sortBy: $sortBy
      sortType: $sortType
    ) {
      nodes {
        id
        name
        numCourses
        persona
        status
        createdAt
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;
