import styled from '@emotion/styled/macro';

export const ModuleWrapper = styled.div`
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 4px 10px 0 rgb(60 60 60 / 10%);
  margin-top: 25px;
  margin-bottom: 25px;

  input::placeholder,
  textarea::placeholder {
    color: #979797;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
  }

  input,
  textarea {
    font-size: 14px;
    font-weight: 500;
    color: #4a4a4a;
  }

  label {
    color: #4a4a4a;
    font-family: Roboto;
    font-size: 16px;
    margin-bottom: 10px;
    display: block;
  }
`;

export const ModuleHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #979797;
  border-radius: 5px;
  background-color: #f8f8f8;
  padding: 12px 30px;
  min-height: 70px;

  label {
    color: #333333;
    font-family: Roboto;
    font-size: 17px;
    font-weight: bold;
    margin-right: 50px;
  }
`;

export const InputWrapper = styled.div`
  min-width: 60%;
  input[type='text'] {
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    background-color: #fff;
    min-height: 40px;
    width: 100%;
    padding: 12px;
  }
`;

export const ErrorMsgWrapper = styled.div`
  ~ input {
    border: 1px solid red;
  }
  color: red;
  margin-top: 5px;
`;

export const ModuleBody = styled.div`
  padding: 0;
`;

export const ContentWrapper = styled.div`
  padding: 30px;
  padding-left: 85px;
`;

export const AssessmentContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 30px;
`;

export const AssessmentInputWrapper = styled.div`
  min-width: calc(50% - 25px);
  position: relative;

  input[type='text'] {
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    background-color: #fff;
    min-height: 40px;
    width: 100%;
    padding: 12px;
  }

  span {
    position: absolute;
    right: 10px;
    top: 45px;
    color: #4a4a4a;
    font-size: 14px;
  }
`;

export const TextAreaWrapper = styled.div`
  margin-bottom: 30px;
  label {
    color: #4a4a4a;
    font-family: Roboto;
    font-size: 16px;
    margin-bottom: 10px;
    display: block;
  }

  .ck.ck-content.ck-editor__editable {
    min-height: 120px;
  }

  .ck.ck-editor {
    max-width: 50%;
  }
`;

export const ArrowWrapper = styled.div`
  position: absolute;
  cursor: pointer;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  img {
    max-width: 15px;
  }

  img.flip {
    transform: rotate(180deg);
  }
`;

export const LessonsWrapper = styled.div`
  h2 {
    color: #333333;
    font-size: 17px;
    font-weight: bold;
    margin: 30px 0 0 0;
  }

  p {
    margin-bottom: 30px;
    font-weight: bold;
  }
`;

export const DeleteModuleBtn = styled.div`
  display: flex;
  padding: 30px;
  justify-content: center;
  border-top: 1px solid #c4c4c4;
  gap: 16px;

  button[type='submit'] {
    outline: none;
    border: none;
    box-shadow: none;
    color: #fff;
    height: 32px;
    padding: 0 15px;
    background-color: #1890ff;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    &.disabled {
      color: rgba(0, 0, 0, 0.25);
      background-color: #f5f5f5;
      border-color: #d9d9d9;
      text-shadow: none;
      box-shadow: none;
    }
    &:hover {
      color: #fff;
      background-color: #40a9ff;
      border-color: #40a9ff;
    }

    span {
      text-decoration: underline;
      color: #ff574d;
      font-family: Roboto;
      font-size: 16px;
    }
  }

  button.disabled {
    span {
      color: #c4c4c4;
    }
  }
`;

export const PreAssessmentBtn = styled.div`
  position: absolute;
  cursor: pointer;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  button {
    outline: none;
    padding: 0;
    border: 0;
    background: transparent;
    color: #4a4a4a;
    font-family: Roboto;
    font-size: 12px;
    font-style: italic;
    cursor: pointer;
  }

  .plus-sign {
    color: #4a4a4a;
    font-weight: bold;
    border: 2px solid;
    border-radius: 50%;
    padding: 2px 10px;
    margin-right: 10px;
    font-size: 20px;
    font-style: normal;
  }
`;
