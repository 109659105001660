import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { Form, Radio, Button, Icon } from 'antd';
import { FormItem, McqAnswerItem } from '@components';
import { objectToArray } from '@utilities';
import validationRules from './validation_rules';
import { questionInitialValues } from './initial_values';
import { en as strings } from '../locales/data.json';

/** custom styling of add new answer button */
const AddAnswerButton = styled(Button)`
  display: block;
`;

/** custom styling for ant d form */
const CustomForm = styled(Form)`
  .ant-radio-group,
  .ant-radio-group-outline {
    display: flex;
    flex-direction: column;
    margin: 15px 0;

    .ant-row,
    .ant-form-item {
      margin-bottom: 0;
    }
  }
`;

/** styling of answer item wrapper */
const AnswerWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const McqQuestion = props => {
  const { form, updateTargetForm, editQuestionData } = props;
  const {
    questionType,
    body,
    mcq_answers,
    rightExplanation,
    wrongExplanation,
    rightAnswerIndex,
  } = questionInitialValues(editQuestionData || { questionType: 'mcq' });

  const [parsedAnswers, updateAnswers] = useState(mcq_answers);
  const [rightAnswer, updateRightAnswer] = useState(rightAnswerIndex);

  useEffect(() => {
    form && updateTargetForm(form);
  }, []);

  /** update parsedAnswers on changing in editQuestionData value */
  useEffect(() => {
    updateAnswers(mcq_answers);
  }, [editQuestionData]);

  /**
   * handle changing in right answer value
   *
   * @param {Event} e
   */
  const handleRightAnswerChange = e => {
    e.preventDefault();
    updateRightAnswer(e.target.value);
  };

  /** handle Adding new answer   */
  const handleAddNewAnswer = () => {
    updateAnswers([
      ...parsedAnswers,
      {
        body: '',
      },
    ]);
  };

  /**
   * handling delete mcq answer option
   *
   * @param {Number} answerIndex
   */
  const handleDeleteAnswerItem = answerIndex => {
    const formAnswersValues = form.getFieldsValue().answers;
    delete formAnswersValues[`answer_${answerIndex}`];

    const clonedAnswers = objectToArray(formAnswersValues);
    updateAnswers(clonedAnswers);
    updateRightAnswer(null);

    /** updating form values */
    form.setFieldsValue({
      answers: Object.values(formAnswersValues).reduce((acc, el, index) => {
        acc[`answer_${index}`] = {
          body: el.body,
        };

        return acc;
      }, {}),
      rightAnswerIndex: null,
    });
  };

  return (
    <CustomForm>
      <FormItem
        inputName="questionType"
        placeHolder="Question Type"
        hidden={true}
        validationRules={validationRules.questionType}
        initialValue={questionType}
        validateTrigger={['onChange', 'onBlur']}
        form={form}
      />
      <FormItem
        inputName="body"
        placeHolder="Question Title"
        validationRules={validationRules.body}
        initialValue={body}
        validateTrigger={['onChange', 'onBlur']}
        form={form}
      />
      <Form.Item label="Answers">
        {form.getFieldDecorator(`rightAnswerIndex`, {
          rules: validationRules.rightAnswerIndex,
          initialValue: parseInt(rightAnswer),
          validateTrigger: ['onChange', 'onBlur'],
          onChange: handleRightAnswerChange,
        })(
          <Radio.Group>
            {parsedAnswers.map((answer, index) => {
              return (
                <AnswerWrapper key={index}>
                  <McqAnswerItem
                    answerIndex={index}
                    initialValue={answer.body}
                    answer={answer}
                    key={index}
                    form={form}
                    validationRules={validationRules.answers}
                    inputName={`answers.answer_${index}`}
                    hideLabel={true}
                    validateTrigger={['onChange', 'onBlur']}
                    deleteAnswerItem={handleDeleteAnswerItem}
                  />
                </AnswerWrapper>
              );
            })}
          </Radio.Group>
        )}
      </Form.Item>
      <AddAnswerButton onClick={handleAddNewAnswer}>
        <Icon type="plus" /> {strings.addNewAnswer}
      </AddAnswerButton>
      <FormItem
        inputName="rightExplanation"
        placeHolder="Right Explanation"
        validationRules={validationRules.rightExplanation}
        initialValue={rightExplanation}
        validateTrigger={['onChange', 'onBlur']}
        form={form}
      />
      <FormItem
        inputName="wrongExplanation"
        placeHolder="Wrong Explanation"
        validationRules={validationRules.wrongExplanation}
        initialValue={wrongExplanation}
        validateTrigger={['onChange', 'onBlur']}
        form={form}
      />
    </CustomForm>
  );
};

McqQuestion.propTypes = {
  /** form prop of type object for ant d form method apis */
  form: PropTypes.object.isRequired,
  /** updateTargetForm prop of type function for updating target form object in QuestionForm component
   *  which is required for submission */
  updateTargetForm: PropTypes.func.isRequired,
  /** editQuestionData prop for fetched edit question data */
  editQuestionData: PropTypes.shape({
    id: PropTypes.string,
    body: PropTypes.string,
    questionType: PropTypes.string,
    answers: PropTypes.arrayOf(
      PropTypes.shape({
        body: PropTypes.string,
      })
    ),
    rightAnswerIndex: PropTypes.string,
    rightExplanation: PropTypes.string,
    wrongExplanation: PropTypes.string,
  }),
};

const McqQuestionForm = Form.create()(McqQuestion);

export default McqQuestionForm;
