import gql from 'graphql-tag';

export const ListNavbarSection = gql`
  query listNavbarItems {
    listNavbarItems {
      id
      name
      index
      type
      categories_ids
      tags_ids
      is_active
    }
  }
`;
