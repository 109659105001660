import gql from 'graphql-tag';

export const ADMIN_NOTIFICATIONS_LIST = gql`
  query adminNotifications(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $sortBy: String
    $sortType: String
  ) {
    adminNotifications(
      first: $first
      after: $after
      last: $last
      before: $before
      sortBy: $sortBy
      sortType: $sortType
    ) {
      totalCount
      nodes {
        id
        title
        content
        date
        status
        linkTo
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;
