import React, { useState } from 'react';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';
import CSVReader from 'react-csv-reader';
import { ErrorNote } from '@components';
import { sanitizeString } from '@utilities';
import validateCsvFile from './validate_csv_file';
import { en as strings } from './locales/data.json';

const TemplateDownload = styled.a`
  display: inline-block;
  margin-left: 5px;
`;

const ReaderWrapper = styled.div`
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  cursor: pointer;
  height: 32px;
  padding: 0 15px;
  font-size: 14px;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  border-color: #d9d9d9;
  cursor: pointer;

  .csv-reader-input {
    height: 100%;
    display: flex;
    align-items: center;
  }

  input[type='file'] {
    display: none;
  }

  label {
    cursor: pointer;
  }
`;

const ReadCsvFile = ({
  onComplete,
  fileTempExample,
  validations,
  uploadButtonText,
}) => {
  const [error, updateError] = useState(null);

  const parseDataElement = data => {
    if (data === 'null') return null;

    /** multiple values in same column are separated with ( | )  */
    const dataArr = data.split('|');

    const hasMultipleVal = dataArr.length > 1;
    return hasMultipleVal
      ? dataArr.map(dataEl => sanitizeString(dataEl.trim()))
      : sanitizeString(dataArr[0].trim());
  };

  const onFileLoaded = async (data, fileInfo) => {
    const isFileValid = validateCsvFile({
      file: {
        data,
        fileInfo,
      },
      validations,
      errorHandler: updateError,
    });

    if (!isFileValid) return;

    updateError(null);

    onComplete(data);
  };

  const onError = err => {
    updateError(err);
  };

  const parserConfig = {
    header: true,
    skipEmptyLines: true,
    transformHeader: header => sanitizeString(header.trim()).toCamelCase(),
    transform: data => parseDataElement(data),
  };

  return (
    <>
      <ReaderWrapper>
        <CSVReader
          onFileLoaded={onFileLoaded}
          label={uploadButtonText}
          parserOptions={parserConfig}
          fileEncoding="UTF-8"
          onError={onError}
        />
      </ReaderWrapper>
      <p>
        {strings.exampleFileHint}
        <TemplateDownload href={fileTempExample}>
          {strings.downloadExampleFileButtonText}
        </TemplateDownload>
      </p>
      {error && <ErrorNote>{error}</ErrorNote>}
    </>
  );
};

ReadCsvFile.propTypes = {
  onComplete: PropTypes.func.isRequired,
  fileTempExample: PropTypes.string.isRequired,
  validations: PropTypes.shape({
    maxSize: PropTypes.number,
  }),
  uploadButtonText: PropTypes.string.isRequired,
};

export default ReadCsvFile;
