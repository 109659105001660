import gql from 'graphql-tag';

export const DISCUSSION_COMMENTS = gql`
  query listThreadComments(
    $id: ID!
    $first: Int
    $after: String
    $last: Int
    $before: String
  ) {
    listThreadComments(
      id: $id
      first: $first
      after: $after
      last: $last
      before: $before
    ) {
      nodes {
        comment
        createdAt
        id
        replies {
          createdAt
          id
          reply
          user {
            id
            firstName
            lastName
          }
        }
        user {
          id
          firstName
          lastName
        }
      }
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;
