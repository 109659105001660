import gql from 'graphql-tag';

export const ShowCourseInfoQuery = gql`
  query ShowCourseInfo($courseId: ID!) {
    showCourseInfo(id: $courseId) {
      name
      description
      category {
        id
        name
      }
      courseGroup
      language
      rectangleImage
      squareImage
      providers {
        id
        name
      }
      courseType
      tags {
        id
        name
      }
      questionableConfig {
        trialDuration
        trialMaxLimit
      }
      ageGroups
    }
  }
`;
