import { gql } from '@apollo/client';

export const listAllProvidersQuery = gql`
  query {
    getCreateAdminPrerequisites {
      statusCode
      message
      errors {
        field_name
        message
      }
      providers {
        id
        name
      }
    }
  }
`;
