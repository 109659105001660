import { useQuery } from '@apollo/client';
import { PAGINATION_PAGE_SIZE } from '@constants';
import { LIST_USERS } from '@services';
import { useCallback } from 'react';

export const useUserList = ({ queryVariables }) => {
  const { loading, error, data, refetch } = useQuery(LIST_USERS, {
    variables: {
      first: PAGINATION_PAGE_SIZE,
      ...queryVariables,
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const users = data?.listUsers?.data || [];
  const pageInfo = data?.listUsers?.paginatorInfo || {};

  const refetchPageResults = useCallback(async ({ first, page }) => {
    await refetch({ first, page });
  }, []);

  return { users, pageInfo, loading, error, refetchPageResults };
};
