import React from 'react';
import { DButton } from '@components';
import LoadingGIF from './images/loading.jpg';
import { ar as strings } from './locales/data.json';
import styled from '@emotion/styled/macro';

const Button = styled(props => <DButton {...props} />)`
  padding: 0.7rem;
`;

const Img = styled.img`
  width: 1rem;
  margin-inline-start: 1rem;
`;

export const LoadingButton = () => {
  return (
    <Button outline={true} disabled={true}>
      {strings.loading}
      <Img src={LoadingGIF} srcSet={LoadingGIF} alt="Loading" />
    </Button>
  );
};

export default LoadingButton;
