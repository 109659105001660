import gql from 'graphql-tag';

export const EDIT_JOURNEY_MILESTONES = gql`
  mutation updateJourneyMilestones(
    $journeyId: ID!
    $milestones: [UpdateMilestonesInput!]!
    $deletedMilestonesIds: [ID!]
    $deletedCoursesIds: [ID!]
  ) {
    updateJourneyMilestones(
      journeyId: $journeyId
      milestones: $milestones
      deletedMilestonesIds: $deletedMilestonesIds
      deletedCoursesIds: $deletedCoursesIds
    ) {
      errors {
        errors
        fieldName
      }
      statusCode
      journey {
        id
        name
        description
        estimatedTimeTaken
        banner
        howTo
        numCourses
        status
        persona
        age
        milestones {
          id
          estimated
          milestoneCourses {
            id
            required
            course {
              id
              name
            }
          }
        }
      }
    }
  }
`;
