import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { Form, Upload, message, Button, Icon } from 'antd';
import { en as strings } from './locales/data.json';

/** styling of input hint */
const Hint = styled.p`
  opacity: 0.4;
  font-size: 12px;
  margin: 0;
  line-height: 20px;
`;

const LogosWrapper = styled.p`
  a {
    margin: 0 10px;
  }
`;

/** course photo upload component */
const PhotoUpload = ({
  getFieldDecorator,
  fieldDecorator,
  editablePhoto,
  fieldLabel,
  validationRules,
  hints,
}) => {
  const { Item } = Form;
  const { imageValidation, editImageValidation } = validationRules;

  /** handle change in upload image field */
  const onChange = info => {
    if (info.file.status === 'uploading')
      message.info(`uploading ${info.file.name} file ....`);

    if (info.file.status === 'done')
      message.success(`${info.file.name} file uploaded successfully`);
    else if (info.file.status === 'error')
      message.error(`${info.file.name} file upload failed.`);
  };

  /** to disable direct upload after selecting file */
  const beforeUpload = file => {
    return false;
  };

  /** pass value to field from upload event */
  const normFile = e => {
    if (Array.isArray(e)) return e;

    if (e.fileList.length > 1) e.fileList.shift();

    /** return fileList array if upload input allows multiple file upload
     * otherwise return file obj
     */
    return e?.file;
  };

  return (
    <>
      <Item label={fieldLabel ? fieldLabel : strings.imageLabel}>
        {getFieldDecorator(fieldDecorator || 'image', {
          rules: editablePhoto ? editImageValidation : imageValidation,
          initialValue: '',
          getValueFromEvent: normFile,
        })(
          <Upload name="file" onChange={onChange} beforeUpload={beforeUpload}>
            <Button>
              <Icon type="upload" />
              {strings.UploadButtonText}
            </Button>
          </Upload>
        )}
      </Item>
      {editablePhoto && (
        <LogosWrapper>
          Previously uploaded photo:
          <a href={editablePhoto.url} target="_blank" rel="noopener noreferrer">
            photo
          </a>
        </LogosWrapper>
      )}
      {hints?.length &&
        hints.map((hintTxt, index) => {
          return <Hint key={`${hintTxt}_${index}`}>{hintTxt}</Hint>;
        })}
    </>
  );
};

PhotoUpload.propTypes = {
  /** getFieldDecorator prop of type function passed from ant d form */
  getFieldDecorator: PropTypes.func,
  /** fieldDecorator prop of type string */
  fieldDecorator: PropTypes.string,
  /** setFieldsValue prop of type function passed from ant d form */
  setFieldsValue: PropTypes.func,
  /** editablePhoto is string that represents the path of a previously uploaded photo */
  editablePhoto: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  fieldLabel: PropTypes.string,
  validationRules: PropTypes.object,
  hints: PropTypes.arrayOf(PropTypes.string),
};

export default PhotoUpload;
