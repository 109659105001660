import gql from 'graphql-tag';

export const DELETE_THREAD_COMMENT = gql`
  mutation deleteThreadComment($commentId: ID!) {
    deleteThreadComment(commentId: $commentId) {
      statusCode
      errors {
        errors
        fieldName
      }
    }
  }
`;
