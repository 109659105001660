/**
 * @author Raghda Wessam
 * @date 2020-10-18
 * @description New Question Component
 * @filename new_question.js
 */

import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { Field, ErrorMessage, FieldArray, Formik, Form } from 'formik';
import { Button, Icon, notification, Spin } from 'antd';
import { useMutation } from '@apollo/client';
import { QUESTION_TYPE } from '@utilities';
import {
  createQuestionMutation,
  deleteQuestionMutation,
  updateQuestionMutation,
} from '@services';
import {
  QuestionWrapper,
  QuestionHeader,
  InputWrapper,
  ErrorMsgWrapper,
  QuestionBody,
  ContentWrapper,
  ArrowWrapper,
  AnswersWrapper,
  DeleteQuestionBtn,
  AnswerInputWrapper,
} from './styles/new_question';
import ArrowIcon from '../../../components/main_layout/images/arrow.png';

const questionTypeOptions = [
  {
    value: QUESTION_TYPE.MCQ,
    key: QUESTION_TYPE.MCQ,
  },
  {
    value: QUESTION_TYPE.TRUE_OR_FALSE,
    key: 'True/False',
  },
];

const QuestionCard = props => {
  const [createQuestion, { loading: loadingCreateQuestion }] = useMutation(
    createQuestionMutation
  );
  const [editQuestion, { loading: loadingEditQuestion }] = useMutation(
    updateQuestionMutation
  );
  const [deleteQuestion, { loading: loadingDeleteQuestion }] = useMutation(
    deleteQuestionMutation
  );
  const [deletedAnswerId, setDeletedAnswerId] = useState([]);
  const [isQuestionUpdated, setIsQuestionUpdated] = useState(false);

  const onSubmitQuestion = (values, actions) => {
    let content = {};
    actions.setSubmitting(true);
    if (props.question.id) {
      const deletedAnswerIdCleaned = deletedAnswerId.filter(Boolean);
      if (deletedAnswerIdCleaned.length > 0) {
        content = {
          id: props.question.id,
          body: values.body,
          type: values.type,
          answers: {
            delete_answers: deletedAnswerIdCleaned,
          },
          right_explanation: values.right_explanation,
          wrong_explanation: values.wrong_explanation,
        };
      } else if (
        values.answers.filter(answer => answer.id === null).length > 0
      ) {
        let newAnswer = values.answers
          .filter(answer => answer.id === null)
          ?.map(answer => {
            return {
              body: answer.body,
              is_right: answer.is_right,
            };
          });

        content = {
          id: props.question.id,
          body: values.body,
          type: values.type,
          answers: { create_answers: newAnswer },
          right_explanation: values.right_explanation,
          wrong_explanation: values.wrong_explanation,
        };
      } else {
        if (values.answers.length) {
          content = {
            id: props.question.id,
            body: values.body,
            type: values.type,
            answers: {
              update_answers: values.answers,
            },
            right_explanation: values.right_explanation,
            wrong_explanation: values.wrong_explanation,
          };
        }
      }
      editQuestion({
        variables: { content: content },
      })
        .then(response => {
          if (response.data?.updateQuestion?.question?.id) {
            actions.setSubmitting(false);
            setIsQuestionUpdated(true);
            setDeletedAnswerId([]);
            props.onSaveQuestion(response.data?.updateQuestion?.question);
            notification.success({
              message: 'Question saved successfully',
            });
          } else {
            return Promise.reject(response.errors);
          }
        })
        .catch(error => {
          actions.setSubmitting(false);
          console.error(error);
          notification.error({
            message: error?.message ? error?.message : error,
          });
        });
    } else {
      createQuestion({
        variables: {
          content: {
            assessment_id: props.assessmentId,
            body: values.body,
            type: values.type,
            answers: values.answers.map(answer => {
              return {
                body: answer.body,
                is_right: answer.is_right,
              };
            }),
            right_explanation: values.right_explanation,
            wrong_explanation: values.wrong_explanation,
          },
        },
      })
        .then(response => {
          if (response.data?.createQuestion?.question?.id) {
            actions.setSubmitting(false);
            setIsQuestionUpdated(true);
            props.onSaveQuestion(response.data?.createQuestion?.question);
            notification.success({
              message: 'Question saved successfully',
            });
          } else {
            return Promise.reject(response.errors);
          }
        })
        .catch(error => {
          actions.setSubmitting(false);
          console.error(error);
          notification.error({
            message: error?.message ? error?.message : error,
          });
        });
    }
  };

  const onSelectQuestionType = (type, formikBag) => {
    if (formikBag.values['type'] !== type) {
      formikBag.setFieldValue('type', type);
      switch (type) {
        case QUESTION_TYPE.MCQ:
          formikBag.setFieldValue('answers', [
            {
              body: '',
              is_right: true,
            },
            {
              body: '',
              is_right: false,
            },
          ]);
          break;
        case QUESTION_TYPE.TRUE_OR_FALSE:
          formikBag.setFieldValue('answers', [
            {
              body: 'True',
              is_right: true,
            },
            {
              body: 'False',
              is_right: false,
            },
          ]);
          break;
        default:
          break;
      }
    }
  };

  const onDeleteQuestion = () => {
    if (props.question.id) {
      deleteQuestion({
        variables: {
          id: props.question.id,
        },
      })
        .then(response => {
          if (response.data?.deleteQuestion?.statusCode === 200) {
            setIsQuestionUpdated(true);
            props.onDeleteQuestion();
            notification.success({
              message: 'Question deleted successfully',
            });
          } else {
            return Promise.reject(response.errors);
          }
        })
        .catch(error => {
          console.error(error);
          notification.error({
            message: error?.message ? error?.message : error,
          });
        });
    } else {
      props.onDeleteQuestion();
    }
  };

  let initialAnswers = props.question?.answers?.map(answer => {
    return {
      body: answer.body,
      is_right: props.question.right_answer_id === answer.id,
      id: answer.id,
    };
  });
  if (initialAnswers?.length === 0) {
    initialAnswers = [
      {
        id: null,
        body: '',
        is_right: true,
      },
      {
        id: null,
        body: '',
        is_right: false,
      },
    ];
  }
  const initialValues = {
    body: props.question?.body || '',
    type: props.question?.type || QUESTION_TYPE.MCQ,
    answers: initialAnswers
      ? initialAnswers
      : [
          {
            id: null,
            body: '',
            is_right: true,
          },
          {
            id: null,
            body: '',
            is_right: false,
          },
        ],
    right_explanation: props.question?.right_explanation || '',
    wrong_explanation: props.question?.wrong_explanation || '',
  };
  const loading = loadingCreateQuestion || loadingDeleteQuestion;
  return (
    <QuestionWrapper>
      <QuestionHeader>
        <ArrowWrapper
          onClick={() => {
            props.onToggleQuestion();
          }}
        >
          <img
            className={props.opened ? 'flip' : null}
            src={ArrowIcon}
            alt="toggle collapse arrow"
          />
        </ArrowWrapper>
        <label htmlFor="name">{`Question`}</label>
      </QuestionHeader>

      {props.opened && (
        <div
          className={`assessment-body ${!props.assessmentCardExpand &&
            'collapsed'}`}
        >
          <Formik
            // TODO: add validation schema
            initialValues={initialValues}
            onSubmit={onSubmitQuestion}
            enableReinitialize={true}
          >
            {formikBag => (
              <Form>
                {loading && <Spin />}
                <QuestionBody>
                  <ContentWrapper>
                    <div className="form-control">
                      <label>
                        Type
                        <span className="required">*</span>
                      </label>
                      <div className="radio-btns-wrapper">
                        <Field name="type">
                          {({ field }) => {
                            return questionTypeOptions.map(option => {
                              return (
                                <div
                                  className="radio-btn-item "
                                  key={option.key}
                                >
                                  <label htmlFor={option.value}>
                                    <input
                                      type="radio"
                                      id={option.value}
                                      name={option.value}
                                      value={option.value}
                                      checked={field.value === option.value}
                                      onClick={() =>
                                        onSelectQuestionType(
                                          option.value,
                                          formikBag
                                        )
                                      }
                                    />
                                    {option.key}
                                  </label>
                                </div>
                              );
                            });
                          }}
                        </Field>
                      </div>
                      <ErrorMessage component={ErrorMsgWrapper} name="type" />
                    </div>

                    <InputWrapper>
                      <label htmlFor={`body`}>
                        Question Body {<span className="required">*</span>}
                      </label>
                      <Field
                        name={`body`}
                        type="text"
                        placeholder="Add question body"
                      />

                      <ErrorMessage component={ErrorMsgWrapper} name={`body`} />
                    </InputWrapper>

                    {(formikBag.values.type === QUESTION_TYPE.TRUE_OR_FALSE ||
                      formikBag.values.type === QUESTION_TYPE.MCQ) && (
                      <>
                        <AnswersWrapper>
                          <h2>Answers {<span className="required">*</span>}</h2>
                          <FieldArray name={`answers`}>
                            {arrayHelpers => (
                              <div role="group">
                                {formikBag.values.answers?.map(
                                  (answer, index) => {
                                    return (
                                      <AnswerInputWrapper key={index}>
                                        <InputWrapper
                                          className="answer-input-wrapper"
                                          key={index}
                                        >
                                          <Field
                                            type="radio"
                                            name={`is_right`}
                                            checked={
                                              formikBag.values.answers[index]
                                                .is_right
                                            }
                                            onClick={() => {
                                              const updatedAnswers = formikBag.values.answers?.map(
                                                (answer, i) => {
                                                  if (i === index) {
                                                    return {
                                                      ...answer,
                                                      is_right: true,
                                                    };
                                                  }
                                                  return {
                                                    ...answer,
                                                    is_right: false,
                                                  };
                                                }
                                              );
                                              formikBag.setFieldValue(
                                                'answers',
                                                updatedAnswers
                                              );
                                            }}
                                          />
                                          <div className="input-error-wrapper">
                                            <Field
                                              name={`answers[${index}].body`}
                                              type="text"
                                              placeholder={'Add answer'}
                                              disabled={
                                                formikBag.values.type ===
                                                QUESTION_TYPE.TRUE_OR_FALSE
                                              }
                                            />
                                            <ErrorMessage
                                              component={ErrorMsgWrapper}
                                              name={`answers[${index}].body`}
                                            />
                                          </div>
                                        </InputWrapper>
                                        {/* <Icon
                                          type="delete"
                                          style={{
                                            color:
                                              formikBag.values.answers?.length >
                                              2
                                                ? 'red'
                                                : 'gray',
                                            cursor:
                                              formikBag.values.answers?.length >
                                              2
                                                ? 'pointer'
                                                : 'not-allowed',
                                            fontSize: '20px',
                                            position: 'absolute',
                                            transform: 'translateY(-50%)',
                                            top: '50%',
                                            left: 'calc(100% + 10px)',
                                          }}
                                          onClick={() => {
                                            if (
                                              formikBag.values.answers?.length >
                                              2
                                            ) {
                                              arrayHelpers.remove(index);
                                            }
                                          }}
                                        /> */}
                                      </AnswerInputWrapper>
                                    );
                                  }
                                )}
                                {formikBag.values.type ===
                                  QUESTION_TYPE.MCQ && (
                                  <Button
                                    type="primary"
                                    onClick={() => {
                                      if (
                                        formikBag.values.answers?.length < 5
                                      ) {
                                        arrayHelpers.push({
                                          body: '',
                                          is_right: false,
                                        });
                                      }
                                    }}
                                    disabled={
                                      formikBag.values.answers?.length > 4
                                    }
                                  >
                                    Add another answer
                                  </Button>
                                )}

                                <div className="right-answer-note">
                                  Please select the right answer
                                  <ErrorMessage
                                    component={ErrorMsgWrapper}
                                    name={`is_right`}
                                  />
                                </div>
                              </div>
                            )}
                          </FieldArray>
                        </AnswersWrapper>
                        <InputWrapper>
                          <label htmlFor={`right_explanation`}>
                            Right Explanation
                          </label>
                          <Field
                            name={`right_explanation`}
                            type="text"
                            placeholder="Validate if they answer right."
                          />
                          <ErrorMessage
                            component={ErrorMsgWrapper}
                            name={`right_explanation`}
                          />
                        </InputWrapper>
                        <InputWrapper>
                          <label htmlFor={`wrong_explanation`}>
                            Wrong Explanation
                          </label>
                          <Field
                            name={`wrong_explanation`}
                            type="text"
                            placeholder="Validate if they answer wrong."
                          />
                          <ErrorMessage
                            component={ErrorMsgWrapper}
                            name={`wrong_explanation`}
                          />
                        </InputWrapper>
                      </>
                    )}
                  </ContentWrapper>
                  <DeleteQuestionBtn>
                    <Button
                      className={!props.canDeleteQuestion ? 'disabled' : null}
                      type="primary"
                      disabled={!props.canDeleteQuestion}
                      onClick={onDeleteQuestion}
                    >
                      Delete Question
                    </Button>
                    <button
                      className={!formikBag.isValid ? 'disabled' : null}
                      type="submit"
                      disabled={
                        !formikBag.isValid || loading || formikBag.isSubmitting
                      }
                    >
                      Save Question
                    </button>
                  </DeleteQuestionBtn>
                </QuestionBody>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </QuestionWrapper>
  );
};

QuestionCard.propTypes = {
  assessmentId: PropTypes.string.isRequired,
  // workbook question values
  question: PropTypes.object,
  opened: PropTypes.bool.isRequired,
  // function that is fired whenever question header is clicked to toggle question visibility
  onToggleQuestion: PropTypes.func.isRequired,
  // function responsible for deleting a specific question from workbook
  onDeleteQuestion: PropTypes.func.isRequired,
  onSaveQuestion: PropTypes.func.isRequired,
  canDeleteQuestion: PropTypes.bool.isRequired,
};

export default QuestionCard;
