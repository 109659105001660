import gql from 'graphql-tag';

export const GET_COURSE_CERTIFICATE = gql`
  query courseCertificate($id: ID!) {
    courseCertificate(id: $id) {
      id
      certificateCourseName
      imageData
      providerId
    }
  }
`;
