/*eslint no-extend-native: ["error", { "exceptions": ["String"] }]*/

String.prototype.capitalize = function() {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

String.prototype.toCamelCase = function() {
  return this.replace(/\s(.)/g, function($1) {
    return $1.toUpperCase();
  })
    .replace(/\s/g, '')
    .replace(/^(.)/, function($1) {
      return $1.toLowerCase();
    });
};
