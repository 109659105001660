import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Icon } from 'antd';
import styled from '@emotion/styled/macro';

const CustomLink = styled(Link)`
  margin: 0 20px;
  position: relative;
  display: inline-block;
`;

const NotificationsCount = styled.span`
  position: absolute;
  top: 10px;
  right: -8px;
  background-color: #ff0000;
  border-radius: 50px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  color: #fff;
  font-size: 9px;
  font-weight: bold;
`;

const NotificationsBell = () => {
  const [newNotificationsCount, updateCount] = useState(null);

  return (
    <CustomLink to="/admin-notifications">
      <Icon type="bell" style={{ fontSize: 20, color: '#000' }} />
      {newNotificationsCount && <NotificationsCount></NotificationsCount>}
    </CustomLink>
  );
};

export default NotificationsBell;
