import * as Yup from 'yup';
import { SUPPORTED_FORMATS } from '@constants';

export const NEW_LIVE_SESSION_SCHEMA = shouldValidateStartDate => {
  return Yup.object().shape({
    title: Yup.string()
      .required('Session title is required')
      .max(100, 'Session title must not exceed 100 characters!'),
    description: Yup.string().required('Session descprition is required'),
    link: Yup.string()
      .required('Session link is required')
      .matches(/(http(s?)):\/\//i),
    recommended_age: Yup.string().required('Recommended age is required'),
    started_at: shouldValidateStartDate
      ? Yup.date()
          .min(new Date(), 'Start Date must be later than today')
          .required('Started date time is required')
      : Yup.date(),
    ended_at: Yup.date()
      .min(new Date(), 'End Date/time must be later than Start Date/time')
      .when(
        'started_at',
        (started_at, schema) => started_at && schema.min(started_at)
      )
      .required('Ended date time is required'),
    personas_ids: Yup.array()
      .min(1, 'Please assign session to one or more roles')
      .required('roles are required'),
    age_grades: Yup.array()
      .required()
      .when('personas_ids', {
        is: personas_ids => personas_ids.includes('23'), //student id
        then: schema =>
          schema.test(
            'type',
            'should select at least one student grade',
            value => value?.some(val => val.includes('grade')) //check if any grade is selected
          ),
        otherwise: Yup.array(),
      }),
    image: Yup.mixed()
      .required('Image is required.')
      .test(
        'fileFormat',
        'Please choose .png, .jpg or .jpeg image format',
        value => {
          if (!value?.type) return true;
          return SUPPORTED_FORMATS.includes(value?.type);
        }
      )
      .test({
        name: 'aspectRatio',
        message: 'Aspect ratio must be 16:9',
        test: function(value) {
          if (!value) {
            return false;
          }

          return new Promise(resolve => {
            if (!value?.url) {
              const reader = new FileReader();
              reader.readAsDataURL(value);
              reader.addEventListener('load', event => {
                const _loadedImageUrl = event.target.result;
                const image = document.createElement('img');
                image.src = _loadedImageUrl;
                image.addEventListener('load', () => {
                  const { width, height } = image;
                  const aspectRatio = width / height;
                  const desiredAspectRation = 16 / 9;
                  const ratiosDivision = aspectRatio / desiredAspectRation;
                  const threshold = 0.05; // the accepted deviation in aspect ration
                  resolve(
                    ratiosDivision >= 1 - threshold &&
                      ratiosDivision <= 1 + threshold
                  );
                });
              });
            } else if (value?.url) {
              resolve(true);
            }
          });
        },
      }),
  });
};

/**
 * return initialValues to be used by courseDetails form.
 *
 * @export
 * @param {Object} sessionDetails current course details to populate initialValues.
 * @return {Object} initialValues to be used by courseDetails form.
 */
export function getLiveSessionDetailsInitialValues(sessionDetails) {
  let initialValues = {
    title: '',
    description: '',
    image: null,
    link: '',
    started_at: '',
    ended_at: '',
    personas_ids: [],
    age_grades: [],
    is_published: false,
    recommended_age: '',
  };

  if (Object.keys(sessionDetails)?.length !== 0) {
    const ageGroups =
      sessionDetails?.age_groups?.map(ageGroup => `${ageGroup.id}_age`) || [];
    const grades =
      sessionDetails?.grades?.map(grade => `${grade.id}_grade`) || [];
    const personasIds = sessionDetails.roles
      ? sessionDetails?.roles?.map(role => role.id)
      : [];

    const image = { url: '' };
    if (sessionDetails?.image?.url) {
      image.url = sessionDetails?.image?.url;
    }

    initialValues = {
      title: sessionDetails.title ? sessionDetails.title : '',
      description: sessionDetails?.description
        ? sessionDetails.description
        : '',
      link: sessionDetails?.link ? sessionDetails.link : '',
      recommended_age: sessionDetails?.recommended_age
        ? sessionDetails?.recommended_age
        : '',
      started_at: sessionDetails?.started_at ? sessionDetails.started_at : '',
      personas_ids: personasIds,
      ended_at: sessionDetails?.ended_at ? sessionDetails.ended_at : '',
      image,
      age_grades:
        ageGroups?.length > 0
          ? [...ageGroups]
          : grades?.length > 0
          ? [...grades]
          : ageGroups?.length > 0 && grades?.length > 0
          ? [...ageGroups, ...grades]
          : [],
    };
  }

  return initialValues;
}
