import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from 'react';
import MatchList from './match_list';
import MatchCreateOption from './match_create_option';
import PropTypes from 'prop-types';
import { WORKBOOK } from '@services';
import { Spin } from 'antd';
import { useLazyQuery } from '@apollo/client';

const MatchQuestions = forwardRef(
  ({ workbookId, updateMatchQuestions, question }, ref) => {
    let listRef;
    const [matchOptionValue, setMatchOptionValue] = useState({
      question: '',
      answer: '',
    });
    const [answers, setAnswers] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [matchQuestionId, updateMatchQuestionId] = useState(null);
    const [questionableId, updateQuestionableId] = useState(null);

    const [getWorkbookQuestions, { error, loading }] = useLazyQuery(WORKBOOK, {
      variables: { id: workbookId },
      onCompleted: ({ workbook: { questions, questionableId } }) => {
        const getAnswers = [];
        const getQuestions = [];
        const getMatchQuestions = [];

        questions.forEach(item => {
          const tempAnswer = item.answers[0];
          const question = Object.assign(
            {},
            {
              id: +item.id,
              type: 'question',
              body: item.body,
              matchQuestionId: item.matchQuestionId,
              questionId: +item.id,
              questionableId: questionableId,
            }
          );

          /** setting matchQuestionId and questionableId in editing state */
          updateMatchQuestionId(item.matchQuestionId);
          updateQuestionableId(questionableId);

          const answer = Object.assign(
            {},
            { id: +tempAnswer.id, type: 'answer', body: tempAnswer.body }
          );

          getAnswers.push(answer);
          getQuestions.push(question);
          getMatchQuestions.push({ question, answer });
        });

        setAnswers(getAnswers);
        setQuestions(getQuestions);
        updateMatchQuestions(getMatchQuestions);
        listRef.setMatchQuestions(getMatchQuestions);
      },
    });

    useEffect(() => {
      if (question?.id) getWorkbookQuestions();
    }, [question]);

    /**
     * Return from the callback passed as the second argument
     */
    useImperativeHandle(ref, () => listRef);

    /**
     * Set an option (question or answer) to its list
     *
     * @access public
     */
    const setOption = (type, item) => {
      const setter = { questions: setQuestions, answers: setAnswers };

      setter[type](list => list.concat(item));
    };

    if (loading) return <Spin size="large" />;
    if (error) return <p>{error.message}</p>;

    return (
      <>
        <MatchCreateOption
          setOption={setOption}
          type="question"
          listName="questions"
          value={matchOptionValue.question}
        />
        <MatchCreateOption
          setOption={setOption}
          type="answer"
          listName="answers"
          value={matchOptionValue.answer}
        />
        <MatchList
          ref={ref => ref && (listRef = ref)}
          questions={questions}
          answers={answers}
          setQuestions={setQuestions}
          setAnswers={setAnswers}
          updateMatchQuestions={updateMatchQuestions}
          setMatchOptionValue={setMatchOptionValue}
          matchOptionValue={matchOptionValue}
          isEdit={!!workbookId}
          matchQuestionId={matchQuestionId}
          questionableId={questionableId}
        />
      </>
    );
  }
);

MatchQuestions.propTypes = {
  workbookId: PropTypes.string,
  updateMatchQuestions: PropTypes.func.isRequired,
  question: PropTypes.object,
};

export default MatchQuestions;
