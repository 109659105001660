import React from 'react';
import Proptypes from 'prop-types';
import { Link } from 'react-router-dom';
import { getNumberReadableValue } from '@utilities';

const CompletionRateCard = ({ title, courses, type }) => {
  return (
    <div className="completion-rate-card">
      <h3 className="title">{title}</h3>
      <ul className="rates-list">
        {courses?.map(course => {
          return (
            <li key={course?.course_id} className="course-rate-item">
              <div className="name-container">
                <p className="name">{course.course_name}</p>
                <Link className="link" to="#">
                  View Details
                </Link>
              </div>
              <div
                className={
                  type === 'lowest'
                    ? 'rate-container lowest'
                    : type === 'count'
                    ? 'rate-container count'
                    : 'rate-container'
                }
              >
                {type === 'count' ? (
                  <p className="rate">
                    {getNumberReadableValue(course.total_enrollments_count) ||
                      0}
                  </p>
                ) : (
                  <p className="rate">
                    {course.course_completions_count > 0 &&
                    course.total_completions_count >=
                      course.course_completions_count
                      ? Math.round(
                          Number(
                            (course.course_completions_count /
                              course.total_completions_count) *
                              100
                          )
                        )
                      : 0}
                    &#37;
                  </p>
                )}

                <p className="provider">{course.provider_name}</p>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

CompletionRateCard.propTypes = {
  title: Proptypes.string,
  type: Proptypes.string,
  courses: Proptypes.arrayOf(
    Proptypes.shape({
      course_name: Proptypes.string,
      total_enrollments_count: Proptypes.number,
      completed_enrollments_count: Proptypes.number,
      provider_name: Proptypes.string,
    })
  ),
};

export default CompletionRateCard;
