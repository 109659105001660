import gql from 'graphql-tag';

export const CreateNavbarSection = gql`
  mutation createNavbarItem(
    $name: String!
    $index: Int!
    $type: NavbarDataType!
    $tags_ids: [ID!]
    $roles_ids: [ID!]!
    $categories_ids: [ID!]
    $is_active: Boolean
  ) {
    createNavbarItem(
      name: $name
      index: $index
      type: $type
      tags_ids: $tags_ids
      roles_ids: $roles_ids
      categories_ids: $categories_ids
      is_active: $is_active
    ) {
      statusCode
      navbar_item {
        id
        name
        index
        type
        categories_ids
        tags_ids
        roles_ids
        is_active
      }
      message
      errors {
        message
      }
    }
  }
`;
