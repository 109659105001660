import { createContext } from 'react';

export const authContext = createContext({
  authenticated: false,
  user: {},
  role: '',
  token: '',
  login: () => {},
  logout: () => {},
});

export const AuthProvider = authContext.Provider;
export const AuthConsumer = authContext.Consumer;
