import React from 'react';
import { Redirect } from 'react-router-dom';
import { DynamicLoading } from '@components';
import { AuthConsumer, Can } from '@auth';
import { ACTIONS } from '../../../rbac_rules';

const EditCompetition = props => {
  return (
    <AuthConsumer>
      {({ role }) => (
        <Can
          role={role}
          perform={ACTIONS.EDIT_COMPETITION}
          yes={() => (
            <DynamicLoading
              componentImport={() => import('./partials/edit_competition')}
              data={{ ...props }}
            />
          )}
          no={() => {
            return <Redirect to="/" />;
          }}
        />
      )}
    </AuthConsumer>
  );
};

export default EditCompetition;
