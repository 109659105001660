import gql from 'graphql-tag';

export const ADD_REPLY = gql`
  mutation AddReply($id: Int!, $reply: String!) {
    addCommentReply(commentId: $id, reply: $reply) {
      errors {
        errors
        fieldName
      }
      statusCode
    }
  }
`;
