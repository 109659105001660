/**
 * @author Ahmed Serag
 * @date 2022-03-14
 * @description Content of module card.
 * @filename module_card_content.js
 */

import React from 'react';
import { Field, Form, ErrorMessage, FieldArray, Formik } from 'formik';
import { PropTypes } from 'prop-types';
import { useMutation } from '@apollo/client';
import numeral from 'numeral';
import { Button, notification, Spin } from 'antd';
import { WysiWygEditor } from '@components';
import {
  deleteCourseItem,
  CreateCourseOutlineMutation,
  updateCourseItem,
  createCourseItem,
} from '@services';
import LessonCard from './lesson_card';
import ArrowIcon from '../../../components/main_layout/images/arrow.png';
import {
  ModuleHeader,
  InputWrapper,
  ErrorMsgWrapper,
  ModuleBody,
  ContentWrapper,
  TextAreaWrapper,
  ArrowWrapper,
  LessonsWrapper,
  DeleteModuleBtn,
  ModuleWrapper,
} from './styles/module_card_styles';
import { MODULE_SCHEMA } from '@utilities';

const ModuleCard = props => {
  const [deleteModuleMutation, { loading: loadingDeleteModule }] = useMutation(
    deleteCourseItem
  );

  const [createModuleMutation, { loading: loadingCreateModule }] = useMutation(
    CreateCourseOutlineMutation
  );

  const [
    updateCourseItemMutation,
    { loading: loadingUpdateModule },
  ] = useMutation(updateCourseItem);

  const [
    createCourseItemMutation,
    { loading: loadingCreateCourseItem },
  ] = useMutation(createCourseItem);

  const deleteModule = () => {
    if (!props.canDeleteModule) {
      notification.error({
        message: "You can't delete this module",
      });
      return;
    }
    if (!props.moduleData.id) {
      props.onDeleteModule(props.moduleData.id);
      return;
    }

    deleteModuleMutation({
      variables: {
        courseItemId: props.moduleData.id,
      },
    })
      .then(() => {
        props.onDeleteModule();
      })
      .catch(error => {
        console.error(error);
        notification.error({
          message: "Can't delete module",
          error,
        });
      });
  };

  const updateModuleLessons = lessons => {
    // TODO: implement update module lessons
    let lessonsPromises = [];

    for (let lesson of lessons) {
      const oldModuleLesson = props.moduleData.lessons.find(
        moduleLesson => moduleLesson.id === lesson.id
      );
      if (oldModuleLesson && oldModuleLesson.name !== lesson.name) {
        lessonsPromises.push(
          updateCourseItemMutation({
            variables: {
              content: {
                id: lesson.id,
                name: lesson.name,
              },
            },
          })
        );
      } else if (!oldModuleLesson) {
        lessonsPromises.push(
          createCourseItemMutation({
            variables: {
              content: {
                name: lesson.name,
                parent_id: props.moduleData.id,
              },
            },
          })
        );
      }
    }

    for (let oldModuleLesson of props.moduleData.lessons) {
      if (!lessons.find(lesson => lesson.id === oldModuleLesson.id)) {
        lessonsPromises.push(
          deleteModuleMutation({
            variables: {
              courseItemId: oldModuleLesson.id,
            },
          })
        );
      }
    }

    return Promise.all(lessonsPromises);
  };

  const updateModule = values => {
    let promise = updateModuleLessons(values.lessons).then(() => {
      return updateCourseItemMutation({
        variables: {
          content: {
            id: props.moduleData.id,
            name: values.name,
            description: values.description,
          },
        },
      });
    });

    promise = promise.then(response => {
      if (response.data?.updateCourseItem?.course_item) {
        const name = response.data?.updateCourseItem?.course_item.name;
        const description =
          response.data?.updateCourseItem?.course_item.description;
        const id = response.data?.updateCourseItem?.course_item.id;
        const lessons = response.data?.updateCourseItem?.course_item.children.map(
          lesson => {
            return {
              id: lesson.id,
              name: lesson.name,
            };
          }
        );
        return {
          name,
          description,
          id,
          lessons,
        };
      }
      return Promise.reject(response);
    });

    return promise;
  };

  const createModule = values => {
    return createModuleMutation({
      variables: {
        content: {
          course_id: props.courseId,
          module: {
            name: values.name,
            description: values.description,
            lessons: values.lessons.map(lesson => ({ name: lesson.name })),
          },
        },
      },
    }).then(response => {
      if (response.data?.createCourseOutline?.course?.modules) {
        return response.data?.createCourseOutline?.course?.modules[
          props.moduleIndex
        ];
      }
      return Promise.reject(response);
    });
  };

  const handleSubmitModule = (values, actions) => {
    let promise;
    actions.setSubmitting(true);
    if (props.moduleData?.id) {
      promise = updateModule(values);
    } else {
      promise = createModule(values);
    }

    return promise.then(savedModule => {
      actions.setSubmitting(false);
      props.onSaveModule(savedModule);
      notification.success({
        message: 'Module saved successfully',
      });
    });
  };

  const loading =
    loadingCreateModule ||
    loadingUpdateModule ||
    loadingDeleteModule ||
    loadingCreateCourseItem;
  return (
    <ModuleWrapper>
      <Formik
        initialValues={{
          name: props.moduleData.name,
          description: props.moduleData.description,
          lessons: props.moduleData.lessons,
        }}
        onSubmit={handleSubmitModule}
        validationSchema={MODULE_SCHEMA}
      >
        {formikBag => (
          <Form>
            {loading && <Spin />}
            <ModuleHeader>
              <ArrowWrapper
                onClick={() => {
                  props.onToggleModule();
                }}
              >
                <img
                  className={props.opened ? 'flip' : null}
                  src={ArrowIcon}
                  alt="toggle collapse arrow"
                />
              </ArrowWrapper>
              <label htmlFor="name">
                <span className="required" />

                {`${numeral(Number(props.moduleIndex) + 1).format(
                  '0o'
                )} Module`}
              </label>
              <InputWrapper>
                <Field
                  name="name"
                  type="text"
                  placeholder="Add module name"
                  disabled={!props.opened}
                />
                <ErrorMessage component={ErrorMsgWrapper} name="name" />
              </InputWrapper>
            </ModuleHeader>
            {props.opened && (
              <ModuleBody>
                <ContentWrapper>
                  <TextAreaWrapper>
                    <label htmlFor="description">
                      <span className="required" /> Module Description
                    </label>

                    <WysiWygEditor
                      name="description"
                      bindValueChange={description => {
                        formikBag.setFieldValue('description', description);
                      }}
                      placeHolder="Add module description"
                      initialValue={formikBag.values?.description}
                    />
                    <ErrorMessage
                      component={ErrorMsgWrapper}
                      name="description"
                    />
                  </TextAreaWrapper>

                  <LessonsWrapper>
                    <h2>Lessons</h2>
                    <p>Each module can have a maximum number of 10 Lessons</p>
                    <FieldArray name="lessons">
                      {({ remove, push }) => (
                        <div>
                          {formikBag.values.lessons?.map((lesson, index) => {
                            return (
                              <LessonCard
                                key={index}
                                index={index}
                                remove={remove}
                                canDeleteLesson={
                                  formikBag.values.lessons?.length > 1
                                }
                              />
                            );
                          })}

                          <Button
                            type="primary"
                            onClick={() => {
                              if (formikBag.values.lessons?.length < 10) {
                                push('');
                              }
                            }}
                            disabled={formikBag.values.lessons?.length > 9}
                          >
                            Add Lesson
                          </Button>
                        </div>
                      )}
                    </FieldArray>
                  </LessonsWrapper>
                </ContentWrapper>
                <DeleteModuleBtn>
                  <Button
                    className={!props.canDeleteModule ? 'disabled' : null}
                    type="primary"
                    disabled={!props.canDeleteModule}
                    onClick={() => {
                      deleteModule(module.index);
                    }}
                  >
                    Delete Module
                  </Button>
                  <button
                    className={
                      !formikBag.isValid ||
                      loading ||
                      formikBag.isSubmitting ||
                      !formikBag.values.description ||
                      !formikBag.values.name ||
                      formikBag.values?.lessons[0]?.name?.length === 0
                        ? 'disabled'
                        : null
                    }
                    type="submit"
                    disabled={
                      !formikBag.isValid ||
                      loading ||
                      formikBag.isSubmitting ||
                      !formikBag.values.description ||
                      !formikBag.values.name ||
                      formikBag.values?.lessons[0]?.name?.length === 0
                    }
                  >
                    Save Module
                  </button>
                </DeleteModuleBtn>
              </ModuleBody>
            )}
          </Form>
        )}
      </Formik>
    </ModuleWrapper>
  );
};

ModuleCard.propTypes = {
  moduleData: PropTypes.object,
  courseId: PropTypes.string,
  opened: PropTypes.bool,
  moduleIndex: PropTypes.number,
  canDeleteModule: PropTypes.bool,
  onDeleteModule: PropTypes.func,
  onToggleModule: PropTypes.func,
  onSaveModule: PropTypes.func.isRequired,
};

export default ModuleCard;
