import gql from 'graphql-tag';

export const JOURNEY_DETAILS = gql`
  query journeyDetails($id: ID!) {
    journeyDetails(id: $id) {
      journey {
        id
        name
        description
        estimatedTimeTaken
        banner
        howTo
        numCourses
        status
        persona
        age
        milestones {
          id
          estimated
          milestoneCourses {
            id
            required
            course {
              id
              name
            }
          }
        }
      }
    }
  }
`;
