import gql from 'graphql-tag';

export const UPDATE_ADMIN_NOTIFICATIONS_SETTINGS = gql`
  mutation subscribeUnsubscribeNotifications($notificationIds: [ID!]!) {
    subscribeUnsubscribeNotifications(notificationIds: $notificationIds) {
      notificationSubscriptions {
        id
        name
        subscribed
      }
      errors {
        errors
        fieldName
      }
      statusCode
    }
  }
`;
