import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dropdown,
  Form,
  Icon,
  Input,
  Menu,
  Radio,
  Spin,
  notification,
} from 'antd';
import { useMutation } from '@apollo/client';
import styled from '@emotion/styled';
import { CREATE_QUESTION, EDIT_QUESTION } from '@services';
import { en as strings } from '../locales/data.json';

const RadioBtnsContainer = styled.div`
  display: flex;

  .ant-form-item-with-help:first-of-type {
    display: flex;
    flex-direction: column;
    width: 25px;
  }

  .ant-form-explain {
    width: 400px;
  }
`;

const RadioInputsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const radioStyle = {
  position: 'relative',
  lineHeight: '38px',
  marginBottom: '24px',
  verticalAlign: 'middle',
};

//the id used to keep track of the currently viewed dynamic forms
let id = 0;

const QuestionsForm = props => {
  const {
    form: { getFieldDecorator, getFieldValue, setFieldsValue, validateFields },
    questionableId,
    listQuestions,
    listingLoading,
    questionInfoLoading,
    editFormData,
    setDisableMenu,
    setQuestionEditedId,
  } = props;

  //state used to set the current question type to be used during saving question
  const [currentQuestionType, setCurrentQuestionType] = useState('');
  //state used to disable the add button whenever a question is being added
  const [disableButton, setDisableButton] = useState(false);

  const [createQuestion, { loading }] = useMutation(CREATE_QUESTION, {
    onError: () =>
      notification.error({
        message: strings.notifications.createQuestion.title,
        description: strings.notifications.createQuestion.error,
      }),

    onCompleted: ({ createQuestion: { errors } }) => {
      if (!errors) {
        id = 0;
        //reset existing questions
        setFieldsValue({
          questionsIds: [],
        });
        listQuestions({ variables: { questionableId } });
        setDisableButton(false);
        setDisableMenu(false);

        notification.success({
          message: strings.notifications.createQuestion.title,
          description: strings.notifications.createQuestion.success,
        });
      } else {
        notification.error({
          message: strings.notifications.createQuestion.title,
          description: errors[0].errors[0],
        });
      }
    },
  });

  const [editQuestion, { loading: editLoading }] = useMutation(EDIT_QUESTION, {
    onError: () =>
      notification.error({
        message: strings.notifications.editQuestion.title,
        description: strings.notifications.editQuestion.error,
      }),

    onCompleted: ({
      editQuestion: {
        errors,
        question: { id: questionEditedId },
      },
    }) => {
      if (!errors) {
        id = 0;
        // reset existing questions
        setFieldsValue({
          questionsIds: [],
        });
        listQuestions({ variables: { questionableId } });
        setDisableButton(false);
        setDisableMenu(false);

        if (setQuestionEditedId) setQuestionEditedId(questionEditedId);

        notification.success({
          message: strings.notifications.editQuestion.title,
          description: strings.notifications.editQuestion.success,
        });
      } else {
        notification.error({
          message: strings.notifications.editQuestion.title,
          description: errors[0].errors[0],
        });
      }
    },
  });

  const questionInfo = editFormData.question;

  const {
    answers,
    body,
    id: questionId,
    questionType,
    rightAnswer,
    rightExplanation,
    wrongExplanation,
  } = questionInfo || {
    answers: [],
    body: '',
    id: '',
    questionType: '',
    rightAnswer: '',
    rightExplanation: '',
    wrongExplanation: '',
  };

  /**
  Used to find the right answer index to check it in the form while in edit mode
 */
  const rightAnswerIndex = answers.findIndex(answer => {
    return answer.id === rightAnswer.id;
  });

  /**
  called on clicking a drop down menu item
  creates a typed question based on the menu item clicked
  @props: event {key: string,...}
*/
  const handleMenuClick = event => {
    setDisableButton(true);
    setDisableMenu(true);
    const questionType = ['mcq', 'true_false'];
    addQuestion(questionType[event.key - 1]);
  };

  const questionsTypesMenu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1" disabled={disableButton}>
        {strings.addMcqQuestionButtonText}
      </Menu.Item>
      <Menu.Item key="2" disabled={disableButton}>
        {strings.addTrueFalseQuestionButtonText}
      </Menu.Item>
    </Menu>
  );

  /**
  called on clicking add typed question button
  adds a new dynamic form question to the view
  @props: type : string (type of the question mcq or true_false)
*/
  const addQuestion = type => {
    setCurrentQuestionType(type);
    const questions = getFieldValue('questionsIds');
    const nextQuestions = questions.concat(id++);
    // important! notify form to detect changes
    setFieldsValue({
      questionsIds: nextQuestions,
    });
  };

  /**
  called on clicking the remove icon next to a question
  removes the specified question
  @props: id : number (the question's id)
*/
  const removeQuestion = k => {
    const questions = getFieldValue('questionsIds');
    // decrement id by one
    id--;
    // remove the deleted question
    setFieldsValue({
      questionsIds: questions.filter(key => key !== k),
    });
    // re-enable buttons to add new questions
    setDisableButton(false);
    setDisableMenu(false);
  };

  /**
  called on clicking save Button to save question
  creates a new question then updates the listing with the new question added
  edits existing questions then updates the listing
  @props: event : submitEvent
*/
  const handleSubmit = e => {
    e.preventDefault();
    validateFields((err, values) => {
      if (err) return;

      const {
        body,
        answers,
        rightExplanation,
        wrongExplanation,
        rightAnswerIndex,
      } = values;
      if (questionId)
        editQuestion({
          variables: {
            questionId: questionId,
            question: {
              body,
              answers,
              rightExplanation,
              wrongExplanation,
              questionType,
              rightAnswerIndex,
            },
          },
        });
      else
        createQuestion({
          variables: {
            questionableId,
            question: {
              body,
              answers,
              rightExplanation,
              wrongExplanation,
              questionType: currentQuestionType,
              rightAnswerIndex,
            },
          },
        });
    });
  };

  getFieldDecorator('questionsIds', { initialValue: [] });
  const questions = getFieldValue('questionsIds');

  //the dynamic component that gets add on click
  const questionsFormItems = questions.map((k, index) => (
    <div key={k}>
      <Form.Item
        label={'Question'}
        required={true}
        key={'question' + k}
        validateStatus="success"
        help="Please select the right answer"
      >
        {getFieldDecorator(`body`, {
          validateTrigger: ['onChange', 'onBlur'],
          rules: [
            {
              required: true,
              whitespace: true,
              message: 'Please input a question or delete this field.',
            },
          ],
          initialValue: body ? body : '',
        })(
          <Input
            placeholder="Question Body"
            style={{ width: '92%', marginRight: 8 }}
          />
        )}
        {k === questions.length - 1 ? (
          <Icon
            className="dynamic-delete-button"
            type="minus-circle-o"
            onClick={() => removeQuestion(k)}
          />
        ) : null}
      </Form.Item>
      <RadioBtnsContainer key={'RadioBtn' + k}>
        <Form.Item>
          {getFieldDecorator(`rightAnswerIndex`, {
            validateTrigger: ['onChange', 'onBlur'],
            rules: [
              {
                required: true,
                message: 'Please select an answer or delete this question.',
              },
            ],
            initialValue: rightAnswerIndex,
          })(
            <Radio.Group
              name="radiogroup"
              initialValue={1}
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              {currentQuestionType === 'mcq' ? (
                answers.length > 0 ? (
                  answers.map((item, index) => (
                    <Radio key={index} value={index} style={radioStyle} />
                  ))
                ) : (
                  [...Array(4).keys()].map((item, index) => (
                    <Radio key={index} value={index} style={radioStyle} />
                  ))
                )
              ) : (
                <>
                  <Radio value={0}>True</Radio>
                  <Radio value={1}>False</Radio>
                </>
              )}
            </Radio.Group>
          )}
        </Form.Item>
        <RadioInputsContainer>
          {currentQuestionType === 'mcq' ? (
            answers.length > 0 ? (
              answers.map((item, index) => (
                <Form.Item key={`item${index}`}>
                  {getFieldDecorator(`answers[${index}].body`, {
                    initialValue: answers.length > 0 ? answers[index].body : '',
                  })(<Input placeholder="Answer" />)}
                </Form.Item>
              ))
            ) : (
              [...Array(4).keys()].map((item, index) => (
                <Form.Item key={`item${index}`}>
                  {getFieldDecorator(`answers[${index}].body`, {
                    initialValue: answers.length > 0 ? answers[index].body : '',
                  })(<Input placeholder="Answer" />)}
                </Form.Item>
              ))
            )
          ) : (
            <>
              <Form.Item key={'itemTrue'}>
                {getFieldDecorator(`answers[0].body`, {
                  initialValue: 'True',
                })(<Input placeholder="True" disabled={true} type="hidden" />)}
              </Form.Item>
              <Form.Item key={`itemFalse`}>
                {getFieldDecorator(`answers[1].body`, {
                  initialValue: 'False',
                })(<Input placeholder="False" disabled={true} type="hidden" />)}
              </Form.Item>
            </>
          )}
        </RadioInputsContainer>
      </RadioBtnsContainer>
      <Form.Item label="Right Answer Explanation" key={'rightExplanation'}>
        {getFieldDecorator('rightExplanation', {
          rules: [
            {
              required: true,
              message: 'Please select an answer or delete this question.',
            },
          ],
          initialValue: rightExplanation ? rightExplanation : '',
        })(<Input placeholder="Right Explanation" />)}
      </Form.Item>
      <Form.Item label="Wrong Answer Explanation" key={'wrongExplanation'}>
        {getFieldDecorator('wrongExplanation', {
          rules: [
            {
              required: true,
              message: 'Please select an answer or delete this question.',
            },
          ],
          initialValue: wrongExplanation ? wrongExplanation : '',
        })(<Input placeholder="Wrong Explanation" />)}
      </Form.Item>
    </div>
  ));

  useEffect(() => {
    // Opens question form and scroll up to it when clicking on edit button
    if (questionInfo) {
      document.body.scrollTop = 150;
      document.documentElement.scrollTop = 150;
      addQuestion(questionType);
      setDisableMenu(true);
      setDisableButton(true);
    }
  }, [questionInfo]);

  return (
    <>
      {questionsFormItems}
      {id > 0 ? (
        <Form.Item>
          <Button type="primary" htmlType="submit" onClick={handleSubmit}>
            {strings.saveQuestionButtonText}
          </Button>
        </Form.Item>
      ) : null}
      <Form.Item>
        <Dropdown overlay={questionsTypesMenu}>
          <Button type="dashed">
            <Icon type="plus" /> {strings.addQuestionButtonText}
          </Button>
        </Dropdown>

        {(loading || editLoading || listingLoading || questionInfoLoading) && (
          <Spin size="large" />
        )}
      </Form.Item>
    </>
  );
};

QuestionsForm.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    getFieldValue: PropTypes.func,
    setFieldsValue: PropTypes.func,
    validateFields: PropTypes.func,
  }).isRequired,
  questionableId: PropTypes.string.isRequired,
  listQuestions: PropTypes.func.isRequired,
  listingLoading: PropTypes.bool.isRequired,
  questionInfoLoading: PropTypes.bool.isRequired,
  editFormData: PropTypes.object.isRequired,
  setDisableMenu: PropTypes.func.isRequired,
  setQuestionEditedId: PropTypes.func,
};

export default Form.create()(QuestionsForm);
