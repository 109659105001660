import gql from 'graphql-tag';

export const EDIT_DISCUSSION = gql`
  mutation updateThread($threadId: Int!, $threadData: ThreadUpdateInputType!) {
    updateThread(threadId: $threadId, threadData: $threadData) {
      statusCode
      errors {
        errors
        fieldName
      }
      thread {
        id
      }
    }
  }
`;
