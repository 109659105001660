/**
 * @author Raghda Wessam
 * @date 2022-01-04
 * @description Assessment Card Component. card can be for pre, post or graded assessment
 * @filename assessment_card.js
 */

import React from 'react';
import { Field, Form, ErrorMessage, Formik } from 'formik';
import { PropTypes } from 'prop-types';
import { Button, notification } from 'antd';
import ArrowIcon from '../../../components/main_layout/images/arrow.png';
import {
  COURSE_OUTLINE_TYPES,
  GRADED_ASSESSMENT_SCHEMA,
  PRE_ASSESSMENT_SCHEMA,
} from '@utilities';
import { useMutation } from '@apollo/client';
import {
  createAssessment,
  updateAssessment,
  deleteAssessment,
} from '@services';
import {
  ModuleHeader,
  ErrorMsgWrapper,
  ModuleBody,
  AssessmentContentWrapper,
  AssessmentInputWrapper,
  ArrowWrapper,
  DeleteModuleBtn,
  PreAssessmentBtn,
  ModuleWrapper,
} from './styles/module_card_styles';

const AssessmentCard = props => {
  const [
    createAssessmentMutation,
    { loading: loadingCreateAssessment },
  ] = useMutation(createAssessment);
  const [
    updateAssessmentMutation,
    { loading: loadingUpdateAssessment },
  ] = useMutation(updateAssessment);
  const [
    deleteAssessmentMutation,
    { loading: loadingDeleteAssessment },
  ] = useMutation(deleteAssessment);

  const deleteCurrentAssessment = formikBag => {
    formikBag.resetForm();
    let promise = Promise.resolve();
    if (props.assessment?.id) {
      promise = deleteAssessmentMutation({
        variables: {
          id: props.assessment.id,
        },
      });
    }

    promise = promise.then(() => {
      notification.success({
        message: 'Assessment deleted successfully',
      });
      props.onDeleteAssessment(props.assessment?.id);
    });

    promise.catch(error => {
      notification.error({
        message: "Can't delete assessment",
        error,
      });
    });
  };

  /**
   * call Graphql mutation to create assessment.
   *
   * @param {*} content - create assessment input.
   * @returns {Promise<Assessment>} Promise to create a new assessment.
   */
  const createNewAssessment = content => {
    return createAssessmentMutation({
      variables: {
        content,
      },
    }).then(response => {
      if (response.data?.createAssessment?.assessment) {
        return response.data.createAssessment.assessment;
      }
      return Promise.reject(response);
    });
  };

  /**
   * call Graphql mutation to update assessment.
   * @param {*} content - update assessment input.
   * @returns {Promise<Assessment>} Promise to return the updated assessment.
   */
  const updateCurrentAssessment = content => {
    return updateAssessmentMutation({
      variables: { content },
    }).then(response => {
      if (response.data?.updateAssessment?.assessment) {
        return response.data.updateAssessment.assessment;
      }
      return Promise.reject(response);
    });
  };

  const submitAssessment = (values, actions) => {
    let content = {
      estimated_time: Number(values.estimatedTime),
      assessmentable_type: props.parentType,
      assessmentable_id: props.parentId,
    };
    let promise;

    switch (props.type) {
      case COURSE_OUTLINE_TYPES.PRE_ASSESSMENT:
        content = {
          ...content,
          type: 'PRE',
        };
        break;
      case COURSE_OUTLINE_TYPES.POST_ASSESSMENT:
        content = {
          ...content,
          type: 'POST',
        };
        break;
      case COURSE_OUTLINE_TYPES.GRADED_ASSESSMENT:
        content = {
          ...content,
          type: 'GRADED',
          passing_grade: Number(values.passingGrade),
          number_of_trials: Number(values.numberOfTrials),
          reset_time: Number(values.resetTime),
        };
        break;
      default:
        break;
    }

    if (props.assessment?.id) {
      delete content.assessmentable_type;
      delete content.assessmentable_id;
      content = {
        ...content,
        id: props.assessment.id,
      };
      promise = updateCurrentAssessment(content);
    } else {
      promise = createNewAssessment(content);
    }

    promise
      .then(assessment => {
        props.onSaveAssessment(assessment);
        actions.setSubmitting(false);
        notification.success({
          message: 'Assessment saved successfully',
        });
      })
      .catch(error => {
        notification.error({
          message: error?.message ? error?.message : error,
        });
        actions.setSubmitting(false);
      });
  };

  let initialValues = {
    estimatedTime: '',
  };
  if (props.assessment) {
    initialValues = {
      estimatedTime: props.assessment.estimated_time,
    };
  }
  if (props.type === COURSE_OUTLINE_TYPES.GRADED_ASSESSMENT) {
    initialValues = {
      ...initialValues,
      passingGrade: '',
      numberOfTrials: '',
      resetTime: '',
    };
    if (props.assessment) {
      initialValues = {
        ...initialValues,
        passingGrade: props.assessment.passing_grade,
        numberOfTrials: props.assessment.number_of_trials,
        resetTime: props.assessment.reset_time,
      };
    }
  }

  const loading =
    loadingCreateAssessment ||
    loadingUpdateAssessment ||
    loadingDeleteAssessment;

  return (
    <ModuleWrapper>
      <Formik
        initialValues={initialValues}
        onSubmit={submitAssessment}
        validationSchema={
          props.type === COURSE_OUTLINE_TYPES.GRADED_ASSESSMENT
            ? GRADED_ASSESSMENT_SCHEMA
            : PRE_ASSESSMENT_SCHEMA
        }
      >
        {formikBag => (
          <Form>
            <ModuleHeader>
              {props.opened ? (
                <ArrowWrapper
                  onClick={() => {
                    props.onToggleAssessment();
                  }}
                >
                  <img
                    className={props.opened ? 'flip' : null}
                    src={ArrowIcon}
                    alt="toggle collapse arrow"
                  />
                </ArrowWrapper>
              ) : (
                <PreAssessmentBtn>
                  <button
                    type="button"
                    onClick={() => {
                      props.onToggleAssessment();
                    }}
                  >
                    <span className="plus-sign">+</span>
                    {props.assessment?.id ? 'expand' : 'Click to add'}
                  </button>
                </PreAssessmentBtn>
              )}

              <label htmlFor="name">{props.title}</label>
            </ModuleHeader>
            {props.opened && (
              <ModuleBody>
                <AssessmentContentWrapper>
                  <AssessmentInputWrapper>
                    <label htmlFor="estimatedTime">Estimated Time</label>
                    <Field
                      name="estimatedTime"
                      type="text"
                      placeholder="Add a number"
                      min={0}
                      step={1}
                    />
                    <span>min</span>
                    <ErrorMessage
                      component={ErrorMsgWrapper}
                      name="estimatedTime"
                    />
                  </AssessmentInputWrapper>
                  {props.type === COURSE_OUTLINE_TYPES.GRADED_ASSESSMENT && (
                    <AssessmentInputWrapper>
                      <label htmlFor="passingGrade">Min Passing Grade</label>
                      <Field
                        name="passingGrade"
                        type="text"
                        placeholder="Add a percentage"
                        min={0}
                        max={100}
                      />
                      <span>%</span>
                      <ErrorMessage
                        component={ErrorMsgWrapper}
                        name="passingGrade"
                      />
                    </AssessmentInputWrapper>
                  )}
                </AssessmentContentWrapper>
                {props.type === COURSE_OUTLINE_TYPES.GRADED_ASSESSMENT && (
                  <AssessmentContentWrapper>
                    <AssessmentInputWrapper>
                      <label htmlFor="numberOfTrials">Number of Trials</label>
                      <Field
                        name="numberOfTrials"
                        type="text"
                        placeholder="Add a number"
                        min={0}
                      />
                      <ErrorMessage
                        component={ErrorMsgWrapper}
                        name="numberOfTrials"
                      />
                    </AssessmentInputWrapper>
                    {formikBag.values?.numberOfTrials ? (
                      <AssessmentInputWrapper>
                        <label htmlFor="resetTime">reset time</label>
                        <Field
                          name="resetTime"
                          type="text"
                          placeholder="Add a reset time"
                          disabled={!formikBag.values?.numberOfTrials}
                          min={0}
                          step={1}
                        />
                        <span>min</span>
                        <ErrorMessage
                          component={ErrorMsgWrapper}
                          name="resetTime"
                        />
                      </AssessmentInputWrapper>
                    ) : null}
                  </AssessmentContentWrapper>
                )}
                <DeleteModuleBtn>
                  <Button
                    type="button"
                    onClick={() => deleteCurrentAssessment(formikBag)}
                  >
                    Delete Assessment
                  </Button>
                  <button
                    className={!formikBag.isValid ? 'disabled' : null}
                    type="submit"
                    disabled={
                      !formikBag.isValid || loading || formikBag.isSubmitting
                    }
                  >
                    Save Assessment
                  </button>
                </DeleteModuleBtn>
              </ModuleBody>
            )}
          </Form>
        )}
      </Formik>
    </ModuleWrapper>
  );
};

AssessmentCard.propTypes = {
  //type of currently active course outline item(module or assessment)
  type: PropTypes.string.isRequired,
  //title of assessment
  title: PropTypes.string.isRequired,
  // assessment data
  assessment: PropTypes.object,
  // function submits active form and toggle assessment
  onToggleAssessment: PropTypes.func.isRequired,
  //delete assessment
  onDeleteAssessment: PropTypes.func.isRequired,
  // boolean that determines of the current assessment is expanded or not
  opened: PropTypes.bool.isRequired,
  parentId: PropTypes.string.isRequired,
  parentType: PropTypes.string.isRequired,
  onSaveAssessment: PropTypes.func.isRequired,
};

export default AssessmentCard;
