import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  gql,
} from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { BACKEND_URL } from '@config';
import { createUploadLink } from 'apollo-upload-client';
import jwtDecode from 'jwt-decode';

// time margin 5 minutes
const timeMargin = 60 * 5;
let pendingAccessTokenPromise = false;

// function to get the refresh token
const getNewToken = async () => {
  try {
    const { data } = await client.mutate({
      mutation: gql`
        mutation refreshToken {
          refreshToken {
            token
          }
        }
      `,
    });
    const token = data?.refreshToken?.token;
    localStorage.setItem('token', token);
    return token;
  } catch (err) {
    localStorage.clear();
    client.resetStore();
    window.location.href = '/';
  }
};
// function to get the check if the token is expired
const isExpired = token => {
  const decoded = jwtDecode(token);
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime + timeMargin) {
    return true;
  }
  return false;
};

export function getAccessTokenPromise() {
  let token = localStorage.getItem('token');

  if (token) {
    // check if token is expired if not resolve the token directly
    if (!isExpired(token)) {
      return new Promise(resolve => resolve(token));
    }
    // if token is expired get the new one then resolve the promise
    if (!pendingAccessTokenPromise) {
      pendingAccessTokenPromise = getNewToken().finally(
        () => (pendingAccessTokenPromise = null)
      );
    }

    return pendingAccessTokenPromise;
  }
  return null;
}

const authLink = setContext(async (request, { headers }) => {
  /** Avoiding clickJacking attack by denying x-frame option header */
  if (headers) {
    headers['X-Frame-Options'] = 'DENY';
  }

  if (request.operationName === 'refreshToken') {
    let token = localStorage.getItem('token');
    if (token) {
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${token}`,
        },
      };
    } else {
      return { headers };
    }
  }
  const token = await getAccessTokenPromise();

  if (token) {
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  } else {
    return {
      headers: { ...headers },
    };
  }
});

const link = createUploadLink({
  uri: BACKEND_URL,
});

export const appCache = new InMemoryCache();

/** Apollo client configurations */
export const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path, statusCode }) => {
          if (statusCode === 401) {
            localStorage.clear();
            window.location.reload();
          }
          return console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          );
        });
      if (networkError?.result) {
        const { statusCode } = networkError.result.error;
        if (statusCode === 401) {
          localStorage.clear();
          window.location.reload();
        }
        console.log(`[Network error]: ${networkError}`);
      }
    }),
    authLink.concat(link),
  ]),
  cache: appCache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
    },
  },
});
