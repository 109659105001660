import styled from '@emotion/styled/macro';
import { colors } from '@utilities/theme';

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
`;

export const ModalContent = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  min-width: 200px;
  width: ${props => props.width};
  max-width: 95%;
  min-height: 200px;
  height: ${props => props.height};
  max-height: 95%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 100;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;

  .close {
    position: absolute;
    left: 1rem;
    border: none;
    cursor: pointer;
  }

  .headerText {
    color: ${colors.primaryButtonColor};
    font-weight: 500;
    margin-top: 0;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
  }
  .content {
    margin: 20px 0;
  }
`;

export const ActionsButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    max-width: 50%;
    font-size: 13px;
    margin: 0 10px;
  }

  @media (max-width: 768px) {
    width: 90%;
  }
`;
