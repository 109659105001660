import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useLazyQuery } from '@apollo/client';
import {
  CreateCourseUnitMutation,
  UpdateCourseUnitMutation,
  deleteCourseItem,
  GetCourseQuery,
} from '@services';
import { notification, Spin } from 'antd';
import { UNIT_TYPES } from '@utilities';
import TextIcon from '../../../../components/main_layout/images/film.png';
import TextTypeContent from './text_type_content';
import VideoTypeContent from './video_type_content';
import WorkbookTypeContent from './workbook_type_content';
import ScormTypeContent from './scorm_type_content';
import CollapseArrow from './images/collapse-arrow.png';

const unitTypeOptions = [
  {
    value: UNIT_TYPES.TEXT,
    key: 'Text & Images',
    img: TextIcon,
  },
  {
    value: UNIT_TYPES.VIDEO,
    key: 'Video',
    img: TextIcon,
  },
  {
    value: UNIT_TYPES.WORKBOOK,
    key: 'Workbook',
    img: TextIcon,
  },
  {
    value: UNIT_TYPES.SCORM,
    key: 'Scorm Unit',
    img: TextIcon,
  },
  {
    value: UNIT_TYPES.ALMENTOR_VIDEO,
    key: 'Almentor Video',
    img: TextIcon,
  },
];

export default function UnitCard(props) {
  const [unitType, setUnitType] = useState(
    props.unit?.workbook?.length > 0 ? 'WORKBOOK' : props.unit?.content[0]?.type
  );
  const [unitDetails, updateUnitDetails] = useState(props.unit);
  const [courseDetails, updateCourseDetails] = useState(null);
  const [fetchCourse, { loading: fetchCourseLoading }] = useLazyQuery(
    GetCourseQuery,
    {
      variables: { id: props?.courseId },
      onCompleted: data => {
        if (props?.unit?.id) {
          updateUnitDetails(
            data?.getCourse?.modules
              ?.find(_module => _module.id === props.moduleId)
              ?.lessons?.find(_lesson => _lesson.id === props.lessonId)
              ?.units?.find(_unit => _unit.id === props.unit.id)
          );
        }
        updateCourseDetails(data.getCourse);
      },
    }
  );
  const handleAddedUnit = unit => {
    props.addWorkbookCallback(unit);
  };
  const handleQuestionUpdateCallback = isQuestionUpdated => {
    if (isQuestionUpdated) {
      fetchCourse();
    }
  };
  useEffect(() => {
    setUnitType(
      props.unit?.workbook?.length > 0
        ? 'WORKBOOK'
        : props.unit?.content[0]?.type
    );
  }, [props.unit]);

  useEffect(() => {
    fetchCourse();
  }, [unitType]);

  useEffect(() => {
    if (courseDetails) {
      props.addUnitCallback(courseDetails);
    }
  }, [courseDetails]);

  const [unitCardExpand, setUnitCardExpand] = useState(true);

  const [createCourseUnit, { loading: loadingCreateUnit }] = useMutation(
    CreateCourseUnitMutation,
    {
      onCompleted: data => {
        fetchCourse();
      },
      onError: errors => {
        console.log({ errors });
        const description =
          errors?.graphQLErrors[0]?.errors[
            Object.keys(errors?.graphQLErrors[0]?.errors)[0]
          ];

        notification.error({
          message: 'Create Course Unit',
          description,
        });
      },
    }
  );
  const [updateCourseUnit, { loading: loadingUpdateUnit }] = useMutation(
    UpdateCourseUnitMutation,
    {
      onCompleted: data => {
        console.log({ data });
      },
      onError: errors => {
        console.log({ errors });
        const description =
          errors?.graphQLErrors[0]?.errors[
            Object.keys(errors?.graphQLErrors[0]?.errors)[0]
          ];

        notification.error({
          message: 'Create Course Unit',
          description,
        });
      },
    }
  );
  const [deleteUnitMutation, { loading: loadingDeleteUnit }] = useMutation(
    deleteCourseItem
  );

  const deleteUnit = unitId => {
    console.log('[unitCard] deleteUnit');
    let deleteUnitPromise = Promise.resolve();
    if (unitId) {
      // TODO: handle delete unit errors
      deleteUnitPromise = deleteUnitMutation({
        variables: {
          courseItemId: unitId,
        },
      });
    }

    deleteUnitPromise
      .then(() => {
        console.log('[unitCard] deleteUnitPromise');
        props.onDeleteUnit();
      })
      .catch(error => {
        notification.error({
          message: `Unit cann't be deleted`,
        });
      });
  };

  const getActiveUnitTypeComponent = () => {
    let activeUnitTypeComponent;
    switch (unitType) {
      case UNIT_TYPES.VIDEO:
      case UNIT_TYPES.ALMENTOR_VIDEO:
        activeUnitTypeComponent = (
          <VideoTypeContent
            courseId={props.courseId}
            lessonId={props.lessonId}
            createCourseUnit={createCourseUnit}
            updateCourseUnit={updateCourseUnit}
            unitType={unitType}
            unitDetails={unitDetails}
            onDeleteUnit={() => deleteUnit(props.unit?.id)}
            canDeleteUnit={props.canDeleteUnit}
          />
        );
        break;
      case UNIT_TYPES.WORKBOOK:
        activeUnitTypeComponent = (
          <WorkbookTypeContent
            courseId={props.courseId}
            lessonId={props.lessonId}
            createCourseUnit={createCourseUnit}
            updateCourseUnit={updateCourseUnit}
            unitDetails={unitDetails}
            onDeleteUnit={() => deleteUnit(props.unit?.id)}
            canDeleteUnit={props.canDeleteUnit}
            handleAddedUnit={handleAddedUnit}
            handleQuestionUpdateCallback={handleQuestionUpdateCallback}
          />
        );
        break;
      case UNIT_TYPES.SCORM:
        activeUnitTypeComponent = (
          <ScormTypeContent
            courseId={props.courseId}
            lessonId={props.lessonId}
            createCourseUnit={createCourseUnit}
            updateCourseUnit={updateCourseUnit}
            unitDetails={unitDetails}
            onDeleteUnit={() => deleteUnit(props.unit?.id)}
            canDeleteUnit={props.canDeleteUnit}
          />
        );
        break;
      default:
        activeUnitTypeComponent = (
          <TextTypeContent
            courseId={props.courseId}
            lessonId={props.lessonId}
            createCourseUnit={createCourseUnit}
            updateCourseUnit={updateCourseUnit}
            onSaveUnit={props.onSaveUnit}
            unitDetails={unitDetails}
            onDeleteUnit={() => deleteUnit(props.unit?.id)}
            canDeleteUnit={props.canDeleteUnit}
            handleAddedUnit={handleAddedUnit}
          />
        );
        break;
    }
    return activeUnitTypeComponent;
  };
  const loading =
    loadingCreateUnit ||
    loadingUpdateUnit ||
    loadingDeleteUnit ||
    fetchCourseLoading;
  return (
    <div className="unit-card-wrapper">
      {loading && <Spin />}
      <div className="unit-card-header">
        <button
          className="collapse-icon"
          onClick={() => setUnitCardExpand(!unitCardExpand)}
        >
          {unitCardExpand ? (
            <img className="collapse-icon" src={CollapseArrow} alt="Collapse" />
          ) : (
            <img src={CollapseArrow} alt="Collapse" />
          )}
        </button>
      </div>
      <div className={`unit-card-body ${!unitCardExpand && 'collapsed'}`}>
        <div className="unit-card-section">
          <h2>Content Type</h2>
          <span>Please select one content type.</span>
          <div className="unit-types">
            {unitTypeOptions.map((unitTypeOption, index) => {
              return (
                <div
                  key={index}
                  className={`unit-type ${
                    unitTypeOption.value === unitType ? 'active' : ''
                  }`}
                  onClick={() => {
                    if (unitTypeOption.value !== unitType) {
                      setUnitType(unitTypeOption.value);
                    }
                  }}
                >
                  <div className="img-wrapper">
                    <img src={unitTypeOption.img} alt="unit type" />
                  </div>
                  <span> {unitTypeOption.key} </span>
                </div>
              );
            })}
          </div>
        </div>
        <div className="unit-card-section-content">
          <h2>Content Details</h2>
          {getActiveUnitTypeComponent()}
        </div>
      </div>
    </div>
  );
}

UnitCard.propTypes = {
  unit: PropTypes.object,
  courseId: PropTypes.string,
  lessonId: PropTypes.string,
  onSaveUnit: PropTypes.func,
  onDeleteUnit: PropTypes.func,
  canDeleteUnit: PropTypes.bool,
  moduleId: PropTypes.string,
};
