import gql from 'graphql-tag';

export const PROVIDER_DETAILS = gql`
  query providerDetails(
    $providerId: ID!
    $coursesFirst: Int
    $coursesAfter: String
    $coursesLast: Int
    $coursesBefore: String
    $adminsFirst: Int
    $adminsAfter: String
    $adminsLast: Int
    $adminsBefore: String
    $adminsSortBy: String
    $adminsSortType: String
    $coursesSortBy: String
    $coursesSortType: String
  ) {
    providerDetails(
      providerId: $providerId
      adminsSortBy: $adminsSortBy
      adminsSortType: $adminsSortType
      coursesSortBy: $coursesSortBy
      coursesSortType: $coursesSortType
    ) {
      admins(
        first: $adminsFirst
        after: $adminsAfter
        last: $adminsLast
        before: $adminsBefore
      ) {
        nodes {
          id
          name
          email
          adminType
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
      averageRating
      courses(
        first: $coursesFirst
        after: $coursesAfter
        last: $coursesLast
        before: $coursesBefore
      ) {
        nodes {
          id
          name
          createdAt
          courseGroup
          averageRating
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
      }
      id
      name
      photo
      photo2x
    }
  }
`;
