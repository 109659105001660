import gql from 'graphql-tag';

export const ListCategoriesQuery = gql`
  query allCategories {
    allCategories {
      label: name
      value: id
    }
  }
`;
