import gql from 'graphql-tag';

export const UpdateStatusMutation = gql`
  mutation updateStatus($id: Int!, $status: GeneralStatusEnum!) {
    updateThreadStatus(threadId: $id, status: $status) {
      errors {
        errors
        fieldName
      }
      statusCode
    }
  }
`;
