import React from 'react';
import { useField, useFormikContext } from 'formik';
import DatePicker from 'react-datepicker';

export const DatePickerField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();

  const filterPassedTime = time => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };
  const [field] = useField(props);
  return (
    <DatePicker
      {...field}
      {...props}
      selected={
        props.selected
          ? props.selected
          : (field.value && new Date(field.value)) || null
      }
      showTimeSelect
      filterTime={filterPassedTime}
      dateFormat="yyyy-MM-dd HH:mm:ss"
      placeholderText={props.placeholder}
      minDate={props.minDate}
      maxDate={props.maxDate}
      minTime={props.minTime}
      maxTime={props.maxTime}
      readOnly={props.readOnly}
      onChange={val => {
        setFieldValue(field.name, val);
      }}
    />
  );
};

export default DatePickerField;
