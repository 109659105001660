import gql from 'graphql-tag';

export const ViewNavbarSection = gql`
  query viewNavbarItem($id: ID!) {
    viewNavbarItem(id: $id) {
      id
      name
      index
      type
      categories_ids
      roles_ids
      tags_ids
      is_active
    }
  }
`;
