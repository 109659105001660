/**
 * @author Raghda Wessam
 * @date 2021-11-29
 * @description Implementation of course content related utilities.
 * @filename course_content.js
 */
import { string, object, array, mixed, boolean, number } from 'yup';
import { SUPPORTED_FORMATS } from '@constants';

/**
 * schema to validate the fields for creating new question.
 */
export const NEW_QUESTION_SCHEMA = object({
  type: string().required('Question Type is required'),
  body: string().required('Question Body is required'),
  answers: array().of(string().required('Answers for question is required')),
  correctAnswer: string().required('Choosing the correct answer is required'),
  right_explanation: string().nullable(),
  wrong_explanation: string().nullable(),
});

export const QUESTIONS_SCHEMA = object({
  questions: array().of(NEW_QUESTION_SCHEMA),
});

// available question types
export const QUESTION_TYPE = {
  TRUE_OR_FALSE: 'TRUE_OR_FALSE',
  MCQ: 'MCQ',
  ESSAY: 'ESSAY',
  TEXT: 'TEXT',
};

export const UNIT_TYPES = {
  TEXT: 'TEXT_HTML',
  VIDEO: 'VIDEO',
  WORKBOOK: 'WORKBOOK',
  SCORM: 'SCORM',
  ALMENTOR_VIDEO: 'ALMENTOR_VIDEO',
};

export const ASSESSMENT_TYPES = {
  WORKBOOK: 'WORKBOOK',
  GRADED_WORKBOOK: 'GRADED_WORKBOOK',
};

const SUPPORTED_IMAGE_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];
const SUPPORTED_VIDEO_FORMATS = ['video/mp4'];
const SUPPORTED_FILE_FORMATS = [
  'application/zip',
  'application/x-zip-compressed',
];

export const validateImageType = value => {
  if (value) {
    let type = value?.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
    return SUPPORTED_IMAGE_FORMATS?.includes(type);
  }
};

export const validateVideoType = value => {
  if (value) {
    let type = value?.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
    return SUPPORTED_VIDEO_FORMATS?.includes(type);
  }
};

/**
 * schema to validate the fields for creating new question.
 */
export const WORKBOOK_QUESTION_SCHEMA = object({
  type: string().required('Question Type is required'),
  body: string().required('Question Body is required'),
  answers: array().of(string()),
  answers: array()
    .of(string())
    .when('type', {
      is: value =>
        value === QUESTION_TYPE.MCQ || value === QUESTION_TYPE.TRUE_OR_FALSE,
      then: array().of(string().required('Answers for question is required')),
    }),
  correctAnswer: string().test({
    name: 'checkQuestionType',
    exclusive: false,
    params: {},
    message: 'Choosing the correct answer is required',
    test: function(value) {
      // You can access other field with `this.parent`.
      if (
        this.parent.type === QUESTION_TYPE.MCQ ||
        this.parent.type === QUESTION_TYPE.TRUE_OR_FALSE
      ) {
        return value !== undefined && value !== null && value !== 'undefined';
      } else {
        return true;
      }
    },
  }),
  right_explanation: string(),
  wrong_explanation: string(),
});

// combined schema of all unit types (text, video, workbook & scorm)
export const NEW_UNIT_SCHEMA = object({
  name: string().required('Unit name is required'),
  image: mixed().test(
    'fileFormat',
    'Please choose .png, .jpg or .jpeg image format',
    value => {
      if (!value?.type) return true;
      return SUPPORTED_FORMATS.includes(value?.type);
    }
  ),
  type: string().required('Content type is required'),
  body: string().when('type', {
    is: UNIT_TYPES.TEXT,
    then: string().required('Unit description is required'),
  }),
  selectedVideoType: string().when('type', {
    is: UNIT_TYPES.VIDEO,
    then: string().required('Choosing video option is required'),
  }),
  video_url: string().when(['type', 'selectedVideoType'], {
    is: (type, selectedVideoType) =>
      type === UNIT_TYPES.VIDEO && selectedVideoType === 'video_url',
    then: string().required('Video url is required'),
  }),
  file: mixed()
    .when(['type', 'selectedVideoType'], {
      is: (type, selectedVideoType) =>
        type === UNIT_TYPES.VIDEO && selectedVideoType === 'file',
      then: mixed()
        .required('Uploading video file is required')
        .test(
          'fileType',
          'Unsupported Video Format',
          value =>
            SUPPORTED_VIDEO_FORMATS.includes(value?.type) || value === null
        ),
    })
    .when('type', {
      is: UNIT_TYPES.SCORM,
      then: mixed()
        .required('Scorm File is required')
        .test('fileType', 'Unsupported File Format', value =>
          SUPPORTED_FILE_FORMATS.includes(value?.type)
        ),
    }),
  isGraded: boolean(),
  passing_grade: number().when(['type', 'isGraded'], {
    is: (type, isGraded) => type === UNIT_TYPES.WORKBOOK && isGraded,
    then: number()
      .typeError('Minimum passing grade must be a number')
      .required('Passing grade is required'),
  }),
  questions: array().when('type', {
    is: UNIT_TYPES.WORKBOOK,
    then: array().of(WORKBOOK_QUESTION_SCHEMA),
  }),
});
