import React from 'react';
import Proptypes from 'prop-types';
import { Link } from 'react-router-dom';
import noteIcon from '@assets/analytics/note.svg';
import { getNumberReadableValue } from '@utilities';

/*
 This analytics card component represent the total number of (courses, users, provider...etc)
 newly created and shows how many is published and un-published.
*/
const NewCreationCard = ({
  label,
  publishedLabel,
  unPublishedLabel,
  publishedNumber,
  unPublishedNumber,
}) => {
  const totalNumber = publishedNumber + unPublishedNumber;
  return (
    <div className="total-card total-card--newCreation total-enrollments-card">
      <div className="card-header">
        <img src={noteIcon} />
        <Link to="#">View</Link>
      </div>
      <div className="card-body">
        <div className="total-enrollments">
          <span className="total-enrollments-count">
            {getNumberReadableValue(totalNumber) || 0}
          </span>
        </div>
        <p className="total-enrollments-label">{label}</p>
      </div>
      <div className="card-footer">
        <div className="footer-item footer-item--published">
          <p className="title">{publishedLabel}</p>
          <p className="value">
            {getNumberReadableValue(publishedNumber) || 0}
          </p>
        </div>
        <div className="footer-item footer-item--unpublished">
          <p className="title">{unPublishedLabel}</p>
          <p className="value">
            {getNumberReadableValue(unPublishedNumber) || 0}
          </p>
        </div>
      </div>
    </div>
  );
};

NewCreationCard.propTypes = {
  label: Proptypes.string,
  publishedLabel: Proptypes.string,
  unPublishedLabel: Proptypes.string,
  publishedNumber: Proptypes.number,
  unPublishedNumber: Proptypes.number,
};

export default NewCreationCard;
