import React from 'react';
import Loadable from 'react-loadable';
import PropTypes from 'prop-types';
import LoadingController from './loading_controller';

/** component used for synamic importing and code splitting using
 *react loadable check https://github.com/jamiebuilds/react-loadable
 */

class DynamicLoading extends React.Component {
  constructor(props) {
    super(props);
    this.LoadableInstance = Loadable({
      loader: props.componentImport,
      loading: LoadingController,
      delay: 300,
      timeout: 100000,
    });
  }

  render() {
    const {
      LoadableInstance,
      props: { data },
    } = this;
    return <LoadableInstance data={data} />;
  }
}

export default DynamicLoading;

DynamicLoading.propTypes = {
  /** function defines component to be dynamically loaded */
  componentImport: PropTypes.func,
  /** data prop of type object passed to child component */
  data: PropTypes.object,
};
