import gql from 'graphql-tag';

export const ToggleNavbarSectionStatus = gql`
  mutation toggleNavbarItemStatus($ids: [ID!]!) {
    toggleNavbarItemStatus(ids: $ids) {
      statusCode
      message
      errors {
        field_name
        message
      }
    }
  }
`;
