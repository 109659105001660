import gql from 'graphql-tag';

export const PublishJourney = gql`
  mutation publishJourney($journeyId: ID!) {
    publishJourney(journeyId: $journeyId) {
      errors {
        errors
        fieldName
      }
      statusCode
    }
  }
`;
