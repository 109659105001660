import React from 'react';
import Proptypes from 'prop-types';
import { getNumberReadableValue } from '@utilities';

const TotalCountCard = ({ title, numberPerPeriod, totalNumber }) => {
  return (
    <div className="total-card total-enrollments-card">
      <div className="total-enrollments">
        <span className="total-enrollments-count">
          {getNumberReadableValue(numberPerPeriod) || 0}
        </span>
        <span className="total-users-count">{`/${getNumberReadableValue(
          totalNumber
        ) || 0} Users`}</span>
      </div>
      <p className="total-enrollments-label">{title}</p>
    </div>
  );
};

TotalCountCard.propTypes = {
  title: Proptypes.string,
  numberPerPeriod: Proptypes.number,
  totalNumber: Proptypes.number,
};

export default TotalCountCard;
