import React from 'react';
import PropTypes from 'prop-types';
import { FormItem } from '@components';
import { COURSE_STATUS } from '@constants';
import { LIVE_SESSION_STATUS } from '@constants';

const StatusFilter = ({
  initialValue,
  mode,
  validations = [],
  onChange,
  form,
  isLiveSession = false,
}) => {
  return (
    <FormItem
      inputName="status"
      inputLabel="Status"
      className="inline-label"
      inputType="select"
      placeHolder={isLiveSession ? 'Select Status' : 'Select Course Status'}
      validationRules={validations}
      mode={mode}
      initialValue={mode ? undefined : initialValue}
      options={isLiveSession ? LIVE_SESSION_STATUS : COURSE_STATUS}
      onChange={onChange}
      form={form}
    />
  );
};

StatusFilter.propTypes = {
  initialValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  onChange: PropTypes.func.isRequired,
  validations: PropTypes.array,
  mode: PropTypes.string,

  form: PropTypes.shape({
    /** getFieldDecorator prop of type function passed from antd form to get each field decorator  */
    getFieldDecorator: PropTypes.func,
    /** setFieldsValue prop of type function passed form ant d form to set field value by it's name */
    setFieldsValue: PropTypes.func,
    /** validateFields prop of type function passed from antd form to run its validations  */
    validateFields: PropTypes.func,
  }).isRequired,
};

export default StatusFilter;
