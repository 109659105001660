import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  Login,
  CoursesList,
  NewCourse,
  NewCategory,
  Categories,
  NewAdmin,
  AdminDashboard,
  ChangePassword,
  ForgotPassword,
  ResetPassword,
  ManualNotificationsList,
  AutomaticNotificationsList,
  CreateManualNotification,
  EditAutomaticNotifications,
  ThirdPartyContent,
  Competitions,
  Competition,
  EditCompetition,
  ViewCompetition,
  Analytics,
  AnalyticsCourse,
  DashboardAnalytics,
  CoursesOverviewAnalytics,
  Users,
  NewUser,
  OngoingJobs,
  Providers,
  Journeys,
  Comments,
  PointsSystem,
  AdminNotifications,
  DiscussionsList,
  DiscussionTheardForm,
  DiscussionThreadDetails,
  SetAdminPassword,
  NavbarSections,
  NewNavbarSection,
  NewLiveSessionSection,
  LiveSessions,
} from '@scenes';

import { Auth } from '@auth';
import GlobalStyles from '@utilities/global_styles';
import OtpPage from './scenes/login_form/partials/otp_page';

class App extends Component {
  initializeRoutes = () => {
    return (
      <Switch>
        <Route exact path="/" component={Login} />

        {/* OTP */}
        <Route exact path="/otp" component={OtpPage} />

        {/* authorization */}
        <Route exact path="/change-password" component={ChangePassword} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route exact path="/reset-password/:token?" component={ResetPassword} />

        {/* courses */}
        <Route exact path="/courses" component={CoursesList} />
        <Route exact path="/course/:id?" component={NewCourse} />

        {/* providers */}
        <Route path="/providers" component={Providers} />

        {/* categories */}
        <Route exact path="/new-category" component={NewCategory} />
        <Route exact path="/category/:id?" component={NewCategory} />
        <Route exact path="/categories" component={Categories} />

        {/* live session */}
        <Route
          exact
          path="/new-live-session"
          component={NewLiveSessionSection}
        />
        <Route
          exact
          path="/live-session/:id?"
          component={NewLiveSessionSection}
        />
        <Route exact path="/live-sessions" component={LiveSessions} />

        {/* admin */}
        <Route exact path="/add-admin" component={NewAdmin} />
        <Route exact path="/admin-dashboard" component={AdminDashboard} />
        <Route
          exact
          path="/courses-analytics"
          component={CoursesOverviewAnalytics}
        />

        {/* users */}
        <Route exact path="/user/:id?" component={NewUser} />
        <Route exact path="/users/:status/:id?" component={Users} />

        {/* ongoing jobs */}
        <Route exact path="/ongoing-jobs" component={OngoingJobs} />

        {/* 3rd-party-content */}
        <Route exact path="/3rd-party-content" component={ThirdPartyContent} />

        {/* journeys */}
        <Route path="/journeys" component={Journeys} />

        {/* comments */}
        <Route path="/comments" component={Comments} />

        {/* navbar sections */}
        <Route path="/navbar-sections" component={NavbarSections} />
        <Route path="/new-navbar-section" component={NewNavbarSection} />
        <Route exact path="/navbar-section/:id?" component={NewNavbarSection} />

        {/* Discussions */}
        <Route path="/discussions/:type" component={DiscussionsList} />
        <Route
          exact
          path="/discussion/form/:id?"
          component={DiscussionTheardForm}
        />
        <Route
          exact
          path="/discussion-details/:id"
          component={DiscussionThreadDetails}
        />

        {/* notifications */}
        <Route
          path="/manual-message/:id?"
          component={CreateManualNotification}
        />
        <Route
          exact
          path="/manual-messages"
          component={ManualNotificationsList}
        />
        <Route
          exact
          path="/notifications-automatic"
          component={AutomaticNotificationsList}
        />
        <Route
          exact
          path="/notifications-automatic/edit/:id?"
          component={EditAutomaticNotifications}
        />

        {/* competitions */}
        <Route exact path="/competitions" component={Competitions} />
        <Route exact path="/competition" component={Competition} />
        <Route exact path="/competition/edit/:id" component={EditCompetition} />
        <Route exact path="/competition/view/:id" component={ViewCompetition} />

        {/* analytics */}
        <Route exact path="/analytics/course/:id" component={AnalyticsCourse} />
        <Route exact path="/analytics" component={DashboardAnalytics} />

        {/* Points System*/}
        <Route path="/points-system" component={PointsSystem} />

        {/* admin notifications */}
        <Route path="/admin-notifications" component={AdminNotifications} />

        <Route path="/set-password/:token?" component={SetAdminPassword} />
      </Switch>
    );
  };

  render() {
    return (
      <>
        <GlobalStyles />
        <Auth>{this.initializeRoutes()}</Auth>
      </>
    );
  }
}

export default App;
