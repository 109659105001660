/**
 * @Author: sarahnouh
 * @Date:   2020-02-09T09:46:34+02:00
 * @Last modified by:   sarahnouh
 * @Last modified time: 2020-02-11T15:28:47+02:00
 */

import gql from 'graphql-tag';

export const updateQuestionMutation = gql`
  mutation updateQuestion($content: UpdateQuestionInput!) {
    updateQuestion(content: $content) {
      statusCode
      message
      errors {
        field_name
        message
      }
      question {
        id
        type
        body
        answers {
          id
          body
        }
        right_answer {
          id
          body
        }
        right_explanation
        wrong_explanation
      }
    }
  }
`;
