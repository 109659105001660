/**
 * @Author: sarahnouh
 * @Date:   2019-12-30T15:59:46+02:00
 * @Last modified by:   sarahnouh
 * @Last modified time: 2019-12-30T16:20:44+02:00
 */
import gql from 'graphql-tag';

export const DeleteCategoryMutation = gql`
  mutation deleteCategories($ids: [ID!]!) {
    deleteCategories(ids: $ids) {
      id
      name
      index
      educational_subject
      image {
        url
      }
      published_courses_count
    }
  }
`;
