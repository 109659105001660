import gql from 'graphql-tag';

export const CoursesOverviewExportCsvQuery = gql`
  query csvCourseOverviewAnalytics(
    $startDate: String
    $endDate: String
    $providerId: ID
    $persona: CourseGroupEnum
  ) {
    csvCourseOverviewAnalytics(
      startDate: $startDate
      endDate: $endDate
      providerId: $providerId
      persona: $persona
    )
  }
`;
