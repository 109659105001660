import React from 'react';
import { Redirect } from 'react-router-dom';
import NewCourseContainer from './new_course_container';
import { AuthConsumer, Can } from '@auth';
import { ACTIONS } from '../../rbac_rules';

/** exported dynamic import for new course scene */
const NewCourse = props => {
  return (
    <AuthConsumer>
      {({ role }) => (
        <Can
          role={role}
          perform={ACTIONS.ADD_NEW_COURSE}
          yes={() => <NewCourseContainer {...props} />}
          no={() => {
            return <Redirect to="/" />;
          }}
        />
      )}
    </AuthConsumer>
  );
};

export default NewCourse;
