export const colors = {
  primaryColor: '#FF0000',
  secondaryColor: '#16202B',
  borderColor: '#ccc',
  errorColor: '#e60000',
  dimmedColor: '#d3d3d3',
  gray: '#858585',
  primaryButtonColor: '#0c9890',
  lightGray: '#e9e9e9;',
  blue: '#1890ff',
};
