import { MonthDays, MonthsNames, WeekDays } from '@constants/periods';
import Chart from 'chart.js/auto';
import { addDays, differenceInCalendarDays, format } from 'date-fns';

/**
 *
 *
 * @export
 * @param {HTMLCanvasElement} canvas
 * @param {string[]} labels
 * @param {{
 *     values: number[];
 *     label: string;
 *     backgroundColor?: string;
 *     borderColor?: string;
 *   }[]} data
 * @param {string} yAxisLabel
 * @returns {Chart<'line', number[], string>}
 */
export function getLineChart(canvas, labels, data) {
  const ctx = canvas.getContext('2d');
  const datasets = data.map(stream => {
    const gradient = ctx.createLinearGradient(0, 0, 0, 350);
    gradient.addColorStop(0, stream.borderColor);
    gradient.addColorStop(1, 'rgba(255,205,205,0.09)');
    return {
      tension: 0.5,
      label: stream.label,
      data: stream.values,
      borderColor: stream.borderColor,
      backgroundColor: gradient,
      fill: true,
      hoverBackgroundColor: stream.backgroundColor,
    };
  });

  return new Chart(ctx, {
    type: 'line',
    options: {
      responsive: true,
      interaction: {
        intersect: false,
      },
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
        },
      },
    },
    data: {
      labels,
      datasets,
    },
  });
}

/**
 *
 * return x-axis values of chart for different period
 * @export
 * @param {{
 *     from: string;
 *     type: string;
 *     to: string;
 *   }} period
 * @returns {string[]}
 */
export function getChartRange(period) {
  let range;
  const rangeDays = differenceInCalendarDays(
    new Date(period.from),
    new Date(period.to)
  );

  switch (period.type) {
    case 'year':
      range = MonthsNames;
      break;
    case 'month':
      range = MonthDays;
      break;
    case 'week':
      range = WeekDays;
      break;
    default:
      range = [];
      for (let i = 0; i <= Math.abs(rangeDays); i += 1) {
        range.push(format(addDays(new Date(period.from), i), 'dd/MM/yy'));
      }
      break;
  }

  return range;
}

export function getPieChart(canvas, data, backgroundColor) {
  const ctx = canvas.getContext('2d');
  let filteredValuesData = {};
  for (let key in data) {
    if (typeof data[key] === 'number') {
      filteredValuesData[key] = data[key];
    }
  }
  return new Chart(ctx, {
    type: 'pie',
    options: {
      responsive: true,
      maintainAspectRatio: true,
      interaction: {
        intersect: false,
      },
      plugins: {
        legend: {
          display: false,
          position: 'top',
          labels: {
            boxWidth: 10,
            boxHeight: 10,
            color: '#152C5B',
            font: {
              size: 14,
              family: 'Nunito Sans',
              weight: '600',
            },
          },
        },
      },
      scales: {
        x: {
          display: false,
        },
        y: {
          display: false,
        },
      },
    },
    data: {
      labels: Object.keys(filteredValuesData),
      datasets: [
        {
          label: 'Points',
          backgroundColor: backgroundColor || [
            '#f1c40f',
            '#e67e22',
            '#16a085',
            '#2980b9',
          ],

          data: Object.values(filteredValuesData),
          hoverOffset: 4,
        },
      ],
    },
  });
}
export function getDoughnutChart(canvas, sections) {
  const ctx = canvas.getContext('2d');
  const updatedSections = prepareDoughnutChartData(sections);
  const { labels, data, backgroundColor } = updatedSections.reduce(
    (prev, cur) => {
      return {
        labels: [...prev.labels, cur.name],
        data: [...prev.data, cur.count],
        backgroundColor: [...prev.backgroundColor, cur.color],
      };
    },
    { labels: [], data: [], backgroundColor: [] }
  );

  return new Chart(ctx, {
    type: 'doughnut',
    data: {
      labels,
      datasets: [
        {
          data,
          backgroundColor,
        },
      ],
    },
    options: {
      cutout: '75%',
      radius: '100%',
      layout: {
        padding: 0,
      },
      responsive: true,
      maintainAspectRatio: true,
      plugins: {
        legend: {
          display: false,
        },
      },
    },
  });
}

export function prepareDoughnutChartData(sections) {
  const totalCounts = sections.reduce((prev, cur) => {
    return prev + Number(cur.count);
  }, 0);
  const OthersThreshold = 0.005;
  const newSections = [];
  const others = {
    count: `0`,
    name: 'Other',
  };
  for (const i in sections) {
    if (Number(sections[i].count) < totalCounts * OthersThreshold) {
      others.count = `${Number(sections[i].count) + Number(others.count)}`;
    } else {
      newSections.push(sections[i]);
    }
  }

  if (Number(others.count) > 0) {
    newSections.push(others);
  }
  return newSections;
}

export function getBarChart(canvas, labels, data) {
  const ctx = canvas.getContext('2d');
  const datasets = data.map(stream => {
    const color = '#4A90E2';
    return {
      tension: 0.5,
      data: stream.values,
      borderColor: color,
      backgroundColor: stream.backgroundColor || color,
      borderRadius: 50,
      barThickness: 6,
    };
  });

  return new Chart(ctx, {
    type: 'bar',
    options: {
      responsive: true,
      maintainAspectRatio: true,
      interaction: {
        intersect: false,
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
        },
        y: {
          grid: {
            display: false,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
      },
    },
    data: {
      labels,
      datasets,
    },
  });
}
