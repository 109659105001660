import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ReactComponent as Ellipsis } from './images/ellipsis-h.svg';
import {
  DropDownButton,
  DropDownContainer,
  DropDownList,
} from './styledComponent';

/**
 * dropdown menu
 * @param {object} props
 * @param {string} props.id
 * @param {array} props.list
 * @param {string} props.list.title
 * @param {string} props.list.type
 * - link // for internal links and should have url
 * - externalLink // for outside links and should have url
 * - button //for buttons and should have onClick
 * @param {string} props.list.url
 * @param {function} props.onClick
 */

export const CustomDropDown = ({ list, id }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropDownRef = useRef(id);

  const toggleDropDown = useCallback(() => {
    setIsOpen(prev => !prev);
  }, []);

  const handleClickOutside = useCallback(
    event => {
      if (isOpen && !dropDownRef?.current?.contains(event.target)) {
        setIsOpen(false);
      }
    },
    [isOpen]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <DropDownContainer ref={dropDownRef}>
      <DropDownButton onClick={toggleDropDown}>
        <Ellipsis />
      </DropDownButton>
      {isOpen && (
        <DropDownList>
          {list.map((item, index) => (
            <li key={index}>
              {item?.type === 'link' ? (
                <Link to={item.url}>{item.title}</Link>
              ) : item?.type === 'externalLink' ? (
                <a href={item.url} target="_blank" rel="noopener noreferrer">
                  {item.title}
                </a>
              ) : item?.type === 'button' ? (
                <button
                  onClick={e => {
                    item.onClick(e);
                    setIsOpen(false);
                  }}
                >
                  {item.title}
                </button>
              ) : (
                item?.title
              )}
            </li>
          ))}
        </DropDownList>
      )}
    </DropDownContainer>
  );
};

CustomDropDown.defaultProps = {
  id: '',
};

CustomDropDown.propTypes = {
  list: PropTypes.array.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
