/**
 * @author Raghda Wessam
 * @date 2020-10-19
 * @description lesson Card Component
 * @filename lesson_card.js
 */

import React from 'react';
import { PropTypes } from 'prop-types';
import { Field, ErrorMessage } from 'formik';
import { Icon } from 'antd';
import {
  LessonWrapper,
  ErrorMsgWrapper,
  InputWrapper,
} from './styles/lesson_card_styles';

const LessonCard = ({ index, remove, canDeleteLesson }) => {
  return (
    <LessonWrapper>
      <label htmlFor={`lessons[${index}].name`}>Lesson Name</label>
      <InputWrapper>
        <Field
          name={`lessons[${index}].name`}
          type="text"
          placeholder="Add lesson name"
        />
        <ErrorMessage
          component={ErrorMsgWrapper}
          name={`lessons[${index}].name`}
        />
      </InputWrapper>
      <Icon
        type="delete"
        style={{
          color: 'red',
          fontSize: '20px',
          position: 'absolute',
          transform: 'translateY(-50%)',
          top: '50%',
          right: '30px',
        }}
        onClick={() => {
          if (canDeleteLesson) {
            remove(index);
          }
        }}
      />
    </LessonWrapper>
  );
};

LessonCard.propTypes = {
  // index of lesson
  index: PropTypes.number,
  // function for deleting a lesson passed through formik
  remove: PropTypes.func,
  // bool that determines if i can delete a lesson or not
  canDeleteLesson: PropTypes.bool,
};

export default LessonCard;
