/**
 * @Author: sarahnouh
 * @Date:   2020-01-15T14:14:05+02:00
 * @Last modified by:   sarahnouh
 * @Last modified time: 2020-01-15T17:24:25+02:00
 */
import gql from 'graphql-tag';

export const AddAdminMutation = gql`
  mutation AddAdmin(
    $name: String!
    $email: String!
    $role: AdminRole!
    $provider_id: ID
  ) {
    createAdmin(
      name: $name
      email: $email
      role: $role
      provider_id: $provider_id
    ) {
      admin {
        id
        name
      }
      errors {
        field_name
        message
      }
      statusCode
    }
  }
`;
