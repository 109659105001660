import gql from 'graphql-tag';

export const ToggleProviderStatus = gql`
  mutation toggleProviderStatus($ids: [ID!]!) {
    toggleProviderStatus(ids: $ids) {
      statusCode
      errors {
        field_name
        message
      }
    }
  }
`;
