import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useUserAnalytics } from '@hooks';
import { AnalyticsContext } from './analytics-context';
import { getPercentage, userRoles, selectedRoleOptions } from '@utilities';
import RoleSelcetDropdown from './roles-select-dropdown';
import PieChart from './pie-chart';
import maleIcon from '@assets/analytics/male.png';
import femaleIcon from '@assets/analytics/female.png';
import { Spin } from 'antd';
const GenderDistributionCard = ({ label }) => {
  const analyticsContext = useContext(AnalyticsContext);
  const [selectedGenderRole, setSelectedGenderRole] = useState(
    userRoles?.parent
  );
  const { analytics, error, loading } = useUserAnalytics(
    analyticsContext?.currentPeriod?.from,
    analyticsContext?.currentPeriod?.to,
    selectedGenderRole
  );

  return (
    <div className="total-card total-performance-card gender-distribution-card">
      {loading && <Spin size="large" />}
      <h3 className="title">{label}</h3>
      <RoleSelcetDropdown
        setSelectedUserRole={setSelectedGenderRole}
        options={selectedRoleOptions}
      />
      {analytics?.gender_distribution?.male == 0 &&
      analytics?.gender_distribution?.female == 0 ? (
        <p>No data for this period.</p>
      ) : (
        <>
          <PieChart
            genderPerPeriod={analytics?.gender_distribution}
            colors={['#4A90E2', '#FF832B']}
          />
          <div className="pie-chart-info">
            <div className="info-item">
              <img className="icon" src={femaleIcon} alt="female" />
              <div className="text">
                <label>Female</label>
                <div className="percent">
                  {getPercentage(
                    analytics?.gender_distribution?.female,
                    analytics?.gender_distribution?.female +
                      analytics?.gender_distribution?.male
                  )}
                  &#37;
                </div>
              </div>
            </div>
            <div className="info-item">
              <img className="icon" src={maleIcon} alt="male" />
              <div className="text">
                <label>Male</label>
                <div className="percent">
                  {getPercentage(
                    analytics?.gender_distribution?.male,
                    analytics?.gender_distribution?.female +
                      analytics?.gender_distribution?.male
                  )}
                  &#37;
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

GenderDistributionCard.propTypes = {
  label: PropTypes.string,
};

export default GenderDistributionCard;
