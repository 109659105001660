import gql from 'graphql-tag';

export const ExportCompetitionDetails = gql`
  mutation exportCompetitionDetails($id: ID!) {
    exportCompetitionDetails(id: $id) {
      statusCode
      message
      errors {
        field_name
        message
      }
    }
  }
`;
