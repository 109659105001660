import React from 'react';
import { Input, Form } from 'antd';
import PropTypes from 'prop-types';

const formItemStyle = {
  marginBottom: 0,
};

const BlockReasonForm = ({
  form: { getFieldDecorator },
  inputName,
  label,
  requiredValidationMessage,
}) => {
  return (
    <Form layout="vertical">
      <Form.Item label={label} style={formItemStyle}>
        {getFieldDecorator(`${inputName}`, {
          rules: [{ required: true, message: requiredValidationMessage }],
        })(<Input.TextArea />)}
      </Form.Item>
    </Form>
  );
};

BlockReasonForm.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
  }),
  inputName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  requiredValidationMessage: PropTypes.string.isRequired,
};

export default Form.create()(BlockReasonForm);
