import React, { PureComponent } from 'react';
import { PieChart, Pie, Cell, Legend, Tooltip } from 'recharts';
import PropTypes from 'prop-types';

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor="middle"
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

class PieChartComponent extends PureComponent {
  render() {
    const {
      data,
      width,
      height,
      positionX,
      positionY,
      labelLine,
      outerRadius,
      fill,
      colors,
    } = this.props;

    const settings = {
      width: width || 400,
      height: height || 300,
      positionX: positionX,
      positionY: positionY,
      labelLine: labelLine || false,
      outerRadius: outerRadius || 80,
      fill: fill || '#8884d8',
      colors: colors || [
        'red',
        'blue',
        'brown',
        'purple',
        'darkslategray',
        'gold',
        'darkgreen',
        'chocolate',
        'darkblue',
        'dimgray',
        'magenta',
        'orange',
      ],
    };

    return (
      <PieChart
        width={settings.width}
        height={settings.height}
        textAnchor="middle"
      >
        <Legend verticalAlign="top" />
        <Pie
          data={data}
          cx={settings.positionX}
          cy={settings.positionY}
          labelLine={settings.labelLine}
          label={renderCustomizedLabel}
          outerRadius={settings.outerRadius}
          fill={settings.fill}
          dataKey="value"
          isAnimationActive={false}
        >
          {data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={settings.colors[index % settings.colors.length]}
              title={entry.name}
            />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
    );
  }
}

renderCustomizedLabel.propTypes = {
  cx: PropTypes.number,
  cy: PropTypes.number,
  midAngle: PropTypes.number,
  innerRadius: PropTypes.number,
  outerRadius: PropTypes.number,
  percent: PropTypes.number,
  index: PropTypes.number,
};

PieChartComponent.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    })
  ).isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  positionX: PropTypes.number,
  positionY: PropTypes.number,
  labelLine: PropTypes.bool,
  outerRadius: PropTypes.number,
  fill: PropTypes.string,
  colors: PropTypes.arrayOf(PropTypes.string.isRequired),
};

export default PieChartComponent;
