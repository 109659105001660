/**
 * @Author: sarahnouh
 * @Date:   2020-02-11T18:50:50+02:00
 * @Last modified by:   sarahnouh
 * @Last modified time: 2020-02-11T18:55:00+02:00
 */
import gql from 'graphql-tag';

export const updateAssessment = gql`
  mutation updateAssessmentMutation($content: UpdateAssessmentInput!) {
    updateAssessment(content: $content) {
      statusCode
      message
      errors {
        field_name
        message
      }
      assessment {
        id
        type
        estimated_time
        passing_grade
        number_of_trials
        reset_time
      }
    }
  }
`;
