import gql from 'graphql-tag';

export const USER_COMPETITIONS = gql`
  query competitionsByUser(
    $userId: ID!
    $first: Int
    $after: String
    $last: Int
    $before: String
    $sortBy: String
    $sortType: String
  ) {
    competitionsByUser(
      userId: $userId
      first: $first
      after: $after
      last: $last
      before: $before
      sortBy: $sortBy
      sortType: $sortType
    ) {
      nodes {
        key: name
        competitionId: id
        name
        rank
        points
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;
