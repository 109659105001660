import gql from 'graphql-tag';

export const CourseMapQuery = gql`
  query CourseMap($courseId: ID!) {
    courseMap(courseId: $courseId) {
      courseMap {
        id
        body {
          ... on GeneralCourseMap {
            id
            __typename
            name
            contentType
            itemType
            children {
              id
              name
              contentType
              itemType
              hasChildren
              description
              children {
                id
                name
                description
                contentType
                itemType
                hasChildren
                children {
                  id
                  name
                  contentType
                  itemType
                  workbookSize
                  gradedWorkbookSize
                  children {
                    id
                    name
                    contentType
                    itemType
                    hasChildren
                    workbookSize
                    gradedWorkbookSize
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
