import gql from 'graphql-tag';

export const EDIT_CERTIFICATE_TEMPLATE = gql`
  mutation editCourseCertificate(
    $courseId: Int!
    $certificateId: Int!
    $certificateCourseName: String
  ) {
    editCourseCertificate(
      courseId: $courseId
      certificateId: $certificateId
      certificateCourseName: $certificateCourseName
    ) {
      errors {
        fieldName
        errors
      }
      statusCode
    }
  }
`;
