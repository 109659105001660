import gql from 'graphql-tag';

export const EditCourseInfoMutation = gql`
  mutation EditCourse(
    $courseId: Int!
    $course: EditCourseInput!
    $rectangleImage: File
    $squareImage: File
  ) {
    editCourse(
      courseId: $courseId
      course: $course
      rectangleImage: $rectangleImage
      squareImage: $squareImage
    ) {
      course {
        id
        name
      }
      errors {
        fieldName
        errors
      }
      statusCode
    }
  }
`;
