/**
 * sanitize input string
 *
 * @param {String} str
 * @returns {String}
 */
export const sanitizeString = str => {
  str = str.replace(
    /[^a-z0-9áéíóúñü \.,_-][\u0600-\u06ff][\u0750-\u077f][\ufb50-\ufc3f][\ufe70-\ufefc]/gim,
    ''
  );
  return str;
};
