import gql from 'graphql-tag';

export const DeleteNotification = gql`
  mutation DeleteNotification($notificationId: Int!) {
    deleteNotification(notificationId: $notificationId) {
      errors {
        fieldName
        errors
      }
      statusCode
    }
  }
`;
