import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { en as strings } from './locales/data.json';

/**
 * export to csv report button
 */
const ExportCsvReportButton = ({
  dataFilters,
  query,
  dataKey,
  shouldResetDownloadButton,
  resetDownloadButton,
}) => {
  const [reportLink, updateReportLink] = useState('');

  /**
   * Remove null values from filters obj
   *
   * @returns {object}
   */
  const dataFiltersFiltered = () => {
    return Object.entries(dataFilters).reduce(
      (acc, [key, val]) => (val === null ? acc : { ...acc, [key]: val }),
      {}
    );
  };

  const [fetchReportLink, { loading, data }] = useLazyQuery(query, {
    variables: dataFiltersFiltered(),
  });

  const handleExportingReport = () => {
    fetchReportLink();
  };

  useEffect(() => {
    if (data && data[dataKey]) {
      updateReportLink(data[dataKey]);
      resetDownloadButton(false);
    }
  }, [data]);

  return (
    <>
      {data && !shouldResetDownloadButton ? (
        <a
          className="ant-btn ant-btn-primary"
          href={`//${reportLink}`}
          download
        >
          {strings.downloadButtonText}
        </a>
      ) : (
        <Button
          type="primary"
          onClick={handleExportingReport}
          loading={loading}
        >
          {strings.exportReportButtonText}
        </Button>
      )}
    </>
  );
};

ExportCsvReportButton.propTypes = {
  /** dataFilters prop passed from parent component */
  dataFilters: PropTypes.object,
  /** query prop of type object for used fetch query */
  query: PropTypes.object.isRequired,
  /** dataKey prop of type string for fetched data object key */
  dataKey: PropTypes.string.isRequired,
  /** shouldResetDownloadButton prop of type boolean for resetting download button state */
  shouldResetDownloadButton: PropTypes.bool.isRequired,
  /** resetDownloadButton prop for resetting download report button */
  resetDownloadButton: PropTypes.func.isRequired,
};

export default ExportCsvReportButton;
