import gql from 'graphql-tag';

export const CreateCourseOutlineMutation = gql`
  mutation createCourseOutlineMutation($content: CreateCourseOutlineInput!) {
    createCourseOutline(content: $content) {
      statusCode
      message
      course {
        modules {
          id
          name
          description
          lessons {
            name
            id
          }
        }
      }
      errors {
        field_name
        message
      }
    }
  }
`;
