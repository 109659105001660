import { useQuery } from '@apollo/client';
import { PAGINATION_PAGE_SIZE } from '@constants';
import { LIST_PROVIDERS } from '@services';

export const useProviders = () => {
  const { loading, error, data, refetch } = useQuery(LIST_PROVIDERS, {
    variables: {
      first: PAGINATION_PAGE_SIZE,
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  let providers = [];

  if (data) {
    data.listProviders.edges.forEach(provider => {
      providers.push(provider.node);
    });
  }
  const pageInfo = data?.listProviders?.pageInfo || {};

  return { providers, pageInfo, loading, error, refetch };
};
