import gql from 'graphql-tag';

export const ASSESSMENT_BY_COURSE = gql`
  query assessmentByCourse($courseId: ID!, $assessmentType: AssessmentEnum!) {
    assessmentByCourse(courseId: $courseId, assessmentType: $assessmentType) {
      id
      questions {
        answers {
          body
          id
        }
        body
        id
        questionType
        rightAnswer {
          body
          id
        }
        rightExplanation
        wrongExplanation
      }
    }
  }
`;
