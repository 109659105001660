import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Spin } from 'antd';
import { NavBarSteps, MainLayout } from '@components';
import { en as strings } from './course_details/locales/data.json';
import { ADD_NEW_COURSE_STEPS } from '@constants';
import { GetCourseQuery } from '@services';
import CourseDetails from './course_details';
import CourseOutline from './course_outline';
import CourseContent from './course_content/index';
/**
 * New course form scene
 */
const NewCourseContainer = props => {
  const {
    NEW_COURSE_FORM,
    COURSE_OUTLINE,
    COURSE_CONTENT,
    COURSE_PREVIEW,
  } = ADD_NEW_COURSE_STEPS;

  //TODO: to be updated navigating back
  const [activeTab, updateActiveTab] = useState(NEW_COURSE_FORM);
  const courseId = props.match.params?.id;
  const pageTitle = strings.titles[courseId ? 'edit' : 'new'];
  const [courseDetails, setCourseDetails] = useState(null);
  const isCoursePublished = courseDetails?.status === 'PUBLISHED';
  const [loading, setLoading] = useState(false);

  // initially load course details in case of edit.
  const { loading: loadingCourse, error } = useQuery(GetCourseQuery, {
    fetchPolicy: 'network-only',
    variables: {
      id: courseId,
    },
    skip: !courseId,
    onCompleted: data => {
      setCourseDetails(data.getCourse);
    },
  });

  /**
   * replace the current course object in the state with a new one.
   * @param {Object} course new course object to be added.
   */
  const updateCourseDetails = course => {
    setCourseDetails(course);
  };

  /**
   * update the active tab in the state based on the current step
   * or redirect to courses page in case of the last step.
   *
   * @param {number} currentStep
   */
  const updateActiveStep = currentStep => {
    const tabsLength = Object.keys(ADD_NEW_COURSE_STEPS).length;
    if (tabsLength > currentStep + 1) {
      updateActiveTab(currentStep + 1);
    } else {
      props.history.push(`/courses`);
    }
  };

  /**
   * update the active tab in the state based on the current step.
   *
   * @return {React.ReactElement}} active tab component.
   */
  const getActiveComponent = () => {
    switch (activeTab) {
      case NEW_COURSE_FORM:
        return (
          <CourseDetails
            {...props}
            courseDetails={courseDetails}
            onCompleted={newCourseDetails => {
              setLoading(true);
              updateCourseDetails(newCourseDetails);
              updateActiveStep(NEW_COURSE_FORM);
              setLoading(false);
            }}
          />
        );
      case COURSE_OUTLINE:
        return (
          <CourseOutline
            {...props}
            courseId={courseDetails?.id}
            modules={courseDetails?.modules}
            assessments={courseDetails?.assessments}
            onCompleted={(modules, assessments) => {
              setLoading(true);
              updateCourseDetails({
                ...courseDetails,
                modules,
                assessments,
              });
              updateActiveStep(COURSE_OUTLINE);
              setLoading(false);
            }}
          />
        );
      case COURSE_CONTENT:
        return (
          <CourseContent
            {...props}
            courseId={courseDetails?.id}
            modules={courseDetails?.modules}
            assessments={courseDetails?.assessments}
            onCompleted={(modules, assessments) => {
              setLoading(true);
              updateCourseDetails({
                ...courseDetails,
                modules,
                assessments,
              });
              updateActiveStep(COURSE_CONTENT);
              setLoading(false);
            }}
          />
        );
      case COURSE_PREVIEW:
        //TODO: to be implemented
        return <h1>Course preview</h1>;

      default:
        console.log('Sorry, we are out of ' + activeTab + '.');
    }
  };
  return (
    <>
      <MainLayout
        bannerTitle={pageTitle}
        navBar={
          loadingCourse ? null : isCoursePublished ? (
            <p>{strings.editPublishedCourseDisclaimer}</p>
          ) : (
            <NavBarSteps current={activeTab} titles={strings.stepsTitles} />
          )
        }
      >
        {error && <div>Error: {error.message}</div>}
        {loadingCourse || loading ? (
          <Spin size="large" />
        ) : (
          getActiveComponent()
        )}
      </MainLayout>
    </>
  );
};

NewCourseContainer.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default NewCourseContainer;
