import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Radio, Button } from 'antd';
import styled from '@emotion/styled/macro';
import { FormItem } from '@components';

const AnswerWrapper = styled.div`
  display: flex;
  align-items: center;

  label.ant-radio-wrapper {
    margin-top: 15px;
  }

  button {
    margin: 15px 0 0 20px;
  }
`;

/** answer component in McqWorkbook */
const McqAnswerItem = ({
  answer,
  form,
  inputName,
  initialValue,
  answerIndex,
  validationRules,
  hideLabel,
  validateTrigger,
  deleteAnswerItem,
  disableIfSingleAnswer,
}) => {
  const [radioValue, updateRadioValue] = useState('');

  useEffect(() => {
    /** update radio button value with answer index */
    updateRadioValue(answerIndex);
  });

  return (
    <AnswerWrapper>
      <Radio value={radioValue} />
      <FormItem
        inputName={`${inputName}.body`}
        initialValue={initialValue}
        placeHolder="Answer"
        validationRules={validationRules}
        form={form}
        hideLabel={hideLabel}
        validateTrigger={validateTrigger}
      />
      <Button
        icon="delete"
        onClick={() => deleteAnswerItem(answerIndex)}
        disabled={disableIfSingleAnswer}
      />
    </AnswerWrapper>
  );
};

McqAnswerItem.propTypes = {
  answer: PropTypes.object,
  form: PropTypes.object,
  inputName: PropTypes.string,
  initialValue: PropTypes.string,
  questionIndex: PropTypes.number,
  answerIndex: PropTypes.number,
  validationRules: PropTypes.array,
  hideLabel: PropTypes.bool,
  /* validateTrigger prop of type array for events to trigger field validation upon*/
  validateTrigger: PropTypes.arrayOf(String),
  deleteAnswerItem: PropTypes.func,
  disableIfSingleAnswer: PropTypes.bool,
};

export default McqAnswerItem;
