/**
 * Change the date format
 *
 * @param {string} date
 * @returns {string}
 */
export function dateFormate(date) {
  if (!date) return;

  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  const locale = 'en-GB';
  const formattedDate = date.includes(' UTC')
    ? date.split(' UTC')[0].replace(/\s+/g, 'T') + 'Z'
    : date;

  return new Date(formattedDate).toLocaleString(locale, options);
}

export function dateNumericFormate(date) {
  const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
  const locale = 'en-US';

  return new Date(date).toLocaleString(locale, options);
}
