import { useQuery } from '@apollo/client';
import { CategoriesListQuery } from '@services';
import { useCallback } from 'react';

export const useCategoryList = PAGINATION_PAGE_SIZE => {
  const { loading, error, data, refetch, networkStatus } = useQuery(
    CategoriesListQuery,
    {
      variables: {
        first: PAGINATION_PAGE_SIZE,
        page: 1,
      },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    }
  );

  const categories = data?.listCategories?.data || [];
  const pageInfo = data?.listCategories?.paginatorInfo || {};

  const refetchPageResults = useCallback(async ({ first, page }) => {
    await refetch({ first, page });
  }, []);

  return {
    categories,
    pageInfo,
    loading,
    error,
    networkStatus,
    refetchPageResults,
  };
};
