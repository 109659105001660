import React, { useRef, useEffect, useState } from 'react';
import { getPieChart } from '@utilities/charts';
import PropTypes from 'prop-types';

const PieChart = ({ genderPerPeriod, colors }) => {
  const canvasRef = useRef();
  const [chart, setChart] = useState(undefined);

  useEffect(() => {
    if (chart) {
      chart.destroy();
    }

    setChart(getPieChart(canvasRef.current, genderPerPeriod, colors));
  }, [genderPerPeriod]);
  return (
    <div className="pie-chart">
      <canvas ref={canvasRef} id={Date.now()} />
    </div>
  );
};

PieChart.propTypes = {
  genderPerPeriod: PropTypes.object,
  colors: PropTypes.arrayOf(PropTypes.string),
};
export default PieChart;
