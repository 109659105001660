/**
 * @Author: sarahnouh
 * @Date:   2020-02-09T09:46:34+02:00
 * @Last modified by:   sarahnouh
 * @Last modified time: 2020-02-11T15:28:47+02:00
 */

import gql from 'graphql-tag';

export const createAssessment = gql`
  mutation createAssessmentMutation($content: CreateAssessmentInput!) {
    createAssessment(content: $content) {
      statusCode
      message
      errors {
        field_name
        message
      }
      assessment {
        id
        type
        estimated_time
        passing_grade
        number_of_trials
        reset_time
      }
    }
  }
`;
