import gql from 'graphql-tag';

export const ADMIN_REPLY_COMMENT = gql`
  mutation adminCreateReply($commentId: ID!, $replyText: String!) {
    adminCreateReply(commentId: $commentId, replyText: $replyText) {
      statusCode
      errors {
        errors
        fieldName
      }
    }
  }
`;
