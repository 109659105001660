import { useQuery } from '@apollo/client';
import { ListNavbarSection } from '@services';
import { useCallback } from 'react';

export const useNavbarList = () => {
  const { loading, error, data, refetch, networkStatus } = useQuery(
    ListNavbarSection,
    {
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    }
  );

  const navbarSections = data?.listNavbarItems || [];

  const refetchPageResults = useCallback(async () => {
    await refetch({});
  }, []);

  return {
    navbarSections,
    loading,
    error,
    networkStatus,
    refetchPageResults,
  };
};
