import { string, object, number } from 'yup';

export const NEW_ADMIN_SCHEMA = object({
  name: string().required('Name is required'),
  email: string()
    .email('Invalid email')
    .required('Email is required'),
  role: string()
    .oneOf(['ADMIN', 'PROVIDER_ADMIN'], 'Invalid role')
    .required('Role is required'),
  provider_id: number().when('role', {
    is: 'PROVIDER_ADMIN',
    then: number().required('Provider is required'),
  }),
});
