import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import config from './custom_config';

/** wysiwyg of type Classic ckEditor component with custom configuration */
class WysiWygEditor extends Component {
  /** handle change in wysizwyg editor data value */
  handleChange(editor) {
    const data = editor.getData();

    this.props.bindValueChange(data);
  }

  render() {
    const {
      placeHolder,
      initialValue,
      hasUploadImage,
      hasActiveLink,
    } = this.props;

    return (
      <CKEditor
        editor={ClassicEditor}
        data={initialValue || ''}
        config={config(placeHolder, hasUploadImage, hasActiveLink)}
        onChange={(ev, editor) => this.handleChange(editor)}
        disabled={this.props.disabled}
      />
    );
  }
}

WysiWygEditor.propTypes = {
  initialValue: PropTypes.string,
  /** placeHolder prop of type string as initial editor data */
  placeHolder: PropTypes.string,
  /** bindValueChange prop of type function for binding change of editor value to parent component */
  bindValueChange: PropTypes.func.isRequired,
  hasUploadImage: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default WysiWygEditor;
