import gql from 'graphql-tag';

export const GetCourseQuery = gql`
  query getCourse($id: ID!, $courseModuleOnly: Boolean = false) {
    getCourse(id: $id) {
      id
      index
      name @skip(if: $courseModuleOnly)
      description @skip(if: $courseModuleOnly)
      objectives @skip(if: $courseModuleOnly)
      status @skip(if: $courseModuleOnly)
      content_lang @skip(if: $courseModuleOnly)
      points @skip(if: $courseModuleOnly)
      semester @skip(if: $courseModuleOnly)
      is_deci @skip(if: $courseModuleOnly)
      image @skip(if: $courseModuleOnly) {
        url
      }
      age_groups @skip(if: $courseModuleOnly) {
        name
        id
      }
      grades @skip(if: $courseModuleOnly) {
        id
        name
      }
      roles @skip(if: $courseModuleOnly) {
        id
        name
      }
      certificates @skip(if: $courseModuleOnly) {
        id
      }
      category @skip(if: $courseModuleOnly) {
        id
        name
      }
      tags @skip(if: $courseModuleOnly) {
        id
        name
      }
      providers @skip(if: $courseModuleOnly) {
        id
        name
      }
      duration @skip(if: $courseModuleOnly)
      modules {
        id
        name
        course_id
        type
        description
        progress @skip(if: $courseModuleOnly) {
          percentage
        }
        description
        lessons {
          id
          name
          type
          progress {
            percentage
          }
          description
          units {
            id
            progress {
              percentage
            }
            name
            type
            content {
              type
              id
              body
              media {
                url
              }
            }
            workbook {
              id
              type
              estimated_time
              passing_grade
              questions {
                id
                body
                type
                answers {
                  id
                  body
                }
                right_answer {
                  id
                  body
                }
                right_answer_id
                right_explanation
                wrong_explanation
              }
            }
          }
        }
      }
      assessments {
        id
        estimated_time
        passing_grade
        number_of_trials
        type
        questions {
          id
          type
          body
          answers {
            id
            body
          }
          right_answer {
            id
            body
          }
          right_answer_id
          right_explanation
          wrong_explanation
        }
      }
    }
  }
`;
