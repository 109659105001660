import numeral from 'numeral';

export function getNumberReadableValue(number) {
  if (!number) {
    return undefined;
  }
  return numeral(number).format('0.[00]a');
}

/** get the percentage of the number according to the total number supplied  */
export function getPercentage(number, totalNumber) {
  return Math.round((number / totalNumber) * 100);
}

export const analyticsTabs = {
  courses: 'courses',
  users: 'users',
  providers: 'providers',
};

export const userRoles = {
  parent: 'PARENT',
  teacher: 'TEACHER',
  student: 'STUDENT',
  child: 'CHILD',
  teacher_parent: 'TEACHER_PARENT',
};

export const selectedRoleOptions = [
  {
    label: 'parent',
    value: 'PARENT',
  },
  {
    label: 'teacher',
    value: 'TEACHER',
  },
  {
    label: 'student',
    value: 'STUDENT',
  },
  {
    label: 'child',
    value: 'CHILD',
  },
  {
    label: 'teacher_parent',
    value: 'TEACHER_PARENT',
  },
];
