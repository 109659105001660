export const adminSideListData = [
  {
    title: 'change password',
    path: '/change-password',
  },
  {
    title: 'analytics',
    path: '/analytics',
  },
  {
    title: 'Live sessions',
    path: '/live-sessions',
  },
  {
    title: 'users',
    subMenu: [
      { title: 'Active Users', path: '/users/active' },
      { title: 'Blocked Users', path: '/users/blocked' },
      { title: 'Unverified Users', path: '/users/unverified' },
    ],
  },
  {
    title: 'journeys',
    path: '/journeys',
  },
  {
    title: 'admin dashboard',
    path: '/admin-dashboard',
  },
  // {
  //   title: '3rd party content',
  //   path: '/3rd-party-content',
  // },
  {
    title: 'competitions',
    path: '/competitions',
  },
  // {
  //   title: 'discussions',
  //   subMenu: [
  //     { title: 'Discussions list', path: '/discussions/list' },
  //     // { title: 'Reported ', path: '/discussions/reported' },
  //   ],
  // },
  {
    title: 'providers',
    path: '/providers',
  },
  {
    title: 'courses',
    path: '/courses',
  },
  {
    title: 'course categories',
    path: '/categories',
  },
  {
    title: 'Navbar Sections',
    path: '/navbar-sections',
  },
  {
    title: 'Notifications Center',
    path: '',
    subMenu: [
      {
        title: 'Manual Notifications',
        path: '/manual-messages',
      },
      {
        title: 'Automatic Notification',
        path: '/notifications-automatic',
      },
    ],
  },
  {
    title: 'Go to CRG',
    path: '/cgr',
  },
  // {
  //   title: 'Ongoing Jobs',
  //   path: '/ongoing-jobs',
  // },
  // {
  //   title: 'Comments',
  //   path: '/comments',
  // },
  // {
  //   title: 'Points System',
  //   path: '/points-system',
  // },
];

export const providerAdminSideListData = [
  {
    title: 'change password',
    path: '/change-password',
  },
  {
    title: 'courses',
    path: '/courses',
  },
];
