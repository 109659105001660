import { useQuery } from '@apollo/client';
import { listAllProvidersQuery } from '@services';

export const useProviderList = () => {
  const { error, loading, data } = useQuery(listAllProvidersQuery, {
    fetchPolicy: 'cache-and-network',
  });

  const providersList = data?.getCreateAdminPrerequisites?.providers || [];

  return { providersList, error, loading };
};
