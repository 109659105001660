import gql from 'graphql-tag';

export const LIST_USERS = gql`
  query listUsers($user: UserFilters, $first: Int!, $page: Int) {
    listUsers(user: $user, first: $first, page: $page) {
      paginatorInfo {
        count
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
        currentPage
      }
      data {
        id
        firstName: first_name
        lastName: last_name
        phone
        gender
        enrolled_courses_count
        grade {
          id
          name
        }
        roles {
          id
          name
        }
        age_group {
          id
          name
        }
      }
    }
  }
`;
