import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Upload, message, Button, Icon, Modal, Spin } from 'antd';
import validationRules from './questions_validations';
import { useMutation } from '@apollo/client';
import { questionsBulkUpload } from '@services';
import styled from '@emotion/styled/macro';
import { en as strings } from '../locales/data.json';

const BulkUploadButton = styled.button`
  margin: 10px 0;
  display: block;
`;
const Error = styled.h4`
  color: #f00;
`;
let uploaderFileListRef = null;

const QuestionsUpload = props => {
  const { assessment_id, onUploadCallback } = props;
  const { Item } = Form;
  const { questionsFileValidation } = validationRules;
  const [validations, setValidations] = useState([]);

  const {
    form: { getFieldDecorator, validateFields, resetFields },
  } = props;
  const [modalVisibility, setModalVisibility] = useState(false);

  const [uploadQuestions, { error, loading }] = useMutation(
    questionsBulkUpload,
    {
      onCompleted: response => {
        /** close modal after the questions file finishes uploading */
        resetFields();
        uploaderFileListRef.shift();
        if (response?.importAssessmentQuestions?.validations?.length > 0) {
          setValidations(response?.importAssessmentQuestions?.validations);
        } else {
          setModalVisibility(false);
          setValidations([]);
        }
      },
    }
  );

  const handleSubmit = ev => {
    ev.preventDefault();
    validateFields((error, values) => {
      if (!error) {
        uploadQuestions({
          variables: {
            content: { file: values.file, assessment_id: assessment_id },
          },
        }).then(response => {
          if (response) {
            onUploadCallback(
              response?.data?.importAssessmentQuestions?.assessment?.questions
            );
          }
        });
      }
    });
  };

  /** handle change in upload file field */
  const onChange = info => {
    switch (info.file.status) {
      case 'uploading':
        message.info(`uploading ${info.file.name} file ....`);
        break;
      case 'done':
        message.success(`${info.file.name} file uploaded successfully`);
        break;
      case 'error':
        message.error(`${info.file.name} file upload failed.`);
        break;
      default:
        console.log('Unknown Status');
    }
  };

  /** to disable direct upload after selecting file */
  const beforeUpload = file => {
    return false;
  };

  /** pass value to field from upload event */
  const normFile = e => {
    uploaderFileListRef = e.fileList;
    if (Array.isArray(e)) {
      return e;
    }
    if (e.fileList.length > 1) {
      e.fileList.shift();
    }
    return e && e.file;
  };

  return (
    <>
      <BulkUploadButton
        onClick={() => setModalVisibility(true)}
        data-cy="openUploadQuestionsModal"
        className="bulkUpload"
      >
        {strings.uploadQuestionsButtonText}
      </BulkUploadButton>
      <Modal
        visible={modalVisibility}
        onCancel={() => setModalVisibility(false)}
        footer={null}
      >
        <Form onSubmit={handleSubmit}>
          <Item required={true}>
            {getFieldDecorator('file', {
              rules: questionsFileValidation,
              initialValue: '',
              getValueFromEvent: normFile,
            })(
              <Upload
                name="file"
                onChange={onChange}
                beforeUpload={beforeUpload}
                accept=".xls, .xlsx, .csv"
              >
                <Button data-cy="uploadAssessmentQuestions">
                  <Icon type="upload" />
                  {strings.uploadFileButtonText}
                </Button>
              </Upload>
            )}
          </Item>
          <Item>
            <Button
              type="primary"
              htmlType="submit"
              data-cy="submitUploadedQuestions"
            >
              {strings.submitButtonText}
            </Button>
          </Item>
        </Form>

        {!error && loading && <Spin />}
        {validations?.length > 0 &&
          validations.map((validation, index) => {
            return (
              <Error key={index}>
                Error in row #{validation?.row_number} :{validation?.errors}
              </Error>
            );
          })}
        {!loading && error && (
          <p>
            {error.message}
            {!!error.graphQLErrors?.[0]?.errors && (
              <ul>
                {Object.values(error.graphQLErrors[0].errors).map(
                  (err, index) => (
                    <li key={`sub-errors-${index}`}>{err}</li>
                  )
                )}
              </ul>
            )}
          </p>
        )}
      </Modal>
    </>
  );
};

QuestionsUpload.propTypes = {
  /** ant d form methods passed from parent component or (form.create() function) */
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFields: PropTypes.func,
    resetFields: PropTypes.func,
  }).isRequired,
  /** questionableId prop of type string for current updating model(assessment/competition) if exists */
  questionableId: PropTypes.string.isRequired,
  /** listQuestion prop of type function for requesting questionsList based on model(assessment/competition) id */
  listQuestions: PropTypes.func.isRequired,
};

export default Form.create()(QuestionsUpload);
