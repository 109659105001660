import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'antd';

/** custom styling for maximized modal */
const maximizedModalStyles = {
  height: '80vh',
};

const CustomModal = ({
  title,
  visible,
  onOkText,
  onCancelText,
  onOk,
  onCancel,
  children,
  hideOnCancel,
  hideOnOk,
  disableOk,
  submitButtonCyDataAttr,
}) => {
  /** create modal footer */
  const renderFooter = () => {
    let footer = [];
    const onCancelButton = (
      <Button key="back" onClick={onCancel}>
        {onCancelText}
      </Button>
    );
    const onOkButton = (
      <Button
        key="submit"
        type="primary"
        onClick={onOk}
        disabled={disableOk}
        data-cy={submitButtonCyDataAttr}
      >
        {onOkText}
      </Button>
    );
    !hideOnCancel && footer.push(onCancelButton);
    !hideOnOk && footer.push(onOkButton);

    return footer;
  };

  return (
    <Modal
      title={title}
      width="80vw"
      style={maximizedModalStyles}
      centered
      visible={visible}
      onCancel={onCancel}
      footer={renderFooter()}
    >
      {children}
    </Modal>
  );
};

CustomModal.propTypes = {
  /** title prop of type string for modal header title */
  title: PropTypes.string,
  /** visible prop of type boolean for setting modal visibility */
  visible: PropTypes.bool,
  /** onOk prop of type function for handling submit action in modal  */
  onOk: PropTypes.func,
  /** onOkText prop of type string for on ok button text */
  onOkText: PropTypes.string,
  /** onCancelText prop of type string for on cancel button text */
  onCancelText: PropTypes.string,
  /** onCancel Prop of type function for handling closing modal */
  onCancel: PropTypes.func,
  /** children prop of type array for rendered elements in modal */
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  /** hideOnCancel prop of type boolean for hiding on cancel button  */
  hideOnCancel: PropTypes.bool,
  /** hideOnOk prop of type boolean for hiding on ok button  */
  hideOnOk: PropTypes.bool,
  /** disableOk prop of type boolean for disabling ok button in modal state */
  disableOk: PropTypes.bool,
  /** submitButtonCyDataAttr prop of type string for cypress helper data attr */
  submitButtonCyDataAttr: PropTypes.string,
};

export default CustomModal;
