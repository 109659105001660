import { UploadAdapter } from './upload_adapter';

function CustomUploadAdapterPlugin(editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = loader => {
    return new UploadAdapter(loader);
  };
}

/** custom configuration for ckeditor wyziwyg */
const config = (placeHolder, hasUploadImage, hasActiveLink) => {
  const configuration = {
    fontColor: {
      colors: [
        {
          color: 'hsl(0, 0%, 0%)',
          label: 'Black',
        },
        {
          color: 'hsl(0, 0%, 30%)',
          label: 'Dim grey',
        },
        {
          color: 'hsl(0, 0%, 60%)',
          label: 'Grey',
        },
        {
          color: 'hsl(0, 0%, 90%)',
          label: 'Light grey',
        },
        {
          color: 'hsl(0, 0%, 100%)',
          label: 'White',
          hasBorder: true,
        },
      ],
    },
    fontSize: {
      options: ['tiny', 'default', 'big'],
    },
    toolbar: [
      'heading',
      '|',
      'bold',
      'italic',
      'underline',
      'strikethrough',
      'fontSize',
      'fontColor',
      'bulletedList',
      'numberedList',
      '|',
      'undo',
      'redo',
    ],
    disallowedContent: 'script',
    placeholder: placeHolder,
    link: {
      addTargetToExternalLinks: true,
    },
  };

  if (hasActiveLink) {
    configuration.extraPlugins = [CustomUploadAdapterPlugin];
    configuration.toolbar.splice(
      configuration.toolbar.indexOf('numberedList') + 1,
      0,
      'link'
    );
  }
  if (hasUploadImage) {
    configuration.extraPlugins = [CustomUploadAdapterPlugin];
    configuration.toolbar.splice(
      configuration.toolbar.indexOf('numberedList') + 1,
      0,
      'imageUpload'
    );
  }

  return configuration;
};

export default config;
