import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Layout, Menu } from 'antd';
import { ImportOutlined } from '@ant-design/icons';
import styled from '@emotion/styled/macro';
import HeaderLogo from './logo';

const { Sider } = Layout;
const { SubMenu, Item } = Menu;

/** custom style for layout sider */
const CustomSider = styled(Sider)`
  text-align: center;
  background-color: #fff;
  font-size: 18px;
  li {
    text-transform: capitalize;
  }
`;

const CRGContainer = styled.div`
  position: absolute;
  bottom: 0;
  background: #fff;
  width: 100%;
  padding: 10px 0;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
`;

/** constructing data of sidebar list */
const constructListData = list => {
  let dataList = [];
  for (var i = 0; i < list.length; i++) {
    if (list[i].subMenu) {
      dataList.push(
        <SubMenu key={i} title={list[i].title}>
          {list[i].subMenu.map((item, index) => (
            <Item key={index} disabled={item.disabled}>
              <Link to={item.path}>{item.title}</Link>
            </Item>
          ))}
        </SubMenu>
      );
    } else {
      dataList.push(
        <Item key={i} disabled={list[i].disabled}>
          <Link
            target={list[i].path === '/cgr' ? '_blank' : '_self'}
            to={list[i].path}
          >
            {list[i].path === '/cgr' ? (
              <>
                <ImportOutlined /> Go to CRG
              </>
            ) : (
              list[i].title
            )}
          </Link>
        </Item>
      );
    }
  }
  return dataList;
};

/** main layout sider bar */
const LayoutSider = ({ list }) => {
  return (
    <CustomSider
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
      }}
    >
      <HeaderLogo />
      <Menu>{constructListData(list)}</Menu>
    </CustomSider>
  );
};

LayoutSider.propTypes = {
  /** list prop of type array for side list data */
  list: PropTypes.array,
};

export default LayoutSider;
