import gql from 'graphql-tag';

export const REJECT_COURSE = gql`
  mutation rejectAndUnpublish($courseId: Int!) {
    rejectAndUnpublish(courseId: $courseId) {
      course {
        id
        status
      }
      errors {
        fieldName
        errors
      }
      statusCode
    }
  }
`;
