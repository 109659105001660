import gql from 'graphql-tag';

export const GetLiveSessionQuery = gql`
  query viewLiveSession($id: ID!) {
    viewLiveSession(id: $id) {
      id
      title
      description
      image {
        url
      }
      started_at
      ended_at
      link
      roles {
        id
        name
      }
      age_groups {
        id
        name
      }
      grades {
        id
        name
      }
      is_published
      recommended_age
    }
  }
`;
