import gql from 'graphql-tag';

export const CreateUserMutation = gql`
  mutation createUser(
    $first_name: String!
    $last_name: String!
    $gender: GenderEnum
    $phone: String!
    $grade_id: ID
    $age_group_id: ID
    $country: CountryEnum
    $roles: [UserRole!]!
  ) {
    createUser(
      first_name: $first_name
      last_name: $last_name
      gender: $gender
      phone: $phone
      grade_id: $grade_id
      age_group_id: $age_group_id
      country: $country
      roles: $roles
    ) {
      statusCode
      message
      errors {
        field_name
        message
      }
      user {
        id
      }
    }
  }
`;
