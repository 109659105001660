import gql from 'graphql-tag';

export const UPDATE_ADMIN_NOTIFICATION_STATUS = gql`
  mutation updateNotificationsStatus(
    $notificationIds: [ID!]!
    $status: Boolean!
  ) {
    updateNotificationsStatus(
      notificationIds: $notificationIds
      status: $status
    ) {
      errors {
        errors
        fieldName
      }
      statusCode
    }
  }
`;
