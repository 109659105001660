/**
 * @author Ahmed Serag
 * @date 2021-07-02
 * @description Implementation of Analytics context that
 * holds meta info about the current analytics.
 * @filename analytics-context.ts
 */

import React from 'react';

/**
 * Menu context that holds active branch's menu.
 */
export const AnalyticsContext = React.createContext({
  currentPeriod: null,
  updateCurrentPeriod: null,
  activeAnalyticsTab: null,
  setActiveAnalyticsTab: null,
  // comparedPeriod: null,
  // updateComparedPeriod: null,
});
