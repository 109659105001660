import { useQuery } from '@apollo/client';
import { PAGINATION_PAGE_SIZE } from '@constants';
import { COURSES_LIST } from '@services';
import { useCallback } from 'react';

export const useCourses = () => {
  const { loading, error, data, refetch } = useQuery(COURSES_LIST, {
    variables: { first: PAGINATION_PAGE_SIZE },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const courses = data?.getCourses?.data || [];
  const pageInfo = data?.getCourses?.paginatorInfo || {};

  const refetchPageResults = useCallback(async ({ first, page }) => {
    await refetch({ first, page });
  }, []);

  return { courses, pageInfo, loading, error, refetchPageResults };
};
