import React from 'react';
import { Icon, Button } from 'antd';
import { en as strings } from './locales/data.json';
import styled from '@emotion/styled/macro';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 1rem;
`;

/**
 * handling render questions table row's action menu
 *
 * @param {string} questionId
 * @param {func} fetchQuestionInfo
 * @param {func} deleteQuestion
 * @param {func} disableMenu
 */
const ActionMenu = ({
  questionId,
  answerId,
  editMatchQuestion,
  deleteMatchQuestion,
}) => {
  const buttonSize = 'small';

  return (
    <Wrapper>
      <Button
        key={`${questionId}${answerId}-edit`}
        onClick={() => {
          editMatchQuestion(questionId, answerId);
        }}
        size={buttonSize}
      >
        <Icon type="edit" />
        {strings.edit}
      </Button>

      <Button
        key={`${questionId}${answerId}-delete`}
        onClick={() => {
          deleteMatchQuestion(questionId, answerId);
        }}
        size={buttonSize}
      >
        <Icon type="delete" />
        {strings.delete}
      </Button>
    </Wrapper>
  );
};

ActionMenu.propTypes = {
  questionId: PropTypes.number.isRequired,
  answerId: PropTypes.number.isRequired,
  editMatchQuestion: PropTypes.func.isRequired,
  deleteMatchQuestion: PropTypes.func.isRequired,
};

export default ActionMenu;
