import { useQuery } from '@apollo/client';
import { LiveSessionsListQuery } from '@services';
import { useCallback } from 'react';

export const useLiveSessionsList = PAGINATION_PAGE_SIZE => {
  const { loading, error, data, refetch, networkStatus } = useQuery(
    LiveSessionsListQuery,
    {
      variables: {
        first: PAGINATION_PAGE_SIZE,
        page: 1,
      },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    }
  );

  const liveSessions = data?.listLiveSessions?.data || [];
  const pageInfo = data?.listLiveSessions?.paginatorInfo || {};

  const refetchPageResults = useCallback(
    async ({ first, page, LiveSession }) => {
      await refetch({ first, page, LiveSession });
    },
    []
  );

  return {
    liveSessions,
    pageInfo,
    loading,
    error,
    networkStatus,
    refetchPageResults,
  };
};
