import gql from 'graphql-tag';

export const BLOCK_USER = gql`
  mutation adminBlockUser($userId: ID!, $reason: String!) {
    adminBlockUser(userId: $userId, reason: $reason) {
      statusCode
      errors {
        errors
        fieldName
      }
    }
  }
`;
