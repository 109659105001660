import React from 'react';
import { Redirect } from 'react-router-dom';
import { DynamicLoading } from '@components';
import { AuthConsumer, Can } from '@auth';
import { ACTIONS } from '../../rbac_rules';

/** exported dynamic import for new course scene */
const ThirdPartyContent = props => {
  return (
    <AuthConsumer>
      {({ role }) => (
        <Can
          role={role}
          perform={ACTIONS.SHOW_THIRD_PARTY_CONTENT}
          yes={() => (
            <DynamicLoading
              componentImport={() => import('./third_party_content_list')}
              data={{ ...props }}
            />
          )}
          no={() => {
            return <Redirect to="/" />;
          }}
        />
      )}
    </AuthConsumer>
  );
};

export default ThirdPartyContent;
