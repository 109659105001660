import styled from '@emotion/styled/macro';

export const InputWrapper = styled.div`
  margin-bottom: 20px;

  label {
    margin-bottom: 10px;
    display: block;
    font-weight: 500;
  }

  input {
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    background-color: #fff;
    min-height: 40px;
    width: 50%;
    padding: 12px;
    display: block;
  }

  span.required {
    color: red;
    ::before {
      display: none;
    }
  }
`;

export const SubmitButton = styled.button`
  color: #fff;
  background-color: #1890ff;
  border-color: #1890ff;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  line-height: 1.4;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  height: 32px;
  padding: 0 15px;
  font-size: 14px;
  border-radius: 4px;
  outline: 0;
  border: 0;
  cursor: pointer;
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  :hover,
  :focus {
    color: #fff;
    background-color: #40a9ff;
    border-color: #40a9ff;
  }
`;
