import styled from '@emotion/styled/macro';

export const CourseContentNavContent = styled.div`
  border: 1px solid #c4c4c4;
  min-width: 200px;
  border-radius: 5px;
  background-color: #fff;

  h2 {
    color: #4a4a4a;
    font-family: Roboto;
    font-size: 16px;
    font-weight: bold;
    background: #90baec;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 15px;
    margin: 0;
  }

  ul.menu {
    list-style: none;
    margin: 0;
    padding: 0;
    background: #fff;

    > li {
      padding: 25px 30px;
      padding-right: auto;
      font-family: Roboto;
      font-size: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      .anticon {
        margin-left: 15px;
      }

      svg {
        width: 20px;
        height: 20px;
      }
    }

    > li.active {
      font-weight: bold;
      background-color: #c4d9f2;
    }

    > li:not(:last-child) {
      border-bottom: 1px solid #c4c4c4;
    }

    > li:last-child {
      margin-bottom: 2px;
    }
  }

  ul.sub-menu {
    list-style: none;
    padding-left: 60px;
    margin: 0;
    border-bottom: 1px solid #c4c4c4;

    li {
      font-size: 14px;
      font-weight: 300;
      padding-bottom: 30px;
      cursor: pointer;
    }

    li.active {
      font-weight: bold;
    }

    li:first-of-type {
      padding-top: 30px;
    }
  }
`;
