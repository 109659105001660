import React from 'react';
import { Menu, Icon } from 'antd';
import { en as strings } from '../locales/data.json';

/**
 * handling render questions table row's action menu
 *
 * @param {string} questionId
 * @param {func} fetchQuestionInfo
 * @param {func} deleteQuestion
 * @param {func} disableMenu
 */
const renderActionsMenu = (
  questionId,
  fetchQuestionInfo,
  deleteQuestion,
  disableMenu
) => (
  <Menu>
    <Menu.Item
      key={`${questionId}-edit`}
      disabled={disableMenu}
      onClick={() => {
        fetchQuestionInfo({ variables: { questionId } });
      }}
    >
      <Icon type="edit" />
      {strings.edit}
    </Menu.Item>

    <Menu.Item
      key={`${questionId}-delete`}
      disabled={disableMenu}
      onClick={() => {
        deleteQuestion({ variables: { questionId } });
      }}
    >
      <Icon type="delete" />
      {strings.delete}
    </Menu.Item>
  </Menu>
);

export default renderActionsMenu;
