import { useQuery } from '@apollo/client';
import { ListAdminsQuery } from '@services';
import { useCallback } from 'react';

export const useAdminsList = PAGINATION_PAGE_SIZE => {
  const { data, loading, error, refetch } = useQuery(ListAdminsQuery, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: { first: PAGINATION_PAGE_SIZE, page: 1 },
  });

  const adminList = data?.listAdmins?.data || [];
  const pageInfo = data?.listAdmins?.paginatorInfo || {};

  const refetchPageResults = useCallback(async ({ first, page }) => {
    await refetch({ first, page });
  }, []);

  return { adminList, pageInfo, loading, error, refetchPageResults };
};
