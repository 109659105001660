/**
 * modify table sort order key
 *
 * @param {string} order
 */
export const parseTableSortOrder = order => {
  return order === 'ascend' ? 'asc' : 'desc';
};

/**
 * modify table key from camel case to snake case
 *
 * @param {string} key
 */
export const parseTableKey = key => {
  return key
    .split(/(?=[A-Z])/)
    .join('_')
    .toLocaleLowerCase();
};
