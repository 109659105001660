import React, { useRef, useEffect, useState } from 'react';
import { getLineChart, getChartRange } from '@utilities/charts';
import Proptypes from 'prop-types';

const LineChartCard = ({
  label,
  period,
  values,
  backgroundColor,
  borderColor,
}) => {
  const canvasRef = useRef();
  const [chart, setChart] = useState(undefined);

  useEffect(() => {
    if (chart) {
      chart.destroy();
    }
    let dataset = {};
    if (period?.type === 'year') {
      const mapDayToMonth = values.map(value => ({
        ...value,
        day: new Date(value.date).getMonth(),
      }));
      const sumPerMonth = mapDayToMonth.reduce((acc, cur) => {
        acc[cur.day] = acc[cur.day] + cur.count || cur.count; // increment or initialize to cur.value
        return acc;
      }, []);

      dataset = {
        //line chart values --> to be drawn
        values: sumPerMonth,
        backgroundColor,
        borderColor,
      };
    } else {
      dataset = {
        //line chart values --> to be drawn
        values,
        backgroundColor,
        borderColor,
      };
    }
    setChart(getLineChart(canvasRef.current, getChartRange(period), [dataset]));
  }, [period, values, backgroundColor, borderColor]);
  return (
    <div className="line-chart-card">
      <h1 className="label">{label}</h1>
      <canvas ref={canvasRef} id={Date.now()} />
    </div>
  );
};

LineChartCard.propTypes = {
  label: Proptypes.string,
  period: Proptypes.shape({
    type: Proptypes.string,
    alias: Proptypes.string,
    from: Proptypes.string,
    to: Proptypes.string,
  }),
  values: Proptypes.arrayOf(Proptypes.number),
  backgroundColor: Proptypes.string,
  borderColor: Proptypes.string,
};
export default LineChartCard;
