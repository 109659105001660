import { useQuery } from '@apollo/client';
import { getStudentGrades } from '@services';

export const useStudentGrades = () => {
  const { error, loading, data } = useQuery(getStudentGrades, {
    fetchPolicy: 'cache-and-network',
  });

  const studentGrades = data;

  return { studentGrades, error, loading };
};
