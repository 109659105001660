import React from 'react';
import { Tag, Dropdown, Icon, notification } from 'antd';
import renderActionsMenu from './actions_menu';
import { useMutation } from '@apollo/client';
import { DELETE_QUESTION } from '@services';
import { en as strings } from '../locales/data.json';

/**
 * setting question table questions
 *
 * @param {func} fetchQuestionInfo
 * @param {func} deleteQuestion
 * @param {func} disableMenu
 */
const questionsTableColumns = (
  fetchQuestionInfo,
  deleteQuestion,
  disableMenu
) => {
  const [deleteQuestionMutation] = useMutation(DELETE_QUESTION, {
    onError: () => {
      notification.error({
        message: strings.notifications.deleteQuestion.title,
        description: strings.notifications.deleteQuestion.error,
      });
    },
    onCompleted: ({ deleteQuestion: { errors } }) => {
      if (errors)
        notification.error({
          message: strings.notifications.deleteQuestion.title,
          description: errors[0].errors[0],
        });
      else
        notification.success({
          message: strings.notifications.deleteQuestion.title,
          description: strings.notifications.deleteQuestion.success,
        });
    },
  });

  /**
   * truncate table data string
   *
   * @param {String} str
   */
  const truncateDataStr = str => {
    return str && str.length > 30 ? `${str.substring(0, 30)} ...` : str;
  };

  return [
    {
      title: 'Question Type',
      dataIndex: 'questionType',
      key: 'questionType',
    },
    {
      title: 'Question Body',
      dataIndex: 'body',
      key: 'body',
      render: body => <span>{truncateDataStr(body)}</span>,
    },
    {
      title: 'Answers',
      dataIndex: 'answers',
      key: 'answers',
      render: answers => (
        <span>
          {answers.map(answer => {
            return (
              <Tag color="red" key={answer.id}>
                {truncateDataStr(answer.body)}
              </Tag>
            );
          })}
        </span>
      ),
    },
    {
      title: 'Right Answer',
      dataIndex: 'rightAnswer',
      key: 'rightAnswer',
      render: answer => (
        <span>
          <Tag color="green" key={answer.id}>
            {truncateDataStr(answer.body)}
          </Tag>
        </span>
      ),
    },
    {
      title: 'Right Explanation',
      dataIndex: 'rightExplanation',
      key: 'rightExplanation',
      render: rightExplanation => (
        <span>{truncateDataStr(rightExplanation)}</span>
      ),
    },
    {
      title: 'Wrong Explanation',
      dataIndex: 'wrongExplanation',
      key: 'wrongExplanation',
      render: wrongExplanation => (
        <span>{truncateDataStr(wrongExplanation)}</span>
      ),
    },
    {
      title: '',
      dataIndex: 'menu',
      key: 'menu',
      render: id => (
        <Dropdown
          overlay={renderActionsMenu(
            id,
            fetchQuestionInfo,
            deleteQuestion.bind(this, deleteQuestionMutation),
            disableMenu
          )}
          trigger={['click']}
        >
          <a className="ant-dropdown-link" href="/">
            <Icon type="dash" />
          </a>
        </Dropdown>
      ),
    },
  ];
};

export default questionsTableColumns;
