/**
 * @Author: sarahnouh
 * @Date:   2020-01-13T14:09:41+02:00
 * @Last modified by:   sarahnouh
 * @Last modified time: 2020-01-14T12:42:48+02:00
 */

import gql from 'graphql-tag';

export const ChangePasswordMutation = gql`
  mutation changePassword(
    $oldPassword: String!
    $newPassword: String!
    $newPasswordConfirmation: String!
  ) {
    adminChangePassword(
      oldPassword: $oldPassword
      newPassword: $newPassword
      newPasswordConfirmation: $newPasswordConfirmation
    ) {
      errors {
        errors
      }
      statusCode
    }
  }
`;
