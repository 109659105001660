import React, { useEffect } from 'react';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import questionsTableColumns from './table_columns';

/**
 * parse question type column title
 *
 * @param {string} type
 */
const parseQuestionType = type => {
  const questionTypes = {
    mcq: 'MCQ',
    true_false: 'True or False',
    essay: 'Essay',
    match: 'Match',
  };

  return questionTypes[type];
};

const QuestionsList = ({
  questionableId,
  fetchQuestionInfo,
  disableMenu,
  deleteQuestion,
  listQuestions,
  questions,
}) => {
  useEffect(() => {
    listQuestions({ variables: { questionableId } });
  }, [questionableId]);

  const dataSource = questions.map(question => {
    return {
      key: question.id,
      questionType: parseQuestionType(question.questionType),
      body: question.body,
      answers: question.answers,
      rightAnswer: question.rightAnswer,
      rightExplanation: question.rightExplanation,
      wrongExplanation: question.wrongExplanation,
      menu: question.id,
    };
  });

  const columns = questionsTableColumns(
    fetchQuestionInfo,
    deleteQuestion,
    disableMenu
  );

  return <Table columns={columns} dataSource={dataSource} />;
};

QuestionsList.propTypes = {
  /** fetchQuestionInfo prop of type function to be called in parent component for fetching
   * question data base on question id
   */
  fetchQuestionInfo: PropTypes.func.isRequired,
  /** disableMenu prop of type function for disabling question menu edit/delete
   *  while creating or deleting question in question form component
   */
  disableMenu: PropTypes.bool.isRequired,
  /** questionableId prop of type string for current updating assessment if exists */
  questionableId: PropTypes.string.isRequired,
  listQuestions: PropTypes.func.isRequired,
  deleteQuestion: PropTypes.func.isRequired,

  questions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      questionType: PropTypes.string,
      body: PropTypes.string,

      answers: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          body: PropTypes.string,
        })
      ),

      rightAnswer: PropTypes.shape({
        id: PropTypes.string,
        body: PropTypes.string,
      }),

      rightExplanation: PropTypes.string,
      wrongExplanation: PropTypes.string,
    })
  ).isRequired,
};

export default QuestionsList;
