import gql from 'graphql-tag';

export const GetNotification = gql`
  query Notification($notificationId: ID!) {
    notification(notificationId: $notificationId) {
      id
      content
      channelType
      notificationType
      scheduledAt
      sendingInterval
      title
      criteria {
        age
        gender
        persona
        courses {
          id
          name
        }
      }
    }
  }
`;
