import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { QuestionsList } from '@components';
import QuestionsUpload from './components/questions_upload';
import QuestionsForm from './components/question_form';
import { en as strings } from './locales/data.json';

const ListTitle = styled.p`
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 20px;
  margin-top: 20px;

  &:first-of-type {
    font-weight: 700;
  }
`;

const Questions = ({
  questionableId,
  listingLoading,
  listQuestions,
  questions,
  editFormData,
  deleteQuestion,
  questionInfoLoading,
  setEditFormData,
  setDisableMenu,
  fetchQuestionInfo,
  disableMenu,
  setQuestionEditedId,
}) => {
  return (
    <>
      <ListTitle>{strings.mainSectionTitle}</ListTitle>
      <QuestionsUpload
        listQuestions={listQuestions}
        questionableId={questionableId}
      />
      <ListTitle>{strings.addQuestionSectionTitle}</ListTitle>
      <QuestionsForm
        questionableId={questionableId}
        listQuestions={listQuestions}
        listingLoading={listingLoading}
        editFormData={editFormData}
        questionInfoLoading={questionInfoLoading}
        setEditFormData={setEditFormData}
        setDisableMenu={setDisableMenu}
        setQuestionEditedId={setQuestionEditedId}
      />
      <QuestionsList
        fetchQuestionInfo={fetchQuestionInfo}
        disableMenu={disableMenu}
        listQuestions={listQuestions}
        deleteQuestion={deleteQuestion}
        questionableId={questionableId}
        questions={questions}
      />
    </>
  );
};

Questions.propTypes = {
  questionableId: PropTypes.string.isRequired,
  editFormData: PropTypes.object.isRequired,
  questionInfoLoading: PropTypes.bool.isRequired,
  setEditFormData: PropTypes.func.isRequired,
  setDisableMenu: PropTypes.func.isRequired,
  fetchQuestionInfo: PropTypes.func.isRequired,
  disableMenu: PropTypes.bool.isRequired,

  listingLoading: PropTypes.bool.isRequired,
  listQuestions: PropTypes.func.isRequired,
  deleteQuestion: PropTypes.func.isRequired,
  setQuestionEditedId: PropTypes.func,

  questions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      questionType: PropTypes.string,
      body: PropTypes.string,

      answers: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          body: PropTypes.string,
        })
      ),

      rightAnswer: PropTypes.shape({
        id: PropTypes.string,
        body: PropTypes.string,
      }),

      rightExplanation: PropTypes.string,
      wrongExplanation: PropTypes.string,
    })
  ).isRequired,
};

export default Questions;
