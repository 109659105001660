import * as Yup from 'yup';
import { SUPPORTED_FORMATS } from '@constants';

export const NEW_CATEGORY_SCHEMA = () => {
  return Yup.object().shape({
    name: Yup.string()
      .required('Category name is required')
      .max(50, 'Category Name must not exceed 50 characters!'),
    description: Yup.string().max(
      255,
      'Category description must not exceed 255 characters!'
    ),
    index: Yup.string().required('Category Order is required'),
    certificate_id: Yup.mixed().when('hasCertificate', {
      is: value => value === true,
      then: Yup.string().required('Certificate is required'),
    }),
    banner: Yup.mixed().when('is_featured', {
      is: value => value === true,
      then: Yup.mixed().when({
        is: value => !value?.url,
        then: Yup.mixed()
          .required('Banner is required')
          .test(
            'fileFormat',
            'Please choose .png, .jpg or .jpeg image format',
            value => value && SUPPORTED_FORMATS.includes(value?.type)
          )
          .test(
            'fileSize',
            'Banner max size is 1MB',
            value => value && value.size <= 1024 * 1024
          ),
      }),
    }),
    image: Yup.mixed().when({
      is: value => !value?.url,
      then: Yup.mixed()
        .required('Image is required.')
        .test(
          'fileSize',
          'Image max size is 1MB',
          value => value && value.size <= 1024 * 1024
        )
        .test(
          'fileFormat',
          'Please choose .png, .jpg or .jpeg image format',
          value => {
            if (!value?.type) return true;
            return SUPPORTED_FORMATS.includes(value?.type);
          }
        ),
    }),
    snippet_video: Yup.string().url('This field should be a valid URL'),
    snippet_thumbnail: Yup.mixed().when('snippet_video', {
      is: value => !!value,
      then: Yup.mixed().when({
        is: value => !value?.url,
        then: Yup.mixed()
          .required('This field is required when you provide a video URL')
          .test(
            'fileFormat',
            'Please choose .png, .jpg or .jpeg image format',
            value => value && SUPPORTED_FORMATS.includes(value?.type)
          )
          .test(
            'fileSize',
            'Thumbnail must have a max size of 1MB',
            value => value && value.size <= 1024 * 1024
          ),
      }),
    }),
    tags: Yup.array().of(Yup.string()),
  });
};

/**
 * return initialValues to be used by courseDetails form.
 *
 * @export
 * @param {Object} categoryDetails current course details to populate initialValues.
 * @return {Object} initialValues to be used by courseDetails form.
 */
export function getCategoryDetailsInitialValues(categoryDetails) {
  let initialValues = {
    name: '',
    index: '',
    image: null,
    banner: null,
    is_featured: false,
    educational_subject: false,
    certificate_id: '',
    hasCertificate: false,
    snippet_video: '',
    snippet_thumbnail: '',
    tags: [],
  };
  if (Object.keys(categoryDetails)?.length !== 0) {
    const image = { url: '' };
    const banner = { url: '' };
    const snippet_thumbnail = { url: '' };
    if (categoryDetails?.image?.url) {
      image.url = categoryDetails?.image?.url;
    }
    if (categoryDetails?.banner?.url) {
      banner.url = categoryDetails?.banner?.url;
    }
    if (categoryDetails?.snippet_thumbnail?.url) {
      snippet_thumbnail.url = categoryDetails?.snippet_thumbnail?.url;
    }
    initialValues = {
      name: categoryDetails.name || '',
      description: categoryDetails.description || '',
      index: categoryDetails?.index || '',
      hasCertificate: categoryDetails.certificates?.length > 0,
      certificate_id:
        categoryDetails.certificates?.length > 0
          ? parseInt(categoryDetails.certificates[0].id)
          : '',
      image,
      banner,
      is_featured: categoryDetails.is_featured
        ? categoryDetails.is_featured
        : false,
      educational_subject: categoryDetails.educational_subject
        ? categoryDetails.educational_subject
        : false,
      snippet_video: categoryDetails?.snippet_video?.url,
      snippet_thumbnail,
      tags: categoryDetails?.tags?.map(tag => tag?.id?.toString()) || [],
    };
  }

  return initialValues;
}
