import gql from 'graphql-tag';

export const DeleteThreadMutation = gql`
  mutation deleteThread($id: Int!) {
    deleteThread(threadId: $id) {
      errors {
        errors
        fieldName
      }
      statusCode
    }
  }
`;
