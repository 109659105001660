import gql from 'graphql-tag';

export const updateCourseItem = gql`
  mutation updateCourseItem($content: UpdateCourseItemInput!) {
    updateCourseItem(content: $content) {
      statusCode
      message
      course_item {
        id
        name
        type
        description
        children {
          type
          id
          name
          children {
            type
            id
          }
        }
      }
      errors {
        field_name
        message
      }
    }
  }
`;
