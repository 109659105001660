import gql from 'graphql-tag';

export const ListPoints = gql`
  query listCompetitionActions {
    listCompetitionActions {
      id
      name
      points
    }
  }
`;
