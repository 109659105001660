import gql from 'graphql-tag';

export const ListNotifications = gql`
  query listNotifications(
    $notification: NotificationFilters
    $first: Int!
    $page: Int
  ) {
    listNotifications(notification: $notification, first: $first, page: $page) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        id
        title
        type
        sending_type
        channel_type
        content
        roles {
          id
          name
        }
        age_groups {
          id
          name
        }
        scheduled_at
        sent_at
      }
    }
  }
`;
