import { useQuery } from '@apollo/client';
import { getAgeGroups } from '@services';

export const useAgeGroups = () => {
  const { error, loading, data } = useQuery(getAgeGroups, {
    fetchPolicy: 'cache-and-network',
  });

  const ageGroups = data;

  return { ageGroups, error, loading };
};
