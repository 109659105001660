import gql from 'graphql-tag';

export const EDIT_JOURNEY_DETAILS = gql`
  mutation updateJourney(
    $journeyId: ID!
    $updatedJourney: UpdateJourneyInput!
    $banner: File
  ) {
    updateJourney(id: $journeyId, journey: $updatedJourney, banner: $banner) {
      errors {
        errors
        fieldName
      }
      statusCode
    }
  }
`;
