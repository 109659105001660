import React from 'react';
import styled from '@emotion/styled/macro';
import TaleemyLogo from '../images/taleemy-logo.png';
import TaleemyLogo2X from '../images/taleemy-logo@2x.png';

/** custom style for layout header logo conatiner */
const LogoContainer = styled.div`
  min-height: 8vh;
  display: flex;
  max-width: 150px;
  padding: 10px;
  margin: 0 auto;
  img {
    width: 100%;
  }
`;

/** main layout header logo */
const HeaderLogo = () => {
  return (
    <LogoContainer className="logo">
      <a href="/">
        <img src={TaleemyLogo} srcSet={TaleemyLogo2X} alt="Taleemy Logo" />
      </a>
    </LogoContainer>
  );
};

export default HeaderLogo;
