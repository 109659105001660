import React, { useState, useEffect } from 'react';
import { Form, Input, Button } from 'antd';
import PropTypes from 'prop-types';
import { en as strings } from './locales/data.json';

const itemStyle = {
  textTransform: 'capitalize',
};

const MatchCreateOption = ({ value, type, listName, setOption }) => {
  const [optionValue, setOptionValue] = useState(value);

  const onChange = ({ target: { value } }) => setOptionValue(value);

  useEffect(() => {
    setOptionValue(value);
  }, [value]);

  /**
   * Add option (question or answer) handler
   */
  const addOption = () => {
    setOption(listName, {
      id: Math.floor(Math.random() * 1e6),
      body: optionValue,
      type,
    });

    setOptionValue('');
  };

  return (
    <Form.Item label={type} style={itemStyle}>
      <Input value={optionValue} onChange={onChange} />
      <Button onClick={addOption}>
        {strings.add}&nbsp;{type}
      </Button>
    </Form.Item>
  );
};

MatchCreateOption.propTypes = {
  value: PropTypes.string.isRequired,
  setOption: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  listName: PropTypes.string.isRequired,
};

export default MatchCreateOption;
