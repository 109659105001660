import gql from 'graphql-tag';

export const PUBLISH_COURSE = gql`
  mutation publishCourse($ids: [ID!]!) {
    publishCourse(ids: $ids) {
      statusCode
      message
      errors {
        field_name
        message
      }
    }
  }
`;
