import gql from 'graphql-tag';

export const TOGGLE_BLOCK_USER = gql`
  mutation toggleUserStatus($id: ID!, $blocking_reason: String) {
    toggleUserStatus(id: $id, blocking_reason: $blocking_reason) {
      statusCode
      errors {
        message
        field_name
      }
    }
  }
`;
