// handle construct server side validation errors object
export const constructErrors = errors => {
  let parsedErrors = {};
  errors = errors.forEach(error => {
    const fieldName = error.fieldName;
    parsedErrors[fieldName] = error.errors;
  });
  return parsedErrors;
};

/** handle map server validation errors to form fields */
export function mapErrorsToFields(props) {
  let obj = {};
  const { errors, form } = props;
  if (!errors) {
    return;
  }
  Object.keys(errors).forEach(key => {
    // return object with input name as key and value object of input value and server validation errors
    obj[key] = {
      value: form.getFieldValue(key),
      errors: [new Error(errors[key].join())],
    };
  });
  form.setFields(obj);
}

/** modifying input names in course content form
 * by removing (_) in values object keys
 */
export const modifyInputNames = values => {
  let newObj = {};
  Object.keys(values).map(key => {
    if (key.includes('_')) {
      var newKey = key.split('_')[1];
      newObj[newKey] = values[key];
    } else {
      newObj[key] = values[key];
    }
    if (typeof values[key] === 'object') {
      newObj[key] = modifyInputNames(values[key]);
    }
    return false;
  });
  return newObj;
};

/** convert object item value to array */
export const objectToArray = obj => {
  if (typeof obj !== 'object') {
    return;
  }
  let arr = Array.from(Object.values(obj));
  arr.map(item => {
    if (typeof item === 'object') {
      Object.keys(item).map(key => {
        if (typeof item[key] === 'object') {
          item[key] = objectToArray(item[key]);
        }
        return item[key];
      });
    }
    return item;
  });
  return arr;
};

/**
 * return reversed array
 *
 * @param {Array} arr
 */
export const reverseArray = arr => {
  return arr.reverse();
};

/** flatten form errors object */
const flattenErrorsObj = errorsObj => {
  const flattenObj = {};
  Object.keys(errorsObj).forEach(key => {
    if (typeof errorsObj[key] === 'object' && errorsObj[key] !== null) {
      Object.assign(flattenObj, flattenErrorsObj(errorsObj[key]));
    } else {
      flattenObj[key] = errorsObj[key];
    }
  });
  return flattenObj;
};

export const hasErrors = fieldsError => {
  const flattenObj = flattenErrorsObj(fieldsError);
  return Object.keys(flattenObj).some(field => flattenObj[field]);
};

/**
 * remove object attrs if value === null or empty string
 *
 * @param {Object} obj
 */
export const cleanObj = obj => {
  Object.keys(obj).forEach(key => {
    const emptyArr = Array.isArray(obj[key]) && obj[key].length === 0;
    if (obj[key] && typeof obj[key] === 'object' && !Array.isArray(obj[key]))
      cleanObj(obj[key]);
    else if (obj[key] == null || obj[key] === '' || emptyArr) {
      delete obj[key];
    }
  });

  return obj;
};

/**
 * check if all values in form are empty
 *
 * @param {Object} values
 */
export const isEmptyForm = values => {
  const valuesArr = Object.values(values);

  const isArray = el => {
    return Array.isArray(el);
  };

  const isObject = el => {
    return typeof el === 'object' && !Array.isArray(el) && el !== null;
  };

  for (let i = 0; i < valuesArr.length; i++) {
    if (valuesArr[i] && !isArray(valuesArr[i]) && !isObject(valuesArr[i])) {
      return false;
    }

    if (valuesArr[i] && isArray(valuesArr[i])) {
      if (valuesArr[i].length && !valuesArr[i].includes(undefined)) {
        return false;
      }
    }

    if (isObject(valuesArr[i])) {
      isEmptyForm(valuesArr[i]);
    }
  }

  return true;
};
