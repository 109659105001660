import gql from 'graphql-tag';

export const LIST_PROVIDERS = gql`
  query listProviders($first: Int!, $after: String) {
    listProviders(first: $first, after: $after) {
      edges {
        node {
          id
          name
          logo {
            url
          }
          banner {
            url
          }
          is_active
          courses_count
          admins_count
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;
