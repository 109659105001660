import React, { useState, useContext } from 'react';
import { Select } from 'antd';
import { useUserAnalytics } from '@hooks';
import { AnalyticsContext } from './analytics-context';
import numeral from 'numeral';
import { userRoles, selectedRoleOptions } from '@utilities';

const AgeDistribuationCard = () => {
  const analyticsContext = useContext(AnalyticsContext);
  const [selectedUserRole, setSelectedUserRole] = useState(userRoles.parent);

  const { analytics, error, loading } = useUserAnalytics(
    analyticsContext?.currentPeriod?.from,
    analyticsContext?.currentPeriod?.to,
    selectedUserRole
  );

  const { Option } = Select;

  function getAgeDistribuationPerRole() {
    let ageDistribuationData = [];
    let gradeDistribuationData = [];
    for (let key in analytics?.age_distribution) {
      if (key.includes('grade_')) {
        gradeDistribuationData.push({
          label: key,
          value: numeral(analytics?.age_distribution[key]).format('0a'),
          percentage:
            (analytics?.age_distribution[key] /
              analytics?.all_time_age_distribution[key]) *
            100,
        });
      } else if (typeof analytics?.age_distribution[key] === 'number') {
        ageDistribuationData.push({
          label: key,
          value: numeral(analytics?.age_distribution[key]).format('0a'),
          percentage:
            (analytics?.age_distribution[key] /
              analytics?.all_time_age_distribution[key]) *
            100,
        });
      }
    }
    return selectedUserRole === userRoles.student ||
      selectedUserRole === userRoles.child
      ? gradeDistribuationData
      : ageDistribuationData;
  }
  const personaAgeDistribuation = getAgeDistribuationPerRole();

  function handleChange(value) {
    setSelectedUserRole(value);
  }

  return (
    <div className="total-card total-performance-card">
      <div className="card_filter-title-wrapper">
        <h3 className="title">Age Distribution Per Role</h3>
        <Select defaultValue={userRoles.parent} onChange={handleChange}>
          {selectedRoleOptions?.map((option, index) => {
            return (
              <Option key={index} value={option?.value}>
                {option?.label}
              </Option>
            );
          })}
        </Select>
      </div>
      <div className="content-wrapper">
        {personaAgeDistribuation.length ? (
          personaAgeDistribuation.map((role, i) => {
            return (
              <div key={i} className="age_distribuation-progress">
                <p>{role.label}</p>
                <div className="age-role-progressbar">
                  <div
                    style={{ width: `${role.percentage}%`, opacity: 1 }}
                    className="progressbar"
                  ></div>
                </div>
                <span>{role.value}</span>
              </div>
            );
          })
        ) : (
          <p>No data for this persona</p>
        )}
      </div>
    </div>
  );
};

export default AgeDistribuationCard;
