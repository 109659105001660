import React from 'react';
import { FormItem } from '@components';
import PropTypes from 'prop-types';

const SearchFilter = ({
  inputName,
  label,
  form,
  onChange,
  validations = [],
  initialValue,
  placeholder,
}) => {
  return (
    <FormItem
      inputName={inputName}
      inputLabel={label}
      placeHolder={placeholder}
      validationRules={validations}
      initialValue={initialValue}
      onChange={onChange}
      form={form}
    />
  );
};

SearchFilter.propTypes = {
  inputName: PropTypes.string,
  label: PropTypes.string,
  initialValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  validations: PropTypes.array,
  placeholder: PropTypes.string,

  form: PropTypes.shape({
    /** getFieldDecorator prop of type function passed from antd form to get each field decorator  */
    getFieldDecorator: PropTypes.func,
    /** setFieldsValue prop of type function passed form ant d form to set field value by it's name */
    setFieldsValue: PropTypes.func,
    /** validateFields prop of type function passed from antd form to run its validations  */
    validateFields: PropTypes.func,
  }).isRequired,
};

export default SearchFilter;
