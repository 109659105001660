import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { en as strings } from './locales/data.json';

/** styling of courses list pagination buttons wrapper */
const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding: 30px 0;
  width: 50%;
  margin: 0 auto;
`;

/** styling of courses list pagination button */
const PaginationButton = styled.button`
  font-weight: 300;
  font-size: 15px;
  padding: 3px 12px;
  background: none;
  border: 0;
  cursor: pointer;

  &:active,
  &:focus {
    outline: none;
    border: 0;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
`;

const CursorPagination = ({ pageInfo, fetchMore }) => {
  const [after, updateAfter] = useState('');
  const [before, updateBefore] = useState('');
  const [hasPreviousPage, updatePrevStatus] = useState(false);
  const [hasNextPage, updateNextStatus] = useState(false);

  useEffect(() => {
    const { endCursor, startCursor, hasPreviousPage, hasNextPage } = pageInfo;

    updateAfter(endCursor);
    updateBefore(startCursor);
    updatePrevStatus(hasPreviousPage);
    updateNextStatus(hasNextPage);
  }, [pageInfo]);

  const hasPagination = hasPreviousPage || hasNextPage;

  return (
    <>
      {hasPagination && (
        <PaginationWrapper>
          <PaginationButton
            disabled={!hasPreviousPage}
            onClick={() => fetchMore({ before }, 'last')}
          >
            {strings.prev}
          </PaginationButton>
          <PaginationButton
            disabled={!hasNextPage}
            onClick={() => fetchMore({ after }, 'first')}
          >
            {strings.next}
          </PaginationButton>
        </PaginationWrapper>
      )}
    </>
  );
};

CursorPagination.propTypes = {
  /**
   * fetchMore prop of type function passed from parent component
   * for handling fetching prev or next courses list
   */
  fetchMore: PropTypes.func,
  /**
   * pageInfo prop of type object passed from parent component
   */
  pageInfo: PropTypes.shape({
    /** startCursor prop of type string for courses list first item's cursor id */
    startCursor: PropTypes.string,
    /** endCursor prop of type string for courses list last item's cursor id */
    endCursor: PropTypes.string,
    /** hasNextPage prop of type boolean for current paginated list has next nodes */
    hasNextPage: PropTypes.bool,
    /** hasPreviousPage prop of type boolean for current paginated list has prev nodes */
    hasPreviousPage: PropTypes.bool,
  }),
};

export default CursorPagination;
