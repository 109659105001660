import gql from 'graphql-tag';

export const COMMENTS_LIST = gql`
  query listComments(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $sortBy: String
    $sortType: String
    $partialUserName: String
    $partialComment: String
    $personas: [String!]
    $courses: [ID!]
    $statuses: [String!]
    $createdAtFrom: String
    $createdAtTo: String
  ) {
    listAllComments(
      first: $first
      after: $after
      last: $last
      before: $before
      sortBy: $sortBy
      sortType: $sortType
      partialUserName: $partialUserName
      partialComment: $partialComment
      personas: $personas
      courses: $courses
      statuses: $statuses
      createdAtFrom: $createdAtFrom
      createdAtTo: $createdAtTo
    ) {
      nodes {
        id
        comment
        courseName
        createdAt
        crumbs
        persona
        phone
        status
        userId
        userName
        userStatus
        isAdmin
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export default COMMENTS_LIST;
