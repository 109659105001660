import React, { useRef, useEffect, useState } from 'react';
import { getPieChart } from '@utilities/charts';
import ChartLegend from './chart-legend';
import Proptypes from 'prop-types';

const PieChartCard = ({ label, roles }) => {
  const canvasRef = useRef();
  const [chart, setChart] = useState(undefined);

  const backgroundColors = ['#f1c40f', '#e67e22', '#16a085', '#2980b9'];

  useEffect(() => {
    if (chart) {
      chart.destroy();
    }
    if (canvasRef?.current) setChart(getPieChart(canvasRef.current, roles));
  }, []);

  const totalRolesSum = Object.values(roles)
    .filter(_role => typeof _role === 'number')
    .reduce((a, b) => a + b);

  const percentageOfEachRole = [];
  for (let role in roles) {
    let percentage = ((+roles[role] / +totalRolesSum) * 100).toFixed();
    if (typeof roles[role] === 'number' && percentage !== 'NaN') {
      percentageOfEachRole.push({
        type: role,
        value: percentage,
      });
    }
  }

  return (
    <div className="pie-chart-card">
      <h1 className="label">{label}</h1>
      {percentageOfEachRole?.length > 0 ? (
        <div className="pie-chart-container">
          <div className="chart-container">
            <canvas ref={canvasRef} id={Date.now()} />
          </div>
          <div className="chart-info">
            {percentageOfEachRole.map((_role, i) => {
              return (
                <ChartLegend
                  key={i}
                  label={_role.type}
                  color={backgroundColors[i]}
                  value={+_role.value}
                />
              );
            })}
          </div>
        </div>
      ) : (
        <div>No data for this period</div>
      )}
    </div>
  );
};
export default PieChartCard;

PieChartCard.propTypes = {
  label: Proptypes.string,
  roles: Proptypes.object,
};
