import React from 'react';
import { Redirect } from 'react-router-dom';
import { DynamicLoading } from '@components';
import { AuthConsumer, Can } from '@auth';
import { ACTIONS } from '../../rbac_rules';

/** exported dynamic import for users list scene */
const Users = props => {
  return (
    <AuthConsumer>
      {({ role }) => (
        <Can
          role={role}
          perform={ACTIONS.SHOW_USERS_LIST}
          yes={() => (
            <DynamicLoading
              data={{ ...props }}
              componentImport={() => import(`./users_wrapper`)}
            />
          )}
          no={() => {
            return <Redirect to="/" />;
          }}
        />
      )}
    </AuthConsumer>
  );
};

export default Users;
