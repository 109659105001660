import gql from 'graphql-tag';

export const UnitContentQuery = gql`
  query courseItems($id: ID!) {
    courseItems(id: $id) {
      itemType
      name
      content
      contentType
      description
      videoUrl
    }
  }
`;
