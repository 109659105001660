import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import { UploadFileWrapper } from '../styles/course_content_styles';
import { ErrorMsgWrapper } from './styles/new_unit_styles';
import { UNIT_TYPES, NEW_UNIT_SCHEMA } from '@utilities';
import { notification } from 'antd';

export default function ScormTypeContent(props) {
  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState(false);

  const onSubmit = values => {
    setDisabledSubmitBtn(true);

    const unitContent = {
      name: values.name,
      course_id: props.courseId,
      lesson_id: props.lessonId,
      content: {
        type: UNIT_TYPES.SCORM,
        file: values.file,
      },
    };

    const updatedUnitContent = {
      id: props.unitDetails?.id,
      name: values.name,
      content: {
        type: UNIT_TYPES.SCORM,
        file: values.file,
      },
    };

    if (!props.unitDetails?.id) {
      props
        .createCourseUnit({
          variables: {
            content: unitContent,
          },
        })
        .then(({ data }) => {
          const { errors } = data?.createCourseUnit;
          if (!errors) {
            notification.success({
              message: 'Success',
              description: 'Unit created successfully',
            });
          }
          setDisabledSubmitBtn(false);
          // navigateToNextItem();
        })
        .catch(errors => {
          console.error(errors);
        });
    } else {
      props
        .updateCourseUnit({
          variables: {
            content: updatedUnitContent,
          },
        })
        .then(({ data }) => {
          const { errors } = data?.updateCourseUnit;
          if (!errors) {
            notification.success({
              message: 'Success',
              description: 'Unit updated successfully',
            });
          }
          setDisabledSubmitBtn(false);
          // navigateToNextItem();
        })
        .catch(errors => {
          console.error(errors);
        });
    }
  };

  let initialValues = {
    name:
      props.unitDetails?.content[0]?.type === UNIT_TYPES.SCORM
        ? props.unitDetails?.name
        : '',
    file: '',
    type: UNIT_TYPES.SCORM,
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={NEW_UNIT_SCHEMA}
      enableReinitialize={true}
    >
      {({ setFieldValue }) => {
        return (
          <Form>
            <div className="form-control unit-title">
              <label htmlFor="name">Unit Name</label>
              <Field type="text" name="name" id="name" />
              <ErrorMessage name="name" component={ErrorMsgWrapper} />
            </div>
            <div className="form-control">
              <div className="scorm-file-wrapper file-input-wrapper">
                <label> Scorm File </label>
                <UploadFileWrapper>
                  <input
                    type="file"
                    name="file"
                    id="uploadFile"
                    onChange={event => {
                      const file = event.target.files[0];
                      // only run the validation once after the file is selected.
                      setFieldValue('file', file, true);
                    }}
                  />
                  <div>
                    Hint: only supported versions of scorm files is{' '}
                    <b>(1.2, 2004 2nd edition)</b>
                  </div>
                  <ErrorMessage name="file" component={ErrorMsgWrapper} />
                </UploadFileWrapper>
              </div>
            </div>
            <div className="actions-btns">
              <button
                type="submit"
                className={`${disabledSubmitBtn && 'disabled'}`}
                disabled={disabledSubmitBtn}
              >
                Save
              </button>
              <button
                className="delete-unit"
                type="button"
                disabled={!props.canDeleteUnit}
                onClick={props.onDeleteUnit}
              >
                Delete Unit
              </button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

ScormTypeContent.propTypes = {
  courseId: PropTypes.string,
  lessonId: PropTypes.string,
  createCourseUnit: PropTypes.func,
  updateCourseUnit: PropTypes.func,
  unitDetails: PropTypes.object,
  canDeleteUnit: PropTypes.bool,
  onDeleteUnit: PropTypes.func,
};
