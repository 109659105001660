import { useQuery } from '@apollo/client';
import { getProviderAnalyticsQuery } from '@services';

export const useProviderAnalytics = (from, to) => {
  const { loading, error, data } = useQuery(getProviderAnalyticsQuery, {
    variables: {
      from,
      to,
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const analytics = data?.getProvidersAnalytics;

  return { analytics, loading, error };
};
