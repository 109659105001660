import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { WysiWygEditorFormik } from '@components';
import PropTypes from 'prop-types';
import {
  SearchbarWrapper,
  UploadFileWrapper,
} from '../styles/course_content_styles';
import { ErrorMsgWrapper } from './styles/new_unit_styles';
import { UNIT_TYPES, NEW_UNIT_SCHEMA } from '@utilities';
import { notification } from 'antd';

export default function VideoTypeContent(props) {
  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState(false);

  const onSubmit = values => {
    setDisabledSubmitBtn(true);

    const unitContent = {
      name: values.name,
      course_id: props.courseId,
      lesson_id: props.lessonId,
      content: {
        type: props.unitType,
        body: values.body,
      },
    };
    const updatedUnitContent = {
      id: props.unitDetails.id,
      name: values.name,
      content: {
        type: props.unitType,
        body: values.body,
      },
    };
    switch (values.selectedVideoType) {
      case 'video_url':
        !props.unitDetails.id
          ? (unitContent.content.video_url = values.video_url)
          : (updatedUnitContent.content.video_url = values.video_url);
        break;
      case 'file':
        !props.unitDetails.id
          ? (unitContent.content.file = values.file)
          : (updatedUnitContent.content.file = values.file);
        break;
      default:
        console.log('default', values.selectedVideoType);
        break;
    }

    if (!props.unitDetails.id) {
      props
        .createCourseUnit({
          variables: {
            content: unitContent,
          },
        })
        .then(({ data }) => {
          const { errors } = data?.createCourseUnit;
          if (!errors) {
            notification.success({
              message: 'Success',
              description: 'Unit created successfully',
            });
          }
          setDisabledSubmitBtn(false);
          // navigateToNextItem();
        })
        .catch(errors => {
          console.error(errors);
        });
    } else {
      props
        .updateCourseUnit({
          variables: {
            content: updatedUnitContent,
          },
        })
        .then(({ data }) => {
          const { errors } = data?.updateCourseUnit;
          if (!errors) {
            notification.success({
              message: 'Success',
              description: 'Unit updated successfully',
            });
          }
          setDisabledSubmitBtn(false);
          // navigateToNextItem();
        })
        .catch(errors => {
          console.error(errors);
        });
    }
  };

  let videoUrl = '';
  if (
    props.unitDetails?.content[0]?.type === props.unitType &&
    props.unitDetails.content[0]?.media[0]
  ) {
    videoUrl = props.unitDetails.content[0]?.media[0]?.url;
  }

  let initialValues = {
    name:
      props.unitDetails?.content[0]?.type === props.unitType
        ? props.unitDetails?.name
        : '',
    body:
      props.unitDetails?.content[0]?.type === props.unitType
        ? props.unitDetails.content[0]?.body
        : '',
    type: props.unitType,
    selectedVideoType: 'video_url',
    file: '',
    video_url: videoUrl,
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={NEW_UNIT_SCHEMA}
      enableReinitialize={true}
    >
      {({ values, setFieldValue, setTouched }) => {
        return (
          <Form>
            <div className="form-control unit-title">
              <label htmlFor="name">Unit Name</label>
              <Field type="text" name="name" id="name" />
              <ErrorMessage name="name" component={ErrorMsgWrapper} />
            </div>
            <div className="form-control" role="group">
              <div className="link-input-wrapper">
                <label>
                  <Field
                    type="radio"
                    name="selectedVideoType"
                    value="video_url"
                  />
                  Video URL
                </label>
                <SearchbarWrapper>
                  <div className="input">
                    <Field
                      type="search"
                      placeholder="Search for or paste the link for the video"
                      name="video_url"
                    />
                    <button>Search</button>
                  </div>
                  <ErrorMessage name="video_url" component={ErrorMsgWrapper} />
                </SearchbarWrapper>
              </div>
              {props.unitType !== UNIT_TYPES.ALMENTOR_VIDEO && (
                <div className="file-input-wrapper">
                  <label>
                    <Field type="radio" name="selectedVideoType" value="file" />
                    Upload Video File
                  </label>
                  <UploadFileWrapper>
                    <input
                      type="file"
                      name="file"
                      accept="video/mp4,video/x-m4v,video/*"
                      id="uploadFile"
                      onChange={event => {
                        const file = event.target.files[0];
                        // only run the validation once after the file is selected.
                        setFieldValue('file', file, true);
                      }}
                    />
                    <ErrorMessage name="file" component={ErrorMsgWrapper} />
                  </UploadFileWrapper>
                </div>
              )}

              <Field
                name="body"
                id="body"
                component={WysiWygEditorFormik}
                placeholder="Add unit description"
              />
              <div className="actions-btns">
                <button
                  type="submit"
                  className={`${disabledSubmitBtn && 'disabled'}`}
                  disabled={disabledSubmitBtn}
                >
                  Save
                </button>
                <button
                  className="delete-unit"
                  type="button"
                  disabled={!props.canDeleteUnit}
                  onClick={props.onDeleteUnit}
                >
                  Delete Unit
                </button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

VideoTypeContent.propTypes = {
  courseId: PropTypes.string,
  lessonId: PropTypes.string,
  unitDetails: PropTypes.object,
  unitType: PropTypes.string,
  canDeleteUnit: PropTypes.bool,
  createCourseUnit: PropTypes.func,
  updateCourseUnit: PropTypes.func,
  onDeleteUnit: PropTypes.func,
};
